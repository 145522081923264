/* eslint-disable no-prototype-builtins */
import {
  Grid,
  Div,
  InputCard,
  Row,
  RegistrationSession,
  RegistrationButtonBox,
} from 'components';
import React, { useEffect, useState } from 'react';

export function RegistrationList({
  readOnly,
  moduleTop,
  mModuleTop,
  backgroundColor,
  designType,
  isEdit,
  confirmType,
  isSubmit,
  isCompleted,
  isTemporary,
  targetSession,
  changeTargetSession,
  sessionList,
  invalids,
  values,
  etcValues,
  setEtcValues,
  onChange,
  onEtcChange,
  onMutipleChange,
  onCheckChange,
  activeSession,
  confirm,
  cancel,
  tempConfirm,
  getEventPaymentCode,
  setPassCodeAlert,
  setEventpaymentWithPassCodeAlert,
}) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [row, setRow] = useState();
  const [totalRow, setTotalRow] = useState();

  // 주어진 두 수의 최대공약수를 계산하는 함수
  function findGreatestCommonDivisor(a, b) {
    while (b !== 0) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  }

  // 주어진 두 수의 최소공배수를 계산하는 함수
  function findLeastCommonMultipleOfTwo(a, b) {
    return (a * b) / findGreatestCommonDivisor(a, b);
  }

  // 주어진 여러 수의 최소공배수를 계산하는 함수
  function findLeastCommonMultipleOfMultipleNumbers(numbers) {
    let lcm = numbers[0];

    for (let i = 1; i < numbers.length; i += 1) {
      lcm = findLeastCommonMultipleOfTwo(lcm, numbers[i]);
    }

    return lcm;
  }

  useEffect(() => {
    if (sessionList && targetSession) {
      const currentList = sessionList?.find(
        (session) => session?.sessionCode === targetSession?.sessionCode,
      )?.itemList;
      let tempRow = {};
      let loop = false;
      let totalArray = [];
      if (currentList) {
        currentList?.forEach((el, index) => {
          // 코드 순서 변경 하지 말것
          if (el.itemType === 'rowEnd') {
            loop = false;
          }
          if (loop || loop === 0) {
            tempRow = { ...tempRow, [index]: currentList[loop].totalRows };
          }
          if (el.itemType === 'rowStart') {
            loop = index;
            totalArray = [...totalArray, el.totalRows];
          }
        });
        const total = findLeastCommonMultipleOfMultipleNumbers(totalArray);
        setTotalRow(total || 1);
        setRow(tempRow);
        setList([...currentList]);
      }
      setLoading(false);
    }
  }, [sessionList, targetSession]);

  return (
    <Row
      margin="0 auto"
      gap={40}
      alignItems="flex-start"
      mFlexDirection="column"
    >
      <RegistrationSession
        mBackgroundColor={
          backgroundColor || 'backgroundColor' || 'layeredBackgroundColor'
        }
        top={moduleTop}
        mTop={mModuleTop}
        isSubmit={isSubmit}
        sessionList={sessionList}
        targetSession={targetSession}
        changeTargetSession={changeTargetSession}
        activeSession={activeSession}
      />
      {!loading && (
        <Grid
          width="100%"
          margin="0 auto"
          maxWidth={920}
          minWidth={820}
          mMinWidth="unset"
          cols={totalRow}
          colGap={20}
          rowGap={40}
        >
          {list?.map(
            (item, itemIndex) =>
              !item.itemType.includes('row') && (
                <Div
                  key={item.itemCode}
                  style={{
                    gridColumn:
                      (item.direction.includes('row') &&
                        `auto / span ${totalRow / row[itemIndex]}`) ||
                      `auto / span ${totalRow}`,
                  }}
                  mMaxWidth="calc(100vw - 40px)"
                >
                  <InputCard
                    designType={designType}
                    isTemporary={isTemporary}
                    disabled={isSubmit}
                    invalid={
                      (item.isRequired === 1 &&
                        invalids &&
                        invalids[item.itemCode]) ||
                      ''
                    }
                    value={
                      (item.itemType.includes('multiple') &&
                        values[item.itemCode]) ||
                      (values[item.itemCode] && values[item.itemCode][0]) ||
                      ''
                    }
                    initValue={
                      (item.itemType.includes('multiple') && item.answer) ||
                      (item.answer && item.answer[0]) ||
                      ''
                    }
                    etcValues={etcValues}
                    setEtcValues={setEtcValues}
                    name={item.itemCode}
                    onChange={onChange}
                    onEtcChange={onEtcChange}
                    onMutipleChange={onMutipleChange}
                    onCheckChange={onCheckChange}
                    item={item}
                    isRegistration
                  />
                  {values[item.itemCode] &&
                    values[item.itemCode][0] &&
                    item.childList[
                      values[item.itemCode][0]?.countryCode ||
                        values[item.itemCode][0]?.selectionCode
                    ]?.map((childItem) => (
                      <InputCard
                        designType={designType}
                        isTemporary={isTemporary}
                        disabled={isSubmit}
                        key={childItem.itemCode}
                        invalid={
                          (childItem.isRequired === 1 &&
                            invalids &&
                            invalids[childItem.itemCode]) ||
                          ''
                        }
                        initValue={
                          (childItem.itemType.includes('multiple') &&
                            childItem.answer) ||
                          (childItem.answer && childItem.answer[0]) ||
                          ''
                        }
                        value={
                          (childItem.itemType.includes('multiple') &&
                            values[childItem.itemCode]) ||
                          (values[childItem.itemCode] &&
                            values[childItem.itemCode][0]) ||
                          ''
                        }
                        etcValues={etcValues}
                        setEtcValues={setEtcValues}
                        name={childItem.itemCode}
                        onChange={onChange}
                        onEtcChange={onEtcChange}
                        onMutipleChange={onMutipleChange}
                        onCheckChange={onCheckChange}
                        item={childItem}
                        isRegistration
                        mt={20}
                      >
                        {childItem?.childList &&
                          Object.keys(childItem?.childList).length > 0 &&
                          values[childItem.itemCode] &&
                          values[childItem.itemCode][0] &&
                          childItem.childList[
                            values[childItem.itemCode][0]?.countryCode ||
                              values[childItem.itemCode][0].selectionCode
                          ]?.map((grandChildItem) => (
                            <InputCard
                              designType={designType}
                              isTemporary={isTemporary}
                              disabled={isSubmit}
                              key={grandChildItem.itemCode}
                              invalid={
                                (grandChildItem.isRequired === 1 &&
                                  invalids &&
                                  invalids[grandChildItem.itemCode]) ||
                                ''
                              }
                              initValue={
                                (grandChildItem.itemType.includes('multiple') &&
                                  grandChildItem.answer) ||
                                (grandChildItem.answer &&
                                  grandChildItem.answer[0]) ||
                                ''
                              }
                              value={
                                (grandChildItem.itemType.includes('multiple') &&
                                  values[grandChildItem.itemCode]) ||
                                (values[grandChildItem.itemCode] &&
                                  values[grandChildItem.itemCode][0]) ||
                                ''
                              }
                              etcValues={etcValues}
                              setEtcValues={setEtcValues}
                              name={grandChildItem.itemCode}
                              onChange={onChange}
                              onEtcChange={onEtcChange}
                              onMutipleChange={onMutipleChange}
                              onCheckChange={onCheckChange}
                              item={grandChildItem}
                              isRegistration
                              mt={20}
                            />
                          ))}
                      </InputCard>
                    ))}
                </Div>
              ),
          )}
          <RegistrationButtonBox
            isEdit={isEdit}
            isSubmit={isSubmit}
            isCompleted={isCompleted}
            cancel={cancel}
            confirmType={confirmType}
            tempConfirm={tempConfirm}
            sessionList={sessionList}
            targetSession={targetSession}
            changeTargetSession={changeTargetSession}
            getEventPaymentCode={getEventPaymentCode}
            setEventpaymentWithPassCodeAlert={setEventpaymentWithPassCodeAlert}
            setPassCodeAlert={setPassCodeAlert}
            confirm={confirm}
            style={{
              gridColumn: `span ${totalRow}`,
            }}
          />
        </Grid>
      )}
    </Row>
  );
}
