import React, { Suspense, lazy } from 'react';
import { Skeleton } from 'components';

const CardComponent = lazy(() => import('./CardComponent'));
const CardComponentB = lazy(() => import('./CardComponentB'));

const renderLoader = () => (
  <Skeleton
    // width="max(100%, 295px)"
    height={220}
  />
);

export function StreamingCard({ type, setRef, item, user, ...props }) {
  return (
    <Suspense fallback={renderLoader()}>
      {type === 'list1' && (
        <CardComponent setRef={setRef} item={item} user={user} {...props} />
      )}
      {type === 'list2' && (
        <CardComponentB setRef={setRef} item={item} user={user} {...props} />
      )}
    </Suspense>
  );
}
