import { Div, Icon, Row, StreamingCard } from 'components';
import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

export function PlayList({ list, user, changeStreaming }) {
  const scrollRef = useRef();
  const [swiper, setSwiper] = useState();

  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (scrollRef.current && list) {
      const scrollElem = document.getElementById('playlist');
      const overflowContent = scrollElem.clientWidth < scrollElem.scrollWidth;
      setIsScroll(overflowContent);
    }
  }, [scrollRef, list]);

  return (
    <Div mt={10} pt={20} padding="20px 20px 0" borderTop={{ color: 'g200' }}>
      <Swiper
        id="playlist"
        ref={scrollRef}
        onSwiper={(swiper) => setSwiper(swiper)}
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          720: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        }}
      >
        {list?.map((item, index) => (
          <SwiperSlide key={item.itemCode} style={{ width: 'auto' }}>
            <StreamingCard
              type="list1"
              mMr={(list?.length - 1 === index && 20) || ''}
              className={index}
              user={user}
              key={item.itemCode}
              item={item}
              onClick={() => changeStreaming(item)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {isScroll && (
        <Row gap={4} mt={10} justifyContent="flex-end" padding="0 20px">
          <Icon
            name="arrow"
            fill="keyColor"
            border={{ color: 'keyColor' }}
            backgroundColor="key100"
            borderRadius="999px"
            padding="3px"
            size={24}
            backdropFilter="blur(10px)"
            hover
            cursor="pointer"
            transform="rotate(90deg)"
            justifyContent="center"
            alignItems="center"
            onClick={() => swiper.slidePrev()}
          />
          <Icon
            name="arrow"
            fill="keyColor"
            border={{ color: 'keyColor' }}
            backgroundColor="key100"
            borderRadius="999px"
            padding="3px"
            size={24}
            backdropFilter="blur(10px)"
            hover
            cursor="pointer"
            transform="rotate(-90deg)"
            justifyContent="center"
            alignItems="center"
            onClick={() => swiper.slideNext()}
          />
        </Row>
      )}
    </Div>
  );
}
