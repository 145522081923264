import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InputStyles } from 'styles/InputStyles';
import { Div, Row, Icon, Text, Invalid } from 'components';
import { DEFAULT_INVALID_KEYS, NUM_REG } from 'utils/invalids';
import { useTranslation } from 'react-i18next';

const DEFAULT_PADDING = 16 + 21;

export function SimpleInput({
  setRef,
  type,
  label,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  maxLength,
  position,
  wrapStyles,
  invalid,
  ...props
}) {
  const { t } = useTranslation();
  const [defaultInvalid, setDefaultInvalid] = useState();
  const onKeyDownHandler = (event) => {
    if (onKeyDown && onKeyDownActive) {
      if (event.key === 'Enter') {
        onKeyDown();
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (Object.keys(DEFAULT_INVALID_KEYS).includes(name) || type === 'tel') {
      if (value && !value.match(DEFAULT_INVALID_KEYS[name])) {
        // 정규식 통과 실패
        setDefaultInvalid({ type: 'error', text: t('invalid_email') });
      } else if (value && type === 'tel' && !value.match(NUM_REG)) {
        setDefaultInvalid({ type: 'error', text: t('invalid_number') });
      } else {
        // 정규식을 통과했거나 value 값이 초기화 됐을때
        setDefaultInvalid();
      }
    }
    const tempValue = (type === 'tel' && value.replaceAll(/\D/g, '')) || value;
    onChange(tempValue, name);
  };

  const deleteValue = () => {
    onChange('', name);
  };

  const invalidResult = useMemo(
    () => invalid || defaultInvalid,
    [invalid, defaultInvalid],
  );

  return (
    <Div {...wrapStyles}>
      {label && (
        <Text type="b3" mb={4}>
          {label}
        </Text>
      )}
      <Div position={position || 'relative'}>
        <InputStyles
          ref={setRef}
          name={name}
          value={value}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          invalid={invalidResult?.type}
          pr={DEFAULT_PADDING}
          {...props}
        />
        <Row
          className="input-button"
          position="absolute"
          right="10px"
          top="50%"
          transform="translateY(-50%)"
          zIndex="0"
          alignItems="center"
          gap={6}
        >
          <Icon
            visibility={(value && 'visible') || 'hidden'}
            className="input-delete"
            size={21}
            name="delete"
            cursor="pointer"
            onMouseDown={deleteValue}
          />
        </Row>
      </Div>
      <Invalid invalid={invalidResult} />
    </Div>
  );
}
