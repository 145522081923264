import { authApi } from 'api';
import { Div, Input, Button } from 'components';
import { useApiParams } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/encrypt';

export function ChangepasswordVerified({
  loginMethod,
  loginToMain,
  setAlert,
  isUsedSignUpBtn,
  ...props
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { DEFAULT_PARAMS } = useApiParams();

  const [confirmCode, setConfirmCode] = useState({});
  const [password, setPassword] = useState();
  const [passwordInvalid, setPasswordInvalid] = useState({
    type: '',
    text: '',
  });
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] = useState({
    type: '',
    text: '',
  });
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const confirm = async () => {
    const [info] = await authApi.confirmPasswordCode({
      ...DEFAULT_PARAMS,
    });
    if (info.resCode === 200) {
      setConfirmCode(info.result.confirmCode);
    }
  };

  useEffect(() => {
    if (DEFAULT_PARAMS) {
      confirm();
    }
  }, []);

  const onChange = (value) => {
    if (value) {
      setPassword(value);

      setPasswordInvalid({ type: '', text: '' });
    } else if (!value) {
      setPasswordInvalid({ type: 'error', text: t('password_placeholder') });
    }
  };

  useEffect(() => {
    if (passwordConfirm && passwordConfirm !== password) {
      setPasswordConfirmInvalid({ type: 'error', text: t('invalid_password') });
    } else {
      setPasswordConfirmInvalid({ type: '', text: '' });
    }
  }, [password, passwordConfirm]);

  const active = useMemo(() => {
    if (!password || !passwordConfirm) return false;
    if (passwordConfirm !== password) return false;

    return true;
  }, [password, passwordConfirm]);

  const [loading, setLoading] = useState(false);
  const change = async () => {
    if (active) {
      setLoading(true);
      const [info, error] = await authApi.changePassword({
        ...DEFAULT_PARAMS,
        confirmCode,
        password: encrypt(password),
      });
      if (info) {
        setAlert({
          title: t('alert_title'),
          content: t('success_changepassword'),
          confirm: () => navigate(-1),
        });
      } else if (error?.resCode === 452) {
        setPasswordInvalid({ type: 'error', text: t('password_placeholder') });
        setLoading(false);
      } else if (error) {
        setAlert({
          title: t('alert_title'),
          content: t('fail_changepassword'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      }
    }
  };

  return (
    <Div>
      <>
        <Input
          type="password"
          label={t('password')}
          placeholder={t('password_placeholder')}
          wrapStyles={{ mt: 10 }}
          name="password"
          onChange={onChange}
          value={password || ''}
          invalid={passwordInvalid}
          disabled={loading}
          onKeyDown={change}
          onKeyDownActive={active}
        />
        <Input
          type="password"
          label={t('password_confirm')}
          placeholder={t('password_confirm_placeholder')}
          wrapStyles={{ mt: 10 }}
          name="password"
          onChange={(value) => setPasswordConfirm(value)}
          value={passwordConfirm || ''}
          invalid={passwordConfirmInvalid}
          disabled={loading}
        />
        <Button
          buttonType="large"
          active={active}
          onClick={change}
          mt={20}
          loading={loading}
          display="block"
          margin="0 auto"
        >
          {t('change_password')}
        </Button>
      </>
    </Div>
  );
}
