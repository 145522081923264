import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModule } from 'store/module';
import StreamingPresenter from './StreamingPresenter';

function StreamingContainer({
  type,
  module,
  filters,
  setFilters,
  streamingItem,
  streamingItemCallback,
}) {
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  const [tagList, setTagList] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      const { filterList } = module;
      setTagList(filterList?.tagList);
    }
  }, [module]);

  const changeStreaming = (item) => {
    window.scrollTo(0, 0);
    streamingItemCallback({
      ...module,
      playItemCode: item.itemCode,
      playItemContent: item.content,
      playItemLabel: item.label,
      playItemMainContent: item.mainContent,
      playItemName: item.name,
      playItemTags: [...item.tags],
      playItemUrl: item.url,
    });
    setContentView(false);
  };

  const [contentView, setContentView] = useState(false);

  return (
    <StreamingPresenter
      type={type}
      streamingItem={streamingItem}
      changeStreaming={changeStreaming}
      module={module}
      user={user}
      filters={filters}
      setFilters={setFilters}
      tagList={tagList}
      contentView={contentView}
      setContentView={setContentView}
    />
  );
}

export default StreamingContainer;
