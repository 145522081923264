import React from 'react';
import { IconStyles } from 'styles/IconStyles';
import { ReactComponent as AddIcon } from 'assets/icon/add.svg';
import { ReactComponent as TagAddIcon } from 'assets/icon/tag-add.svg';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import { ReactComponent as ArrowChatIcon } from 'assets/icon/arrow-chat.svg';
import { ReactComponent as ChatSendIcon } from 'assets/icon/chat-send.svg';
import { ReactComponent as InputDeleteIcon } from 'assets/icon/delete.svg';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import { ReactComponent as CheckIcon } from 'assets/icon/check.svg';
import { ReactComponent as CautionIcon } from 'assets/icon/caution.svg';
import { ReactComponent as CorrectIcon } from 'assets/icon/correct.svg';
import { ReactComponent as TranslateAIcon } from 'assets/icon/translate-a.svg';
import { ReactComponent as TranslateBIcon } from 'assets/icon/translate-b.svg';
import { ReactComponent as UsersIcon } from 'assets/icon/users.svg';
import { ReactComponent as LogoutIcon } from 'assets/icon/logout.svg';
import { ReactComponent as LoginIcon } from 'assets/icon/login.svg';
import { ReactComponent as MenuIcon } from 'assets/icon/menu-a.svg';
import { ReactComponent as MenuBIcon } from 'assets/icon/menu-b.svg';
import { ReactComponent as MoreIcon } from 'assets/icon/more.svg';
import { ReactComponent as ShareIcon } from 'assets/icon/share.svg';
import { ReactComponent as PinIcon } from 'assets/icon/pin.svg';
import { ReactComponent as AttachIcon } from 'assets/icon/attach.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icon/bookmark.svg';
import { ReactComponent as BookmarkDoneIcon } from 'assets/icon/bookmark-done.svg';
import { ReactComponent as HeartIcon } from 'assets/icon/heart.svg';
import { ReactComponent as CommentIcon } from 'assets/icon/comment.svg';
import { ReactComponent as OpenPlusIcon } from 'assets/icon/open-plus.svg';
import { ReactComponent as ProfileIcon } from 'assets/icon/profile.svg';
import { ReactComponent as WriteIcon } from 'assets/icon/write.svg';
import { ReactComponent as ViewIcon } from 'assets/icon/view.svg';
import { ReactComponent as ArrowOpenIcon } from 'assets/icon/arrow-open.svg';
import { ReactComponent as ArrowTriangleIcon } from 'assets/icon/arrow-triangle.svg';
import { ReactComponent as SideUserListIcon } from 'assets/icon/side-users.svg';
import { ReactComponent as SideChatIcon } from 'assets/icon/side-chat.svg';
import { ReactComponent as SideMemoIcon } from 'assets/icon/side-memo.svg';
import { ReactComponent as SidePlayIcon } from 'assets/icon/side-play.svg';
import { ReactComponent as SideBoardIcon } from 'assets/icon/side-board.svg';
import { ReactComponent as SideWYSIWYGIcon } from 'assets/icon/side-wysiwyg.svg';
import { ReactComponent as SearchIcon } from 'assets/icon/search.svg';
import { ReactComponent as MinusIcon } from 'assets/icon/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icon/plus.svg';
import { ReactComponent as MinimizeIcon } from 'assets/icon/window-minimize.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icon/window-maximize.svg';
import { ReactComponent as PasswordHiddenIcon } from 'assets/icon/password-hidden.svg';
import { ReactComponent as PasswordSeeIcon } from 'assets/icon/password-see.svg';
import { ReactComponent as FailIcon } from 'assets/icon/fail.svg';
import { ReactComponent as CheckDoneIcon } from 'assets/icon/check-done.svg';
import { ReactComponent as DownloadIcon } from 'assets/icon/download.svg';
import { ReactComponent as SimpleDeleteIcon } from 'assets/icon/delete-simple.svg';
import { ReactComponent as ScannerIcon } from 'assets/icon/scanner.svg';
import { ReactComponent as AttendanceCheckIcon } from 'assets/icon/check-attendance.svg';
import { ReactComponent as PauseIcon } from 'assets/icon/pause.svg';
import { ReactComponent as Play } from 'assets/icon/play.svg';
import { ReactComponent as AlarmIcon } from 'assets/icon/alarm.svg';
import { ReactComponent as LockIcon } from 'assets/icon/lock.svg';
import { ReactComponent as UnlockIcon } from 'assets/icon/unlock.svg';

function Icon({ name, position, display, size, children, ...props }) {
  return (
    <IconStyles
      position={position || 'relative'}
      display={display || 'inline-flex'}
      minWidth={size}
      minHeight={size}
      width={size}
      height={size}
      maxWidth={size}
      maxHeight={size}
      {...props}
    >
      {name === 'arrow' && <ArrowIcon />}
      {name === 'delete' && <InputDeleteIcon />}
      {name === 'close' && <CloseIcon />}
      {name === 'caution' && <CautionIcon />}
      {name === 'correct' && <CorrectIcon />}
      {name === 'check' && <CheckIcon />}
      {name === 'translateA' && <TranslateAIcon />}
      {name === 'translateB' && <TranslateBIcon />}
      {name === 'users' && <UsersIcon />}
      {name === 'logout' && <LogoutIcon />}
      {name === 'login' && <LoginIcon />}
      {name === 'menu' && <MenuIcon />}
      {name === 'open-plus' && <OpenPlusIcon />}
      {name === 'more' && <MoreIcon />}
      {name === 'pin' && <PinIcon />}
      {name === 'attach' && <AttachIcon />}
      {name === 'bookmark' && <BookmarkIcon />}
      {name === 'bookmark-done' && <BookmarkDoneIcon />}
      {name === 'like' && <HeartIcon />}
      {name === 'comment' && <CommentIcon />}
      {name === 'profile' && <ProfileIcon />}
      {name === 'arrow-triangle' && <ArrowTriangleIcon />}
      {name === 'arrow-open' && <ArrowOpenIcon />}
      {name === 'write' && <WriteIcon />}
      {name === 'tag-add' && <TagAddIcon />}
      {name === 'add' && <AddIcon />}
      {name === 'view' && <ViewIcon />}
      {name === 'side-userlist' && <SideUserListIcon />}
      {name === 'side-chat' && <SideChatIcon />}
      {name === 'side-memo' && <SideMemoIcon />}
      {name === 'side-play' && <SidePlayIcon />}
      {name === 'side-board' && <SideBoardIcon />}
      {name === 'side-wysiwyg' && <SideWYSIWYGIcon />}
      {name === 'minus' && <MinusIcon />}
      {name === 'window-minimize' && <MinimizeIcon />}
      {name === 'window-maximize' && <MaximizeIcon />}
      {name === 'chat-bottom' && <ArrowChatIcon />}
      {name === 'chat-send' && <ChatSendIcon />}
      {name === 'search' && <SearchIcon />}
      {name === 'password-see' && <PasswordSeeIcon />}
      {name === 'password-hidden' && <PasswordHiddenIcon />}
      {name === 'fail' && <FailIcon />}
      {name === 'share' && <ShareIcon />}
      {name === 'check-done' && <CheckDoneIcon />}
      {name === 'download' && <DownloadIcon />}
      {name === 'delete-simple' && <SimpleDeleteIcon />}
      {name === 'scanner' && <ScannerIcon />}
      {name === 'menu-b' && <MenuBIcon />}
      {name === 'plus' && <PlusIcon />}
      {name === 'attendance-check' && <AttendanceCheckIcon />}
      {name === 'pause' && <PauseIcon />}
      {name === 'alarm' && <AlarmIcon />}
      {name === 'play' && <Play />}
      {name === 'lock' && <LockIcon />}
      {name === 'unlock' && <UnlockIcon />}
      {children}
    </IconStyles>
  );
}

export default Icon;
