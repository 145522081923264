import {
  BoardDefaultCard,
  BoardDefaultWrite,
  BoardGalleryCard1,
  BoardGalleryWrite1,
  Div,
  Grid,
} from 'components';
import React from 'react';
import { useTheme } from 'styled-components';

function BoardPresenter({
  user,
  module,
  getModules,
  filters,
  setFilters,
  tagList,
  list,
  logging,
  targetEditItem,
  setTargetEditItem,
  deleting,
  moduleCode,
  itemClick,
  setError,
}) {
  const theme = useTheme();
  return (
    <>
      <Div minHeight={theme.windowSize.height - 124}>
        <Grid
          width="100%"
          height="100%"
          cols={module?.designType === 'gallery1' ? 3 : 1}
          colGap={10}
          rowGap={10}
        >
          {(module?.designType === 'gallery1' &&
            list?.map((item) => (
              <BoardGalleryCard1
                module={module}
                key={item.itemCode}
                item={item}
                itemClick={(event) => itemClick(event, item)}
                logging={logging}
                targetEditItem={targetEditItem}
                setTargetEditItem={setTargetEditItem}
                deleting={deleting}
              />
            ))) ||
            list?.map((item) => (
              <BoardDefaultCard
                module={module}
                key={item.itemCode}
                item={item}
                itemClick={(event) => itemClick(event, item)}
                logging={logging}
                targetEditItem={targetEditItem}
                setTargetEditItem={setTargetEditItem}
                deleting={deleting}
              />
            ))}
        </Grid>
      </Div>
      {(module?.designType === 'gallery1' && (
        <BoardGalleryWrite1
          module={module}
          user={user}
          moduleCode={moduleCode}
          setFilters={setFilters}
          getModules={getModules}
          setError={setError}
        />
      )) || (
        <BoardDefaultWrite
          module={module}
          user={user}
          moduleCode={moduleCode}
          setFilters={setFilters}
          getModules={getModules}
          setError={setError}
          targetEditItem={targetEditItem}
          setTargetEditItem={setTargetEditItem}
        />
      )}
    </>
  );
}

export default BoardPresenter;
