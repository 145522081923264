import Registration from './Registration';
import Chat from './Chat';
import WYSIWYG from './WYSIWYG';
import Schedule from './Schedule';
import Streaming from './Streaming';
import Enumeration from './Enumeration';
import Custom from './Custom';
import Board from './Board';
import Survey from './Survey';
import Slide from './Slide';
import Userlist from './Userlist';
import Memo from './Memo';
import RegistrationCheck from './RegistrationCheck';
import Mypage from './Mypage';
import Stamp from './Stamp';

export const modules = (
  bucket,
  menu,
  type,
  module,
  filters,
  setFilters,
  getModules,
  setModule,
  socket,
  loadingRef,
  loading,
  streamingItem,
  streamingItemCallback,
  isTemporaryRegistration,
) => ({
  registration: (
    <Registration
      bucket={bucket}
      menu={menu}
      module={module}
      isTemporaryRegistration={isTemporaryRegistration}
    />
  ),
  registrationCheck: <RegistrationCheck module={module} />,
  chat: (
    <Chat
      module={module}
      filters={filters}
      setFilters={setFilters}
      socket={socket}
      loading={loading}
      loadingRef={loadingRef}
    />
  ),
  wysiwyg: <WYSIWYG module={module} />,
  schedule: <Schedule module={module} />,
  streaming: (
    <Streaming
      type={type}
      module={module}
      streamingItem={streamingItem}
      streamingItemCallback={streamingItemCallback}
      filters={filters}
      setFilters={setFilters}
    />
  ),
  enumeration: (
    <Enumeration
      bucket={bucket}
      module={module}
      filters={filters}
      setFilters={setFilters}
    />
  ),
  custom: <Custom module={module} />,
  board: (
    <Board
      type={type}
      bucket={bucket}
      module={module}
      filters={filters}
      setFilters={setFilters}
      getModules={getModules}
    />
  ),
  survey: <Survey module={module} />,
  slide: <Slide module={module} />,
  userList: (
    <Userlist
      bucket={bucket}
      module={module}
      filters={filters}
      loadingRef={loadingRef}
      socket={socket}
      setFilters={setFilters}
    />
  ),
  memo: (
    <Memo
      module={module}
      filters={filters}
      setFilters={setFilters}
      setModule={setModule}
      getModules={getModules}
      loadingRef={loadingRef}
    />
  ),
  mypage: <Mypage bucket={bucket} module={module} />,
  stamp: <Stamp module={module} />,
});
