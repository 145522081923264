import React, { Suspense, lazy } from 'react';
import { Skeleton } from 'components';

const renderLoader = (height) => <Skeleton height={height} />;

const CardComponent = lazy(() => import('./EmptyCard'));

export function EnumerationEmptyCard({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(185)}>
      <CardComponent
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}

const TopCardComponent = lazy(() => import('./DefaultTopCard'));

export function EnumerationTopCard({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(504)}>
      <TopCardComponent
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}

const RightCardComponent = lazy(() => import('./DefaultRightCard'));

export function EnumerationRightCard({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(54)}>
      <RightCardComponent
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}

const LeftCardComponent = lazy(() => import('./DefaultLeftCard'));

export function EnumerationLeftCard({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(394)}>
      <LeftCardComponent
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}

const FAQCard1Component = lazy(() => import('./FAQCard1'));

export function EnumerationFAQ1Card({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(54)}>
      <FAQCard1Component
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}

const SpeakerCard1Component = lazy(() => import('./SpeakerCard1'));

export function EnumerationSpeaker1Card({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(340)}>
      <SpeakerCard1Component
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}
