import {
  Div,
  HTMLWrap,
  Icon,
  PopupA,
  Row,
  Text,
  Editor,
  Button,
} from 'components';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/css/editor-memo.css';

export function MemoCard({
  moduleCode,
  item,
  index,
  edit,
  deleting,
  ...props
}) {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState({ content: '' });

  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Div
      padding="20px 0"
      mb={14}
      backgroundColor={`m${(index % 5) + 1}`}
      borderRadius="12px"
      className="memo"
      {...props}
    >
      {!editing && (
        <>
          <Row
            alignItems="center"
            justifyContent="space-between"
            mb={6}
            padding="0 20px"
          >
            <Text color="g400">
              {moment(item?.createdDt).format('YYYY-MM-DD HH:mm')}
            </Text>
            <PopupA
              name={item?.itemCode}
              items={
                <>
                  <Text
                    hover
                    cursor="pointer"
                    pb={10}
                    onClick={() => {
                      setEditing(true);
                      setValues({
                        ...values,
                        content: item.content,
                        itemCode: item.itemCode,
                      });
                    }}
                  >
                    {t('edit')}
                  </Text>
                  <Text
                    hover
                    cursor="pointer"
                    pt={10}
                    onClick={() => deleting(item)}
                  >
                    {t('delete')}
                  </Text>
                </>
              }
            >
              <Icon
                name="more"
                size={21}
                cursor="pointer"
                hover="circle"
                fill="g500"
              />
            </PopupA>
          </Row>
          <HTMLWrap
            content={item?.content}
            padding="0 20px"
            maxHeight={193}
            overflowY="overlay"
          />
        </>
      )}
      {editing && (
        <>
          <Editor
            heightMax={193}
            heightMin={193}
            value={values.content}
            name="content"
            onChange={onChange}
            moduleCode={moduleCode}
          />
          <Row mt={10} gap={10} padding="0 20px" justifyContent="flex-end">
            <Button
              minWidth={64}
              width={64}
              ghost
              onClick={() => setEditing(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              active={!!values.content}
              width="100%"
              maxWidth={246}
              onClick={async () => {
                const result = await edit(values);
                if (result) {
                  setValues({ ...values, content: '' });
                  setEditing(false);
                }
              }}
            >
              {t('save')}
            </Button>
          </Row>
        </>
      )}
    </Div>
  );
}
