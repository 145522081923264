import { Button, Div, RegistrationList, Row } from 'components';
import React from 'react';

function RegistrationEditPresenter({
  t,
  module,
  targetSession,
  changeTargetSession,
  sessionList,
  activeSession,
  invalids,
  values,
  setValues,
  etcValues,
  setEtcValues,
  onChange,
  onEtcChange,
  onMutipleChange,
  onCheckChange,
  confirm,
  searchParams,
  setSearchParams,
}) {
  return (
    <Div width="100%">
      <RegistrationList
        designType={module?.designType}
        isEdit
        targetSession={targetSession}
        changeTargetSession={changeTargetSession}
        sessionList={sessionList}
        invalids={invalids}
        values={values}
        setValues={setValues}
        etcValues={etcValues}
        setEtcValues={setEtcValues}
        onChange={onChange}
        onEtcChange={onEtcChange}
        onMutipleChange={onMutipleChange}
        onCheckChange={onCheckChange}
        activeSession={activeSession}
        confirm={confirm}
      />
      <Row alignItems="center" justifyContent="center" gap={20} mt={40}>
        <Button
          active
          onClick={() => {
            searchParams.delete('edit');
            setSearchParams(searchParams);
          }}
          color="keyColor"
          border={{ color: 'keyColor' }}
          backgroundColor="white"
        >
          {t('cancel')}
        </Button>
        <Button active onClick={confirm}>
          {t('editing')}
        </Button>
      </Row>
    </Div>
  );
}

export default RegistrationEditPresenter;
