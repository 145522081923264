import React, { useEffect, useState } from 'react';
import { surveyApi } from 'api';
import { useApiError, useApiParams, useLanguageNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Alert, Loading } from 'components';
import { objectArrayParamsCleansing } from 'utils/apiCleansing';
import { isEmptyObject } from 'utils/isEmptyObject';
import SurveyPresenter from './SurveyPresenter';

function SurveyContainer({ module }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { DEFAULT_PARAMS } = useApiParams();
  const { languageNavigate } = useLanguageNavigate();
  const { alert, setAlert, setError } = useApiError();

  const [groupCode, setGroupCode] = useState();
  const getGroupcode = async () => {
    setLoading(true);
    const [info, error] = await surveyApi.groupCode(module.moduleCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setGroupCode(info.result.surveyGroupCode);
      getDetail(info.result.surveyGroupCode);
      setLoading(false);
    } else if (error?.resCode === 4541) {
      setLoading(false);
      setAlert({
        title: t('alert_title'),
        content: t('login_warning'),
        confirm: () => languageNavigate(`/login`),
        cancel: () => setAlert(''),
      });
    }
  };

  useEffect(() => {
    if (module && module.isUsedStartPage === 0) {
      getGroupcode();
    }
  }, [module]);

  const [list, setList] = useState();
  const [values, setValues] = useState();
  const [etcValues, setEtcValues] = useState();
  const getDetail = async (code) => {
    setLoading(true);
    const [info, error] = await surveyApi.surveyInfo(code, {
      ...DEFAULT_PARAMS,
    });
    if (info?.resCode === 200) {
      let tempValue = {};
      info.result.list
        .filter((el) => el.itemType !== 'content')
        .forEach((item) => {
          tempValue = {
            ...tempValue,
            [item.itemCode]: (item.itemType.includes('multiple') && []) || [''],
          };
        });
      setValues({ ...tempValue });
      setList([...info.result.list]);
      setLoading(false);
    } else if (error) {
      setError(error);
      setLoading(false);
    }
  };

  const onChange = (value, name) => {
    setValues({ ...values, [name]: [value] });
  };

  const onEtcChange = (value, name) => {
    setEtcValues({ ...etcValues, [name]: value });
  };

  const onMutipleChange = (value, name, index) => {
    if (value) {
      setValues({ ...values, [name]: [...values[name], value] });
    } else {
      values[name].splice(index, 1);
      setValues({
        ...values,
      });
    }
  };

  const onCheckChange = (value, name, checked) => {
    if (checked) {
      setValues({ ...values, [name]: [...(values[name] || ''), value] });
    } else {
      setValues({
        ...values,
        [name]: values[name].filter((el) => el !== value),
      });
    }
  };

  const [active, setActive] = useState(false);
  useEffect(() => {
    if (values) {
      const requiredArray = list.filter((item) => item.isRequired === 1);
      let result = true;
      for (const requiredElem of requiredArray) {
        const findElem = Object.keys(values).find(
          (key) => key === requiredElem.itemCode,
        );
        if (
          values[findElem] &&
          (!values[findElem][0] ||
            values[findElem][0].length < 1 ||
            isEmptyObject(values[findElem][0]))
        ) {
          result = false;
        }
      }

      setActive(
        result && (!etcValues || (etcValues && !isEmptyObject(etcValues))),
      );
    }
  }, [values, etcValues]);

  const goBack = () => {
    setGroupCode();
  };

  const confirm = async () => {
    if (etcValues) {
      Object.keys(etcValues).forEach((etcKey) => {
        const originItem = list.find((el) => el.itemCode === etcKey);
        const changeIndex = values[etcKey].findIndex(
          (val) => val === originItem,
        );
        values[etcKey].splice(changeIndex, 1, etcValues[etcKey]);
      });
    }

    if (active) {
      const [info] = await surveyApi.survey(groupCode, {
        ...DEFAULT_PARAMS,
        answers: { ...objectArrayParamsCleansing(values) },
      });
      if (info) {
        // success
        setAlert({
          title: t('alert_title'),
          content: t('complete'),
          confirm: () => setAlert(''),
          cancel: () => setAlert(''),
        });
      }
    }
  };

  return (
    <>
      <SurveyPresenter
        t={t}
        module={module}
        groupCode={groupCode}
        getGroupcode={getGroupcode}
        list={list}
        values={values}
        etcValues={etcValues}
        setEtcValues={setEtcValues}
        onEtcChange={onEtcChange}
        onChange={onChange}
        onMutipleChange={onMutipleChange}
        onCheckChange={onCheckChange}
        goBack={goBack}
        confirm={confirm}
        active={active}
      />
      {alert && <Alert {...alert} />}
      {loading && <Loading delay="0" />}
    </>
  );
}

export default SurveyContainer;
