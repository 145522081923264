import { moduleApi } from 'api';
import { Alert } from 'components';
import { useApiError, useApiParams, useLanguageNavigate } from 'hooks';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MemoPresenter from './MemoPresenter';

function MemoContainer({ module, filters, setFilters, setModule, loadingRef }) {
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();
  const { languageNavigate } = useLanguageNavigate();
  const { alert, setError, setAlert } = useApiError();
  const scrollRef = useRef();

  const [createLoading, setCreateLoading] = useState(false);
  const createItem = async (values) => {
    let result = false;
    if (!createLoading && values.content) {
      setCreateLoading(true);
      const [info] = await moduleApi.createModuleItem(module.moduleCode, {
        ...values,
        ...DEFAULT_PARAMS,
      });
      if (info) {
        setFilters({
          page: 1,
          lastRowNum: 0,
          perPage: 12,
        });
        if (scrollRef.current) {
          scrollRef.current.scrollTo(0, 0);
        }
        setCreateLoading(false);
        result = true;
      } else {
        setCreateLoading(false);
        result = false;
      }
    }

    return result;
  };

  const [editLoading, setEditLoading] = useState(false);
  const editItem = async (values) => {
    let result = false;
    if (!editLoading && values.content) {
      setEditLoading(true);
      const [info] = await moduleApi.editModuleItem(
        module.moduleCode,
        values.itemCode,
        { content: values.content, ...DEFAULT_PARAMS },
      );
      if (info) {
        setEditLoading(false);
        result = true;
        const findEditIndex = module.list.findIndex(
          (el) => el.itemCode === values.itemCode,
        );
        module.list[findEditIndex] = {
          ...module.list[findEditIndex],
          content: values.content,
        };
        setModule({ ...module, list: [...module?.list] });
      } else {
        setEditLoading(false);
        result = false;
      }
    }

    return result;
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteItem = async (itemCode) => {
    if (!deleteLoading) {
      setDeleteLoading(true);
      const [info] = await moduleApi.deleteModuleItem(
        module.moduleCode,
        itemCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setDeleteLoading(false);
        setAlert();
        const deletedList = module.list.filter(
          (el) => el.itemCode !== itemCode,
        );
        setModule({ ...module, list: [...deletedList] });
      } else {
        setDeleteLoading(false);
        setAlert({
          title: t('alert_title'),
          content: t('delete_comment_error'),
          confirm: () => setAlert(),
          close: () => setAlert(),
        });
      }
    }
  };

  const deleting = (item) => {
    setAlert({
      title: t('alert_title'),
      content: t('delete_warning'),
      confirm: () => deleteItem(item.itemCode),
      cancel: () => setAlert(),
    });
  };

  return (
    <>
      <MemoPresenter
        t={t}
        user={user}
        module={module}
        createItem={createItem}
        editItem={editItem}
        deleting={deleting}
        scrollRef={scrollRef}
        loadingRef={loadingRef}
        filters={filters}
        languageNavigate={languageNavigate}
      />
      {alert && <Alert {...alert} />}
    </>
  );
}

export default MemoContainer;
