import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: {},
};

const logoSlice = createSlice({
  name: 'logo',
  initialState,
  reducers: {
    setLogo: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { setLogo } = logoSlice.actions;

export default logoSlice;
