import React from 'react';
import { Row, Icon, Text } from 'components';

export function Invalid({ invalid }) {
  return (
    invalid &&
    invalid.type &&
    invalid.text && (
      <Row mt={2} gap={4} alignItems="center">
        <Icon
          name={(invalid.type === 'error' && 'caution') || 'correct'}
          fill={(invalid.type === 'error' && 'red') || ''}
          size={18}
        />
        <Text type="b3" color={(invalid.type === 'error' && 'red') || 'blue'}>
          {invalid.text}
        </Text>
      </Row>
    )
  );
}
