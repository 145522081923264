import { bucketApi } from 'api';
import {
  API_KEY,
  BUCKET_VERSION,
  FCM_TOKEN,
  REFRESH_CODE,
  TOKEN,
  UUID,
} from 'data/keyFile';
import { useCookieUtil, useLanguageNavigate } from 'hooks';

function useBucketReset(path) {
  const { languageNavigate } = useLanguageNavigate();
  const { getCookie, removeCookies } = useCookieUtil();

  const deleteFCMToken = async () => {
    const [info] = await bucketApi.deleteFCM({
      apiKey: getCookie(API_KEY),
      uuid: getCookie(UUID),
      bucketVersion: getCookie(BUCKET_VERSION),
      fcmToken: getCookie(FCM_TOKEN),
    });
    if (info) {
      languageNavigate(path || '/');
      removeCookies(TOKEN, '/');
      removeCookies(TOKEN);
      removeCookies(REFRESH_CODE);
      removeCookies(BUCKET_VERSION);
      removeCookies(FCM_TOKEN);
    }
  };

  const logout = () => {
    // isNecessaryInit
    if (getCookie(FCM_TOKEN)) {
      deleteFCMToken();
    } else {
      languageNavigate(path || '/');
      removeCookies(TOKEN, '/');
      removeCookies(TOKEN);
      removeCookies(REFRESH_CODE);
      removeCookies(BUCKET_VERSION);
    }
  };

  const reload = () => {
    // isNecessaryReload
    window.location.reload();
  };

  const needLogin = () => {
    // isNecessaryLogin
    languageNavigate('/login');
  };

  const needProfile = () => {
    // isNecessaryProfile
    languageNavigate('/profile');
  };

  return { logout, reload, needLogin, needProfile };
}

export default useBucketReset;
