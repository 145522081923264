import React, { useEffect, useRef, useState } from 'react';
import { Dim, Div, Icon, PaginationArrowButton, Row, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const userStore = () => {
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  return user;
};

export function FilterListA({ module, list, filters, setFilters }) {
  const { t } = useTranslation();
  const ref = useRef();
  const filterClick = (item, checked) => {
    const tags = (checked && filters.tags.filter((el) => el !== item)) || [
      ...(filters.tags || []),
      item,
    ];
    setFilters({
      ...filters,
      page: 1,
      lastRowNum: 0,
      tags,
    });
  };

  const [open, setOpen] = useState(false);
  const [useMore, setUseMore] = useState(false);
  useEffect(() => {
    if (list) {
      const { current } = ref;
      setUseMore(current.scrollWidth > current.clientWidth);
    }
  }, [list, ref?.current]);

  const defaultButton = () => (
    <>
      {module?.isViewedAllTagBtn === 1 && (
        <Row
          padding="6px 8px"
          borderRadius="6px"
          cursor="pointer"
          backgroundColor={
            ((!filters?.tags || filters.tags.length === 0) && 'key200') ||
            'g100'
          }
          onClick={() => {
            delete filters.tags;
            delete filters.myBookmark;
            setFilters({ ...filters });
          }}
        >
          <Text
            type={
              ((!filters?.tags || filters.tags.length === 0) && 'h8') || 'b2'
            }
            color={
              ((!filters?.tags || filters.tags.length === 0) && 'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {t('all_tag')}
          </Text>
        </Row>
      )}
      {userStore().name && module?.isViewedBookmarkTagBtn === 1 && (
        <Row
          padding="6px 8px"
          borderRadius="6px"
          cursor="pointer"
          onClick={() => {
            setFilters({
              ...filters,
              myBookmark: filters?.myBookmark === 1 ? 0 : 1,
            });
          }}
          alignItems="center"
          backgroundColor="g100"
        >
          <Icon name="bookmark" size={21} />
          <Text
            type={(filters?.myBookmark === 1 && 'h8') || 'b2'}
            color="g500"
            whiteSpace="nowrap"
          >
            {t('bookmark_tag')}
          </Text>
        </Row>
      )}
    </>
  );

  return (
    <Row gap={6}>
      <Row
        setRef={ref}
        gap={6}
        mb={20}
        overflowX="hidden"
        overflowY="hidden"
        position="relative"
        flexWrap={open ? 'wrap' : ''}
      >
        {defaultButton()}
        {list?.map((item) => (
          <Row
            padding="6px 8px"
            key={item}
            borderRadius="6px"
            backgroundColor={
              (filters?.tags && filters.tags.includes(item) && 'key200') ||
              'g100'
            }
            cursor="pointer"
            onClick={() =>
              filterClick(item, filters?.tags && filters.tags.includes(item))
            }
          >
            <Text
              type={
                (filters?.tags && filters.tags.includes(item) && 'h8') || 'b2'
              }
              color={
                (filters?.tags && filters.tags.includes(item) && 'keyColor') ||
                'g500'
              }
              whiteSpace="nowrap"
            >
              {item}
            </Text>
          </Row>
        ))}
      </Row>
      {useMore && (
        <Row height="33px" alignItems="center" justifyContent="flex-end">
          <PaginationArrowButton
            backgroundColor="backgroundColor"
            width={28}
            height={28}
            size={26}
            mr={18}
            hover={false}
            transition="all 300ms"
            transform={(open && 'rotate(90deg)') || 'rotate(-90deg)'}
            onClick={() => setOpen(!open)}
          />
        </Row>
      )}
    </Row>
  );
}

export function FilterListB({
  module,
  list,
  filters,
  setFilters,
  wrapStyles,
  ...props
}) {
  const { t } = useTranslation();
  const ref = useRef();
  const filterClick = (item, checked) => {
    const tags = (checked && filters.tags.filter((el) => el !== item)) || [
      ...(filters.tags || []),
      item,
    ];
    setFilters({
      ...filters,
      page: 1,
      lastRowNum: 0,
      tags,
    });
  };

  const [open, setOpen] = useState(false);
  const [useMore, setUseMore] = useState(false);
  useEffect(() => {
    if (list) {
      const { current } = ref;
      setUseMore(current.scrollWidth > current.clientWidth);
    }
  }, [list, ref?.current]);

  const defaultButton = () => (
    <>
      {module?.isViewedAllTagBtn === 1 && (
        <Row
          padding="8px 20px"
          borderRadius="999px"
          backgroundColor={
            ((!filters?.tags || filters.tags.length === 0) &&
              'layeredBackgroundColor') ||
            'g100'
          }
          border={{
            color:
              ((!filters?.tags || filters.tags.length === 0) && 'key500') ||
              'g100',
          }}
          cursor="pointer"
          onClick={() => {
            delete filters.tags;
            delete filters.myBookmark;
            setFilters({ ...filters });
          }}
        >
          <Text
            type={
              ((!filters?.tags || filters.tags.length === 0) && 'h8') || 'b2'
            }
            color={
              ((!filters?.tags || filters.tags.length === 0) && 'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {t('all_tag')}
          </Text>
        </Row>
      )}
      {module?.isViewedBookmarkTagBtn === 1 && (
        <Row
          padding="8px 20px 8px 16px"
          borderRadius="999px"
          backgroundColor={
            (filters?.myBookmark === 1 && 'layeredBackgroundColor') || 'g100'
          }
          border={{
            color: (filters?.myBookmark === 1 && 'g500') || 'transparent',
          }}
          cursor="pointer"
          onClick={() => {
            setFilters({
              ...filters,
              myBookmark: filters?.myBookmark === 1 ? 0 : 1,
            });
          }}
          alignItems="center"
          height={40}
        >
          <Icon name="bookmark-done" fill="g500" />
          <Text
            type={(filters?.myBookmark === 1 && 'h8') || 'b2'}
            color="g500"
            whiteSpace="nowrap"
          >
            {t('bookmark_tag')}
          </Text>
        </Row>
      )}
    </>
  );

  return (
    <>
      {open && (
        <Dim
          top="0"
          backgroundColor="transparent"
          zIndex="1"
          onClick={() => setOpen(false)}
        />
      )}
      <Div position="relative" mb={30} {...wrapStyles}>
        <Row
          setRef={ref}
          gap={6}
          overflowX="hidden"
          position="relative"
          {...props}
        >
          {defaultButton()}
          {list?.map((item) => (
            <Row
              padding="8px 20px"
              key={item}
              borderRadius="999px"
              backgroundColor={
                (filters?.tags &&
                  filters.tags.includes(item) &&
                  'layeredBackgroundColor') ||
                'g100'
              }
              border={{
                color:
                  (filters?.tags && filters.tags.includes(item) && 'key500') ||
                  'g100',
              }}
              cursor="pointer"
              onClick={() =>
                filterClick(item, filters?.tags && filters.tags.includes(item))
              }
            >
              <Text
                type={
                  (filters?.tags && filters.tags.includes(item) && 'h8') || 'b2'
                }
                color={
                  (filters?.tags &&
                    filters.tags.includes(item) &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {item}
              </Text>
            </Row>
          ))}
          {useMore && (
            <Row
              width={86}
              height="40px"
              background="linear-gradient(270deg, #FFFFFF 30.1%, rgba(255, 255, 255, 0) 80.1%)"
              position="absolute"
              right="-18px"
              alignItems="center"
              justifyContent="flex-end"
            >
              <PaginationArrowButton
                backgroundColor="backgroundColor"
                width={28}
                height={28}
                size={26}
                mr={18}
                hover={false}
                transition="all 300ms"
                transform={(open && 'rotate(90deg)') || 'rotate(-90deg)'}
                onClick={() => setOpen(!open)}
              />
            </Row>
          )}
        </Row>
        <Row
          position="absolute"
          borderRadius="12px"
          border={{ color: 'g200' }}
          width="100%"
          padding="30px 40px"
          backgroundColor="backgroundColor"
          top="52px"
          zIndex={500}
          boxShadow="default"
          flexWrap="wrap"
          colGap={10}
          rowGap={12}
          transform={(open && 'scaleY(1)') || 'scaleY(0)'}
          transition="all 300ms"
          transformOrigin="top center"
        >
          {defaultButton()}
          {list?.map((item) => (
            <Row
              padding="8px 20px"
              key={item}
              borderRadius="999px"
              backgroundColor={
                (filters?.tags &&
                  filters.tags.includes(item) &&
                  'backgroundColor') ||
                'g100'
              }
              border={{
                color:
                  (filters?.tags && filters.tags.includes(item) && 'key500') ||
                  'g100',
              }}
              cursor="pointer"
              onClick={() =>
                filterClick(item, filters?.tags && filters.tags.includes(item))
              }
            >
              <Text
                type={
                  (filters?.tags && filters.tags.includes(item) && 'h8') || 'b2'
                }
                color={
                  (filters?.tags &&
                    filters.tags.includes(item) &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {item}
              </Text>
            </Row>
          ))}
        </Row>
      </Div>
    </>
  );
}

export function FilterListC({
  module,
  list,
  filters,
  setFilters,
  wrapStyles,
  ...props
}) {
  const { t } = useTranslation();
  const ref = useRef();
  const filterClick = (item, checked) => {
    const tags = (checked && filters.tags.filter((el) => el !== item)) || [
      ...(filters.tags || []),
      item,
    ];
    setFilters({
      ...filters,
      page: 1,
      lastRowNum: 0,
      tags,
    });
  };

  const [open, setOpen] = useState(false);

  const defaultButton = () => (
    <>
      {module?.isViewedAllTagBtn === 1 && (
        <Row
          padding="0 10px"
          alignItems="center"
          height={40}
          borderRadius="999px"
          backgroundColor={
            ((!filters?.tags || filters.tags.length === 0) &&
              'layeredBackgroundColor') ||
            'g100'
          }
          border={{
            color:
              ((!filters?.tags || filters.tags.length === 0) && 'key500') ||
              'g100',
          }}
          cursor="pointer"
          onClick={() => {
            delete filters.tags;
            delete filters.myBookmark;
            setFilters({ ...filters });
          }}
        >
          <Text
            type={
              ((!filters?.tags || filters.tags.length === 0) && 'h8') || 'b2'
            }
            color={
              ((!filters?.tags || filters.tags.length === 0) && 'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {t('all_tag')}
          </Text>
        </Row>
      )}
      {module?.isViewedBookmarkTagBtn === 1 && (
        <Row
          padding="0 10px"
          alignItems="center"
          height={40}
          borderRadius="999px"
          backgroundColor={
            (filters?.myBookmark === 1 && 'layeredBackgroundColor') || 'g100'
          }
          border={{
            color: (filters?.myBookmark === 1 && 'g500') || 'transparent',
          }}
          cursor="pointer"
          onClick={() => {
            setFilters({
              ...filters,
              myBookmark: filters?.myBookmark === 1 ? 0 : 1,
            });
          }}
        >
          <Icon name="bookmark-done" fill="g500" />
          <Text
            type={(filters?.myBookmark === 1 && 'h8') || 'b2'}
            color="g500"
            whiteSpace="nowrap"
          >
            {t('bookmark_tag')}
          </Text>
        </Row>
      )}
    </>
  );

  return (
    <>
      {open && (
        <Dim
          top="0"
          backgroundColor="transparent"
          zIndex="1"
          onClick={() => setOpen(false)}
        />
      )}
      <Row
        setRef={ref}
        rowGap={13}
        colGap={20}
        flexWrap="wrap"
        position="relative"
        {...props}
      >
        {defaultButton()}
        {list?.map((item) => (
          <Row
            padding="0 10px"
            alignItems="center"
            height={40}
            key={item}
            borderRadius="999px"
            backgroundColor={
              (filters?.tags &&
                filters.tags.includes(item) &&
                'layeredBackgroundColor') ||
              'g100'
            }
            border={{
              color:
                (filters?.tags && filters.tags.includes(item) && 'key500') ||
                'g100',
            }}
            cursor="pointer"
            onClick={() =>
              filterClick(item, filters?.tags && filters.tags.includes(item))
            }
          >
            <Text
              type={
                (filters?.tags && filters.tags.includes(item) && 'h8') || 'b2'
              }
              color={
                (filters?.tags && filters.tags.includes(item) && 'keyColor') ||
                'g400'
              }
              whiteSpace="nowrap"
            >
              {item}
            </Text>
          </Row>
        ))}
      </Row>
    </>
  );
}
