import { moduleApi } from 'api';
import { Alert, Loading, Modal } from 'components';
import { useApiError, useApiParams, useLanguageNavigate } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Detail } from 'routes';
import { linkTypeClick } from 'utils/linkType';
import { setModule } from 'store/module';
import BoardPresenter from './BoardPresenter';

function BoardContainer({
  type,
  bucket,
  module,
  filters,
  setFilters,
  getModules,
}) {
  // console.log(type);
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();
  const { languageNavigate } = useLanguageNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tagList, setTagList] = useState();
  const [sortList, setSortList] = useState();
  const [list, setList] = useState();

  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      const { filterList } = module;
      setTagList(filterList?.tagList);
      setSortList(filterList?.sortTypeList);
      const totalList = Array.isArray(module?.list) ? [...module.list] : [];
      setList(totalList);
    }
  }, [module]);

  const { alert, setError, setAlert } = useApiError();
  const [actionbarLoading, setActionbarLoading] = useState(false);
  const logging = async (type, item) => {
    if (!actionbarLoading && type === 'click' ? true : user.name) {
      setActionbarLoading(true);
      const [info, error] = await moduleApi.moduleLogging(
        type,
        module.moduleCode,
        item.itemCode,
        DEFAULT_PARAMS,
      );
      if (info?.resCode === 200) {
        const { result } = info;
        const findItemIndex = list.findIndex(
          (el) => el.itemCode === item.itemCode,
        );
        list[findItemIndex] = {
          ...list[findItemIndex],
          ...result,
          likes:
            type === 'like'
              ? (result.isLike === 1 && item.likes + 1) || item.likes - 1
              : item.likes,
        };
        setList([...list]);
        setActionbarLoading(false);
      } else if (error) {
        setActionbarLoading(false);
        setError(error);
      }
    } else if (!user.name) {
      setError({ resCode: 457 });
    }
  };

  const deleteItem = async (itemCode) => {
    if (!loading) {
      setLoading(true);
      const [info] = await moduleApi.deleteModuleItem(
        module.moduleCode,
        itemCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setLoading(false);
        setAlert();
        getModules();
      } else {
        setLoading(false);
        setAlert({
          title: t('alert_title'),
          content: t('delete_comment_error'),
          confirm: () => setAlert(),
          close: () => setAlert(),
        });
      }
    }
  };

  const deleting = (item) => {
    setAlert({
      title: t('alert_title'),
      content: t('delete_warning'),
      confirm: () => deleteItem(item.itemCode),
      cancel: () => setAlert(),
    });
  };

  const [detailModal, setDetailModal] = useState();
  const itemClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    if (item.contentType !== 'none') {
      logging('click', item);
    }
    if (item?.contentType === 'modal') setDetailModal(item);
    if (item?.contentType === 'detail')
      languageNavigate(`detail/${item.itemCode}`);
  };

  const [targetEditItem, setTargetEditItem] = useState();

  return (
    <>
      <BoardPresenter
        user={user}
        module={module}
        getModules={getModules}
        filters={filters}
        setFilters={setFilters}
        tagList={tagList}
        list={list}
        logging={logging}
        targetEditItem={targetEditItem}
        setTargetEditItem={setTargetEditItem}
        deleting={deleting}
        moduleCode={module?.moduleCode}
        itemClick={itemClick}
        setError={setError}
      />
      {detailModal && (
        <Modal
          close={() => {
            setDetailModal();
          }}
        >
          <Detail
            type="modal"
            bucket={bucket}
            modalDetailCode={detailModal.itemCode}
            padding="0"
            setDetailModal={setDetailModal}
            setFilters={setFilters}
          />
        </Modal>
      )}
      {alert && <Alert {...alert} />}
      {loading && <Loading />}
    </>
  );
}

export default BoardContainer;
