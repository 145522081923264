import React, { useEffect, useState } from 'react';
import { Div, Text } from 'components';
import { animations } from 'styles/css';

export function Toast({ content }) {
  const [toastContent, setToastContent] = useState();

  useEffect(() => {
    if (content) {
      setToastContent(content);
      setTimeout(() => {
        setToastContent();
      }, [3000]);
    }
  }, [content]);

  return (
    toastContent && (
      <Div
        width="fit-content"
        backgroundColor="dim"
        padding="13px 15px"
        borderRadius="25px"
        position="fixed"
        bottom="77px"
        left="50%"
        transform="translateX(-50%)"
        transition="150ms ease-in"
        animation={animations.slideUp('bottom')}
      >
        <Text color="g0" whiteSpace="nowrap">
          {toastContent}
        </Text>
      </Div>
    )
  );
}
