/* eslint-disable func-names */
import React from 'react';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';

// Import all Froala Editor plugins;
// import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/edit_in_popup.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/files_manager.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/forms.min.js';
import 'froala-editor/js/plugins/help.min.js';
// import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/markdown.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/track_changes.min.js';
import 'froala-editor/js/plugins/trim_video.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';

// Import a third-party plugin.
// import 'froala-editor/js/third_party/image_tui.min.js';
// import 'froala-editor/js/third_party/embedly.min.js';
// import 'froala-editor/js/third_party/spell_checker.min.js';

import 'styles/css/editor.css';
import { Div } from 'components';
import { useApiParams, useCookieUtil } from 'hooks';
import { TOKEN } from 'data/keyFile';
import { BUCKET_CODE } from 'api';

export function Editor({
  moduleCode,
  onChange,
  value,
  name,
  heightMax,
  heightMin,
  ...props
}) {
  const { getCookie } = useCookieUtil();
  const { DEFAULT_PARAMS } = useApiParams();
  const handleModelChange = (model) => {
    onChange(model, name);
  };

  return (
    <Div {...props}>
      <FroalaEditorComponent
        model={value}
        config={{
          heightMin,
          heightMax,
          key: process.env.REACT_APP_FLOALA_API_KEY,
          attribution: false,
          language: 'ko',
          pluginsEnabled: [
            'align',
            'charCounter',
            'codeView',
            'codeBeautifier',
            'colors',
            'draggable',
            'embedly',
            'emoticons',
            'entities',
            'insertFiles',
            'fontAwesome',
            'fontFamily',
            'fontSize',
            'fullscreen',
            'help',
            'image',
            'inlineClass',
            'inlineStyle',
            'lineBreaker',
            'lineHeight',
            'link',
            'list',
            'markdownSupport',
            'paragraphFotmat',
            'paragraphStyle',
            'quickInsert',
            'quote',
            'save',
            'specialCharacters',
            'spellChecker',
            'table',
            'url',
            'video',
            'wordPaste',
          ],
          placeholderText: 'Edit Your Content Here!',
          charCounterCount: false,
          toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'emoticons',
                'clearFormatting',
              ],
            },
            moreParagraph: {
              buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
              ],
            },
            moreRich: {
              buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR',
              ],
            },
            moreMisc: {
              buttons: [
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                'help',
              ],
              align: 'right',
              buttonsVisible: 2,
            },
          },
          fontFamily: {
            'Pretendard, sans-serif': 'Default',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
          },
          fontFamilySelection: true,
          codeBeautifierOptions: {
            end_with_newline: true,
            indent_inner_html: true,
            extra_liners:
              "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
            brace_style: 'expand',
            indent_char: '\t',
            indent_size: 1,
            wrap_line_length: 0,
          },
          tableStyles: {
            'fr-dashed-borders': 'Dashed Borders',
            'fr-alternate-rows': 'Alternate Rows',
            'fr-border-none': 'None Border',
          },
          imageUploadURL: `${process.env.REACT_APP_API_HOST}/module/${moduleCode}/item/upload/froala`,
          imageUploadParams:
            process.env.NODE_ENV !== 'production'
              ? { ...DEFAULT_PARAMS, bucketCode: BUCKET_CODE }
              : { ...DEFAULT_PARAMS },
          requestHeaders: {
            Authorization: `Bearer ${getCookie(TOKEN)}`,
          },
        }}
        onModelChange={handleModelChange}
      />
    </Div>
  );
}
