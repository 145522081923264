import axios from 'axios';
import { objectParamsCleansing } from 'utils/apiCleansing';

export const BUCKET_CODE = 'p1m5nqkj0g1706002502134wr7qqrjg8';

const makeAuthRequest = (type, path, params) => {
  const cleansingParams =
    process.env.NODE_ENV !== 'production'
      ? { bucketCode: BUCKET_CODE, ...objectParamsCleansing(params) }
      : objectParamsCleansing(params);
  if (type === 'get') {
    return axios.get(`${process.env.REACT_APP_AUTH_API_HOST}/${path}`, {
      params: {
        ...cleansingParams,
      },
    });
  } else if (type === 'post') {
    return axios.post(`${process.env.REACT_APP_AUTH_API_HOST}/${path}`, {
      ...cleansingParams,
    });
  } else if (type === 'patch') {
    return axios.patch(`${process.env.REACT_APP_AUTH_API_HOST}/${path}`, {
      ...cleansingParams,
    });
  } else if (type === 'delete') {
    return axios.delete(`${process.env.REACT_APP_AUTH_API_HOST}/${path}`, {
      params: {
        ...cleansingParams,
      },
    });
  }
};

const makeRequest = (type, path, params, isUsedEmptyParams) => {
  const cleansingParams =
    process.env.NODE_ENV !== 'production'
      ? (isUsedEmptyParams && { bucketCode: BUCKET_CODE, ...params }) || {
          bucketCode: BUCKET_CODE,
          ...objectParamsCleansing(params),
        }
      : (isUsedEmptyParams && { ...params }) || objectParamsCleansing(params);
  if (type === 'get') {
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...cleansingParams,
      },
    });
  } else if (type === 'post') {
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...cleansingParams,
    });
  } else if (type === 'patch') {
    return axios.patch(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...cleansingParams,
    });
  } else if (type === 'delete') {
    return axios.delete(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...cleansingParams,
      },
    });
  } else if (type === 'fileUpload') {
    // upload file
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, params);
  } else if (type === 'excelDownload' || type === 'zipDownload') {
    // download file
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      type:
        type === 'excel'
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          : '.zip,.rar,.7zip',
      responseType: 'blob',
    });
  }
};

const getAuthResults = async (type, path, params = {}) => {
  try {
    const {
      data: { results },
      data,
    } = await makeAuthRequest(type, path, params);
    return [
      ((results || data).resCode === 200 && (results || data)) || null,
      ((results || data).resCode !== 200 && (results || data)) || null,
    ];
  } catch (e) {
    console.error(e);
    return [null, e.response?.data, e.response?.status];
  }
};

const getResults = async (type, path, params = {}, isUsedEmptyParams) => {
  try {
    const {
      data: { results },
      data,
    } = await makeRequest(type, path, params, isUsedEmptyParams);
    return [
      ((results || data).resCode === 200 && (results || data)) || null,
      ((results || data).resCode !== 200 && (results || data)) || null,
    ];
  } catch (e) {
    console.error(e);
    return [null, e.response?.data, e.response?.status];
  }
};

export const authApi = {
  uuid: (params) => getAuthResults('post', `required/uuid`, params),
  apiKey: (params) => getAuthResults('post', `required/apiKey`, params),
  guestToken: (params) => getAuthResults('post', `required/token`, params),
  refreshToken: (params) => getAuthResults('post', `token/refresh`, params),

  login: (params) => getAuthResults('post', `auth/signIn`, params),
  signUp: (params) => getAuthResults('post', `auth/signUp`, params),
  verificationSend: (params) => getAuthResults('post', `verifiedCode`, params),
  verificationCheck: (params) =>
    getAuthResults('post', `verifiedCode/confirm`, params),
  findpasswordVerificationCheck: (params) =>
    getAuthResults('post', `password/confirm`, params),
  resetPassword: (params) => getAuthResults('post', `password/reset`, params),

  profileInfo: (params) => getResults('get', `account`, params),
  profileThumbnail: (parmas) =>
    getResults('fileUpload', `account/upload/thumbnail`, parmas),
  profile: (params) => getResults('patch', `account`, params),
  confirmPasswordCode: (params) =>
    getResults('post', `account/confirmPasswordCode`, params),
  changePassword: (params) =>
    getResults('patch', `account/changePassword`, params),
};

export const connectionApi = {
  checkConnection: (params) => getResults('post', `checkConnectedUser`, params),
};

export const bucketApi = {
  getBucketCode: (params) => getResults('post', `bucketCode`, params),
  bucketInfo: (params) => getResults('post', `bucket`, params),
  saveFCM: (params) => getResults('post', `account/fcmToken`, params),
  deleteFCM: (params) => getResults('delete', `account/fcmToken`, params),
  alarm: (params) => getResults('get', `account/log/alarm`, params),
};

export const menuApi = {
  menuInfo: (menuCode, params) => getResults('get', `menu/${menuCode}`, params),
};

export const detailApi = {
  detailInfo: (detailCode, params) =>
    getResults('get', `detail/${detailCode}`, params),
};

export const registrationApi = {
  registrationCode: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/registration`, params),
  registrationCheckCode: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/registrationCheck`, params),
  registrationInfo: (registrationCode, params) =>
    getResults('get', `registration/${registrationCode}`, params),
  registrationCheckInfo: (registrationCode, params) =>
    getResults('get', `registrationCheck/${registrationCode}`, params),
  registration: (registrationCode, params) =>
    getResults('post', `registration/${registrationCode}`, params),
  registrationCancel: (registrationCode, params) =>
    getResults('delete', `registration/${registrationCode}/cancel`, params),
  confirm: (registrationCode, params) =>
    getResults('post', `registration/${registrationCode}/confirm`, params),
  paymentCode: (registrationCode, params) =>
    getResults(
      'post',
      `registrationPayment/${registrationCode}/paymentCode`,
      params,
    ),
  paymentInfo: (registrationCode, params) =>
    getResults(
      'post',
      `registrationPayment/${registrationCode}/orderInfo`,
      params,
    ),
};

export const surveyApi = {
  groupCode: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/survey`, params),
  surveyInfo: (groupCode, params) =>
    getResults('get', `survey/${groupCode}`, params),
  survey: (groupCode, params) =>
    getResults('post', `survey/${groupCode}`, params),
};

export const moduleApi = {
  moduleInfo: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}`, params),
  createModuleItem: (moduleCode, params) =>
    getResults('post', `module/${moduleCode}/item`, params),
  editModuleItem: (moduleCode, itemCode, params) =>
    getResults('patch', `module/${moduleCode}/item/${itemCode}`, params, true),
  deleteModuleItem: (moduleCode, itemCode, params) =>
    getResults('delete', `module/${moduleCode}/item/${itemCode}`, params),
  moduleLogInfo: (type, moduleCode, itemCode, params) =>
    getResults('get', `module/${moduleCode}/item/${itemCode}/${type}`, params),
  moduleLogging: (type, moduleCode, itemCode, params) =>
    getResults('post', `module/${moduleCode}/item/${itemCode}/${type}`, params),
  moduleLogDelete: (type, moduleCode, itemCode, logCode, params) =>
    getResults(
      'delete',
      `module/${moduleCode}/item/${itemCode}/${type}/${logCode}`,
      params,
    ),
  moduleLogEdit: (type, moduleCode, itemCode, logCode, params) =>
    getResults(
      'patch',
      `module/${moduleCode}/item/${itemCode}/${type}/${logCode}`,
      params,
    ),
  fileUpload: (moduleCode, params) =>
    getResults('fileUpload', `module/${moduleCode}/upload/file`, params),
};

export const userListChatApi = {
  getList: (moduleCode, roomCode, params) =>
    getResults(
      'get',
      `module/${moduleCode}/userList/chat/${roomCode}/log`,
      params,
    ),
  roomCode: (moduleCode, params) =>
    getResults('post', `module/${moduleCode}/userList/chat/roomCode`, params),
  send: (moduleCode, roomCode, params) =>
    getResults(
      'post',
      `module/${moduleCode}/userList/chat/${roomCode}/log`,
      params,
    ),
};

export const reservationApi = {
  reservationInfo: (moduleCode, enumItemCode, params) =>
    getResults(
      'get',
      `module/${moduleCode}/reservation/${enumItemCode}`,
      params,
    ),
  reservation: (moduleCode, enumItemCode, params) =>
    getResults(
      'post',
      `module/${moduleCode}/reservation/${enumItemCode}`,
      params,
    ),
  cancel: (moduleCode, enumItemCode, params) =>
    getResults(
      'delete',
      `module/${moduleCode}/reservation/${enumItemCode}`,
      params,
    ),
};

export const stampApi = {
  list: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/stamp`, params),
  item: (moduleCode, itemCode, params) =>
    getResults(
      'get',
      `module/${moduleCode}/stamp/stamping/${itemCode}`,
      params,
    ),
  stampConfirm: (moduleCode, itemCode, params) =>
    getResults(
      'post',
      `module/${moduleCode}/stamp/confirm/${itemCode}`,
      params,
    ),
  reward: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/stamp/reward`, params),
  rewardConfirm: (moduleCode, params) =>
    getResults('post', `module/${moduleCode}/stamp/reward`, params),
};

export const mypageApi = {
  logInfo: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/mypage/log`, params),
};

export const attendanceApi = {
  info: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/streaming/attendance`, params),
  start: (moduleCode, params) =>
    getResults(
      'post',
      `module/${moduleCode}/streaming/attendance/start`,
      params,
    ),
  stop: (moduleCode, params) =>
    getResults('post', `module/${moduleCode}/streaming/attendance/end`, params),
  list: (moduleCode, params) =>
    getResults('get', `module/${moduleCode}/streaming/attendance/logs`, params),
};
