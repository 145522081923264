import { Col } from 'components';
import React from 'react';

export function UserlistDim({ open, onClick, children, ...props }) {
  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="rgba(255,255,255,0.06)"
      backdropFilter="blur(4.5px)"
      alignItems="center"
      justifyContent="center"
      transform={(open && 'scaleY(1)') || 'scaleY(0)'}
      transition="all 0.6s cubic-bezier(0.680, -0.550, 0.265, 1.550)"
      transformOrigin="top center"
      onClick={() => {
        if (onClick) onClick();
      }}
      {...props}
    >
      {children}
    </Col>
  );
}
