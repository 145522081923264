import { Button, Div, Icon, Row, Text } from 'components';
import { useCookieUtil, useWindowSize } from 'hooks';
import Modules from 'modules/Modules';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const fullPageModules = ['memo', 'userList', 'chat'];

const throttle = (func, ms) => {
  let throttled = false;
  return (...args) => {
    if (!throttled) {
      throttled = true;
      setTimeout(() => {
        func(...args);
        throttled = false;
      }, ms);
    }
  };
};

export function ModalStructure({
  bucket,
  module,
  socket,
  target,
  setTarget,
  minModals,
  setMinModals,
}) {
  const dragbarHeight = 45;
  const windowSize = useWindowSize();
  const maxWidth = windowSize.width > 1280 ? 1280 : windowSize.width - 40;
  const maxHeight = Math.round(
    windowSize.height * 0.9 -
      (Number(bucket?.headerOption.headerHeight * 2) || 120),
  );
  const [open, setOpen] = useState(true);
  const close = () => {
    setOpen(false);
  };

  const { getCookie, setCookies } = useCookieUtil();
  const { t } = useTranslation();
  const wrapRef = useRef();
  const [isDrag, setIsDrag] = useState(false);
  const [originSize, setOriginSize] = useState({ width: 0, height: 0 });
  const [originPos, setOriginPos] = useState({ x: 10, y: 10 }); // 드래그 전 포지션값 (e.target.offset의 상대 위치)
  const [clientPos, setClientPos] = useState({ x: 10, y: 10 }); // 실시간 커서위치인 e.client를 갱신하는값
  const [pos, setPos] = useState({ left: 10, top: 10 }); // 실제 drag할 요소가 위치하는 포지션값

  const onDragStart = (e) => {
    const { current } = wrapRef;
    setIsDrag(true);
    document.body.style.cssText += 'overflow-y:hidden;';
    const blankCanvas = document.createElement('canvas');
    blankCanvas.classList.add('canvas');
    e.dataTransfer?.setDragImage(blankCanvas, 0, 0);
    document.body?.appendChild(blankCanvas); // 투명 캔버스를 생성하여 글로벌 아이콘 제거
    e.dataTransfer.effectAllowed = 'move'; // 크롬의그린 +아이콘 제거

    const originPosTemp = { ...originPos };
    originPosTemp['x'] = current.offsetLeft;
    originPosTemp['y'] = current.offsetTop;
    setOriginPos(originPosTemp); // 드래그 시작할때 드래그 전 위치값을 저장

    const clientPosTemp = { ...clientPos };
    clientPosTemp['x'] = e.clientX;
    clientPosTemp['y'] = e.clientY;
    setClientPos(clientPosTemp);
  };

  const isInsideDragArea = (width, height) => {
    const { current } = wrapRef;
    const elementWidth = width || current.getBoundingClientRect().width;
    const elementHeight = height || current.getBoundingClientRect().height;
    const elementLeft = current.offsetLeft + elementWidth;
    const elementTop = current.offsetTop + elementHeight;

    if (
      current.offsetTop < 0 ||
      current.offsetLeft < 0 ||
      elementTop > windowSize.height ||
      elementLeft > windowSize.width
    ) {
      setPos({
        top:
          (current.offsetTop < 0 && 10) ||
          (elementTop > windowSize.height &&
            windowSize.height - elementHeight - 10) ||
          pos.top,
        left:
          (current.offsetLeft < 0 && 10) ||
          (elementLeft > windowSize.width &&
            windowSize.width - elementWidth - 10) ||
          pos.left,
      });
    }
  };

  const onDragEnd = (e) => {
    setIsDrag(false);
    isInsideDragArea();
    document.body.style.setProperty('overflow-y', 'overlay');

    // 캔버스 제거
    const canvases = document.getElementsByClassName('canvas');
    for (let i = 0; i < canvases.length; i += 1) {
      const canvas = canvases[i];
      canvas.parentNode?.removeChild(canvas);
    }
    // 캔버스로 인해 발생한 스크롤 방지 어트리뷰트 제거
    document.body.removeAttribute('style');
  };

  const onDragMove = (e) => {
    if (isDrag) {
      const { current } = wrapRef;
      const PosTemp = { ...pos };
      PosTemp['left'] = current.offsetLeft + e.clientX - clientPos.x;
      PosTemp['top'] = current.offsetTop + e.clientY - clientPos.y;
      setPos(PosTemp);

      const clientPosTemp = { ...clientPos };
      clientPosTemp['x'] = e.clientX;
      clientPosTemp['y'] = e.clientY;
      setClientPos(clientPosTemp);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault(); // 드래그시에 플라잉백하는 고스트이미지를 제거한다
  };

  const [scale, setScale] = useState('origin');
  const changeScale = (newScale) => {
    if (newScale === 'max') {
      const width = originSize.width > maxWidth ? maxWidth : originSize.width;
      const height =
        originSize.height > maxHeight
          ? maxHeight - dragbarHeight
          : originSize.height;
      isInsideDragArea(width, height);
      setResize({
        width,
        height,
      });
    } else if (newScale === 'origin') {
      if (module?.isViewedSizeUpBtn === 1) {
        isInsideDragArea(originSize.width, originSize.height);
      } else {
        setPos({
          top: '50%',
          left: '50%',
        });
      }
      setResize({
        width: originSize.width,
        height: originSize.height - dragbarHeight,
      });
    } else {
      // min
      if (originSize.width === 0 && originSize.height === 0) {
        setOriginSize({
          width: Math.round(wrapRef.current.getBoundingClientRect().width),
          height: Math.round(wrapRef.current.getBoundingClientRect().height),
        });
      }
      if (resize.width) {
        setResize({ width: null, height: null });
      }
      const alreadyMinModals = minModals.length + 1;
      const minLeft = windowSize.width - 310 * alreadyMinModals - 10;
      const minTop = windowSize.height - dragbarHeight - 10;
      setPos({
        left: minLeft < 0 ? windowSize.width - 310 - 10 : minLeft,
        top:
          minLeft < 0 && minModals.length > 0
            ? windowSize.height - (dragbarHeight + 10) * alreadyMinModals
            : minTop,
      });
    }
    setScale(newScale);
  };

  // resize
  const [initialPos, setInitialPos] = useState(null);
  const [initialSize, setInitialSize] = useState(null);
  const [resize, setResize] = useState({ width: null, height: null });

  const initial = (e) => {
    const blankCanvas = document.createElement('canvas');
    blankCanvas.classList.add('canvas');
    e.dataTransfer?.setDragImage(blankCanvas, 0, 0);
    document.body?.appendChild(blankCanvas); // 투명 캔버스를 생성하여 글로벌 아이콘 제거
    e.dataTransfer.effectAllowed = 'move'; // 크롬의그린 +아이콘 제거

    const { current } = wrapRef;

    setInitialPos({ x: e.clientX, y: e.clientY });
    setInitialSize({
      width: current.offsetWidth,
      height: current.offsetHeight,
    });
  };

  const resizeHandler = (e) => {
    const resultWidth =
      parseInt(initialSize.width, 10) + parseInt(e.clientX - initialPos.x, 10);

    const resultHeight =
      parseInt(initialSize.height, 10) + parseInt(e.clientY - initialPos.y, 10);

    const heightTemp =
      (resultHeight < maxHeight + 1 && resultHeight > 299 && resultHeight) ||
      (resultHeight < 255 && 255) ||
      (resultHeight > maxHeight && maxHeight);

    if (heightTemp) {
      setResize({
        width:
          (resultWidth < maxWidth + 1 && resultWidth > 299 && resultWidth) ||
          (resultWidth < 255 && 255) ||
          (resultWidth > maxWidth && maxWidth),
        height:
          (resultHeight < maxHeight + 1 &&
            resultHeight > 299 &&
            resultHeight) ||
          (resultHeight < 255 && 255) ||
          (resultHeight > maxHeight && maxHeight),
      });
      setOriginSize({
        width:
          (resultWidth < maxWidth + 1 && resultWidth > 359 && resultWidth) ||
          (resultWidth < 300 && 300) ||
          (resultWidth > maxWidth && maxWidth),
        height:
          (resultHeight < maxHeight + 1 &&
            resultHeight > 359 &&
            resultHeight + dragbarHeight) ||
          (resultHeight < 255 && 255 + dragbarHeight) ||
          (resultHeight > maxHeight && maxHeight + dragbarHeight),
      });
    }
  };

  const delay = 10;
  const onThrottleDragMove = throttle(resizeHandler, delay);

  const resizeEnd = () => {
    // 캔버스 제거
    const canvases = document.getElementsByClassName('canvas');
    for (let i = 0; i < canvases.length; i += 1) {
      const canvas = canvases[i];
      canvas.parentNode?.removeChild(canvas);
    }
    // 캔버스로 인해 발생한 스크롤 방지 어트리뷰트 제거
    document.body.removeAttribute('style');
  };

  return (
    open &&
    !getCookie(module.moduleCode) && (
      <Div
        setRef={wrapRef}
        maxWidth={maxWidth}
        minWidth={300}
        minHeight={(scale === 'min' && 45) || 'unset'}
        position="fixed"
        zIndex={(target && 9999) || 9000}
        style={{
          width:
            resize.width ||
            (fullPageModules.includes(module?.moduleType) && '300px') ||
            'auto',
          left:
            (module?.isUsedDrag === 0 && scale !== 'min' && '50%') || pos.left,
          top:
            (module?.isUsedDrag === 0 &&
              scale !== 'min' &&
              'calc(50% + 38px)') ||
            pos.top,
        }}
        transform={
          (module?.isUsedDrag === 0 &&
            scale !== 'min' &&
            `translate(-50%, -50%)`) ||
          ''
        }
        borderRadius="1.2rem"
        onMouseDown={() => setTarget(module)}
        boxShadow="default"
      >
        {module?.designType === 'design2' && (
          <Icon
            name="close"
            position="absolute"
            size={28}
            top="-38px"
            right="0"
            cursor="pointer"
            onClick={close}
            stroke="white"
          />
        )}
        {(module?.isViewedSizeUpBtn === 1 ||
          module?.isViewedMinimizeBtn === 1 ||
          module?.isViewedMinimizeBtn === 1) && (
          <Row
            width="100%"
            height={dragbarHeight}
            minHeight={dragbarHeight}
            backgroundColor="white"
            borderRadius="1.2rem 1.2rem 0 0"
            padding="8px"
            alignItems="center"
            justifyContent="flex-end"
            position="relative"
            gap={8}
            draggable={module?.isUsedDrag === 1}
            onDragStart={onDragStart}
            onDrag={onDragMove}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
          >
            {(module?.isViewedSizeUpBtn === 1 || scale === 'min') && (
              <Icon
                name={
                  (scale === 'max' && 'window-minimize') || 'window-maximize'
                }
                size={24}
                cursor="pointer"
                onClick={() => {
                  changeScale(
                    ((scale === 'max' || scale === 'min') && 'origin') || 'max',
                  );
                  setMinModals(
                    minModals.filter((el) => el !== module.moduleCode),
                  );
                }}
                position="relative"
                zIndex={1}
              />
            )}
            {/* {module?.isViewedMinimizeBtn === 1 && scale !== 'min' && (
              <Icon
                stroke="fontColor"
                name="minus"
                size={24}
                cursor="pointer"
                position="relative"
                zIndex={1}
                onClick={() => {
                  changeScale('min');
                  if (!minModals.find((el) => el === module.moduleCode))
                    setMinModals([...minModals, module.moduleCode]);
                }}
              />
            )} */}
            {module?.isViewedCloseBtn === 1 && (
              <Icon
                stroke="fontColor"
                name="close"
                size={24}
                cursor="pointer"
                onClick={close}
                position="relative"
                zIndex={1}
              />
            )}
          </Row>
        )}
        <Div
          className="dim-disabled-overlay"
          minWidth={300}
          overflowX="hidden"
          overflowY="overlay"
          width={
            ((scale === 'max' || scale === 'origin') && '100%') ||
            (scale === 'min' && '300px')
          }
          height={(scale === 'min' && '0') || '100%'}
          maxHeight={maxHeight}
          borderRadius={
            (module?.designType === 'design2' && '1.2rem  1.2rem 0 0') ||
            (module?.isUsedReappear24 && '0rem') ||
            '0 0 1.2rem 1.2rem'
          }
          backgroundColor="backgroundColor"
          transform={(scale !== 'min' && 'scale(1)') || 'scale(0)'}
        >
          <Modules
            bucket={bucket}
            type="modal"
            style={{
              height:
                (resize.height && `${resize.height}px`) ||
                (fullPageModules.includes(module.moduleType) &&
                  Math.round(windowSize.height * 0.85)) ||
                'auto',
            }}
            minHeight="unset"
            socket={socket}
            moduleCode={module.moduleCode}
            moduleType={module.moduleType}
            width="100%"
          />
        </Div>
        {module?.isUsedReappear24 === 1 && scale !== 'min' && (
          <Row
            backgroundColor="white"
            width="100%"
            height={module?.designType === 'design2' ? 'auto' : 54}
            borderRadius="0 0 1.2rem 1.2rem"
            alignItems="center"
          >
            <Button
              buttonType="text"
              type="b1"
              height="100%"
              color="black"
              width="100%"
              onClick={() => setCookies(module.moduleCode, true, 1)}
              whiteSpace="normal"
            >
              {t('is_used_reappear_24')}
            </Button>
            <Div width={1} height={35} backgroundColor="g500" />
            <Button
              buttonType="text"
              type="b1"
              height="100%"
              color="black"
              width="100%"
              onClick={close}
            >
              {t('close')}
            </Button>
          </Row>
        )}
        {module?.isUsedResize === 1 && scale !== 'min' && (
          <Div
            position="absolute"
            width={15}
            height={15}
            right="0"
            bottom="0"
            cursor="nwse-resize"
            draggable
            onDragStart={initial}
            onDrag={onThrottleDragMove}
            onDragOver={onDragOver}
            onDragEnd={resizeEnd}
          />
        )}
      </Div>
    )
  );
}
