import React from 'react';
import { RadioStyles } from 'styles/RadioStyles';
import { Icon } from 'components';

export function Radio({
  checked,
  onChange,
  name,
  value,
  children,
  disabled,
  invalid,
  checkStyles,
  ...props
}) {
  const onChangeHandler = (event) => {
    const { value, name, checked } = event.target;
    onChange(value, name, checked);
  };

  return (
    <RadioStyles
      display="flex"
      width="fit-content"
      alignItems="center"
      cursor={(disabled && 'default') || 'pointer'}
      gap={10}
      invalid={invalid}
      checked={checked}
      disabled={disabled}
      {...props}
    >
      <input
        className="hidden-radio"
        type="radio"
        checked={checked}
        onChange={onChangeHandler}
        disabled={disabled}
        name={name}
        value={value}
      />
      <Icon
        name="check"
        className="styled-radio"
        fill={(checked && 'g0') || 'g500'}
        borderRadius="999px"
        {...checkStyles}
      />
      {children}
    </RadioStyles>
  );
}
