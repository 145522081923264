import React, { useState } from 'react';
import { Col, Dim, Div } from 'components';

export function PopupB({ name, children, label, items, popStyles, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <Dim
          top="0"
          backgroundColor="transparent"
          zIndex="1"
          onClick={() => setOpen(false)}
        />
      )}
      <Div position="relative" {...props}>
        <Div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(!open);
          }}
          position="relative"
          zIndex={(open && 5) || '0'}
        >
          {children}
        </Div>
        {open && (
          <Col
            backgroundColor="g0"
            width="fit-content"
            padding="8px"
            borderRadius="10px"
            position="absolute"
            zIndex={10}
            gap={10}
            boxShadow="default"
            right="0"
            transform={(open && 'scaleY(1)') || 'scaleY(0)'}
            transition="all 0.3s"
            transformOrigin="top right"
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(false);
            }}
            {...popStyles}
          >
            {label}
            {label && (
              <Div
                height="1"
                width="100%"
                backgroundColor="g200"
                margin="16px 0"
              />
            )}
            {items}
          </Col>
        )}
      </Div>
    </>
  );
}
