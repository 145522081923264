import React, { useEffect, useRef, useState } from 'react';
import { Div, HTMLWrap, Iframe, Text, Badge, Button, Row } from 'components';
import { makeVideoUrl } from 'utils/makeVideoUrl';
import { useViewedItems } from 'hooks';
import { useTranslation } from 'react-i18next';

export function Streaming({ item, user, contentView, setContentView }) {
  const { t } = useTranslation();
  const { setItem } = useViewedItems();

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    if (item) {
      setItem(item);
      if (textRef.current) {
        setIsEllipsisActive(
          textRef.current.offsetHeight < textRef.current.scrollHeight,
        );
      }
    }
  }, [item]);
  return (
    <>
      <Row alignItems="center" aspectRatio="16 / 9" overflowY="auto">
        {(item?.playItemUrl && (
          <Iframe
            width="100%"
            height="100%"
            src={makeVideoUrl(item.playItemUrl, item.autoPlay)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={item?.name}
          />
        )) ||
          (item?.playItemMainContent && (
            <HTMLWrap
              width="100%"
              height="100%"
              content={item.playItemMainContent}
            />
          ))}
      </Row>
      <Div width="100%" padding="10px 20px 0" minHeight={111}>
        {item?.playItemTags && (
          <Row
            justifyContent={
              (item?.playItemTags && 'space-between') || 'flex-end'
            }
            alignItems="center"
            mb={10}
          >
            {item?.playItemTags && (
              <Text display="flex" gap={6} flexWrap="wrap" flex={1}>
                {item?.playItemTags?.map((tag, index) => (
                  <Badge
                    key={tag + index}
                    backgroundColor="keyColor"
                    color="keyFontColor"
                    type="h10"
                  >
                    {tag}
                  </Badge>
                ))}
              </Text>
            )}
          </Row>
        )}
        {item?.playItemName && (
          <Text type="h7" mb={6}>
            {item?.playItemName}
          </Text>
        )}

        {item?.playItemContent && (
          <>
            <div
              style={
                !contentView
                  ? {
                      whiteSpace: 'pre-line',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }
                  : {}
              }
              ref={textRef}
            >
              <HTMLWrap content={item?.playItemContent} />
            </div>

            {isEllipsisActive && (
              <Button
                mt={10}
                buttonType="text"
                type="b2"
                padding="4px"
                onClick={() => setContentView(!contentView)}
              >
                {contentView ? t('briefly') : t('more')}
              </Button>
            )}
          </>
        )}
      </Div>
    </>
  );
}
