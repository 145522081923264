import { css } from 'styled-components';
import { hexToRGB } from 'utils/css/hexToRGB';
import { checkPixel } from 'utils/css/checkPixel';

export const borderElement = css`
  ${({ border, theme }) =>
    (border &&
      `border: ${checkPixel(border.width) || '1px'} ${
        border.style || 'solid'
      } ${hexToRGB(
        theme.colors[border.color] || border.color || theme.colors.g400,
        border.opacity || 1,
      )};`) ||
    ''};
  ${({ borderBottom, theme }) =>
    (borderBottom &&
      `border-bottom: ${checkPixel(borderBottom.width) || '1px'} ${
        borderBottom.style || 'solid'
      } ${hexToRGB(
        theme.colors[borderBottom.color] ||
          borderBottom.color ||
          theme.colors.g400,
        borderBottom.opacity || 1,
      )};`) ||
    ''};
  ${({ borderTop, theme }) =>
    (borderTop &&
      `border-top:  ${checkPixel(borderTop.width) || '1px'} ${
        borderTop.style || 'solid'
      } ${hexToRGB(
        theme.colors[borderTop.color] || borderTop.color || theme.colors.g400,
        borderTop.opacity || 1,
      )};`) ||
    ''};
  ${({ borderRight, theme }) =>
    (borderRight &&
      `border-right: ${checkPixel(borderRight.width) || '1px'} ${
        borderRight.style || 'solid'
      } ${hexToRGB(
        theme.colors[borderRight.color] ||
          borderRight.color ||
          theme.colors.g400,
        borderRight.opacity || 1,
      )};`) ||
    ''};
  ${({ borderLeft, theme }) =>
    (borderLeft &&
      `border-left: ${checkPixel(borderLeft.width) || '1px'} ${
        borderLeft.style || 'solid'
      } ${hexToRGB(
        theme.colors[borderLeft.color] || borderLeft.color || theme.colors.g400,
        borderLeft.opacity || 1,
      )};`) ||
    ''};
  ${({ borderRadius }) =>
    ((borderRadius === 0 || borderRadius) &&
      `border-radius: ${checkPixel(borderRadius)};`) ||
    ''};

  // mobile
  @media ${({ theme }) => theme.device.tablet} {
    ${({ mBorder, theme }) =>
      (mBorder &&
        `border:${checkPixel(mBorder.width) || '1px'} ${
          mBorder.style || 'solid'
        } ${hexToRGB(
          theme.colors[mBorder.color] || mBorder.color || theme.colors.g400,
          mBorder.opacity || 1,
        )};`) ||
      ''};
    ${({ mBorderBottom, theme }) =>
      (mBorderBottom &&
        `border-bottom: ${checkPixel(mBorderBottom.width) || '1px'} ${
          mBorderBottom.style || 'solid'
        } ${hexToRGB(
          theme.colors[mBorderBottom.color] ||
            mBorderBottom.color ||
            theme.colors.g400,
          mBorderBottom.opacity || 1,
        )};`) ||
      ''};
    ${({ mBorderTop, theme }) =>
      (mBorderTop &&
        `border-top: ${checkPixel(mBorderTop.width) || '1px'} ${
          mBorderTop.style || 'solid'
        } ${hexToRGB(
          theme.colors[mBorderTop.color] ||
            mBorderTop.color ||
            theme.colors.g400,
          mBorderTop.opacity || 1,
        )};`) ||
      ''};
    ${({ mBorderRight, theme }) =>
      (mBorderRight &&
        `border-right: ${checkPixel(mBorderRight.width) || '1px'} ${
          mBorderRight.style || 'solid'
        } ${hexToRGB(
          theme.colors[mBorderRight.color] ||
            mBorderRight.color ||
            theme.colors.g400,
          mBorderRight.opacity || 1,
        )};`) ||
      ''};
    ${({ mBorderLeft, theme }) =>
      (mBorderLeft &&
        `border-left: ${checkPixel(mBorderLeft.width) || '1px'} ${
          mBorderLeft.style || 'solid'
        } ${hexToRGB(
          theme.colors[mBorderLeft.color] ||
            mBorderLeft.color ||
            theme.colors.g400,
          mBorderLeft.opacity || 1,
        )};`) ||
      ''};
    ${({ mBorderRadius }) =>
      ((mBorderRadius === 0 || mBorderRadius) &&
        `border-radius: ${checkPixel(mBorderRadius)};`) ||
      ''};
  }
`;
