import { Icon, Row, Bookmark, LabelBadge, CategoryBadge } from 'components';
import { useNavigate } from 'react-router-dom';

export function DetailSubNav({ item, bucket, type, page, logging }) {
  const navigate = useNavigate();

  return (
    ((item?.isViewedContentNewLabel ||
      item?.isViewedContentStatusLabel ||
      item?.isFixedTop === 1 ||
      (item?.isViewedContentCategory && item?.category) ||
      item?.isViewedContentBookmarkBtn) && (
      <Row
        position="sticky"
        top={
          ((bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
            (Number(page?.headerOption?.headerHeight) ||
              Number(bucket.headerOption.headerHeight) ||
              60)) ||
          0
        }
        zIndex={1}
        alignItems="center"
        justifyContent={
          ((item?.isViewedContentNewLabel ||
            item?.isViewedContentStatusLabel ||
            (item?.isViewedContentCategory && item?.category) ||
            item?.isViewedContentBookmarkBtn) &&
            'space-between') ||
          'flex-end'
        }
        mt={-page?.styles.paddingTop}
        pt={10}
        pb={10}
        backgroundColor="backgroundColor"
      >
        {((item?.isViewedContentNewLabel ||
          item?.isViewedContentStatusLabel ||
          item?.isFixedTop === 1 ||
          (item?.isViewedContentCategory && item?.category) ||
          item?.isViewedContentBookmarkBtn) && (
          <>
            <Icon
              name="arrow"
              fill="fontColor"
              size={24}
              transform="rotate(90deg)"
              onClick={() => navigate(-1)}
              cursor="pointer"
              hover="background"
            />
            <Row gap={10} alignItems="center">
              {(item?.isViewedContentCategory &&
                item?.category &&
                item?.designType !== 'speaker1' && (
                  <CategoryBadge backgroundColor="white">
                    {item?.category}
                  </CategoryBadge>
                )) ||
                ''}
              {((item?.isViewedContentNewLabel ||
                item?.isViewedContentStatusLabel) && (
                <Row gap={5} alignItems="center">
                  {(item?.isViewedContentNewLabel && (
                    <LabelBadge
                      type="h10"
                      backgroundColor="white"
                      color="keyColor"
                      border={{ color: 'key500' }}
                    >
                      New
                    </LabelBadge>
                  )) ||
                    ''}
                  {(item?.isViewedContentStatusLabel && (
                    <LabelBadge
                      type="h10"
                      backgroundColor={
                        (item.status === 'completed' && 'done200') || 'g100'
                      }
                      color={(item.status === 'completed' && 'done') || 'g400'}
                      padding="0 10px"
                    >
                      {item?.statusLabels[item?.status]}
                    </LabelBadge>
                  )) ||
                    ''}
                </Row>
              )) ||
                ''}
              {(item?.isFixedTop === 1 && (
                <Icon name="pin" fill="keyColor" />
              )) ||
                ''}
              {(item?.isViewedContentBookmarkBtn && (
                <Bookmark
                  size={28}
                  isBookmark={item?.isBookmark}
                  backgroundColor={
                    (item?.isBookmark === 1 && 'key200') || 'g100'
                  }
                  fill={(item?.isBookmark === 1 && 'keyColor') || ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    logging('bookmark', item);
                  }}
                />
              )) ||
                ''}
            </Row>
          </>
        )) ||
          ''}
      </Row>
    )) ||
    ''
  );
}
