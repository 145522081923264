import { attendanceApi } from 'api';
import { Col, Div, Icon, Modal, Row, Text } from 'components';
import { useApiParams } from 'hooks';
import Modules from 'modules/Modules';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function SideStructure({
  open,
  target,
  socket,
  streamingItem,
  streamingItemCallback,
  designType,
  list,
  sideTargetCallback,
  menu,
  module,
}) {
  const { t } = useTranslation();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { DEFAULT_PARAMS } = useApiParams();
  const [selectTab, setSelectTab] = useState();
  const [menuHeight, setMenuHeight] = useState();
  const menuRef = useRef();

  const [playing, setPlaying] = useState(false);
  const getAttendanceInfo = async () => {
    if (user?.name) {
      const [info] = await attendanceApi.info(module?.moduleCode, {
        ...DEFAULT_PARAMS,
      });
      if (info) {
        setPlaying(info.result.logId);
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const startAttendance = async () => {
    if (!loading) {
      setLoading(true);
      const [info] = await attendanceApi.start(module?.moduleCode, {
        ...DEFAULT_PARAMS,
      });
      if (info) {
        setPlaying(info.result.logId);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const endAttendance = async () => {
    if (!loading) {
      setLoading(true);
      const [info] = await attendanceApi.stop(module?.moduleCode, {
        ...DEFAULT_PARAMS,
        logId: playing,
      });
      if (info) {
        setPlaying(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const [attendanceList, setAttendanceList] = useState();
  const getAttendanceList = async () => {
    const [info] = await attendanceApi.list(module?.moduleCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setAttendanceList(info.result);
    }
  };

  useEffect(() => {
    if (list?.length > 0 && designType === 'design3') {
      sideTargetCallback(list[0]);
      setSelectTab(list[0]);
    }
  }, [list, designType]);

  useEffect(() => {
    if (target?.isUsedAttendance === 1) {
      getAttendanceInfo();
    }
  }, [target]);

  useEffect(() => {
    if (designType === 'design3' && menuRef?.current) {
      setMenuHeight(menuRef?.current.getBoundingClientRect().height);
    }
  }, [designType, menuRef]);

  return (
    <>
      <Col
        width={(open && 360) || '0'}
        mWidth="100%"
        transform={(open && 'scaleX(1)') || 'scaleX(0)'}
        transition="width 200ms"
        transformOrigin="right"
        height="100%"
        gap={menu?.streamingSideStyles?.gap}
      >
        {user?.name && target?.isUsedAttendance === 1 && (
          <Row gap={10} width="100%" alignItems="center">
            <Row
              position="relative"
              borderRadius="20px"
              border={{ color: playing ? 'key200' : 'g200' }}
              backgroundColor={playing ? 'key500' : 'rgba(233, 235, 237, 0.50)'}
              height="44px"
              cursor="pointer"
              onClick={() => {
                if (!loading && !playing) {
                  startAttendance();
                } else if (!loading) {
                  endAttendance();
                }
              }}
              width="100%"
              maxWidth={202}
            >
              <Row
                position="absolute"
                backgroundColor={playing ? 'keyColor' : 'g200'}
                width="50%"
                height="100%"
                top="0px"
                left={playing ? '50%' : '0px'}
                borderRadius="20px"
                gap={5}
                padding="10px 15px"
                alignItems="center"
                justifyContent="center"
              >
                <Icon name={playing ? 'pause' : 'play'} size={24} />
              </Row>
            </Row>
            <Icon
              name="attendance-check"
              fill="keyColor"
              cursor="pointer"
              hover
              onClick={getAttendanceList}
            />
          </Row>
        )}
        {designType === 'design3' && (
          <Row
            setRef={menuRef}
            padding="20px"
            colGap={5}
            borderRadius={menu?.streamingSideStyles?.borderRadius}
            backgroundColor={menu?.streamingSideStyles.backgroundColor}
            backgroundImage={menu?.streamingSideStyles.backgroundImg}
            backgroundRepeat="no-repeat"
            backgroundSize="100% auto"
            backgroundPosition="top left"
            pt={menu?.streamingSideStyles.paddingTop}
            pl={menu?.streamingSideStyles.paddingLeft}
            pr={menu?.streamingSideStyles.paddingRight}
            pb={menu?.streamingSideStyles.paddingBottom}
            boxShadow={menu?.streamingSideStyles?.boxShadow || ''}
            flexWrap="wrap"
          >
            {list?.map((item) => (
              <Row
                flex={1}
                key={item.moduleCode}
                height={41}
                alignItems="center"
                justifyContent="center"
                padding="10px"
                hover={list?.length > 1}
                cursor={list?.length > 1 ? 'pointer' : 'auto'}
                borderBottom={{
                  color:
                    (selectTab?.moduleCode === item.moduleCode && 'keyColor') ||
                    'g300',
                  width:
                    (selectTab?.moduleCode === item.moduleCode && '3px') ||
                    '1px',
                }}
                onClick={() => {
                  setSelectTab(item);
                  sideTargetCallback(item);
                }}
              >
                <Text
                  type={
                    (selectTab?.moduleCode === item.moduleCode && 'h8') || 'b2'
                  }
                  color={
                    (selectTab?.moduleCode === item.moduleCode && 'g800') ||
                    'g500'
                  }
                  whiteSpace="nowrap"
                >
                  {item.name}
                </Text>
              </Row>
            ))}
          </Row>
        )}
        {target && (
          <Modules
            type="side"
            socket={socket}
            moduleCode={target?.moduleCode}
            moduleType={target?.moduleType}
            streamingItem={streamingItem}
            streamingItemCallback={streamingItemCallback}
            maxHeight={`calc(100% - ${
              menuHeight + Number(menu?.streamingSideStyles?.gap)
            }px)`}
          />
        )}
      </Col>
      {attendanceList && (
        <Modal width="400" close={() => setAttendanceList(false)}>
          <Row alignItems="center" justifyContent="space-between" mb={20}>
            <Text type="h7" width={140} textAlign="center">
              {t('start')}
            </Text>
            <Text type="h7" width={140} textAlign="center">
              {t('end')}
            </Text>
          </Row>
          <Col gap={5}>
            {attendanceList?.map((item, index) => (
              <Row
                key={`${item.startedDt}${index}`}
                alignItems="center"
                justifyContent="space-between"
              >
                <Text width={140} textAlign="center">
                  {moment(item.startedDt).format('YYYY-MM-DD HH:mm:ss')}
                </Text>
                <Text width={140} textAlign="center">
                  {item.endedDt
                    ? moment(item.endedDt).format('YYYY-MM-DD HH:mm:ss')
                    : '-'}
                </Text>
              </Row>
            ))}
          </Col>
        </Modal>
      )}
    </>
  );
}
