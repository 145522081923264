import { Col, Div, ReservationDropdown, Text } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReservationList({ item, values, setValues }) {
  const { t } = useTranslation();
  const [childList, setChildList] = useState();
  const [grandChildList, setGrandChildList] = useState();
  const onChange = (value, name, index, parentName) => {
    if (
      Object.keys(item.list[index].childList)?.includes(value.selectionCode)
    ) {
      // child가 있는 항목 선택
      setChildList({
        ...childList,
        [name]: [...item.list[index].childList[value.selectionCode]],
      });
      let tempChildValues = {};
      item.list[index].childList[value.selectionCode].forEach((childEl) => {
        tempChildValues = { ...tempChildValues, [childEl.itemCode]: '' };
      });
      setValues({ ...values, [name]: value.selectionCode, ...tempChildValues });
    } else if (
      Object.keys(item.list[index].childList).length > 0 &&
      !parentName
    ) {
      //  child가 있는 다른 항목 선택
      Object.values(item.list[index].childList)
        .flat(1)
        .forEach((el) => {
          if (values) delete values[el.itemCode];
        });
      setValues({ ...values, [name]: value.selectionCode });
      if (childList) {
        delete childList[name];
        setChildList(childList);
      }
    } else if (
      childList &&
      childList[parentName] &&
      Object.keys(childList[parentName][index].childList).includes(
        value.selectionCode,
      )
    ) {
      const parentItem = childList[parentName].find((child) =>
        child.selections?.find(
          (childSel) => childSel.selectionCode === value.selectionCode,
        ),
      );
      // grand child가 있는 항목 선택
      const targetGrandChildList = parentItem.childList[value.selectionCode];
      setGrandChildList({
        ...grandChildList,
        [name]: [...targetGrandChildList],
      });
      let tempGrandChildValues = {};
      targetGrandChildList.forEach((grandChildEl) => {
        tempGrandChildValues = {
          ...tempGrandChildValues,
          [grandChildEl.itemCode]: '',
        };
      });
      setValues({
        ...values,
        [name]: value.selectionCode,
        ...tempGrandChildValues,
      });
    } else if (
      childList &&
      childList[parentName] &&
      Object.keys(childList[parentName][index].childList).length > 0
    ) {
      // grand child가 있는 다른 항목 선택
      const parentItem = childList[parentName].find((child) =>
        child.selections?.find(
          (childSel) => childSel.selectionCode === value.selectionCode,
        ),
      );
      Object.values(parentItem.childList)
        .flat(1)
        .forEach((el) => {
          if (values) delete values[el.itemCode];
        });
      setValues({ ...values, [name]: value.selectionCode });
      if (grandChildList) {
        delete grandChildList[name];
        setGrandChildList(grandChildList);
      }
    } else {
      setValues({ ...values, [name]: value.selectionCode });
    }
  };

  useEffect(() => {
    if (values) {
      //
    }
  }, [values]);

  return (
    <Div
      backgroundColor="backgroundColor"
      width="100%"
      position="relative"
      mMaxHeight="70vh"
      overflowY="overlay"
      mPadding="0 25px 10px 25px"
      mb={30}
      mMb="0"
    >
      {item?.list.map((el, index) => (
        <Col gap={5} key={el.itemCode}>
          <Div padding="5px 0">
            <Text color="g500" type="b3" mb={5} padding="0 5px">
              {el?.title}
            </Text>
            {/* 일반 */}
            <ReservationDropdown
              placeholder={`${el.title} ${t('select')}`}
              name={el.itemCode}
              value={el.selections?.find(
                (sel) => sel.selectionCode === values?.[el.itemCode],
              )}
              list={el.selections}
              onChange={(value, name) => onChange(value, name, index)}
              customItem={(selectionItem) => selectionItem?.selection}
            />
          </Div>
          {childList &&
            childList[el.itemCode]?.map((childEl, childIndex) => (
              <Div key={childEl.itemCode} padding="5px 0">
                {/* child */}
                <Text color="g500" type="b3" mb={5} padding="0 5px">
                  {childEl?.title}
                </Text>
                <ReservationDropdown
                  placeholder={`${childEl.title} ${t('select')}`}
                  name={childEl.itemCode}
                  value={childEl.selections?.find(
                    (sel) => sel.selectionCode === values?.[childEl.itemCode],
                  )}
                  list={childEl.selections}
                  onChange={(value, name) =>
                    onChange(value, name, childIndex, el.itemCode)
                  }
                  customItem={(selectionItem) => selectionItem?.selection}
                />
                {grandChildList &&
                  grandChildList[childEl.itemCode]?.map((grandChildEl) => (
                    <Div key={grandChildEl.itemCode} padding="10px 0 5px 0">
                      {/* grand child */}
                      <Text color="g500" type="b3" mb={5} padding="0 5px">
                        {grandChildEl?.title}
                      </Text>
                      <ReservationDropdown
                        placeholder={`${grandChildEl.title} ${t('select')}`}
                        name={grandChildEl.itemCode}
                        value={grandChildEl.selections?.find(
                          (sel) =>
                            sel.selectionCode ===
                            values?.[grandChildEl.itemCode],
                        )}
                        list={grandChildEl.selections}
                        onChange={(value, name) =>
                          onChange(value, name, index, childEl.itemCode)
                        }
                        customItem={(selectionItem) => selectionItem?.selection}
                      />
                    </Div>
                  ))}
              </Div>
            ))}
        </Col>
      ))}
    </Div>
  );
}
