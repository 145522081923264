import {
  Badge,
  HTMLWrap,
  Row,
  Text,
  BadgeButton,
  Col,
  Div,
  Image,
  Icon,
  PopupB,
  ProfileImage,
  Span,
} from 'components';
import moment from 'moment';
import React, { useState } from 'react';
import fileDownloader from 'utils/fileDownloader';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'styles/css/swiper.css';
import { isMobile } from 'utils/isMobile';
import { useWindowSize } from 'hooks';
import GalleryWrite1 from '../Board/GalleryWrite1';

export function BoardDetailGalleryCard({
  t,
  item,
  logging,
  targetEditItem,
  setTargetEditItem,
  deleting,
  setError,
  getDetail,
  setFilters,
  type,
  isEdit,
  setIsEdit,
}) {
  const [fileOpen, setFileOpen] = useState(false);

  const handleDragStart = (e) => e.preventDefault();

  const renderSlideInfo = ({ item, itemsCount }) => (
    <>
      <Span type="b3" color="g0">
        {item}
      </Span>{' '}
      <Span type="b3" color="g200">
        /{itemsCount}
      </Span>
    </>
  );
  const [activeIndex, setActiveIndex] = useState(0);

  const windowSize = useWindowSize();

  return (
    item && (
      <>
        <Col className="board-gallery" gap={20}>
          {item?.attachmentFiles?.length > 0 && (
            <AliceCarousel
              activeIndex={activeIndex}
              onSlideChanged={({ item }) => setActiveIndex(item)}
              animationType="fadeout"
              disableDotsControls
              disableSlideInfo={false}
              renderSlideInfo={renderSlideInfo}
              mouseTracking={item.attachmentFiles.length !== 1}
              touchTracking={item.attachmentFiles.length !== 1}
              disableButtonsControls={item.attachmentFiles.length === 1}
              items={item?.attachmentFiles.map((file, index) => (
                <Div
                  key={`gallery_${index}`}
                  style={{
                    width: '100%',
                    aspectRatio: '1 / 1',
                  }}
                  onDragStart={handleDragStart}
                  data-value={index + 1}
                >
                  <Image
                    src={file.fileUrl}
                    alt={item.fileName}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    borderRadius="6px"
                  />
                </Div>
              ))}
            ></AliceCarousel>
          )}
          {((item?.isFixedTop === 1 ||
            !!item.title ||
            (item?.isViewedContentCategory && item?.category) ||
            item?.isMine === 1 ||
            item?.content) && (
            <Col gap={10}>
              {(item?.isFixedTop === 1 ||
                !!item.title ||
                (item?.isViewedContentCategory && item?.category) ||
                item?.isMine === 1) && (
                <Row
                  gap={10}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Row gap={10} alignItems="center">
                    {(item?.isFixedTop === 1 && (
                      <Icon name="pin" fill="keyColor" />
                    )) ||
                      ''}
                    {((!!item.title ||
                      (item?.isViewedContentCategory && item?.category)) && (
                      <Div>
                        <Text type="h4">
                          {(item?.isViewedContentCategory &&
                            item.category &&
                            `[${item.category}] `) ||
                            ''}
                          {item.title}
                        </Text>
                      </Div>
                    )) ||
                      ''}
                  </Row>
                  {((!isMobile() || type === 'modal') &&
                    item?.isMine === 1 &&
                    (item?.isUsedEditingContent ||
                      item?.isUsedDeletingContent) && (
                      <PopupB
                        name={item.itemCode}
                        items={
                          <>
                            {(item?.isUsedEditingContent && (
                              <Text
                                hover="background"
                                cursor="pointer"
                                whiteSpace="nowrap"
                                padding="4px 10px"
                                onClick={() => {
                                  setTargetEditItem(item);
                                  setIsEdit(true);
                                }}
                              >
                                {t('edit')}
                              </Text>
                            )) ||
                              ''}

                            {(item?.isUsedDeletingContent && (
                              <Text
                                hover="background"
                                cursor="pointer"
                                onClick={() => deleting(item)}
                                whiteSpace="nowrap"
                                padding="4px 10px"
                              >
                                {t('delete')}
                              </Text>
                            )) ||
                              ''}
                          </>
                        }
                      >
                        <Icon
                          name="more"
                          size={21}
                          cursor="pointer"
                          hover="circle"
                          transform="rotate(90deg)"
                          fill="g500"
                        />
                      </PopupB>
                    )) ||
                    ''}
                </Row>
              )}
              {((item?.isViewedUserName ||
                item?.isViewedUserThumbnail ||
                item?.isViewedContentCreatedDt) && (
                <Row alignItems="center" gap={10}>
                  {(item?.isViewedUserThumbnail && item?.userThumbnail && (
                    <ProfileImage size={40} thumbnail={item?.userThumbnail} />
                  )) ||
                    ''}
                  <Div>
                    {(item?.isViewedUserName && (
                      <Text type="h7">{item?.userName}</Text>
                    )) ||
                      ''}
                    {(item?.isViewedContentCreatedDt && (
                      <Text color="g400" type="b2">
                        {moment(item.createdDt).format('YYYY-MM-DD HH:mm')}
                      </Text>
                    )) ||
                      ''}
                  </Div>
                </Row>
              )) ||
                ''}
              {item?.content && (
                <HTMLWrap
                  pt={20}
                  mb={15}
                  content={
                    windowSize.width > 1279
                      ? item?.content
                      : (item?.mobileContent && item?.mobileContent) ||
                        item?.content
                  }
                  style={{ display: 'block' }}
                  display="block"
                />
              )}
            </Col>
          )) ||
            ''}
          {(((item.isViewedContentTags && item.tags?.length > 0) ||
            item?.isViewedContentLikeBtn ||
            item?.isViewedContentClickCount ||
            item?.isViewedContentCommentBtn) && (
            <Col gap={15}>
              {(item.isViewedContentTags && item.tags?.length > 0 && (
                <Text
                  color="g400"
                  type="b4"
                  display="flex"
                  gap={6}
                  flexWrap="wrap"
                >
                  {item.tags?.map((tag) => (
                    <Badge
                      key={`${tag}${Math.random()}`}
                      color="g400"
                      type="b4"
                    >
                      {tag}
                    </Badge>
                  ))}
                </Text>
              )) ||
                ''}
              {((item?.isViewedContentLikeBtn ||
                item?.isViewedContentClickCount ||
                item?.isViewedContentCommentBtn) && (
                <Row gap={5} alignItems="center">
                  {(item?.isViewedContentLikeBtn && (
                    <BadgeButton
                      name="like"
                      active={item?.isLike === 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        logging('like', item);
                      }}
                    >
                      {(item?.isViewedContentLikeCount &&
                        ((item?.likes > 100 && '100+') ||
                          item?.likes?.toString())) ||
                        ''}
                    </BadgeButton>
                  )) ||
                    ''}
                  {(item?.isViewedContentClickCount && (
                    <BadgeButton name="view" fill="g500" stroke="transparent">
                      {(item?.clicks > 100 && '100+') ||
                        item?.clicks?.toString() ||
                        ''}
                    </BadgeButton>
                  )) ||
                    ''}
                  {(item?.isViewedContentCommentBtn && (
                    <BadgeButton
                      name="comment"
                      point={item?.isNewComment === 1}
                    >
                      {(item?.isViewedContentCommentCount &&
                        ((Number(item?.comments) > 100 && '100+') ||
                          item?.comments?.toString())) ||
                        ''}
                    </BadgeButton>
                  )) ||
                    ''}
                </Row>
              )) ||
                ''}
            </Col>
          )) ||
            ''}
          {(item?.attachmentFiles?.length > 0 &&
            item?.isViewedContentAttachmentFileBtn && (
              <Row
                alignItems="flex-start"
                gap={20}
                mFlexDirection="column"
                mGap={15}
              >
                <Row alignItems="center">
                  <Row
                    gap={5}
                    alignItems="center"
                    hover
                    cursor="pointer"
                    onClick={() => setFileOpen(!fileOpen)}
                  >
                    <Icon
                      name="arrow"
                      fill="g500"
                      transform={(fileOpen && 'rotate(-180deg)') || 'rotate(0)'}
                      transition="all 150ms"
                    />
                    <Text type="b2">
                      {t('file_count', { count: item?.attachmentFiles.length })}
                    </Text>
                  </Row>
                </Row>
                {fileOpen && (
                  <Col
                    border={{ color: 'g200' }}
                    borderRadius="6px"
                    padding="15px 20px"
                    gap={10}
                  >
                    {item?.attachmentFiles.map((file) => (
                      <Row
                        key={file.fileUrl}
                        onClick={() =>
                          fileDownloader(file.fileUrl, file.fileName)
                        }
                        gap={10}
                        alignItems="center"
                        cursor="pointer"
                        hover="background"
                        justifyContent="space-between"
                      >
                        <Text maxLine={1} style={{ wordBreak: 'break-word' }}>
                          {file.fileName}
                        </Text>
                        <Icon name="download" fill="g800" />
                      </Row>
                    ))}
                  </Col>
                )}
              </Row>
            )) ||
            ''}
        </Col>
        <GalleryWrite1
          module={item}
          type={type}
          display="none"
          targetEditItem={targetEditItem}
          setTargetEditItem={setTargetEditItem}
          setError={setError}
          getModules={getDetail}
          setFilters={setFilters}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      </>
    )
  );
}
