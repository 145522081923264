import { Div, Col, Text, Button, Icon, Dim, Row } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { animations } from 'styles/css';

export function ChildAlert({
  title,
  content,
  confirm,
  close,
  cancel,
  loading,
  children,
  confirmLabel,
  cancelLabel,
  buttonStyles,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <>
      <Dim onClick={close} overflowHidden />
      <Div
        position="fixed"
        zIndex={9999}
        backgroundColor="white"
        top="50%"
        left="50%"
        borderRadius="1.2rem"
        padding="30px 30px 20px 30px"
        animation={animations.slideUp()}
        width={320}
        {...props}
      >
        {close && (
          <Icon
            name="close"
            position="absolute"
            size={36}
            top="-46px"
            right="0"
            cursor="pointer"
            onClick={close}
            stroke="white"
          />
        )}
        {children && (
          <Col gap={10}>
            {title && (
              <Col
                minHeight={(content && 'unset') || 43}
                alignItems="center"
                justifyContent="center"
              >
                <Text type="h6" textAlign="center">
                  {title}
                </Text>
              </Col>
            )}
            {content && (
              <Col alignItems="center" justifyContent="center">
                <Text color="g500" textAlign="center">
                  {content}
                </Text>
              </Col>
            )}
            {children}
          </Col>
        )}
        <Row mt={(children && 20) || 0} gap={10} alignItems="center">
          {cancel && (
            <Button
              borderRadius="4px"
              buttonType="full"
              ghost
              onClick={cancel}
              {...buttonStyles}
            >
              {cancelLabel || t('cancel')}
            </Button>
          )}
          {confirm && (
            <Button
              borderRadius="4px"
              buttonType="full"
              active
              keyColor="keyColor"
              keyFontColor="keyFontColor"
              onClick={confirm}
              loading={loading}
              {...buttonStyles}
            >
              {confirmLabel || t('confirm')}
            </Button>
          )}
        </Row>
      </Div>
    </>
  );
}
