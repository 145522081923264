import React from 'react';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import 'styles/css/editor.css';
import { Div } from 'components';

export function HTMLWrap({ content, ...props }) {
  return (
    content && (
      <Div {...props}>
        <FroalaEditorView
          model={content}
          config={{
            key: process.env.REACT_APP_FLOALA_API_KEY,
          }}
        ></FroalaEditorView>
      </Div>
    )
  );
}
