import { Alert, Loading } from 'components';
import { useLanguageNavigate } from 'hooks';
import React, { useEffect, useState } from 'react';
import ChangePasswordPresenter from './ChangePasswordPresenter';

function ChangePasswordContainer({ t, bucket, setBucket }) {
  const { languageNavigate, currentLanguage } = useLanguageNavigate();
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // terms setting
    if (bucket && currentLanguage) {
      setLoading(false);
    } else {
      languageNavigate(`/`);
    }
  }, [bucket, currentLanguage]);

  return (
    <>
      <ChangePasswordPresenter
        t={t}
        bucket={bucket}
        info={bucket?.loginInfo[currentLanguage]}
        setAlert={setAlert}
      />

      {alert && <Alert {...alert} />}
      {loading && <Loading />}
    </>
  );
}

export default ChangePasswordContainer;
