import React, { useState } from 'react';
import { Dim, Div } from 'components';
import TypeA from './TypeA';
import TypeB from './TypeB';

const headerPopup = (
  open,
  setOpen,
  label,
  items,
  backgroundColor,
  name,
  headerHeight,
) => ({
  1: (
    <TypeA
      open={open}
      setOpen={setOpen}
      label={label}
      items={items}
      backgroundColor={backgroundColor}
      name={name}
      headerHeight={headerHeight}
    />
  ),
  2: (
    <TypeB
      open={open}
      setOpen={setOpen}
      label={label}
      items={items}
      backgroundColor={backgroundColor}
      name={name}
      headerHeight={headerHeight}
    />
  ),
});

export function HeaderPopup({
  type,
  name,
  children,
  label,
  items,
  backgroundColor,
  headerHeight,
  ...props
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <Dim
          top="0"
          backgroundColor="transparent"
          zIndex="1"
          onClick={() => setOpen(false)}
        />
      )}
      <Div position="relative" {...props}>
        <Div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(!open);
          }}
          position="relative"
          zIndex={(open && 5) || '0'}
        >
          {children}
        </Div>
        {
          headerPopup(
            open,
            setOpen,
            label,
            items,
            backgroundColor,
            name,
            headerHeight,
          )[type]
        }
      </Div>
    </>
  );
}
