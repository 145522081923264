import {
  Div,
  Row,
  RegistrationSession,
  RegistrationCheckButtonBox,
  WYSIWYGTitle,
  Text,
  Grid,
} from 'components';
import React, { useEffect, useState } from 'react';
import { isEmptyObject } from 'utils/isEmptyObject';

export function RegistrationListReadonly({
  isCanceled,
  isPaid,
  isSubmit,
  targetSession,
  changeTargetSession,
  sessionList,
  values,
  activeSession,
  isCompleted,
  isModified,
  cancel,
  confirmType,
}) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [row, setRow] = useState();
  const [totalRow, setTotalRow] = useState();

  // 주어진 두 수의 최대공약수를 계산하는 함수
  function findGreatestCommonDivisor(a, b) {
    while (b !== 0) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  }

  // 주어진 두 수의 최소공배수를 계산하는 함수
  function findLeastCommonMultipleOfTwo(a, b) {
    return (a * b) / findGreatestCommonDivisor(a, b);
  }

  // 주어진 여러 수의 최소공배수를 계산하는 함수
  function findLeastCommonMultipleOfMultipleNumbers(numbers) {
    let lcm = numbers[0];

    for (let i = 1; i < numbers.length; i += 1) {
      lcm = findLeastCommonMultipleOfTwo(lcm, numbers[i]);
    }

    return lcm;
  }

  useEffect(() => {
    if (sessionList && targetSession) {
      const currentList = sessionList?.find(
        (session) => session?.sessionCode === targetSession?.sessionCode,
      )?.itemList;
      let tempRow = {};
      let loop = false;
      let totalArray = [];
      if (currentList) {
        currentList?.forEach((el, index) => {
          // 코드 순서 변경 하지 말것
          if (el.itemType === 'rowEnd') {
            loop = false;
          }
          if (loop || loop === 0) {
            tempRow = { ...tempRow, [index]: currentList[loop].totalRows };
          }
          if (el.itemType === 'rowStart') {
            loop = index;
            totalArray = [...totalArray, el.totalRows];
          }
        });
        const total = findLeastCommonMultipleOfMultipleNumbers(totalArray);
        setTotalRow(total || 1);
        setRow(tempRow);
        setList([...currentList]);
      }
      setLoading(false);
    }
  }, [sessionList, targetSession]);

  return (
    <Row
      margin="0 auto"
      gap={40}
      alignItems="flex-start"
      mFlexDirection="column"
    >
      <RegistrationSession
        isSubmit={isSubmit}
        sessionList={sessionList}
        targetSession={targetSession}
        changeTargetSession={changeTargetSession}
        activeSession={activeSession}
      />
      {!loading && (
        <Grid
          width="100%"
          margin="0 auto"
          maxWidth={920}
          minWidth={820}
          mMinWidth="unset"
          cols={totalRow}
          colGap={20}
          rowGap={40}
        >
          {list?.map(
            (item, itemIndex) =>
              !item.itemType.includes('row') && (
                <Div
                  key={`${item.itemCode}-${itemIndex}`}
                  style={{
                    gridColumn:
                      (item.direction.includes('row') &&
                        `auto / span ${totalRow / row[itemIndex]}`) ||
                      `auto / span ${totalRow}`,
                  }}
                >
                  <WYSIWYGTitle title={item.title} item={item} />
                  <Div padding="10px">
                    {(item.answer &&
                      item.itemType.includes('multiple') &&
                      item.answer?.map((answer, index) => (
                        <Text
                          color="g600"
                          key={`${item.itemCode}-${answer.selectionCode}-${index}`}
                        >
                          {answer.fileName || answer.title}
                        </Text>
                      ))) ||
                      (item.answer && (
                        <Text color="g600" whiteSpace="pre-line">
                          {(item.answer && item.answer[0]?.full) ||
                            (item.answer && item.answer[0]?.countryName) ||
                            (item.answer && item.answer[0]?.fileName) ||
                            (item.answer && item.answer[0]?.title) ||
                            (item.answer &&
                              (item.answer[0]?.address ||
                                item.answer[0]?.addressDetail ||
                                item.answer[0]?.postNumber) &&
                              `${item.answer[0]?.postNumber || ''} ${
                                item.answer[0]?.address || ''
                              } ${item.answer[0]?.addressDetail || ''}`) ||
                            (item.answer &&
                              !isEmptyObject(item.answer[0]) &&
                              item.answer[0]) ||
                            ''}
                        </Text>
                      )) ||
                      (!item.itemType.includes('content') && <Text>-</Text>)}
                  </Div>
                  {values[item.itemCode] &&
                    values[item.itemCode][0] &&
                    item.childList[
                      values[item.itemCode][0]?.countryCode ||
                        values[item.itemCode][0]?.selectionCode
                    ]?.map((childItem) => (
                      <Div key={childItem.itemCode} mt={20}>
                        <WYSIWYGTitle
                          title={childItem.title}
                          item={childItem}
                        />
                        {(childItem.itemType.includes('multiple') &&
                          values[childItem.itemCode]?.map(
                            (answer, answerIndex) => (
                              <Text
                                color="g600"
                                key={`${childItem.itemCode}-${answerIndex}`}
                              >
                                {answer.fileName || answer.title}
                              </Text>
                            ),
                          )) || (
                          <Text color="g600" whiteSpace="pre-line">
                            {(values[childItem.itemCode] &&
                              values[childItem.itemCode][0]?.full) ||
                              (values[childItem.itemCode] &&
                                values[childItem.itemCode][0]?.countryName) ||
                              (values[childItem.itemCode] &&
                                values[childItem.itemCode][0]?.fileName) ||
                              (values[childItem.itemCode] &&
                                values[childItem.itemCode][0]?.title) ||
                              (!isEmptyObject(values[childItem.itemCode]) &&
                                values[childItem.itemCode]) ||
                              ''}
                          </Text>
                        )}
                        {childItem?.childList &&
                          Object.keys(childItem?.childList).length > 0 &&
                          values[childItem.itemCode] &&
                          values[childItem.itemCode][0] &&
                          childItem.childList[
                            values[childItem.itemCode][0]?.countryCode ||
                              values[childItem.itemCode][0].selectionCode
                          ]?.map((grandChildItem) => (
                            <Div key={grandChildItem.itemCode} mt={20}>
                              <WYSIWYGTitle
                                title={grandChildItem.title}
                                item={grandChildItem}
                              />
                              {(grandChildItem.itemType.includes('multiple') &&
                                values[grandChildItem.itemCode]?.map(
                                  (answer, answerIndex) => (
                                    <Text
                                      whiteSpace="pre-line"
                                      color="g600"
                                      key={`${grandChildItem.itemCode}-${answerIndex}`}
                                    >
                                      {answer.fileName || answer.title}
                                    </Text>
                                  ),
                                )) || (
                                <Text color="g600" whiteSpace="pre-line">
                                  {(values[grandChildItem.itemCode] &&
                                    values[grandChildItem.itemCode][0]?.full) ||
                                    (values[grandChildItem.itemCode] &&
                                      values[grandChildItem.itemCode][0]
                                        ?.countryName) ||
                                    (values[grandChildItem.itemCode] &&
                                      values[grandChildItem.itemCode][0]
                                        ?.fileName) ||
                                    (values[grandChildItem.itemCode] &&
                                      values[grandChildItem.itemCode][0]
                                        ?.title) ||
                                    (!isEmptyObject(
                                      values[grandChildItem.itemCode],
                                    ) &&
                                      values[grandChildItem.itemCode]) ||
                                    ''}
                                </Text>
                              )}
                            </Div>
                          ))}
                      </Div>
                    ))}
                </Div>
              ),
          )}
          {(isCanceled === 0 && (
            <RegistrationCheckButtonBox
              isCompleted={isCompleted}
              isModified={isModified}
              cancel={cancel}
              confirmType={(isPaid !== 1 && confirmType) || false}
              sessionList={sessionList}
              style={{
                gridColumn: `span ${totalRow}`,
              }}
            />
          )) ||
            ''}
        </Grid>
      )}
    </Row>
  );
}
