import { Row, Div, ProfileImage, Text } from 'components';
import { useViewedItems } from 'hooks';
import moment from 'moment';
import React, { useEffect } from 'react';
import 'moment/locale/ko';

export function Bubble({ item, ...props }) {
  const {
    setItem,
    isViewedCreatedDt,
    isViewedUserThumbnail,
    isViewedUserName,
    isMine,
  } = useViewedItems();

  useEffect(() => {
    if (item) {
      setItem(item);
    }
  }, [item]);

  return (
    <Row justifyContent={(isMine && 'flex-end') || ''} gap={10} {...props}>
      {isViewedUserThumbnail && !isMine && (
        <ProfileImage size={40} thumbnail={item.userThumbnail} />
      )}
      <Div>
        {isViewedUserName && !isMine && (
          <Text type="h9" mb={4}>
            {item.userName}
          </Text>
        )}
        <Text
          width="fit-content"
          borderRadius={(isMine && '18px 18px 0 18px') || '18px 18px 18px 0'}
          backgroundColor={(isMine && 'key200') || 'g100'}
          padding="14px 20px"
          whiteSpace="pre-line"
        >
          {item.content}
        </Text>
        {isViewedCreatedDt && (
          <Text
            type="b4"
            color="g400"
            mt={4}
            textAlign={(isMine && 'right') || ''}
          >
            {moment(item.createdDt).format('LT')}
          </Text>
        )}
      </Div>
    </Row>
  );
}

export function BubbleB({ item, ...props }) {
  const {
    setItem,
    isViewedCreatedDt,
    isViewedUserThumbnail,
    isViewedUserName,
    isMine,
  } = useViewedItems();

  useEffect(() => {
    if (item) {
      setItem(item);
    }
  }, [item]);

  return (
    <Row justifyContent={(isMine && 'flex-end') || ''} gap={10} {...props}>
      {isViewedUserThumbnail && !isMine && (
        <ProfileImage size={40} thumbnail={item.userThumbnail} />
      )}
      <Div>
        {isViewedUserName && !isMine && (
          <Text type="h9" mb={4}>
            {item.userName}
          </Text>
        )}
        <Text
          width="fit-content"
          borderRadius={(isMine && '18px 18px 0 18px') || '18px 18px 18px 0'}
          backgroundColor={(isMine && 'key200') || 'g100'}
          padding="14px 20px"
          whiteSpace="pre-line"
        >
          {item.content}
        </Text>
        {isViewedCreatedDt && (
          <Text
            type="b4"
            color="g400"
            mt={4}
            textAlign={(isMine && 'right') || ''}
          >
            {moment(item.createdDt).format('LT')}
          </Text>
        )}
      </Div>
    </Row>
  );
}

export function UserBubble({ item, ...props }) {
  return (
    <Row
      justifyContent={(item?.isMine && 'flex-end') || ''}
      gap={10}
      {...props}
    >
      {!item?.isMine && (
        <ProfileImage size={40} thumbnail={item.userThumbnail} />
      )}
      <Div>
        {!item?.isMine && (
          <Text type="h9" mb={4}>
            {item.userName}
          </Text>
        )}
        <Text
          borderRadius={
            (item?.isMine && '18px 18px 0 18px') || '18px 18px 18px 0'
          }
          backgroundColor={(item?.isMine && 'key200') || 'g0'}
          padding="14px 20px"
          whiteSpace="pre-line"
        >
          {item.content}
        </Text>
        <Text
          type="b4"
          color="g400"
          mt={4}
          textAlign={(item?.isMine && 'right') || ''}
        >
          {moment(item.createdDt).format('LT')}
        </Text>
      </Div>
    </Row>
  );
}
