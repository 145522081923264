export const emailDomain = [
  '@naver.com',
  '@gmail.com',
  '@daum.net',
  '@hanmail.net',
  '@kakao.com',
  '@nate.com',
  '@hotmail.com',
  '@yahoo.co.kr',
];

export const genders = ['male', 'female'];
export const gendersWithETC = ['male', 'female', 'etc'];
