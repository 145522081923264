import React, { createRef, useEffect, useMemo, useRef } from 'react';
import { TextareaStyles, GalleryTextareaStyles } from 'styles/InputStyles';
import { Div, Row, Icon, Text, Invalid } from 'components';

const DEFAULT_PADDING = 16 + 21;

export function Textarea({
  setRef,
  label,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  disabled,
  rightChildren,
  position,
  wrapStyles,
  invalid,
  ...props
}) {
  const onKeyDownHandler = (event) => {
    if (onKeyDown && onKeyDownActive) {
      if (event.key === 'Enter' && event.shiftKey === false) {
        onKeyDown();
      }
    }
  };

  const textareaRef = createRef();

  const inputSizeHandler = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    onChange(value, name);
  };

  const childrenRef = useRef();

  const calcPadding = () => {
    if (childrenRef.current) {
      return childrenRef.current.clientWidth + DEFAULT_PADDING;
    }
  };

  const inputPr = useMemo(() => calcPadding(), [childrenRef]);

  const deleteValue = () => {
    onChange('', name);
  };

  useEffect(() => {
    inputSizeHandler();
  }, [value, textareaRef.current]);

  return (
    <Div {...wrapStyles}>
      {label && (
        <Text type="b3" mb={4}>
          {label}
        </Text>
      )}
      <Div position={position || 'relative'}>
        <TextareaStyles
          ref={setRef || textareaRef}
          name={name}
          value={value}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          invalid={invalid?.type}
          pr={inputPr}
          rows={3}
          disabled={disabled}
          {...props}
        />
        <Row
          className="input-button"
          setRef={childrenRef}
          position="absolute"
          right="10px"
          top="50%"
          transform="translateY(-50%)"
          zIndex="0"
          alignItems="center"
          gap={6}
        >
          <Icon
            visibility={(value && 'visible') || 'hidden'}
            className="input-delete"
            size={21}
            name="delete"
            cursor="pointer"
            onMouseDown={deleteValue}
          />
          {rightChildren}
        </Row>
      </Div>
      {invalid && <Invalid invalid={invalid} />}
    </Div>
  );
}

export function GalleryTextarea({
  setRef,
  label,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  disabled,
  rightChildren,
  position,
  wrapStyles,
  invalid,
  ...props
}) {
  const onKeyDownHandler = (event) => {
    if (onKeyDown && onKeyDownActive) {
      if (event.key === 'Enter' && event.shiftKey === false) {
        onKeyDown();
      }
    }
  };

  const textareaRef = createRef();

  const inputSizeHandler = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    onChange(value, name);
  };

  const childrenRef = useRef();

  const calcPadding = () => {
    if (childrenRef.current) {
      return childrenRef.current.clientWidth + DEFAULT_PADDING;
    }
  };

  const inputPr = useMemo(() => calcPadding(), [childrenRef]);

  const deleteValue = () => {
    onChange('', name);
  };

  useEffect(() => {
    inputSizeHandler();
  }, [value, textareaRef.current]);

  return (
    <Div {...wrapStyles}>
      {label && (
        <Text type="b3" mb={4}>
          {label}
        </Text>
      )}
      <Div position={position || 'relative'}>
        <GalleryTextareaStyles
          ref={setRef || textareaRef}
          name={name}
          value={value}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          invalid={invalid?.type}
          pr={inputPr}
          rows={3}
          disabled={disabled}
          {...props}
        />
        <Row
          className="input-button"
          setRef={childrenRef}
          position="absolute"
          right="10px"
          top="50%"
          transform="translateY(-50%)"
          zIndex="0"
          alignItems="center"
          gap={6}
        >
          <Icon
            visibility={(value && 'visible') || 'hidden'}
            className="input-delete"
            size={21}
            name="delete"
            cursor="pointer"
            onMouseDown={deleteValue}
          />
          {rightChildren}
        </Row>
      </Div>
      {invalid && <Invalid invalid={invalid} />}
    </Div>
  );
}
