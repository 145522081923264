import { Button, Div, Row, InputCard } from 'components';
import React from 'react';

export function SurveyList({
  t,
  list,
  values,
  etcValues,
  setEtcValues,
  onChange,
  onEtcChange,
  onMutipleChange,
  onCheckChange,
  active,
  confirm,
  goBack,
}) {
  return (
    <>
      {list?.map((item) => (
        <Div key={item.itemCode} mb={40}>
          <InputCard
            designType="design2"
            value={
              (item.itemType.includes('multiple') && values[item.itemCode]) ||
              (values[item.itemCode] && values[item.itemCode][0]) ||
              ''
            }
            etcValues={(etcValues && etcValues[item.itemCode]) || ''}
            setEtcValues={setEtcValues}
            name={item.itemCode}
            onChange={onChange}
            onEtcChange={onEtcChange}
            onMutipleChange={onMutipleChange}
            onCheckChange={onCheckChange}
            item={item}
          />
          {values[item.itemCode] &&
            values[item.itemCode][0] &&
            item.childList[values[item.itemCode][0]] &&
            item.childList[values[item.itemCode][0]].map((childItem) => (
              <InputCard
                key={childItem.itemCode}
                designType="design2"
                value={
                  (childItem.itemType.includes('multiple') &&
                    values[childItem.itemCode]) ||
                  (values[childItem.itemCode] &&
                    values[childItem.itemCode][0]) ||
                  ''
                }
                etcValues={(etcValues && etcValues[item.itemCode]) || ''}
                setEtcValues={setEtcValues}
                name={childItem.itemCode}
                onChange={onChange}
                onEtcChange={onEtcChange}
                onMutipleChange={onMutipleChange}
                onCheckChange={onCheckChange}
                item={childItem}
                mt={20}
              />
            ))}
        </Div>
      ))}
      <Row justifyContent="space-between">
        <Button ghost onClick={goBack}>
          {t('before')}
        </Button>
        <Button onClick={confirm} active={active}>
          {t('confirm')}
        </Button>
      </Row>
    </>
  );
}
