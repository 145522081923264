// value 가 "" 이거나 undefind 일 경우 true 반환

export const isEmptyObject = (obj) => {
  if (
    obj.constructor === Object &&
    (Object.keys(obj).length === 0 ||
      Object.keys(obj).find(
        (el) => obj[el] === '' || obj[el] === false || obj[el] === undefined,
      ))
  ) {
    return true;
  }

  return false;
};
