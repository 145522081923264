import axios from 'axios';
import { Alert, HTMLWrap, Loading, Modal } from 'components';
import { REFRESH_CODE, TOKEN } from 'data/keyFile';
import { useCookieUtil, useLanguageNavigate } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoginPresenter from './LoginPresenter';

function LoginContainer({ t, bucket, setBucket }) {
  const location = useLocation();
  const { languageNavigate, currentLanguage } = useLanguageNavigate();
  const { setCookies } = useCookieUtil();
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(true);

  const [terms, setTerms] = useState();

  useEffect(() => {
    // terms setting
    if (bucket?.loginInfo && currentLanguage) {
      const { loginInfo } = bucket;
      if (loginInfo[currentLanguage]?.terms?.find((el) => el !== '')) {
        let termsToObj = {};
        loginInfo[currentLanguage].terms?.forEach(
          (term) => (termsToObj = { ...termsToObj, [term.key]: false }),
        );
        setTerms({ ...termsToObj });
      }

      setLoading(false);
    }
  }, [bucket, currentLanguage]);

  const onTermChange = (value, name, checked) => {
    setTerms({ ...terms, [name]: checked });
  };

  const [openTerm, setOpenTerm] = useState();
  const viewTerm = (term) => {
    if (term.content) {
      setOpenTerm(term.content);
    } else {
      setTimeout(() => {
        window.open(term.url, '_blank');
      }, []);
    }
  };

  const loginToMain = (token, refreshCode) => {
    try {
      setBucket();
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      setCookies(REFRESH_CODE, refreshCode);
      setCookies(TOKEN, token);
    } finally {
      languageNavigate(
        (location.state?.prevLocation &&
          `${location.state?.prevLocation.pathname}`) ||
          `/`,
      );
    }
  };

  return (
    <>
      <LoginPresenter
        t={t}
        bucket={bucket}
        info={bucket?.loginInfo[currentLanguage]}
        termList={bucket?.loginInfo[currentLanguage]?.terms}
        terms={terms}
        onTermChange={onTermChange}
        viewTerm={viewTerm}
        loginToMain={loginToMain}
        setAlert={setAlert}
      />
      {openTerm && (
        <Modal close={() => setOpenTerm()}>
          <HTMLWrap content={openTerm} />
        </Modal>
      )}
      {alert && <Alert {...alert} />}
      {loading && <Loading />}
    </>
  );
}

export default LoginContainer;
