import { LANGUAGE } from 'data/keyFile';
import { useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useInterval } from 'react-use';

function useCountDown(url) {
  const [count, setCount] = useState(3);
  const [searchParams] = useSearchParams();
  const currentLanguage = searchParams.get(LANGUAGE);
  const location = useLocation();
  const navigate = useNavigate();
  useInterval(() => {
    if (count > 0) {
      setCount(count - 1);
    } else if (count === 0 && location.pathname.includes('result')) {
      navigate(
        {
          pathname: '/',
          search: createSearchParams({
            [LANGUAGE]: currentLanguage,
          }).toString(),
        },
        { replace: true },
      );
    }
  }, 1000);

  return { count };
}

export default useCountDown;
