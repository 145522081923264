import { authApi } from 'api';
import {
  Div,
  Input,
  Text,
  CountryCode,
  RoundButton,
  Button,
  Row,
} from 'components';
import { useApiParams, useLanguageNavigate, useVerificationTime } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/encrypt';
import { hideCodeRefStyles } from 'utils/hideCodeRefStyles';
import { DEFAULT_INVALID_KEYS } from 'utils/invalids';
import { makePhone } from 'utils/makePhone';

export function FindpasswordVerified({
  loginMethod,
  loginToMain,
  setAlert,
  isUsedSignUpBtn,
  ...props
}) {
  const { t } = useTranslation();
  const codeRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { languageNavigate } = useLanguageNavigate();
  const { TOKEN_PARAMS, DEFAULT_PARAMS } = useApiParams();
  const [values, setValues] = useState({ [loginMethod]: '', code: '' });
  const [invalid, setInvalid] = useState({
    type: '',
    text: '',
  });
  const [passwordInvalid, setPasswordInvalid] = useState({
    type: '',
    text: '',
  });
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] = useState({
    type: '',
    text: '',
  });
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const initFocus = () => {
    // timer가 만료되었거나 code 가 아닌 항목을 재입력했을 시 code 초기화
    if (invalid?.type !== 'success') {
      setInvalid({
        type: '',
        text: '',
      });
      setValues({
        ...values,
        code: '',
      });
      document.getElementsByTagName('input')[loginMethod].focus();
    }
  };

  const { minutes, seconds, isRunning, setIsRunning, initTimer } =
    useVerificationTime(initFocus);

  const onChange = (value, name) => {
    if (name === loginMethod || name === 'phoneCountyCode') {
      initTimer();
      setValues({
        ...values,
        [name]: value,
        code: '',
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
    if (name === 'password' && !value) {
      setPasswordInvalid({ type: 'error', text: t('password_placeholder') });
    } else if (name === 'password' && value) {
      setPasswordInvalid({ type: '', text: '' });
    } else {
      setInvalid({
        type: '',
        text: '',
      });
    }
  };

  const timerActive = useMemo(
    () => Boolean((isRunning && minutes < 2) || (!isRunning && minutes === 3)),
    [isRunning, minutes],
  );

  const sendCodeActive = useMemo(
    () =>
      Boolean(
        values[loginMethod]?.match(DEFAULT_INVALID_KEYS[loginMethod]) &&
          timerActive,
      ),
    [values[loginMethod], timerActive],
  );

  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const sendCode = async () => {
    if (sendCodeActive) {
      setIsRunning(true);
      initTimer();
      // 인증번호 보내기
      setSendCodeLoading(true);
      const params = { ...DEFAULT_PARAMS };
      delete params.bucketVersion;

      const loginValue =
        (loginMethod === 'phone' &&
          `${values.phoneCountyCode} ${makePhone(values.phone)}`) ||
        values[loginMethod];
      const [info, error] = await authApi.verificationSend({
        ...params,
        [loginMethod]: loginValue,
      });
      if (info) {
        // success
        setSendCodeLoading(false);
        setIsRunning(true);
        if (codeRef.current) {
          codeRef.current.focus();
        }
      } else if (error) {
        setSendCodeLoading(false);
        setAlert({
          title: 'ERROR',
          content: t('error_verification'),
          confirm: () => {
            initTimer();
            setAlert();
          },
        });
      }
    } else if (!timerActive) {
      setInvalid({ type: 'error', text: t('invalid_request') });
      setTimeout(() => {
        setInvalid();
      }, [1500]);
    }
  };

  const checkCodeActive = useMemo(
    () => Boolean(isRunning && values.code),
    [isRunning, values],
  );

  const [checkCodeLoading, setCheckCodeLoading] = useState(false);
  const checkCode = async () => {
    // 코드 인증
    if (checkCodeActive) {
      setCheckCodeLoading(true);
      const loginValue =
        (loginMethod === 'phone' &&
          `${values.phoneCountyCode} ${makePhone(values.phone)}`) ||
        values[loginMethod];
      const [info, error] = await authApi.findpasswordVerificationCheck({
        ...TOKEN_PARAMS,
        code: values.code,
        [loginMethod]: loginValue,
      });
      if (info) {
        setInvalid({ type: 'success', text: t('success_verification') });
        setCheckCodeLoading(false);
      } else if (error) {
        // 451 : 인증코드 틀림
        setInvalid({ type: 'error', text: t('invalid_code') });
        setCheckCodeLoading(false);
      }
    }
  };

  useEffect(() => {
    if (passwordConfirm && passwordConfirm !== values.password) {
      setPasswordConfirmInvalid({ type: 'error', text: t('invalid_password') });
    } else {
      setPasswordConfirmInvalid({ type: '', text: '' });
    }
  }, [values, passwordConfirm]);

  const active = useMemo(() => {
    if (invalid?.type !== 'success') return false;

    if (!values.password || !passwordConfirm) return false;

    if (passwordConfirm !== values.password) return false;

    return true;
  }, [values, passwordConfirm, invalid]);

  const [loading, setLoading] = useState(false);
  const reset = async () => {
    if (active) {
      setLoading(true);
      const loginValue =
        (loginMethod === 'phone' &&
          `${values.phoneCountyCode} ${makePhone(values.phone)}`) ||
        values[loginMethod];
      const [info, error] = await authApi.resetPassword({
        ...TOKEN_PARAMS,
        code: values.code,
        [loginMethod]: loginValue,
        password: encrypt(values.password),
      });
      if (info) {
        setAlert({
          title: t('alert_title'),
          content: t('success_findpassword'),
          confirm: () => goLogin(),
        });
      } else if (error?.resCode === 452) {
        setPasswordInvalid({ type: 'error', text: t('password_placeholder') });
        setLoading(false);
      } else if (error) {
        setAlert({
          title: t('alert_title'),
          content: t('fail_findpassword'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      }
    }
  };

  const goLogin = () => {
    languageNavigate(`/login`);
  };

  const goSignup = () => {
    languageNavigate(`/signup`);
  };

  return (
    <Div>
      {invalid?.type !== 'success' && (
        <>
          {loginMethod === 'phone' && (
            <CountryCode name="phoneCountyCode" onChange={onChange} />
          )}
          <Input
            type={(loginMethod === 'phone' && 'tel') || ''}
            label={(loginMethod === 'email' && t(loginMethod)) || ''}
            placeholder={t(`${loginMethod}_placeholder`)}
            onChange={onChange}
            name={loginMethod}
            value={(values && values[loginMethod]) || ''}
            onKeyDown={sendCode}
            onKeyDownActive={sendCodeActive || !sendCodeLoading}
            disabled={sendCodeLoading || loading}
            rightChildren={
              invalid?.type !== 'success' && (
                <RoundButton
                  className="input-disabled-click"
                  buttonType="text"
                  borderRadius="999px"
                  type="b3"
                  padding="0.5rem 1.2rem"
                  active={!sendCodeLoading && sendCodeActive}
                  loading={sendCodeLoading}
                  onClick={sendCode}
                >
                  {(isRunning && t('verification_re')) || t('verification')}
                </RoundButton>
              )
            }
          />
          <Input
            type="tel"
            setRef={codeRef}
            value={(values && values?.code) || ''}
            name="code"
            placeholder={t('code_placeholder')}
            autoComplete="off"
            onChange={onChange}
            invalid={invalid}
            onKeyDown={checkCode}
            onKeyDownActive={checkCodeActive || !checkCodeLoading}
            disabled={
              checkCodeLoading || invalid?.type === 'success' || loading
            }
            rightChildren={
              invalid?.type !== 'success' &&
              isRunning && (
                <>
                  <Text type="b3" color="red" className="input-disabled-click">
                    {`0${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
                  </Text>
                  {checkCode && (
                    <RoundButton
                      className="input-disabled-click"
                      buttonType="text"
                      borderRadius="999px"
                      type="b3"
                      padding="0.5rem 1.2rem"
                      active={!checkCodeLoading && checkCodeActive}
                      loading={checkCodeLoading}
                      onClick={checkCode}
                    >
                      {t('confirm')}
                    </RoundButton>
                  )}
                </>
              )
            }
            {...hideCodeRefStyles(
              !sendCodeLoading && isRunning && values[loginMethod],
            )}
          />
        </>
      )}
      {invalid?.type === 'success' && (
        <>
          <Input
            type="password"
            label={t('password')}
            placeholder={t('password_placeholder')}
            wrapStyles={{ mt: 10 }}
            name="password"
            onChange={onChange}
            value={values.password || ''}
            invalid={passwordInvalid}
            disabled={loading}
            onKeyDown={reset}
            onKeyDownActive={active}
          />
          <Input
            type="password"
            label={t('password_confirm')}
            placeholder={t('password_confirm_placeholder')}
            wrapStyles={{ mt: 10 }}
            name="password"
            onChange={(value) => setPasswordConfirm(value)}
            value={passwordConfirm || ''}
            invalid={passwordConfirmInvalid}
            disabled={loading}
          />
          <Button
            buttonType="large"
            active={active}
            onClick={reset}
            mt={20}
            loading={loading}
            display="block"
            margin="0 auto"
          >
            {t('reset_password')}
          </Button>
        </>
      )}
      <Row mt={20} gap={20} alignItems="center" justifyContent="center">
        {isUsedSignUpBtn === 1 && (
          <Button
            buttonType="text"
            padding="3px"
            type="b1"
            color="g800"
            onClick={goSignup}
          >
            {t('signup')}
          </Button>
        )}
        {loginToMain && (
          <Button
            buttonType="text"
            padding="3px"
            type="b1"
            color="g800"
            onClick={goLogin}
          >
            {t('login')}
          </Button>
        )}
      </Row>
    </Div>
  );
}
