import Modules from 'modules/Modules';
import React from 'react';

function MypagePresenter({ bucket, socket, currentLanguage }) {
  return (
    bucket.myPageInfo && (
      <Modules
        bucket={bucket}
        // menu={menu}
        type="main"
        socket={socket}
        moduleCode={bucket.myPageInfo.moduleCode}
        moduleType="mypage"
        minWidth={1280}
        mMinWidth="unset"
        width="100%"
        margin="0 auto"
        pb={(bucket.tabMenuInfo[currentLanguage]?.length > 0 && '80') || '0'}
      />
    )
  );
}

export default MypagePresenter;
