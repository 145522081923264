import {
  Col,
  Div,
  Loading,
  MemoCard,
  MemoWrite,
  Text,
  Button,
} from 'components';
import React from 'react';

function MemoPresenter({
  scrollRef,
  module,
  createItem,
  editItem,
  deleting,
  t,
  loadingRef,
  filters,
  user,
  languageNavigate,
}) {
  return (
    <Col width="100%" height="100%">
      {!user.name && (
        <Col alignItems="center" justifyContent="center" height="100%">
          <Text color="g500" textAlign="center">
            {t('login_warning')}
          </Text>
          <Button
            mt={30}
            width="100%"
            buttonType="full"
            active
            keyColor="blue"
            keyFontColor="g0"
            onClick={() => languageNavigate(`/login`)}
          >
            {t('confirm')}
          </Button>
        </Col>
      )}
      {user.name && (
        <>
          <Div
            padding={(module?.isFullWidth !== 1 && '20px 20px 0 20px') || ''}
            mb={20}
          >
            <Text type="h7" pb={10} borderBottom={{ color: 'g200' }}>
              {t('memo_label')}
            </Text>
            <MemoWrite save={createItem} moduleCode={module?.moduleCode} />
          </Div>
          <Div
            setRef={scrollRef}
            padding={(module?.isFullWidth !== 1 && '0 20px') || '20px 0 0 0'}
            height="100%"
            overflowY="overlay"
          >
            {module?.list.map((item, index) => (
              <MemoCard
                moduleCode={module?.moduleCode}
                key={item.itemCode}
                item={item}
                index={index + 1}
                edit={editItem}
                deleting={deleting}
              />
            ))}
            <Div setRef={loadingRef}>
              {module?.totalPages > filters?.page && (
                <Loading
                  position="relative"
                  width="100%"
                  height="200px"
                  delay="0"
                />
              )}
            </Div>
          </Div>
        </>
      )}
    </Col>
  );
}

export default MemoPresenter;
