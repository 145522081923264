import { css } from 'styled-components';

export const gridContainer = css`
  ${({ cols }) =>
    (cols &&
      `grid-template-columns: ${
        (Number(cols) && `repeat(${cols}, 1fr)`) || cols
      };`) ||
    ''};
  ${({ rows }) =>
    (rows &&
      `grid-template-rows: ${
        (Number(rows) && `repeat(${rows}, 1fr)`) || rows
      };`) ||
    ''};

  ${({ autoRows }) => (autoRows && `grid-auto-rows:${autoRows}fr;`) || ''};
  ${({ autoCols }) => (autoCols && `grid-auto-cols:${autoCols}fr;`) || ''};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ mCols }) =>
      (mCols &&
        `grid-template-columns: ${
          (Number(mCols) && `repeat(${mCols}, 1fr)`) || mCols
        };`) ||
      ''};
    ${({ mRows }) =>
      (mRows &&
        `grid-template-rows: ${
          (Number(mRows) && `repeat(${mRows}, 1fr)`) || mRows
        };`) ||
      ''};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ sCols }) =>
      (sCols &&
        `grid-template-columns: ${
          (Number(sCols) && `repeat(${sCols}, 1fr)`) || sCols
        };`) ||
      ''};
    ${({ sRows }) =>
      (sRows &&
        `grid-template-rows: ${
          (Number(sRows) && `repeat(${sRows}, 1fr)`) || sRows
        };`) ||
      ''};
  }
`;
