import { Div } from 'components';
import React from 'react';

export function RegistrationCard({ active, children, ...props }) {
  return (
    <Div
      border={
        (active && { color: 'keyColor', width: '3px' }) || { color: 'g200' }
      }
      borderRadius="12px"
      transition="all 150ms ease"
      {...props}
    >
      {children}
    </Div>
  );
}
