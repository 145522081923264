import React, { useEffect, useState } from 'react';
import { CommentInputStyles } from 'styles/InputStyles';
import { Div, Row, Button, Alert, ProfileImage, Text } from 'components';
import { useSelector } from 'react-redux';
import { useLanguageNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';

export function CommentInput({
  setRef,
  label,
  onKeyPress,
  onKeyPressActive,
  name,
  disabled,
  wrapStyles,
  send,
  cancel,
  loading,
  initValue,
  initFocus,
  inputFocusHandler,
  isOwnAdmin,
  isViewedCommentUserThumbnail,
  isViewedCommentUserName,
  ...props
}) {
  const [alert, setAlert] = useState();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const onKeyPressHandler = (event) => {
    if (!user.name) {
      setAlert({
        title: t('alert_title'),
        content: t('login_warning'),
        confirm: () => languageNavigate(`/login`),
        cancel: () => setAlert(''),
      });
    } else if (
      !loading &&
      value?.replace(/(\s*)/g, '').length > 0 &&
      event.key === 'Enter' &&
      event.shiftKey === false
    ) {
      event.preventDefault();
      event.stopPropagation();
      sendComment();
    }
  };

  const onChangeHandler = (event) => {
    const { value } = event.target;
    setFocus(true);
    if (user.name) {
      setValue(value);
    }
  };

  const cancelClick = () => {
    if (cancel) {
      cancel();
    }
    setFocus(false);
    setValue('');
  };

  const sendComment = async () => {
    if (user.name) {
      const result = await send(value.trim());
      if (result) {
        setFocus(initFocus || false);
        setValue('');
      }
    } else {
      setAlert({
        title: t('alert_title'),
        content: t('login_warning'),
        confirm: () => languageNavigate(`/login`),
        cancel: () => setAlert(''),
      });
    }
  };

  useEffect(() => {
    if (initFocus) {
      setFocus(true);
    }
  }, [initFocus]);

  useEffect(() => {
    if (initValue) {
      setValue(initValue || '');
    }
  }, [initValue]);

  useEffect(() => {
    if (inputFocusHandler) {
      inputFocusHandler(focus);
    }
  }, [focus]);

  return (
    <>
      <Div
        border={(focus && { color: 'keyColor' }) || { color: 'g200' }}
        padding="20px"
        borderRadius="12px"
        width="100%"
        {...wrapStyles}
      >
        {((isViewedCommentUserThumbnail || isViewedCommentUserName) && (
          <Row mb={10} gap={10} alignItems="center">
            {(isViewedCommentUserThumbnail && (
              <ProfileImage size={32} thumbnail={user?.thumbnail || '1'} />
            )) ||
              ''}
            {isOwnAdmin ? (
              <Text type="h8" flex={1}>
                {isOwnAdmin && !user?.name ? 'Admin' : user?.name}
              </Text>
            ) : (
              !!isViewedCommentUserName && (
                <Text type="h8" flex={1}>
                  {user?.name || ''}
                </Text>
              )
            )}
          </Row>
        )) ||
          ''}
        <CommentInputStyles
          display="block"
          ref={setRef}
          name={name}
          value={value}
          disabled={loading}
          onChange={onChangeHandler}
          onKeyPress={onKeyPressHandler}
          onFocus={() => {
            if (!user.name) {
              setAlert({
                title: t('alert_title'),
                content: t('login_warning'),
                confirm: () => languageNavigate(`/login`),
                cancel: () => setAlert(''),
              });
            } else {
              setFocus(true);
            }
          }}
          onBlur={() => setFocus(false)}
          rows={3}
          {...props}
        />
        <Row mt={10} gap={10} justifyContent="flex-end">
          <Button buttonType="small" ghost onClick={cancelClick}>
            {t('cancel')}
          </Button>
          <Button
            buttonType="small"
            onClick={sendComment}
            active={value?.replace(/(\s*)/g, '').length > 0}
          >
            {t('confirm')}
          </Button>
        </Row>
      </Div>
      {alert && <Alert {...alert} />}
    </>
  );
}
