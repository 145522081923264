import { Row, RegistrationCard, Text, Radio, Button } from 'components';
import React, { useEffect, useState } from 'react';

export function PartType({
  t,
  initValue,
  list,
  setPartType,
  setProgress,
  progress,
}) {
  const [active, setActive] = useState(false);
  const [selectedPart, setSelectedPart] = useState('');
  const onPartHandler = (value) => {
    setSelectedPart(value);
    setActive(true);
  };

  useEffect(() => {
    if (initValue?.partCode) {
      setSelectedPart(initValue);
      setActive(true);
    }
  }, [initValue]);

  const onClick = () => {
    if (active) {
      setPartType(selectedPart);
      setProgress({ ...progress, value: progress.value + 1 });
    }
  };

  return (
    <>
      {list && list?.length > 0 && (
        <Row
          flexWrap="wrap"
          colGap={20}
          rowGap={20}
          maxWidth={676}
          margin="0 auto 40px auto"
        >
          {list.map((part) => (
            <RegistrationCard
              flex={1}
              minWidth={(list?.length % 2 === 0 && 328) || 212}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={20}
              key={part.partCode}
              padding="20px 30px"
              cursor="pointer"
              hover
              onClick={() => onPartHandler(part)}
              active={part.partCode === selectedPart?.partCode}
            >
              <Text color="g800" type="h6">
                {part.title}
              </Text>
              <Radio
                checked={part.partCode === selectedPart?.partCode}
                checkStyles={{ size: 30 }}
              />
            </RegistrationCard>
          ))}
        </Row>
      )}
      <Button
        display="block"
        width="100%"
        margin="0 auto"
        maxWidth={320}
        onClick={onClick}
        active={active}
      >
        {t('next')}
      </Button>
    </>
  );
}
