import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useScrollPagination } from 'hooks';
import ChatPresenter from './ChatPresenter';

export function ChatContainer({
  module,
  socket,
  filters,
  setFilters,
  loading,
}) {
  const scrollRef = useRef();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  const [addChat, setAddChat] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    if (module && module.moduleType === 'chat') {
      setList(
        (list?.length > 0 && [...module.list, ...list]) || [...module?.list],
      );
    }
  }, [module]);

  // socket
  useEffect(() => {
    if (socket && module) {
      receivedMessage();
    }
  }, [socket, module]);

  const sendMessage = (content) => {
    let result;
    try {
      const data = {
        moduleCode: module.moduleCode,
        content,
        userName: user.name,
        userThumbnail: user.thumbnail,
      };
      socket.emit('send chat message', { ...data });
    } catch {
      result = false;
    } finally {
      result = true;
    }

    scrollBottom();
    setPrevScrollHeight(null);
    return result;
  };

  const receivedMessage = () => {
    socket.on(`${module.moduleCode} - new chat message`, (data) => {
      setAddChat({ ...data });
    });
  };

  useEffect(() => {
    if (addChat) {
      setList([...list, addChat]);
    }
  }, [addChat]);

  const [scrolling, setScrolling] = useState(true);
  const scrollBottom = () => {
    setScrolling(true);
    // 채팅 스크롤로 인하여 윈도우 스크롤이 bottom으로 향하는 것을 방지하기 위하여 bottomElem.scrollIntoView를 사용하지 않음
    if (scrollRef.current) {
      setTimeout(() => {
        if (list?.length < 13) {
          // 첫 스크롤 시 스크롤 애니메이션 없음
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        } else {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
        setScrolling(false);
      }, [200]);
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      if (prevScrollHeight) {
        scrollRef.current.scrollTop =
          scrollRef.current.scrollHeight - prevScrollHeight;
        return setPrevScrollHeight(null);
      } else if (upScrolling) {
        // scroll handle 중 새 chatting이 울렸을때 delay
        setScrolling(true);
        setTimeout(() => {
          setScrolling(false);
        }, [200]);
      } else {
        scrollBottom();
      }
    }
  }, [list]);

  let previousY = 0;
  const [throttle, setThrottle] = useState(false);
  const [upScrolling, setUpScrolling] = useState(false);
  const [prevScrollHeight, setPrevScrollHeight] = useState();
  const [showBottomScrollButton, setShowBottomScrollButton] = useState(false);
  const handleScroll = (event) => {
    const current = event.target.scrollTop;
    if (current < previousY) {
      setPrevScrollHeight(event.target.scrollHeight);
    }
    previousY = current;

    if (throttle) return;
    if (!throttle) {
      setThrottle(true);
      setTimeout(async () => {
        setUpScrolling(
          Math.abs(
            event.target.scrollHeight -
              event.target.scrollTop -
              event.target.clientHeight,
          ) > 1,
        );
        setShowBottomScrollButton(
          Math.abs(
            event.target.scrollHeight -
              event.target.scrollTop -
              event.target.clientHeight,
          ) > 1500,
        );
        setThrottle(false);
      }, 300);
    }
  };

  const inputFocusHandler = useCallback(
    (target) => {
      if (target) {
        if (module?.designType === 'type1') {
          scrollRef.current.scrollTop += 94;
        } else if (module?.designType === 'type2') {
          scrollRef.current.scrollTop += 18;
        }
      }
    },
    [module],
  );

  // scrollpagination
  const changePage = () => {
    if (module?.totalPages > filters?.page) {
      const i = filters.page + 1;
      setFilters({
        ...filters,
        page: i,
        lastRowNum: filters.perPage * (i - 1),
        perPage: filters.perPage,
      });
    }
  };

  const { loadingRef, setScrollPaginationLoading } = useScrollPagination(
    changePage,
    { root: scrollRef.current },
  );

  useEffect(() => {
    if (!scrolling) {
      setScrollPaginationLoading(loading);
    }
  }, [loading, scrolling]);

  return (
    <ChatPresenter
      scrollRef={scrollRef}
      scrollHandler={handleScroll}
      showBottomScrollButton={showBottomScrollButton}
      scrollBottom={scrollBottom}
      lastRef={loadingRef}
      module={module}
      filters={filters}
      list={list}
      sendMessage={sendMessage}
      inputFocusHandler={inputFocusHandler}
    />
  );
}

export default ChatContainer;
