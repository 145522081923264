import React, { useState } from 'react';
import { Icon, Div, Text, Row, Col, Dim } from 'components';
import { useTranslation } from 'react-i18next';

export function ReservationDropdown({
  placeholder,
  list,
  value,
  name,
  customItem,
  customKey,
  onChange,
  disabled,
  ...props
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onChangeHandler = (selectItem) => {
    onChange(selectItem, name);
  };

  return (
    <>
      {open && (
        <Dim
          width="100%"
          height="100%"
          mWidth="100vw"
          mHeight="100vh"
          zIndex={505}
          backgroundColor="transparent"
          onClick={() => setOpen(false)}
        />
      )}
      <Div
        position="relative"
        open={open}
        borderRadius="10px"
        border={{ color: 'g200' }}
        zIndex={open ? 510 : 1}
        {...props}
      >
        <Row
          padding="15px 12px"
          alignItems="center"
          justifyContent="space-between"
          borderBottom={(open && { color: 'g200' }) || ''}
          onClick={() => setOpen(!open)}
          cursor="pointer"
        >
          <Text color={value ? 'g800' : 'g200'}>
            {value
              ? (customItem && customItem(value)) || value
              : placeholder || t('select')}
          </Text>
          <Icon
            name="arrow"
            fill="g300"
            transform={(open && 'rotate(-180deg)') || 'rotate(0)'}
          />
        </Row>
        <Col
          width="100%"
          mt={(open && '5px') || ''}
          height={(open && 'auto') || '0px'}
          maxHeight={224}
          borderRadius="0 0 10px 10px"
          overflowY="overlay"
          transform={(open && list?.length > 0 && 'scaleY(1)') || 'scaleY(0)'}
          transition="transform 150ms ease-in-out"
          transformOrigin="top center"
          gap={5}
        >
          {list?.map((item, index) => (
            <Row
              className="dropdown-item"
              key={`${name}-${index}`}
              padding="15px 12px"
              borderRadius="0.2rem"
              backgroundColor={
                (((customItem && customItem(value) === customItem(item)) ||
                  value === item) &&
                  'key200') ||
                ''
              }
              hover="background"
              transition="all 150ms"
              cursor="pointer"
              onMouseDown={() => {
                onChangeHandler(item);
                setOpen(false);
              }}
              gap={10}
              alignItems="center"
            >
              <Row
                position="relative"
                width="17px"
                height="17px"
                borderRadius="999px"
                border={{
                  color:
                    (((customItem && customItem(value) === customItem(item)) ||
                      value === item) &&
                      'keyColor') ||
                    'g500',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Div
                  width="11px"
                  height="11px"
                  minWidth="11px"
                  minHeight="11px"
                  borderRadius="999px"
                  backgroundColor={
                    (((customItem && customItem(value) === customItem(item)) ||
                      value === item) &&
                      'keyColor') ||
                    'g500'
                  }
                />
              </Row>
              <Text>{(customItem && customItem(item)) || item}</Text>
            </Row>
          ))}
        </Col>
      </Div>
    </>
  );
}
