import React, { useState } from 'react';
import { InputFileStyles } from 'styles/InputStyles';
import { Div, Row, Icon, Text, Invalid, Loading } from 'components';
import { useCookieUtil } from 'hooks';
import { API_KEY, UUID, BUCKET_VERSION } from 'data/keyFile';
import { BUCKET_CODE, moduleApi } from 'api';

export function InputFile({
  setRef,
  label,
  moduleCode,
  placeholder,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  disabled,
  rightChildren,
  position,
  wrapStyles,
  invalid,
  fileUpload,
  accept,
  ...props
}) {
  const { getCookie } = useCookieUtil();

  const [loading, setLoading] = useState(false);
  const onChangeHandler = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      reader.onloadend = async () => {
        if (!loading) {
          setLoading(true);
          const fd = new FormData();
          fd.append('file', file);
          fd.append('apiKey', getCookie(API_KEY));
          fd.append('uuid', getCookie(UUID));
          fd.append('bucketVersion', getCookie(BUCKET_VERSION));
          if (process.env.NODE_ENV !== 'production') {
            fd.append('bucketCode', BUCKET_CODE);
          }

          const [info] = await moduleApi.fileUpload(moduleCode, fd);
          if (info) {
            onChange(info.result, name);
            setLoading(false);
            event.target.value = '';
          } else {
            setLoading(false);
            event.target.value = '';
          }
        }
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  const deleteValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange('', name);
  };

  return (
    <Div {...wrapStyles}>
      {label && (
        <Text type="b3" mb={4}>
          {label}
        </Text>
      )}
      <Div position={position || 'relative'}>
        <InputFileStyles
          ref={setRef}
          htmlFor={name}
          value={value}
          disabled={disabled}
          gap={6}
          readOnly
          cursor="pointer"
          invalid={invalid?.type}
          {...props}
        >
          <input
            id={name}
            type="file"
            accept={accept}
            readOnly
            name={name}
            onChange={onChangeHandler}
            style={{ display: 'none' }}
            disabled={disabled}
          />
          <Icon size={21} fill="g300" name="attach" zIndex="0" />
          {loading && <Loading position="absolute" type="inside" />}
          <Row flex={1} gap={10} alignItems="center" height="100%">
            <Text
              flex={1}
              color={(value && 'g800') || 'g300'}
              maxLine={1}
              whiteSpace="nowrap"
              transition="0.2s ease"
              visibility={(loading && 'hidden') || ''}
            >
              {value || placeholder}
            </Text>
            <Row className="input-button" alignItems="center" gap={6}>
              <Icon
                visibility={(value && 'visible') || 'hidden'}
                className="input-delete"
                size={21}
                name="delete"
                cursor="pointer"
                onMouseDown={deleteValue}
              />
              {rightChildren}
            </Row>
          </Row>
        </InputFileStyles>
      </Div>
      <Invalid invalid={invalid} />
    </Div>
  );
}
