const fileDownloader = async (fileUrl, filename) => {
  const downloadUrl = fileUrl;
  const a = document.createElement('a');
  if (filename) {
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  } else {
    window.location = downloadUrl;
  }

  setTimeout(() => {
    if (document.body.contains(a)) {
      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    }
  }, 100); // cleanup
};

export default fileDownloader;
