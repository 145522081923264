import {
  ChildAlert,
  HTMLWrap,
  Scanner,
  StampContent,
  StampList,
  Toast,
  Image,
  Text,
} from 'components';
import { useWindowSize } from 'hooks';
import React from 'react';

function StampPresenter({
  getList,
  module,
  stamp,
  open,
  close,
  searchParams,
  goDetail,
  stamping,
  doneStamping,
  reward,
  rewardItem,
  childAlert,
  toast,
}) {
  const windowSize = useWindowSize();
  return (
    <>
      {!searchParams.get('scanner') && !searchParams.get('content') && (
        <StampList
          windowSize={windowSize}
          module={module}
          stamp={stamp}
          open={open}
          goDetail={goDetail}
          reward={reward}
        />
      )}
      {searchParams.get('scanner') && (
        <Scanner
          windowSize={windowSize}
          module={module}
          close={close}
          goDetail={goDetail}
          getList={getList}
          stamping={stamping}
          doneStamping={doneStamping}
        />
      )}
      {searchParams.get('content') && (
        <StampContent stamp={stamp} stamping={stamping} close={close} />
      )}
      {childAlert && (
        <ChildAlert {...childAlert}>
          <HTMLWrap
            content={
              (childAlert.type === 'reward' && module?.rewardPopupContent) ||
              module?.stampingPopupContent
            }
          />
          {childAlert.type === 'reward' && (
            <>
              {rewardItem.thumbnail && (
                <Image
                  width={150}
                  height={150}
                  objectFit="cover"
                  src={rewardItem.thumbnail}
                  display="block"
                  margin="0 auto"
                />
              )}
              {rewardItem.name && (
                <Text type="h8" textAlign="center">
                  {rewardItem.name}
                </Text>
              )}
            </>
          )}
        </ChildAlert>
      )}
      {toast && <Toast content={toast} />}
    </>
  );
}

export default StampPresenter;
