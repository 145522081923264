import { css } from 'styled-components';
import { checkPixel } from 'utils/css/checkPixel';

export const elementSize = css`
  ${({ fluid }) =>
    (fluid &&
      `
    width:100vw;
    margin-left: calc(-50vw + 50%);
    `) ||
    ''};
  ${({ width }) =>
    ((width === 0 || width) && `width: ${checkPixel(width)};`) || ''};
  ${({ minWidth }) =>
    ((minWidth === 0 || minWidth) && `min-width : ${checkPixel(minWidth)};`) ||
    ''};
  ${({ maxWidth }) =>
    ((maxWidth === 0 || maxWidth) && `max-width: ${checkPixel(maxWidth)};`) ||
    ''};
  ${({ theme, height }) =>
    ((height === 0 || height) &&
      `height:  ${
        height === 'height'
          ? checkPixel(theme.windowSize.height)
          : checkPixel(height)
      };`) ||
    ''};
  ${({ theme, minHeight }) =>
    ((minHeight === 0 || minHeight) &&
      `min-height: ${
        minHeight === 'height'
          ? checkPixel(theme.windowSize.height)
          : checkPixel(minHeight)
      };`) ||
    ''};
  ${({ theme, maxHeight }) =>
    ((maxHeight === 0 || maxHeight) &&
      `max-height:  ${
        maxHeight === 'height'
          ? checkPixel(theme.windowSize.height)
          : checkPixel(maxHeight)
      };`) ||
    ''};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ mWidth }) =>
      ((mWidth === 0 || mWidth) && `width: ${checkPixel(mWidth)};`) || ''};
    ${({ mMinWidth }) =>
      ((mMinWidth === 0 || mMinWidth) &&
        `min-width : ${checkPixel(mMinWidth)};`) ||
      ''};
    ${({ mMaxWidth }) =>
      ((mMaxWidth === 0 || mMaxWidth) &&
        `max-width: ${checkPixel(mMaxWidth)};`) ||
      ''};
    ${({ theme, mHeight }) =>
      ((mHeight === 0 || mHeight) &&
        `height: ${
          mHeight === 'height'
            ? checkPixel(theme.windowSize.height)
            : checkPixel(mHeight)
        };`) ||
      ''};
    ${({ mMinHeight }) =>
      ((mMinHeight === 0 || mMinHeight) &&
        `min-height: ${checkPixel(mMinHeight)};`) ||
      ''};
    ${({ mMaxHeight }) =>
      ((mMaxHeight === 0 || mMaxHeight) &&
        `max-height: ${checkPixel(mMaxHeight)};`) ||
      ''};
  }
`;
