import { LANGUAGE } from 'data/keyFile';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FooterStyle } from 'styles/FooterStyle';
import { HTMLWrap } from 'components';

export function Footer({ content, ...props }) {
  const [searchParams] = useSearchParams();
  return (
    <FooterStyle {...props}>
      <HTMLWrap
        content={(content && content[searchParams.get(LANGUAGE)]) || ''}
      />
    </FooterStyle>
  );
}
