import {
  Grid,
  EnumerationEmptyCard,
  EnumerationTopCard,
  EnumerationRightCard,
  EnumerationLeftCard,
  EnumerationFAQ1Card,
  EnumerationSpeaker1Card,
  Filters,
} from 'components';
import React from 'react';

function EnumerationPresenter({
  module,
  list,
  itemClick,
  logging,
  categoryList,
  tagList,
  filters,
  setFilters,
}) {
  return (
    <>
      <Filters
        module={module}
        categoryList={categoryList}
        tagList={tagList}
        filters={filters}
        setFilters={setFilters}
      />
      <Grid
        width="100%"
        height="100%"
        cols={module?.designType === 'faq1' ? 1 : module?.maxColumns}
        mCols={
          module?.designType === 'faq1'
            ? 1
            : module?.designType === 'speaker1'
            ? 2
            : module?.maxColumns > 2
            ? 2
            : 1
        }
        sCols={1}
        colGap={20}
        rowGap={module?.designType === 'default-image-right' ? 0 : 15}
      >
        {module?.designType === 'default-empty' &&
          list?.map((item) => (
            <EnumerationEmptyCard
              module={module}
              key={item.itemCode}
              item={item}
              itemClick={(event) => itemClick(event, item)}
              logging={logging}
            />
          ))}
        {module?.designType === 'default-image-top' &&
          list?.map((item) => (
            <EnumerationTopCard
              module={module}
              key={item.itemCode}
              item={item}
              itemClick={(event) => itemClick(event, item)}
              logging={logging}
            />
          ))}
        {module?.designType === 'default-image-right' &&
          list?.map((item) => (
            <EnumerationRightCard
              module={module}
              key={item.itemCode}
              item={item}
              itemClick={(event) => itemClick(event, item)}
              logging={logging}
            />
          ))}
        {module?.designType === 'default-image-left' &&
          list?.map((item) => (
            <EnumerationLeftCard
              module={module}
              key={item.itemCode}
              item={item}
              itemClick={(event) => itemClick(event, item)}
              logging={logging}
            />
          ))}
        {module?.designType === 'faq1' &&
          list?.map((item) => (
            <EnumerationFAQ1Card
              module={module}
              key={item.itemCode}
              item={item}
              itemClick={(event) => itemClick(event, item)}
              logging={logging}
            />
          ))}
        {module?.designType === 'speaker1' &&
          list?.map((item) => (
            <EnumerationSpeaker1Card
              module={module}
              key={item.itemCode}
              item={item}
              itemClick={(event) => itemClick(event, item)}
              logging={logging}
            />
          ))}
      </Grid>
    </>
  );
}

export default EnumerationPresenter;
