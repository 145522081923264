import { Row, SideBar, SideStructure } from 'components';
import { useWindowSize } from 'hooks';
import Modules from 'modules/Modules';
import React, { useCallback, useEffect, useState } from 'react';

export function MainStructure({
  bucket,
  module,
  socket,
  menu,
  side1List,
  side2List,
  sidePlayListCallback,
  streamingItem,
  streamingItemCallback,
}) {
  const windowSize = useWindowSize();
  const sideWidth = (side1, side2) => {
    const count1 = side1 ? 1 : 0;
    const count2 = side2 ? 1 : 0;
    return count1 + count2;
  };

  const [sideOpen, setSideOpen] = useState(true);
  const [side1Target, setSide1Target] = useState();
  const [side2Target, setSide2Target] = useState();

  const side1TargetCallback = useCallback((target) => {
    setSide1Target(target);
  }, []);

  const side2TargetCallback = useCallback((target) => {
    setSide2Target(target);
  }, []);

  const sideOpenCallback = useCallback((target) => {
    setSideOpen(target);
  }, []);

  const computedPadding = (element) => {
    const paddingTop = window
      .getComputedStyle(element)
      .getPropertyValue('padding-top')
      .replace('px', '');
    const paddingBottom = window
      .getComputedStyle(element)
      .getPropertyValue('padding-bottom')
      .replace('px', '');

    return Number(paddingTop) + Number(paddingBottom);
  };

  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (menu?.streamingSideDesignType === 'design3') {
      const wrapElem = document.getElementsByClassName('page-wrap')[0];
      const layeredWrapElem =
        document.getElementsByClassName('page-layered-wrap')[0];
      if (wrapElem && layeredWrapElem) {
        const wrapPadding = computedPadding(wrapElem);
        const layeredPadding = computedPadding(layeredWrapElem);

        setHeight(
          wrapElem.getBoundingClientRect().height -
            (wrapPadding + layeredPadding),
        );
      }
    }
  }, [menu]);

  return (
    (module.moduleType !== 'streaming' && (
      <Modules
        bucket={bucket}
        menu={menu}
        type="main"
        socket={socket}
        moduleCode={module.moduleCode}
        moduleType={module.moduleType}
        minWidth={1280}
        mMinWidth="unset"
        width="100%"
        margin="0 auto"
      />
    )) || (
      <Row
        width="100%"
        mWidth="100%"
        justifyContent="center"
        position="relative"
        alignItems="flex-start"
        gap={(menu?.streamingSideDesignType === 'design3' && 24) || 0}
        mFlexDirection="column"
      >
        <Modules
          bucket={bucket}
          menu={menu}
          type="main"
          socket={socket}
          moduleCode={module.moduleCode}
          moduleType={module.moduleType}
          sidePlayListCallback={sidePlayListCallback}
          streamingItem={streamingItem}
          streamingItemCallback={streamingItemCallback}
          minWidth={920}
          mMinWidth="unset"
          mWidth="100%"
          width={`calc(100% - ${
            (!sideOpen && 60) ||
            360 *
              sideWidth(
                menu.streamingSide1Structure.length > 0,
                menu.streamingSide2Structure.length > 0,
              ) +
              60
          }px)`}
        />
        {(side1List?.length > 0 || side2List?.length > 0) &&
          module.moduleType === 'streaming' && (
            <Row
              position="sticky"
              height={height}
              top={
                Number(menu?.styles.layeredPaddingTop) +
                Number(
                  (menu?.headerOption?.isHeaderOverlap !== 1 &&
                    (bucket?.header === 'fixed' ||
                      bucket?.header === 'drawer' ||
                      windowSize.width < 1280) &&
                    (Number(menu?.headerOption?.headerHeight) ||
                      Number(bucket.headerOption.headerHeight) ||
                      60) + Number(menu?.styles.paddingTop)) ||
                    menu?.styles.paddingTop,
                )
              }
              mWidth="100%"
              gap={20}
            >
              {side1List?.length > 0 && (
                <SideStructure
                  socket={socket}
                  target={side1Target}
                  open={sideOpen}
                  streamingItem={streamingItem}
                  streamingItemCallback={streamingItemCallback}
                  designType={menu?.streamingSideDesignType}
                  list={side1List}
                  sideTargetCallback={side1TargetCallback}
                  menu={menu}
                  module={module}
                />
              )}
              {side2List?.length > 0 && (
                <SideStructure
                  socket={socket}
                  target={side2Target}
                  open={sideOpen}
                  designType={menu?.streamingSideDesignType}
                  list={side2List}
                  sideTargetCallback={side2TargetCallback}
                  menu={menu}
                />
              )}
              {menu?.streamingSideDesignType !== 'design3' && (
                <SideBar
                  list1={side1List}
                  list2={side2List}
                  side1TargetCallback={side1TargetCallback}
                  side2TargetCallback={side2TargetCallback}
                  sideOpenCallback={sideOpenCallback}
                />
              )}
            </Row>
          )}
      </Row>
    )
  );
}
