import React from 'react';
import { Div, Icon } from 'components';

export function PaginationArrowButton({ type, size, onClick, ...props }) {
  return (
    <Div
      border={{ color: 'g200' }}
      borderRadius="9px"
      cursor="pointer"
      hover
      onClick={onClick}
      {...props}
    >
      <Icon
        size={size || 21}
        name="arrow"
        fill="g500"
        transform={(type === 'next' && 'rotate(-90deg)') || 'rotate(90deg)'}
      />
    </Div>
  );
}
