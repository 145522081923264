import { useEffect, useCallback, useState, useRef } from 'react';

function useScrollPagination(changePage, options) {
  const [scrollPaginationLoading, setScrollPaginationLoading] = useState(true);
  const dom = useRef();

  const handleScroll = useCallback(
    ([entry]) => {
      if (entry.isIntersecting && !scrollPaginationLoading) {
        changePage();
      }
    },
    [scrollPaginationLoading],
  );

  useEffect(() => {
    let observer;
    const { current } = dom;
    if (current) {
      observer = new IntersectionObserver(handleScroll, {
        threshold: 0,
        ...options,
      });
      observer.observe(current);
    }

    return () => observer && observer.disconnect();
  }, [dom.current, scrollPaginationLoading]);

  return { loadingRef: dom, setScrollPaginationLoading };
}

export default useScrollPagination;
