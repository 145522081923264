import React, { Suspense, lazy } from 'react';
import { Div, Row, Skeleton } from 'components';

const CardComponent = lazy(() => import('./SideCardComponent'));

const renderLoader = () => (
  <Row width="100%" height={100} gap={10}>
    <Skeleton width="min(37%, 120px)" aspectRatio="120 / 101" mb={20} />
    <Div flex={1}>
      <Skeleton width="100%" height={18} mb={10} borderRadius="6px" />
      <Skeleton width="100%" height={20} mb={20} borderRadius="6px" />
      <Skeleton width="20%" height={10} borderRadius="6px" />
    </Div>
  </Row>
);

export function StreamingSideCard({ item, user, ...props }) {
  return (
    <Suspense fallback={renderLoader()}>
      <CardComponent item={item} user={user} {...props} />
    </Suspense>
  );
}
