import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApiParams, useLanguageNavigate, useWindowSize } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { mypageApi } from 'api';
import { isMobile } from 'utils/isMobile';
import { Alert } from 'components';
import MypagePresenter from './MypagePresenter';

function MypageContainer({ bucket, module }) {
  const windowSize = useWindowSize();
  const [alert, setAlert] = useState();
  const { languageNavigate } = useLanguageNavigate();
  const { DEFAULT_PARAMS } = useApiParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  const menus = [
    { label: t('mypage_bookmark'), option: 'isUsedBookmark', menu: 'bookmark' },
    {
      label: t('mypage_reservation'),
      option: 'isUsedReservation',
      menu: 'reservation',
    },
    { label: t('mypage_post'), option: 'isUsedBoard', menu: 'board' },
    { label: t('mypage_comment'), option: 'isUsedComment', menu: 'comment' },
    {
      label: t('mypage_registration'),
      option: 'isUsedRegistration',
      menu: 'registration',
    },
  ];

  const goMenu = (menu) => {
    if (user.name) {
      setLogList();
      searchParams.set('menu', menu);
      setSearchParams(searchParams);
    } else {
      setAlert({
        title: t('alert_title'),
        content: t('login_warning'),
        confirm: () => languageNavigate(`/login`),
        cancel: () => setAlert(''),
      });
    }
  };

  useEffect(() => {
    if (
      module &&
      searchParams.get('menu') &&
      searchParams.get('menu') !== 'info'
    ) {
      getLog();
    } else if (module && !searchParams.get('menu') && windowSize.width > 1279) {
      goMenu(
        module?.isViewedProfile === 1
          ? 'info'
          : menus.find((menuItem) => module?.[menuItem.option] === 1)?.menu,
      );
    }
  }, [module, searchParams.get('menu')]);

  const [logList, setLogList] = useState();
  const getLog = async () => {
    const [info] = await mypageApi.logInfo(module?.moduleCode, {
      ...DEFAULT_PARAMS,
      type: searchParams.get('menu'),
    });
    if (info) {
      setLogList([...info.result]);
    }
  };

  const goList = () => {
    searchParams.delete('menu');
    setSearchParams(searchParams);
  };

  return (
    <>
      <MypagePresenter
        bucket={bucket}
        t={t}
        menus={menus}
        user={user}
        languageNavigate={languageNavigate}
        module={module}
        goMenu={goMenu}
        menu={searchParams.get('menu')}
        logList={logList}
        getLog={getLog}
        goList={goList}
      />
      {alert && <Alert {...alert} />}
    </>
  );
}

export default MypageContainer;
