import React, { useEffect, useRef, useState } from 'react';
import { Div, HTMLWrap, Iframe, Text, Badge, Button, Row } from 'components';
import { makeVideoUrl } from 'utils/makeVideoUrl';
import { useViewedItems } from 'hooks';
import { useTranslation } from 'react-i18next';

export function StreamingB({ item, user, contentView, setContentView }) {
  const { t } = useTranslation();
  const { setItem } = useViewedItems();
  const [thumbnailContent, setThumbnailcontent] = useState();

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    if (item) {
      setItem(item);
      const tempDocument = document.createElement('div');
      tempDocument.style.display = 'none';
      if (item.playItemContent) {
        tempDocument.innerHTML = item.playItemContent
          .replaceAll('<br>', `\n`)
          .replaceAll('</p><p>', `\n`);
        setThumbnailcontent(tempDocument.textContent);
      } else {
        setThumbnailcontent();
      }

      if (textRef.current) {
        setIsEllipsisActive(
          textRef.current.offsetHeight < textRef.current.scrollHeight,
        );
      }
    }
  }, [item]);

  return (
    <>
      <Row alignItems="center" aspectRatio="16 / 9" overflowY="auto">
        {(item?.playItemUrl && (
          <Iframe
            width="100%"
            height="100%"
            src={makeVideoUrl(item.playItemUrl, item.autoPlay)}
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={item?.name}
          />
        )) ||
          (item?.playItemMainContent && (
            <HTMLWrap
              width="100%"
              height="100%"
              content={item.playItemMainContent}
            />
          ))}
      </Row>
      <Div width="100%" padding="30px 20px 10px">
        <Row width="100%" justifyContent="space-between" gap={6} mb={10}>
          {item?.playItemTags && (
            <Text display="flex" gap={6} flexWrap="wrap" alignItems="center">
              {item?.playItemTags?.map((tag) => (
                <Badge key={`${tag}${Math.random()}`} color="g400" type="b4">
                  {tag}
                </Badge>
              ))}
            </Text>
          )}
        </Row>
        {item?.playItemName && (
          <Row
            justifyContent="space-between"
            alignItems="center"
            mb={10}
            gap={25}
          >
            <Text type="h4" flex={1}>
              {item?.playItemName}
            </Text>
          </Row>
        )}

        {item?.playItemContent && (
          <>
            <div
              style={
                !contentView
                  ? {
                      whiteSpace: 'pre-line',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }
                  : {}
              }
              ref={textRef}
            >
              <HTMLWrap content={item?.playItemContent} />
            </div>

            {isEllipsisActive && (
              <Button
                mt={10}
                buttonType="text"
                type="b2"
                padding="4px"
                onClick={() => setContentView(!contentView)}
              >
                {contentView ? t('briefly') : t('more')}
              </Button>
            )}
          </>
        )}
      </Div>
    </>
  );
}
