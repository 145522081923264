import { moduleApi } from 'api';
import { Alert, Modal } from 'components';
import {
  useApiError,
  useApiParams,
  useLanguageNavigate,
  useWindowSize,
} from 'hooks';
import React, { useEffect, useState } from 'react';
import { Detail } from 'routes';
import { linkTypeClick } from 'utils/linkType';
import { setModule } from 'store/module';
import { useDispatch } from 'react-redux';
import EnumerationPresenter from './EnumerationPresenter';

function EnumerationContainer({ bucket, module, filters, setFilters }) {
  const windowSize = useWindowSize();
  const { languageNavigate } = useLanguageNavigate();
  const { DEFAULT_PARAMS } = useApiParams();
  const [categoryList, setCategoryList] = useState();
  const [tagList, setTagList] = useState();
  const [sortList, setSortList] = useState();
  const [list, setList] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      const { filterList, list } = module;
      setList([...list]);
      setCategoryList(filterList.categoryList);
      setTagList(filterList.tagList);
      setSortList(filterList.sortTypeList);
    }
  }, [module]);

  const { alert, setError } = useApiError();
  const [actionbarLoading, setActionbarLoading] = useState(false);
  let clickloading = false;
  const logging = async (type, item, targetType) => {
    if (!clickloading && !actionbarLoading) {
      clickloading = true;
      setActionbarLoading(true);
      const [info, error] = await moduleApi.moduleLogging(
        type,
        module.moduleCode,
        item.itemCode,
        { ...DEFAULT_PARAMS, targetType },
      );
      if (info?.resCode === 200) {
        const { result } = info;
        const findItemIndex = list.findIndex(
          (el) => el.itemCode === item.itemCode,
        );
        list[findItemIndex] = {
          ...list[findItemIndex],
          ...result,
          likes:
            type === 'like'
              ? (result.isLike === 1 && item.likes + 1) || item.likes - 1
              : item.likes,
        };
        setList([...list]);
        setActionbarLoading(false);
        clickloading = false;
      } else if (error) {
        setActionbarLoading(false);
        if (type !== 'click') {
          setError(error);
        }
        clickloading = false;
      }
    }
  };

  const [detailModal, setDetailModal] = useState();
  const itemClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    if (item.linkType !== 'none') {
      logging('click', item, 'link');
    }
    if (item.contentType !== 'none') {
      logging('click', item, 'content');
    }
    if (item.linkType !== 'none' && item.url)
      linkTypeClick(item.linkType, item.url);
    if (item?.contentType === 'modal') setDetailModal(item);
    if (item?.contentType === 'detail')
      languageNavigate(`detail/${item.itemCode}`);
  };

  return (
    <>
      <EnumerationPresenter
        module={module}
        list={list}
        itemClick={itemClick}
        logging={logging}
        categoryList={categoryList}
        tagList={tagList}
        filters={filters}
        setFilters={setFilters}
      />
      {detailModal && (
        <Modal
          close={() => {
            setDetailModal();
          }}
          className="dim-disabled-overlay"
        >
          <Detail
            type="modal"
            bucket={bucket}
            modalDetailCode={detailModal.itemCode}
            padding="0 !important"
            minHeight={windowSize.height * 0.85 - 56 - 68 - 50}
            close={() => {
              setDetailModal();
            }}
          />
        </Modal>
      )}
      {alert && <Alert {...alert} />}
    </>
  );
}

export default EnumerationContainer;
