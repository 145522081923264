import { reservationApi } from 'api';
import { Alert, Button, Div, Loading, Row, Text, Toast } from 'components';
import { useApiParams } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MypageReservation({ list, getLog }) {
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();

  const [alert, setAlert] = useState();
  const deleting = (item) => {
    setAlert({
      title: t('alert_title'),
      content: t('cancel_warning'),
      confirm: () => deleteItem(item),
      cancel: () => setAlert(),
    });
  };

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState('');
  const deleteItem = async (item) => {
    if (!loading) {
      setAlert();
      setLoading(true);
      const [info] = await reservationApi.cancel(
        item.moduleCode,
        item.enumItemCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setLoading(false);
        getLog();
        setToast(t('reservation_cancel_done'));
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {list?.map((item) => (
        <Div
          key={item.enumItemCode}
          width="min(64%, 588px)"
          padding="0 10px"
          mPadding="0"
          mWidth="100%"
          margin="0 auto"
        >
          <Row
            width="100%"
            alignItems="center"
            padding="20px 0"
            gap={10}
            borderBottom={{ color: 'g200' }}
          >
            <Div flex={1}>
              <Text type="h7" maxLine={3} mb={5}>
                {item.title || item.name}
              </Text>
              {item.selections?.map((selection) => (
                <Text color="g500" type="b3" key={selection.title}>
                  {selection.title}: {selection.selection}
                </Text>
              ))}
            </Div>
            <Row gap={10}>
              {/* <Button buttonType="text" color="g800">
              {t('edit')}
            </Button> */}
              <Button
                buttonType="text"
                color="g800"
                onClick={() => deleting(item)}
                type="b2"
              >
                {t('cancel')}
              </Button>
            </Row>
          </Row>
        </Div>
      ))}
      {list?.length === 0 && (
        <Row
          width="100%"
          height={300}
          alignItems="center"
          justifyContent="center"
        >
          <Text color="g600" type="b1">
            {t('mypage_reservation_empty')}
          </Text>{' '}
        </Row>
      )}
      {alert && <Alert {...alert} />}
      {loading && <Loading position="absolute" />}
      {toast && <Toast content={toast} />}
    </>
  );
}
