import { useEffect, useRef, useState } from 'react';
import { Dim, Div, PaginationArrowButton, Row, Text } from 'components';
import styled from 'styled-components';

const BorderButton = styled(Row)`
  padding: 2px 20px;
  border-bottom: ${({ active }) => (active ? '3px' : '1px')} solid
    ${({ active, theme }) =>
      active ? theme.colors.keyColor : theme.colors.g300};
  cursor: pointer;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export function CategoryList1({
  module,
  list,
  filters,
  setFilters,
  wrapStyles,
  ...props
}) {
  const ref = useRef();
  const categoryClick = (item) => {
    delete filters.tags;
    setFilters({
      ...filters,
      page: 1,
      lastRowNum: 0,
      category: item,
    });
  };

  const defaultButton = () => (
    <>
      {module?.categoryAllTitle && (
        <BorderButton
          onClick={() => {
            setFilters({ ...filters, category: module?.categoryAllTitle });
          }}
          active={
            !filters?.category || filters?.category === module?.categoryAllTitle
          }
          mFlex={1}
        >
          <Text
            textAlign="center"
            type={
              ((!filters?.category ||
                filters?.category === module?.categoryAllTitle) &&
                'h7') ||
              'b1'
            }
            color={
              ((!filters?.category ||
                filters?.category === module?.categoryAllTitle) &&
                'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {module?.categoryAllTitle}
          </Text>
        </BorderButton>
      )}
      {module?.categoryEtcTitle && (
        <BorderButton
          active={filters?.category === module?.categoryEtcTitle}
          onClick={() => {
            setFilters({ ...filters, category: module?.categoryEtcTitle });
          }}
          mFlex={1}
        >
          <Text
            textAlign="center"
            type={
              (filters?.category === module?.categoryEtcTitle && 'h7') || 'b1'
            }
            color={
              (filters?.category &&
                filters.category === module?.categoryEtcTitle &&
                'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {module?.categoryEtcTitle}
          </Text>
        </BorderButton>
      )}
    </>
  );

  return (
    <Row
      setRef={ref}
      gap={10}
      mGap={0}
      flexWrap="wrap"
      position="relative"
      {...props}
    >
      {defaultButton()}
      {list?.map((item, index) => (
        <BorderButton
          key={item}
          active={
            (!module?.categoryAllTitle && !filters?.category && index === 0) ||
            (filters?.category && filters.category === item)
          }
          justifyContent="center"
          onClick={() => categoryClick(item)}
          mFlex={1}
          height={40}
        >
          <Text
            textAlign="center"
            type={
              (((!module?.categoryAllTitle &&
                !filters?.category &&
                index === 0) ||
                (filters?.category && filters.category === item)) &&
                'h7') ||
              'b1'
            }
            color={
              (!module?.categoryAllTitle &&
                !filters?.category &&
                index === 0 &&
                'keyColor') ||
              (filters?.category && filters.category === item && 'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {item}
          </Text>
        </BorderButton>
      ))}
    </Row>
  );
}

const RoundButton = styled(Row)`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.layeredBackgroundColor : ''};
  border: 1px solid;
  border-color: ${({ active, theme }) =>
    active ? theme.colors.keyColor : theme.colors.g300};
  cursor: pointer;
  height: 40px;
  align-items: center;
  padding: 2px 20px;
  border-radius: 999px;
`;

export function CategoryList2({ module, list, filters, setFilters, ...props }) {
  const ref = useRef();
  const categoryClick = (item) => {
    delete filters.tags;
    setFilters({
      ...filters,
      page: 1,
      lastRowNum: 0,
      category: item,
    });
  };

  const [open, setOpen] = useState(false);
  const [useMore, setUseMore] = useState(false);
  useEffect(() => {
    if (list) {
      const { current } = ref;
      setUseMore(current.scrollWidth > current.clientWidth);
    }
  }, [list, ref?.current]);

  return (
    <>
      {open && (
        <Dim
          top="0"
          backgroundColor="transparent"
          zIndex="1"
          onClick={() => setOpen(false)}
        />
      )}
      <Div position="relative" width="100%">
        <Row
          setRef={ref}
          gap={10}
          overflowX="hidden"
          position="relative"
          {...props}
        >
          {module?.categoryAllTitle && (
            <RoundButton
              active={
                !filters?.category ||
                filters?.category === module?.categoryAllTitle
              }
              onClick={() => {
                setFilters({ ...filters, category: module?.categoryAllTitle });
              }}
            >
              <Text
                type="h7"
                color={
                  ((!filters?.category ||
                    filters?.category === module?.categoryAllTitle) &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {module?.categoryAllTitle}
              </Text>
            </RoundButton>
          )}
          {module?.categoryEtcTitle && (
            <RoundButton
              padding="2px 20px"
              borderRadius="999px"
              active={filters?.category === module?.categoryEtcTitle}
              onClick={() => {
                setFilters({ ...filters, category: module?.categoryEtcTitle });
              }}
            >
              <Text
                type="h7"
                color={
                  (filters?.category &&
                    filters.category === module?.categoryEtcTitle &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {module?.categoryEtcTitle}
              </Text>
            </RoundButton>
          )}
          {list?.map((item, index) => (
            <RoundButton
              key={item}
              borderRadius="999px"
              active={
                (!module?.categoryAllTitle &&
                  !filters?.category &&
                  index === 0) ||
                filters?.category === item
              }
              onClick={() => categoryClick(item)}
            >
              <Text
                type="h7"
                color={
                  (!module?.categoryAllTitle &&
                    !filters?.category &&
                    index === 0 &&
                    'keyColor') ||
                  (filters?.category &&
                    filters.category === item &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {item}
              </Text>
            </RoundButton>
          ))}
          {useMore && (
            <Row
              width={86}
              height="40px"
              background="linear-gradient(270deg, #FFFFFF 30.1%, rgba(255, 255, 255, 0) 80.1%)"
              position="absolute"
              right="-18px"
              alignItems="center"
              justifyContent="flex-end"
            >
              <PaginationArrowButton
                backgroundColor="backgroundColor"
                width={28}
                height={28}
                size={26}
                mr={18}
                hover={false}
                transition="all 300ms"
                transform={(open && 'rotate(90deg)') || 'rotate(-90deg)'}
                onClick={() => setOpen(!open)}
              />
            </Row>
          )}
        </Row>
        <Row
          position="absolute"
          borderRadius="12px"
          border={{ color: 'g200' }}
          width="100%"
          padding="30px 40px"
          backgroundColor="backgroundColor"
          top="52px"
          zIndex={500}
          boxShadow="default"
          flexWrap="wrap"
          colGap={10}
          rowGap={12}
          transform={(open && 'scaleY(1)') || 'scaleY(0)'}
          transition="all 300ms"
          transformOrigin="top center"
        >
          {module?.categoryAllTitle && (
            <RoundButton
              active={
                !filters?.category ||
                filters?.category === module?.categoryAllTitle
              }
              onClick={() => {
                setFilters({ ...filters, category: module?.categoryAllTitle });
              }}
            >
              <Text
                type="h7"
                color={
                  ((!filters?.category ||
                    filters?.category === module?.categoryAllTitle) &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {module?.categoryAllTitle}
              </Text>
            </RoundButton>
          )}
          {module?.categoryEtcTitle && (
            <RoundButton
              active={filters?.category === module?.categoryEtcTitle}
              onClick={() => {
                setFilters({ ...filters, category: module?.categoryEtcTitle });
              }}
            >
              <Text
                type="h7"
                color={
                  (filters?.category &&
                    filters.category === module?.categoryEtcTitle &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {module?.categoryEtcTitle}
              </Text>
            </RoundButton>
          )}
          {list?.map((item, index) => (
            <RoundButton
              active={
                (!module?.categoryAllTitle &&
                  !filters?.category &&
                  index === 0) ||
                (filters?.category && filters.category === item)
              }
              key={item}
              onClick={() => categoryClick(item)}
            >
              <Text
                type="h7"
                color={
                  (!module?.categoryAllTitle &&
                    !filters?.category &&
                    index === 0 &&
                    'keyColor') ||
                  (filters?.category &&
                    filters.category === item &&
                    'keyColor') ||
                  'g400'
                }
                whiteSpace="nowrap"
              >
                {item}
              </Text>
            </RoundButton>
          ))}
        </Row>
      </Div>
    </>
  );
}
