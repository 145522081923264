import { Row, ProfileImage, Text } from 'components';
import React from 'react';
import { UserlistCardStyles } from 'styles/CardStyles';

export function UserlistCard({ item, ...props }) {
  return (
    <UserlistCardStyles
      gap={10}
      alignItems="center"
      cursor="pointer"
      {...props}
    >
      {item?.isViewedUserThumbnail && (
        <ProfileImage size={34} thumbnail={item.userThumbnail} />
      )}
      {item?.isViewedUserName && <Text color="g500">{item.userName}</Text>}
    </UserlistCardStyles>
  );
}

export function UserlistChatCard({
  item,
  thumbnail,
  name,
  children,
  onClick,
  ...props
}) {
  return (
    <Row
      gap={15}
      padding="10px 25px"
      alignItems="center"
      justifyContent="space-between"
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
      {...props}
    >
      <Row gap={15} alignItems="center">
        {thumbnail === 'all' ? (
          <Row
            backgroundColor="keyColor"
            width={50}
            height={50}
            borderRadius="999px"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize={20} color="g0">
              All
            </Text>
          </Row>
        ) : (
          (thumbnail || item?.isViewedUserThumbnail) && (
            <ProfileImage
              size={50}
              thumbnail={thumbnail || item.userThumbnail}
            />
          )
        )}
        {(name || item?.isViewedUserName) && (
          <Text type="h8">{name || item.userName}</Text>
        )}
      </Row>
      {children}
    </Row>
  );
}
