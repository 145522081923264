import React, { useEffect, useState } from 'react';
import countryCodes from 'country-codes-list';
import { useLanguageNavigate } from 'hooks';
import { Dropdown } from './Dropdown';

export function CountryCode({ type, name, onChange, initValue, disabled }) {
  const [phoneCountryList, setPhoneCountryList] = useState();
  const { currentLanguage } = useLanguageNavigate();
  const [value, setValue] = useState();

  useEffect(() => {
    if (currentLanguage && name) {
      // 850 : north korea 항목 지움
      // countryNameLocal이 없을 경우 countryNameEn으로 대체
      const allCountryCodes = countryCodes.customList(
        'countryCode',
        type === 'country'
          ? '{"label" : "{countryNameEn} ", "value": "{countryCallingCode}", "lang": "{officialLanguageCode}", "empty":"{countryNameLocal}", "enName": "{countryNameEn}"}'
          : '{"label" : "(+{countryCallingCode}) {countryNameEn}", "value": "{countryCallingCode}", "lang": "{officialLanguageCode}", "empty":"{countryNameLocal}", "enName": "{countryNameEn}"}',
      );

      const parseCountryCodes = Object.values(allCountryCodes)
        .sort()
        .map((el) => JSON.parse(el));

      const removeItemIndex = parseCountryCodes.findIndex(
        (el) => el.value === '850',
      );

      parseCountryCodes.splice(removeItemIndex, 1);

      setPhoneCountryList([...parseCountryCodes]);

      if (initValue) {
        const targetValue = parseCountryCodes.find(
          (el) => el.value === initValue.countryCode,
        );
        setValue(targetValue);
      } else if (type !== 'country') {
        const defaultCode = parseCountryCodes.find(
          (el) => el.lang.toUpperCase() === currentLanguage.toUpperCase(),
        );

        setValue(defaultCode);
      }
    }
  }, [currentLanguage, name]);

  useEffect(() => {
    if (value?.value) {
      onChange(value.value, 'phoneCountyCode', value.enName);
    }
  }, [value, name]);

  const onChangeHandler = (value) => {
    if (value) {
      setValue(value);
    } else {
      setValue();
      onChange('', 'phoneCountyCode', '');
    }
  };

  return (
    phoneCountryList && (
      <Dropdown
        list={phoneCountryList}
        customItem={(el) => el?.label}
        name={name}
        value={value}
        onChange={onChangeHandler}
        mb={10}
        disabled={disabled}
      />
    )
  );
}
