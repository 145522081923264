import { authApi, bucketApi, connectionApi } from 'api';
import axios from 'axios';
import { Alert, Loading, Header, HeaderDrawer, Footer, Tab } from 'components';
import {
  API_KEY,
  BUCKET_VERSION,
  FCM_TOKEN,
  LANGUAGE,
  REFRESH_CODE,
  TOKEN,
  UUID,
} from 'data/keyFile';
import {
  useApiError,
  useBucketReset,
  useCookieUtil,
  useLanguageNavigate,
  useWindowSize,
} from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Signup,
  Login,
  Menu,
  Detail,
  Profile,
  Mypage,
  Findpassword,
  Changepassword,
} from 'routes';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'styles';
import socketIo from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/user';
import { setLogo } from 'store/logo';
import { makeTheme } from 'utils/css/makeTheme';
import ChannelService from '../ChannelService';
import { PaymentResult } from './PaymentResult';

// 페이지에 처음 진입한 경우
//  1. UUID 체크 후
//  2. bucket code 와 UUID로 api key를 발급 받음
//  3. browser info 를 포함한 정보로 geust token 발급

export function Main(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { languageNavigate } = useLanguageNavigate();
  const [loading, setLoading] = useState(true);
  const { alert, setError } = useApiError();
  const { getCookie, setCookies, removeCookies } = useCookieUtil();
  const [bucket, setBucket] = useState();
  const [socket, setSocket] = useState();
  const [bucketTheme, setBucketTheme] = useState({ ...theme });
  const [defaultProfileCategories, setDefaultProfileCategories] = useState();
  const [profileCategories, setProfileCategories] = useState();
  const location = useLocation();
  const [menuCode, setMenuCode] = useState(location.pathname.split('/')[1]);
  const disabledHeaderPath = ['login', 'profile', 'signup', 'findpassword'];
  const [accountPath, setAccountPath] = useState(
    disabledHeaderPath.includes(menuCode),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const windowSize = useWindowSize();
  const { logout, reload } = useBucketReset();
  const [customScript, setCustomScript] = useState([]);

  const getUUID = async () => {
    setLoading(true);
    const [info, error] = await authApi.uuid();
    if (info) {
      // api 통신 성공
      setCookies(UUID, info.result.uuid, 365);
    } else if (error) {
      setError(error);
    }
  };

  const getAPIKey = async () => {
    setLoading(true);
    const [info, error] = await authApi.apiKey({
      uuid: getCookie(UUID),
      browserInfo: navigator.userAgent,
    });
    if (info) {
      // api 통신 성공
      setCookies(API_KEY, info.result.apiKey);
    } else if (error) {
      setError(error);
    }
  };

  const getGuestToken = async () => {
    setLoading(true);
    const [info, error] = await authApi.guestToken({
      apiKey: getCookie(API_KEY),
      uuid: getCookie(UUID),
      browserInfo: navigator.userAgent,
    });
    if (info) {
      // api 통신 성공
      setCookies(TOKEN, info.result.token);
      setCookies(REFRESH_CODE, info.result.refreshCode);
    } else if (error?.resCode === 451) {
      if (process.env.NODE_ENV !== 'production') removeCookies(API_KEY);
      logout();
    } else if (error) {
      setError(error);
    }
  };

  const saveFCMToken = async (fcmToken) => {
    setLoading(true);
    const [info, error] = await bucketApi.saveFCM({
      apiKey: getCookie(API_KEY),
      uuid: getCookie(UUID),
      bucketVersion: getCookie(BUCKET_VERSION),
      fcmToken,
    });
    if (info) {
      // api 통신 성공
      setLoading(false);
    } else if (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getBucketInfo = async () => {
    setLoading(true);
    try {
      const [info, error] = await bucketApi.bucketInfo({
        apiKey: getCookie(API_KEY),
        uuid: getCookie(UUID),
        bucketVersion: getCookie(BUCKET_VERSION) || 0,
      });
      if (info) {
        const {
          isNecessaryLogin,
          isNecessaryProfile,
          isNecessaryReload,
          isNecessaryInit,
          defaultProfileCategories,
          profileCategories,
          bucket,
          account,
        } = info.result;

        if (isNecessaryInit === 1) {
          // 버킷 초기화
          logout();
        } else if (isNecessaryReload === 1) {
          reload();
        } else {
          if (account) {
            dispatch(setUser(account));
            if (
              (searchParams.get(FCM_TOKEN) || getCookie(FCM_TOKEN)) &&
              account.accountType === 'basic'
            ) {
              saveFCMToken(searchParams.get(FCM_TOKEN) || getCookie(FCM_TOKEN));
            }
          }
          if (bucket.logoImg) {
            dispatch(setLogo(bucket.logoImg));
          }
          if (profileCategories) {
            setProfileCategories(profileCategories);
          }
          if (defaultProfileCategories) {
            setDefaultProfileCategories(defaultProfileCategories);
          }
          if (bucket.bucketVersion) {
            if (
              getCookie(BUCKET_VERSION) &&
              getCookie(BUCKET_VERSION) !== String(bucket?.bucketVersion)
            ) {
              setCookies(BUCKET_VERSION, bucket.bucketVersion);
              getGuestToken();
              return;
            }
            setCookies(BUCKET_VERSION, bucket.bucketVersion);
          }
          setBucket(bucket);

          const currentLanguage =
            (searchParams.get(LANGUAGE) !== 'null' &&
              searchParams.get(LANGUAGE)) ||
            bucket.languages.find((el) => el.isMain === 1).key;

          if (currentLanguage) {
            const homeMenu =
              (bucket?.homeMenu && bucket?.homeMenu[currentLanguage]?.key) ||
              null;
            if (isNecessaryLogin === 1) {
              // 로그인이 필요한 container
              languageNavigate('login', currentLanguage);
            } else if (isNecessaryProfile === 1) {
              // 강제 프로필 입력
              languageNavigate('profile', currentLanguage);
            } else if (homeMenu && !menuCode && !accountPath) {
              // login 상태이거나 guest mode 허용 된 container
              // home menu 로 이동
              languageNavigate(`${homeMenu}`, currentLanguage);
            } else if (!homeMenu) {
              // home menu page code 가 없을 때 404 페이지로 이동
              navigate('/404');
            } else if (!searchParams.get(LANGUAGE)) {
              // language setting 안되어 있을 때,
              searchParams.append(LANGUAGE, currentLanguage);
              setSearchParams(searchParams);
            }
            i18n.changeLanguage(currentLanguage);
          }
          // app token
          if (searchParams.get(FCM_TOKEN) && !getCookie(FCM_TOKEN)) {
            setCookies(FCM_TOKEN, searchParams.get(FCM_TOKEN));
          }
          settingSocket(bucket.bucketCode);

          if (
            bucket.isUsedChannelTalk &&
            String(bucket.isUsedChannelTalk) === '1' &&
            bucket.channelTalkPluginKey
          ) {
            ChannelService.boot({
              pluginKey: bucket.channelTalkPluginKey,
              language: searchParams.get(LANGUAGE) || 'ko',
            });
          }
          if (
            bucket.customScript &&
            Array.isArray(bucket.customScript) &&
            bucket.customScript.length > 0
          ) {
            setCustomScript(bucket.customScript);
          }
        }
      } else if (error?.resCode === 451) {
        logout();
      } else if (error) {
        setError(error);
      }
    } finally {
      // setLoading(false);
    }
  };

  const settingSocket = (bucketCode) => {
    const initSocket = socketIo(process.env.REACT_APP_SOCKET_API_HOST, {
      path: '/socket',
      reconnection: true,
      transports: ['websocket'],
      query: {
        bucketCode,
        token: getCookie(TOKEN),
        uuid: getCookie(UUID),
        apiKey: getCookie(API_KEY),
        bucketVersion: getCookie(BUCKET_VERSION),
      },
    });
    initSocket.on('checking connection', async (data) => {
      if (data) {
        await connectionApi.checkConnection({
          logId: data.id,
        });
      }
    });
    setSocket(initSocket);
  };

  useEffect(() => {
    const scripts = [];

    if (customScript.length > 0) {
      customScript.forEach((item) => {
        let script = document.querySelector(`script[src="${item.src}"]`);

        if (!script) {
          script = document.createElement('script');
          script.className = 'main';
          script.src = item.src;
          script.innerHTML = `${item.textContent}`;
          item.attributes?.forEach((attribute) => {
            script[attribute.key] = attribute.value;
          });
        }

        document.body.appendChild(script);
        scripts.push(script);
      });
    }

    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, [customScript]);

  useEffect(() => {
    if (bucket && windowSize.height) {
      let fontFamily = 'Pretendard';
      if (bucket.fontStyles.length > 0) {
        const fontStyle = document.createElement('style');
        fontStyle.id = 'font-style';
        let tempFontArray = [];

        bucket.fontStyles.forEach((font) => {
          if (font?.isMain === 1) {
            fontFamily = font.fontFamily;
          }
          const fontFace = `@font-face {
          font-family: '${font?.fontFamily}';
          src: url(${font.url}) format('${font.url
            .split('.')
            .pop()
            .toLowerCase()}');
          font-weight: ${font.fontWeight === 'bold' ? 700 : 400};
          font-style: normal;
        }
          `;
          tempFontArray = [...tempFontArray, fontFace];
        });
        fontStyle.innerHTML = tempFontArray.toString().replaceAll(',', '');
        document.body.style.cssText = `
        font-family: '${fontFamily}';
      `;
        document.getElementsByTagName('head')[0].appendChild(fontStyle);
      }

      // setting theme
      document.fonts.ready.then(() => {
        const { styles, tabMenuInfo } = bucket;
        const currentLanguage =
          searchParams.get(LANGUAGE) ||
          bucket.languages.find((el) => el.isMain === 1).key;

        const userTheme = { ...makeTheme(styles, bucketTheme) };
        setBucketTheme({
          ...bucketTheme,
          colors: { ...bucketTheme.colors, ...userTheme },
          fontFamily,
          windowSize: {
            height:
              windowSize.height -
              Number(bucket?.headerOption.headerHeight || 60) -
              ((tabMenuInfo?.[currentLanguage]?.length > 0 && 55) || 0),
          },
        });
        setLoading(false);
      });
    }

    return () => {
      const customFont = document.getElementById('font-style');
      if (customFont) {
        document.getElementsByTagName('head')[0].removeChild(customFont);
        document.body.style.removeProperty('font-family');
      }
    };
  }, [bucket, windowSize.height]);

  useEffect(() => {
    if (getCookie(UUID) && getCookie(API_KEY) && getCookie(TOKEN)) {
      // token이 존재할 때
      axios.defaults.headers.common.Authorization = `Bearer ${getCookie(
        TOKEN,
      )}`;
      getBucketInfo();
    } else if (getCookie(UUID) && getCookie(API_KEY)) {
      // guest token 발급
      getGuestToken();
    } else if (getCookie(UUID)) {
      // api key 발급
      getAPIKey();
    } else if (!getCookie(UUID)) {
      // container 첫 진입
      getUUID();
    }

    // url 변경 (=== bucketCode 변경) 될 때마다 guest token을 재발급
  }, [getCookie(UUID), getCookie(API_KEY), getCookie(TOKEN)]);

  // 페이지 이동시 menu가 없을때 homemenu setting
  useEffect(() => {
    const tempMenuCode = location.pathname.split('/')[1];
    if (bucket) {
      let tempAccountPath = false;
      if (tempMenuCode && tempMenuCode !== 'null') {
        setMenuCode(tempMenuCode);
        tempAccountPath = disabledHeaderPath.includes(tempMenuCode);
        setAccountPath(tempAccountPath);
      }
      if (
        (!tempMenuCode || tempMenuCode === 'null') &&
        !tempAccountPath &&
        searchParams.get(LANGUAGE)
      ) {
        const currentLanguage =
          searchParams.get(LANGUAGE) ||
          bucket.languages.find((el) => el.isMain === 1).key;

        if (currentLanguage) {
          const homeMenu =
            (bucket?.homeMenu && bucket?.homeMenu[currentLanguage]?.key) ||
            null;
          if (homeMenu) {
            languageNavigate(`/${homeMenu}`, currentLanguage);
          } else {
            getBucketInfo();
          }
        }
      }
    }
  }, [location, searchParams.get(LANGUAGE), bucket]);

  // header transparent 색상 체크를 위함
  const [headerOption, setHeaderOption] = useState();
  const headerCallback = useCallback((target) => {
    setHeaderOption(target);
  }, []);

  return (
    <ThemeProvider theme={bucketTheme}>
      <GlobalStyle />
      {(loading && <Loading backgroundColor="white" />) || (
        <>
          {bucket && !accountPath && bucket?.tabMenuInfo && (
            <Tab t={t} bucket={bucket} menuCode={menuCode} />
          )}
          {bucket &&
            bucket.header !== 'drawer' &&
            (headerOption && headerOption !== 'mypage'
              ? headerOption?.isUsed === 1
              : true) &&
            bucket.headerOption.isUsed === 1 &&
            !accountPath &&
            !location.pathname.includes('changePassword') &&
            windowSize.width > 1279 && (
              <Header
                t={t}
                bucket={bucket}
                menuCode={menuCode}
                headerOption={headerOption}
              />
            )}
          {bucket &&
            !accountPath &&
            !location.pathname.includes('changePassword') &&
            (headerOption && headerOption !== 'mypage'
              ? headerOption?.isUsed === 1
              : true) &&
            bucket.headerOption.isUsed === 1 &&
            (bucket.header === 'drawer' || windowSize.width < 1280) && (
              <HeaderDrawer
                t={t}
                bucket={bucket}
                menuCode={menuCode}
                headerOption={headerOption}
              />
            )}
          <Routes>
            <Route
              path=":menuCode/detail/:detailCode"
              element={
                <Detail bucket={bucket} headerCallback={headerCallback} />
              }
            />
            <Route
              path=":moduleCode/registration/result"
              element={
                <PaymentResult
                  bucket={bucket}
                  headerCallback={headerCallback}
                />
              }
            />
            <Route
              path=":menuCode"
              element={
                <Menu
                  bucket={bucket}
                  socket={socket}
                  headerCallback={headerCallback}
                />
              }
            />
            <Route
              path="login"
              element={<Login t={t} bucket={bucket} setBucket={setBucket} />}
            />
            <Route
              path="signup"
              element={
                <Signup
                  t={t}
                  loginMethod={bucket?.loginMethod}
                  loginInfo={bucket?.loginInfo}
                  bucket={bucket}
                  setBucket={setBucket}
                />
              }
            />
            <Route
              path="findpassword"
              element={
                <Findpassword
                  t={t}
                  loginMethod={bucket?.loginMethod}
                  loginInfo={bucket?.loginInfo}
                  bucket={bucket}
                  setBucket={setBucket}
                />
              }
            />
            <Route
              path="changePassword"
              element={
                <Changepassword
                  t={t}
                  loginMethod={bucket?.loginMethod}
                  loginInfo={bucket?.loginInfo}
                  bucket={bucket}
                  setBucket={setBucket}
                />
              }
            />
            <Route
              path="profile"
              element={
                <Profile
                  t={t}
                  bucket={bucket}
                  defaultProfileCategories={defaultProfileCategories}
                  profileCategories={profileCategories}
                  getBucketInfo={getBucketInfo}
                />
              }
            />
            <Route
              path="mypage/*"
              element={
                <Mypage
                  t={t}
                  bucket={bucket}
                  headerCallback={headerCallback}
                  socket={socket}
                />
              }
            />
          </Routes>
          {alert && <Alert {...alert} />}
          {!accountPath && <Footer content={bucket?.footer} />}
        </>
      )}
      {/* 화면 최상위에 배치되어야 할 컴포넌트를 가장 마지막줄에 선언 */}
    </ThemeProvider>
  );
}
