import { authApi } from 'api';
import { Input, Button, Text, CountryCode, RoundButton } from 'components';
import { useApiParams, useCookieUtil, useVerificationTime } from 'hooks';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { DEFAULT_INVALID_KEYS } from 'utils/invalids';
import { useTranslation } from 'react-i18next';
import { makePhone } from 'utils/makePhone';
import { hideCodeRefStyles } from 'utils/hideCodeRefStyles';

export function Verified({
  useCountryCode,
  type,
  name,
  onChange,
  initValue,
  disabled,
}) {
  const { t } = useTranslation();

  const codeRef = useRef();

  const { DEFAULT_PARAMS } = useApiParams();

  const [values, setValues] = useState({ [type]: '', code: '' });
  const [invalid, setInvalid] = useState();
  const [init, setInit] = useState(false);

  const initFocus = () => {
    if (invalid?.type !== 'success') {
      // timer가 만료되었거나 code 가 아닌 항목을 재입력했을 시 code 초기화
      setInvalid();
      setValues({
        ...values,
        code: '',
      });
      document.getElementsByTagName('input')[type]?.focus();
    }
  };

  const { minutes, seconds, isRunning, setIsRunning, initTimer } =
    useVerificationTime(initFocus);

  const onChangeHandler = (value, name) => {
    if (name !== 'phoneCountyCode') {
      setInit(true);
    }
    if (name === type || name === 'phoneCountyCode') {
      initTimer();
      setValues({
        ...values,
        [name]: value,
        code: '',
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
    setInvalid();
  };

  const timerActive = useMemo(
    () => Boolean((isRunning && minutes < 2) || (!isRunning && minutes === 3)),
    [isRunning, minutes],
  );

  const sendCodeActive = useMemo(
    () =>
      Boolean(values[type]?.match(DEFAULT_INVALID_KEYS[type]) && timerActive),
    [values[type], timerActive],
  );

  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const sendCode = async () => {
    if (sendCodeActive) {
      setIsRunning(true);
      initTimer();
      // 인증번호 보내기
      setSendCodeLoading(true);
      const formatingParams =
        (type === 'phone' &&
          `${(useCountryCode && values.phoneCountyCode) || '82'} ${makePhone(
            values.phone,
          )}`) ||
        values.email;
      const params = { ...DEFAULT_PARAMS, [type]: formatingParams };
      delete params.containerVersion;
      const [info, error] = await authApi.verificationSend({
        ...params,
      });
      if (info) {
        // success
        setSendCodeLoading(false);
        setIsRunning(true);
        if (codeRef.current) {
          codeRef.current.focus();
        }
      } else if (error) {
        setSendCodeLoading(false);
        // setAlert({
        //   title: 'ERROR',
        //   content: t('error_verification'),
        //   confirm: () => {
        //     initTimer();
        //     setAlert();
        //   },
        // });
      }
    } else if (!timerActive) {
      setInvalid({ type: 'error', text: t('invalid_request') });
      setTimeout(() => {
        setInvalid();
      }, [1500]);
    }
  };

  const checkCodeActive = useMemo(
    () => Boolean(isRunning && values.code),
    [isRunning, values],
  );

  const [checkCodeLoading, setCheckCodeLoading] = useState(false);
  const checkCode = async () => {
    const formatingParams =
      (type === 'phone' &&
        `${(useCountryCode && values.phoneCountyCode) || '82'} ${makePhone(
          values.phone,
        )}`) ||
      values.email;
    const params = {
      ...DEFAULT_PARAMS,
      [type]: formatingParams,
      code: values.code,
    };
    delete params.containerVersion;

    try {
      setCheckCodeLoading(true);
      const [info, error] = await authApi.verificationCheck({
        ...params,
      });
      if (info) {
        setInvalid({ type: 'success', text: t('success_verification') });
      } else if (error) {
        // 451 : 인증코드 틀림
        setInvalid({ type: 'error', text: t('invalid_code') });
      }
    } finally {
      setCheckCodeLoading(false);
    }
  };

  useEffect(() => {
    if (type && name && init) {
      if (invalid?.type === 'success') {
        const formatingParams = (type === 'phone' && {
          countryCode: (useCountryCode && values.phoneCountyCode) || '82',
          phone: makePhone(values.phone),
        }) || {
          emailId: values.email.split('@')[0],
          domain: values.email.split('@')[1],
        };
        onChange(formatingParams, name);
      } else {
        onChange('', name);
      }
    }
  }, [type, invalid, name, init]);

  useEffect(() => {
    if (initValue) {
      setValues({
        [type]: (type === 'phone' && initValue.phone) || initValue.full,
        code: '',
      });
    }
  }, [initValue]);

  return (
    <>
      {type === 'phone' && useCountryCode && (
        <CountryCode
          name="phoneCountyCode"
          onChange={onChangeHandler}
          disabled={disabled}
        />
      )}
      <Input
        type={(type === 'phone' && 'tel') || ''}
        value={(values && values[type]) || ''}
        name={name}
        onChange={(val) => onChangeHandler(val, type)}
        onKeyDown={sendCode}
        onKeyDownActive={sendCodeActive || !sendCodeLoading}
        placeholder={t(`${type}_placeholder`)}
        rightChildren={
          invalid?.type !== 'success' && (
            <RoundButton
              className="input-disabled-click"
              buttonType="text"
              type="b3"
              padding="0.5rem 1.2rem"
              active={!sendCodeLoading && sendCodeActive}
              loading={sendCodeLoading}
              onClick={sendCode}
            >
              {(isRunning && t('verification_re')) || t('verification')}
            </RoundButton>
          )
        }
        disabled={disabled}
      />
      <Input
        type="tel"
        setRef={codeRef}
        value={(values && values.code) || ''}
        name="code"
        placeholder={t('code_placeholder')}
        autoComplete="off"
        onChange={onChangeHandler}
        invalid={invalid}
        onKeyDown={checkCode}
        onKeyDownActive={checkCodeActive || !checkCodeLoading}
        disabled={disabled || checkCodeLoading || invalid?.type === 'success'}
        rightChildren={
          invalid?.type !== 'success' &&
          isRunning && (
            <>
              <Text type="b3" color="red" className="input-disabled-click">
                {`0${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
              </Text>
              {checkCode && (
                <RoundButton
                  className="input-disabled-click"
                  buttonType="text"
                  borderRadius="999px"
                  type="b3"
                  padding="0.5rem 1.2rem"
                  active={!checkCodeLoading && checkCodeActive}
                  loading={checkCodeLoading}
                  onClick={checkCode}
                >
                  {t('confirm')}
                </RoundButton>
              )}
            </>
          )
        }
        {...hideCodeRefStyles(!sendCodeLoading && isRunning && values[type])}
      />
    </>
  );
}
