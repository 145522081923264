import {
  Div,
  PartType,
  Progressbar,
  Term,
  RegistrationList,
  RegistrationMessage,
  RegistrationListReadonly,
  Payinfo,
} from 'components';
import React from 'react';

function RegistrationPresenter({
  t,
  moduleTop,
  mModuleTop,
  setProgress,
  setTerm,
  setPartType,
  module,
  progress,
  partType,
  term,
  detail,
  targetSession,
  changeTargetSession,
  sessionList,
  activeSession,
  invalids,
  values,
  etcValues,
  setEtcValues,
  onChange,
  onEtcChange,
  onMutipleChange,
  onCheckChange,
  tempConfirm,
  confirm,
  getEventPaymentCode,
  logout,
  cancel,
  setPassCodeAlert,
  setEventpaymentWithPassCodeAlert,
  payInfo,
}) {
  return (
    <Div width="100%">
      {(detail?.isCanceled === 1 && (
        <RegistrationMessage
          title={t('registration_cancel_success')}
          content={t('registration_cancel_content')}
        />
      )) ||
        (detail?.isCompleted === 1 && detail?.isUsedResult !== 1 && (
          <RegistrationMessage
            title={t('registration_success')}
            content={t('already_registration')}
            onClick={logout}
            buttonLabel={t('new_registration')}
          />
        )) ||
        (detail?.isCompleted === 1 && detail?.isUsedResult === 1 && (
          <Div width="100%">
            <RegistrationListReadonly
              t={t}
              isCanceled={detail?.isCanceled}
              isPaid={detail?.isPaid}
              confirmType={detail?.confirmType}
              isCompleted={detail?.isCompleted}
              isSubmit={detail?.isSubmit}
              isModified={detail?.isModified}
              activeSession={activeSession}
              targetSession={targetSession}
              changeTargetSession={changeTargetSession}
              sessionList={sessionList}
              values={values}
              etcValues={etcValues}
              setEtcValues={setEtcValues}
              cancel={cancel}
            />
            {payInfo && (
              <Payinfo
                t={t}
                payInfo={payInfo}
                detail={detail}
                cancel={cancel}
              />
            )}
          </Div>
        )) ||
        (detail?.isSubmit &&
          !detail?.isCompleted &&
          detail?.confirmType === 'admin' && (
            <RegistrationMessage
              title={t('wait_registration')}
              content={t('wait_registration_content')}
              buttonLabel={t('cancel')}
              onClick={cancel}
            />
          )) ||
        (detail?.isSubmit &&
          !detail?.isCompleted &&
          detail?.confirmType === 'eventpayment' && (
            <RegistrationMessage
              title={t('wait_payment_registration')}
              content={t('wait_payment_registration_content')}
              buttonLabel={t('payment')}
              onClick={getEventPaymentCode}
              cancel={cancel}
            />
          )) || (
          <>
            {module?.isSubmit !== 1 && (
              <>
                {progress?.max > 0 && (
                  <Progressbar {...progress} setProgress={setProgress} />
                )}
                {module?.isUsedPartType === 1 && progress?.value === 0 && (
                  <PartType
                    t={t}
                    initValue={partType}
                    list={module?.partTypes.slice(1)}
                    progress={progress}
                    setPartType={setPartType}
                    setProgress={setProgress}
                  />
                )}
                {module?.terms?.length > 0 &&
                  ((module?.isUsedPartType === 0 && progress?.value === 0) ||
                    (module?.isUsedPartType === 1 &&
                      progress?.value === 1)) && (
                    <Term
                      t={t}
                      progress={progress}
                      list={module?.terms}
                      initValue={term}
                      setTerm={setTerm}
                      setProgress={setProgress}
                    />
                  )}
              </>
            )}
            {(module?.isSubmit === 1 || progress?.value === progress?.max) && (
              <RegistrationList
                moduleTop={moduleTop}
                mModuleTop={mModuleTop}
                backgroundColor={module?.styles?.backgroundColor}
                sessionTop={module?.styles?.paddingTop}
                designType={module?.designType}
                confirmType={detail?.confirmType}
                isTemporary={detail?.isTemporary}
                isCompleted={detail?.isCompleted}
                isSubmit={detail?.isSubmit}
                targetSession={targetSession}
                changeTargetSession={changeTargetSession}
                sessionList={sessionList}
                invalids={invalids}
                values={values}
                etcValues={etcValues}
                setEtcValues={setEtcValues}
                onChange={onChange}
                onEtcChange={onEtcChange}
                onMutipleChange={onMutipleChange}
                onCheckChange={onCheckChange}
                activeSession={activeSession}
                confirm={confirm}
                tempConfirm={tempConfirm}
                getEventPaymentCode={getEventPaymentCode}
                cancel={cancel}
                setPassCodeAlert={setPassCodeAlert}
                setEventpaymentWithPassCodeAlert={
                  setEventpaymentWithPassCodeAlert
                }
              />
            )}
          </>
        )}
    </Div>
  );
}

export default RegistrationPresenter;
