/* eslint-disable no-throw-literal */
import { stampApi } from 'api';
import { HTMLWrap, Button, Div, Alert, ChildAlert } from 'components';
import { useApiParams, useLanguageNavigate } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QrReader } from 'react-qr-reader';

export function Scanner({
  windowSize,
  module,
  close,
  getList,
  goDetail,
  doneStamping,
  stamping,
}) {
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();
  const { languageNavigate } = useLanguageNavigate();

  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(false);

  const [childAlert, setChildAlert] = useState();
  const handleScan = async (itemCode) => {
    if (!loading) {
      setLoading(true);
      const [info, error] = await stampApi.item(module.moduleCode, itemCode, {
        ...DEFAULT_PARAMS,
      });
      if (info) {
        if (info.result.content) {
          // content 있을때
          goDetail(info.result);
        } else if (info.result.isAutoConfirm === 1) {
          getList();
          doneStamping('scanner');
        } else if (info.result.isAutoConfirm === 0) {
          setChildAlert({
            confirmLabel: '스탬프 획득',
            confirm: () => {
              setChildAlert();
              stamping(info.result, 'scanner');
            },
            close: () => setChildAlert(),
            buttonStyles: { buttonType: 'large' },
          });
        }
      } else if (error?.resCode === 4592) {
        setAlert({
          title: t('alert_title'),
          content: '다른 스탬프를 먼저 찍어주세요.',
          confirm: () => {
            setAlert();
            setLoading(false);
          },
        });
      } else if (error?.resCode === 457) {
        setAlert({
          title: t('alert_title'),
          content: t('login_warning'),
          confirm: () => languageNavigate(`/login`),
          cancel: () => setAlert(''),
        });
      }
    }
  };

  return (
    <>
      <HTMLWrap
        content={
          windowSize?.width < 1280
            ? module?.qrMobileContent || module?.qrContent
            : module?.qrContent
        }
        mb={20}
      />
      {loading && (
        <Div
          width="325px"
          height="325px"
          margin="0 auto"
          backgroundColor="key100"
          borderRadius="10px"
        />
      )}
      {!loading && (
        <QrReader
          constraints={{ facingMode: 'environment' }}
          scanDelay={300}
          onResult={(result, error) => {
            if (result) {
              handleScan(result.text);
            }

            if (!!error && JSON.stringify(error) !== JSON.stringify({})) {
              console.info(error);
            }
          }}
          containerStyle={{
            width: '100%',
            height: '100%',
            maxWidth: '325px',
            maxHeight: '325px',
            margin: '0 auto',
          }}
          videoContainerStyle={{
            width: '100%',
            height: '100%',
            maxWidth: '325px',
            maxHeight: '325px',
            borderRadius: '10px',
          }}
          videoStyle={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <Button
        buttonType="large"
        backgroundColor="keyFontColor"
        border={{ color: 'keyColor' }}
        color="keyColor"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={3}
        maxWidth={325}
        onClick={() => close('scanner')}
        margin="0 auto"
        mt={20}
        mb={30}
      >
        스탬프 수집 현황
      </Button>
      {childAlert && <ChildAlert {...childAlert} />}
      {alert && <Alert {...alert} />}
    </>
  );
}
