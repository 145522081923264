import {
  Button,
  Col,
  Dim,
  Div,
  GalleryTextarea,
  GalleryTitleInput,
  Icon,
  Image,
  Loading,
  Row,
  TagBadge,
  TagInput,
  Text,
  Textarea,
} from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingButtonStyle } from 'styles/ButtonStyle';
import { animations } from 'styles/css';
import { moduleApi } from 'api';
import { useApiParams, useWindowSize } from 'hooks';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { InputImageFile } from './InputImageFile';

function GalleryWrite1({
  module,
  getModules,
  setFilters,
  setError,
  targetEditItem,
  setTargetEditItem,
  type,
  isEdit,
  setIsEdit,
  ...props
}) {
  const windowSize = useWindowSize();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { DEFAULT_PARAMS } = useApiParams();
  const { t } = useTranslation();
  const [tag, setTag] = useState('');

  const [values, setValues] = useState({
    title: '',
    content: '',
    tags: [],
    attachmentFiles: [],
  });
  const onChange = (value, name) => {
    if (name === 'tags') {
      setValues({ ...values, [name]: [...values.tags, value] });
    } else if (name === 'attachmentFiles') {
      setValues({ ...values, [name]: [...values.attachmentFiles, value] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const removeTags = (value) => {
    setValues({ ...values, tags: values.tags.filter((tag) => tag !== value) });
  };

  const removeAttachment = (index) => {
    values.attachmentFiles.splice(index, 1);
    setValues({
      ...values,
    });
  };

  const [editing, setEditing] = useState(false);

  const openEditing = () => {
    if (!editing && user?.name) {
      setEditing(true);
    } else if (!editing && !user?.name) {
      setError({ resCode: 457 });
    }
  };

  const closeEditing = () => {
    setEditing(false);
    setValues({ title: '', content: '', tags: [], attachmentFiles: [] });
    if (targetEditItem) {
      setIsEdit(false);
    }
  };

  const [createLoading, setCreateLoading] = useState(false);
  const createItem = async () => {
    if (
      !createLoading &&
      values?.attachmentFiles?.length > 0 &&
      !!values?.content
    ) {
      setCreateLoading(true);
      const [info] = await moduleApi.createModuleItem(module.moduleCode, {
        ...values,
        ...DEFAULT_PARAMS,
      });
      if (info) {
        if (setFilters) {
          setFilters({
            page: 1,
            lastRowNum: 0,
            perPage: 30,
          });
        }

        setCreateLoading(false);
        setEditing(false);
        setValues({ title: '', content: '', tags: [], attachmentFiles: [] });
      } else {
        setCreateLoading(false);
      }
    }
  };

  const [editLoading, setEditLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const editItem = async () => {
    if (
      !editLoading &&
      values.content &&
      values?.attachmentFiles?.length > 0 &&
      values.itemCode
    ) {
      setEditLoading(true);
      const [info] = await moduleApi.editModuleItem(
        module.moduleCode,
        values.itemCode,
        {
          title: values.title,
          content: values.content,
          tags: values.tags,
          attachmentFiles: values.attachmentFiles,
          ...DEFAULT_PARAMS,
        },
      );
      if (info) {
        setEditLoading(false);
        setEditing(false);
        setValues({ title: '', content: '', tags: [], attachmentFiles: [] });
        getModules();
        setFilters({
          page: searchParams.get('page') || 1,
          lastRowNum: searchParams.get('lastRowNum') || 0,
          perPage: 30,
        });
      } else {
        setEditLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isEdit && targetEditItem) {
      const copyTargetEditItem = JSON.parse(JSON.stringify(targetEditItem));
      setValues({
        itemCode: copyTargetEditItem.itemCode,
        title: copyTargetEditItem.title,
        content: copyTargetEditItem.content,
        tags: copyTargetEditItem.tags,
        attachmentFiles: copyTargetEditItem.attachmentFiles,
      });
      openEditing();
    }
  }, [isEdit, targetEditItem]);

  const onDragEnd = async ({ draggableId, source, destination }) => {
    if (!destination || source.index === destination.index) return;
    const items = JSON.parse(JSON.stringify(values?.attachmentFiles));
    const [targetItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, targetItem);
    setValues({ ...values, attachmentFiles: [...items] });
  };

  return (
    <>
      <FloatingButtonStyle
        editing={editing}
        zIndex={7000}
        position="sticky"
        bottom="70px"
        left="100%"
        cursor="pointer"
        width={64}
        maxWidth={64}
        height={64}
        borderRadius="50%"
        boxShadow="floating"
        backgroundColor="keyColor"
        onClick={openEditing}
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        <Icon name="write" size={40} float="right" fill="keyFontColor" />
      </FloatingButtonStyle>
      {editing && <Dim overflowHidden />}
      {createLoading && <Loading position="absolute" />}
      {editing && (
        <Col
          position="fixed"
          zIndex={9999}
          backgroundColor="backgroundColor"
          top="50%"
          left="50%"
          borderRadius="1.2rem"
          padding="62px 77px"
          animation={animations.slideUp()}
          width={1024}
          height="72vh"
          mPadding="20px"
          mWidth="100vw"
          mHeight={windowSize?.height}
          mBorderRadius="0"
          gap={30}
          overflowY="auto"
        >
          {/* <Dropdown
            name="category"
            value={values.category}
            list={module?.categories}
            onChange={onChange}
            wrapStyles={{ width: 'auto', maxWidth: '325px' }}
          /> */}
          {module?.isViewedContentTitle === 1 && (
            <GalleryTitleInput
              value={values.title}
              name="title"
              onChange={onChange}
            />
          )}
          <Col gap={10}>
            <Text type="h6">{t('attach-image')}</Text>
            <Row gap={10} overflowX="auto">
              <InputImageFile
                moduleCode={module?.moduleCode}
                name="attachmentFiles"
                onChange={onChange}
                length={values?.attachmentFiles?.length}
                disabled={values?.attachmentFiles?.length > 2}
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <Row
                      setRef={provided.innerRef}
                      gap={10}
                      {...provided.droppableProps}
                    >
                      {values?.attachmentFiles?.map((file, index) => (
                        <Draggable
                          key={`${file.fileUrl}-${index}`}
                          draggableId={`${file.fileUrl}-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <Div
                              position="relative"
                              setRef={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Image
                                src={file.fileUrl}
                                width={250}
                                height={250}
                                mWidth={100}
                                mHeight={100}
                                objectFit="cover"
                                borderRadius="3px"
                              />
                              {index === 0 && (
                                <Div
                                  borderRadius="4px"
                                  backgroundColor="key500"
                                  padding="8px 10px"
                                  position="absolute"
                                  zIndex={3}
                                  left="10px"
                                  top="10px"
                                >
                                  <Text color="white" type="h9">
                                    {t('representative-image')}
                                  </Text>
                                </Div>
                              )}
                              <Div
                                position="absolute"
                                zIndex={3}
                                right="0"
                                top="0"
                                padding="5px"
                                cursor="pointer"
                                onClick={() => removeAttachment(index)}
                              >
                                <Icon name="close" stroke="g0" />
                              </Div>
                            </Div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Row>
                  )}
                </Droppable>
              </DragDropContext>
              {/* <Row gap={10} overflowX="overlay">
                {values?.attachmentFiles?.map((file, index) => (
                  <Div key={`${file.fileUrl}-${index}`} position="relative">
                    <Image
                      src={file.fileUrl}
                      width={250}
                      height={250}
                      mWidth={100}
                      mHeight={100}
                      objectFit="cover"
                      borderRadius="3px"
                    />
                    {index === 0 && (
                      <Div
                        borderRadius="4px"
                        backgroundColor="key500"
                        padding="8px 10px"
                        position="absolute"
                        zIndex={3}
                        left="10px"
                        top="10px"
                      >
                        <Text color="white" type="h9">
                          {t('representative-image')}
                        </Text>
                      </Div>
                    )}
                    <Div
                      position="absolute"
                      zIndex={3}
                      right="0"
                      top="0"
                      padding="5px"
                      cursor="pointer"
                      onClick={() => removeAttachment(index)}
                    >
                      <Icon name="close" stroke="g0" />
                    </Div>
                  </Div>
                ))}
              </Row> */}
            </Row>
          </Col>
          <GalleryTextarea
            value={values.content}
            name="content"
            onChange={onChange}
            placeholder={t('add_content')}
          />
          <Col
            className="board-writer-tags"
            pt={10}
            alignItems="flex-end"
            justifyContent="space-between"
            width="100%"
            gap={10}
          >
            <Row height="100%" gap={6} flexWrap="wrap" width="100%">
              <TagInput
                placeholder={t('add_tag')}
                value={tag || ''}
                onChange={(value) => setTag(value)}
                onKeyDown={() => {
                  onChange(tag, 'tags');
                  setTag('');
                }}
                onKeyDownActive={tag}
              />
              {values.tags.map((tag, index) => (
                <TagBadge
                  key={`tag_${tag}_${index}`}
                  onClick={() => removeTags(tag)}
                >
                  {tag}
                </TagBadge>
              ))}
            </Row>
            <Row gap={10}>
              <Button
                buttonType="small"
                ghost
                onClick={() => {
                  closeEditing();
                  setTag();
                }}
              >
                {t('cancel')}
              </Button>
              {(targetEditItem && (
                <Button
                  buttonType="small"
                  onClick={editItem}
                  active={
                    values.attachmentFiles?.length > 0 && !!values?.content
                  }
                >
                  {t('edit')}
                </Button>
              )) || (
                <Button
                  buttonType="small"
                  onClick={createItem}
                  active={
                    values.attachmentFiles?.length > 0 && !!values?.content
                  }
                >
                  {t('confirm')}
                </Button>
              )}
            </Row>
          </Col>
        </Col>
      )}
    </>
  );
}

export default GalleryWrite1;
