import { Button } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function SurveyStart({ onClick }) {
  const { t } = useTranslation();

  return (
    <Button active onClick={onClick}>
      {t('survey_start')}
    </Button>
  );
}
