import styled, { css } from 'styled-components';
import { common, space, borderElement, elementSize, flexElement } from './css';

export const ImageStyles = styled.img`
  ${({ objectFit }) => objectFit && `object-fit: ${objectFit};`};
  ${({ objectPosition }) =>
    objectPosition && `object-position: ${objectPosition};`};
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

  ${({ $loading }) =>
    $loading
      ? css`
          filter: blur(10px);
        `
      : css`
          filter: blur(0px);
        `}

  @media ${({ theme }) => theme.device.mobile} {
    ${({ mObjectFit }) => mObjectFit && `object-fit: ${mObjectFit};`};
    ${({ mObjectPosition }) =>
      mObjectPosition && `object-position: ${mObjectPosition};`};
  }

  ${common}
  ${space}
  ${borderElement}
  ${elementSize} 
  ${flexElement}
`;
