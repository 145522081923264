import React, { useEffect, useRef, useState } from 'react';
import { ChatInputStyles } from 'styles/InputStyles';
import { Div, Alert, Icon, Row } from 'components';
import { useSelector } from 'react-redux';
import { useLanguageNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';

export function ChatInput({
  setRef,
  label,
  onKeyPress,
  onKeyPressActive,
  name,
  disabled,
  wrapStyles,
  send,
  cancel,
  loading,
  initValue,
  initFocus,
  inputFocusHandler,
  ...props
}) {
  const [alert, setAlert] = useState();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const [value, setValue] = useState('');
  const textAreaRef = useRef();
  const [chageHeightFlag, setChangeHeightFlag] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');

  const onKeyPressHandler = (event) => {
    if (!user.name) {
      setAlert({
        title: t('alert_title'),
        content: t('login_warning'),
        confirm: () => languageNavigate(`/login`),
        cancel: () => setAlert(''),
      });
    } else if (
      !loading &&
      value &&
      event.key === 'Enter' &&
      event.shiftKey === false
    ) {
      event.preventDefault();
      event.stopPropagation();
      sendComment();
    }
  };

  const onChangeHandler = (event) => {
    const { value } = event.target;
    if (user.name) {
      setTextAreaHeight(`auto`);
      setValue(value);
    }
  };

  const sendComment = async () => {
    if (user.name) {
      const result = await send(value.trim());
      if (result) {
        setValue('');
      }
    } else {
      setAlert({
        title: t('alert_title'),
        content: t('login_warning'),
        confirm: () => languageNavigate(`/login`),
        cancel: () => setAlert(''),
      });
    }
  };

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      setTextAreaHeight(
        value ? `${textAreaRef.current.scrollHeight}px` : 'auto',
      );
      setChangeHeightFlag(value && textAreaRef.current.scrollHeight > 21);
    }
  }, [value]);

  useEffect(() => {
    if (inputFocusHandler) {
      inputFocusHandler(chageHeightFlag);
    }
  }, [chageHeightFlag]);

  return (
    <>
      <Div position="relative" width="100%" {...wrapStyles}>
        <Row
          backgroundColor="g100"
          borderRadius="999px"
          padding="1rem 2rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <ChatInputStyles
            display="block"
            ref={setRef || textAreaRef}
            name={name}
            value={value}
            disabled={loading}
            onChange={onChangeHandler}
            onKeyPress={onKeyPressHandler}
            rows={1}
            height={textAreaHeight || 'auto'}
            maxHeight={42}
            placeholder={t('chat_input_placeholder')}
            {...props}
          />
          <Icon
            size={24}
            name="chat-send"
            stroke={(value && 'keyColor') || 'g500'}
            hover="circle"
            cursor="pointer"
            onClick={sendComment}
          />
        </Row>
      </Div>
      {alert && <Alert {...alert} />}
    </>
  );
}
