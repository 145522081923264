import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError, useApiParams, useLanguageNavigate } from 'hooks';
import { registrationApi } from 'api';
import { Alert, Loading, RegistrationMessage } from 'components';
import { useSearchParams } from 'react-router-dom';
import { isEmptyObject } from 'utils/isEmptyObject';
import { setModule } from 'store/module';
import { useDispatch } from 'react-redux';
import RegistrationCheckPresenter from './RegistrationCheckPresenter';
import RegistrationEdit from './RegistrationEdit';

function RegistrationCheckContainer({ module }) {
  const { DEFAULT_PARAMS } = useApiParams();
  const { languageNavigate } = useLanguageNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      getRegistrationCode();
    }
  }, [module]);

  const { alert, setAlert, setError } = useApiError();

  const [loading, setLoading] = useState(false);
  const [registrationCode, setRegistrationCode] = useState();
  const [ready, setReady] = useState(false);
  const getRegistrationCode = async () => {
    setLoading(true);
    const [info, error] = await registrationApi.registrationCheckCode(
      module.moduleCode,
      {
        ...DEFAULT_PARAMS,
      },
    );
    if (info) {
      setRegistrationCode(info.result.registrationCode);
      getDetail(info.result.registrationCode);
    } else if (error?.resCode === 456) {
      setLoading(false);
      setReady(true);
    } else if (error) {
      setLoading(false);
      setError(error);
    }
  };

  // registration list
  const [sessionList, setSessionList] = useState();
  const [targetSession, setTargetSession] = useState();
  const [values, setValues] = useState();
  const [etcValues, setEtcValues] = useState();
  const [detail, setDetail] = useState();
  const getDetail = async (registrationCode) => {
    const [info, error] = await registrationApi.registrationCheckInfo(
      registrationCode,
      {
        ...DEFAULT_PARAMS,
      },
    );
    if (info?.resCode === 200) {
      if (info?.result.isPaid === 1) {
        getPeymentInfo(registrationCode);
      }
      let tempValue = {};
      let tempEtcValue = {};
      let tempChildValue = {};
      const { list } = info.result;
      list?.forEach((session) => {
        session?.itemList
          .filter((el) => el.itemType !== 'content')
          .forEach((item) => {
            // value
            tempValue = {
              ...tempValue,
              [item.itemCode]: item.answer ||
                (item.itemType.includes('multiple') && []) || [''],
            };
            // etc value
            if (
              item.answer &&
              item.selectionEtcTitle &&
              item.itemType !== 'email' &&
              item.answer?.filter(
                (x) =>
                  !item.selections?.find(
                    (el) => el.selectionCode === x.selectionCode,
                  ),
              ).length > 0
            ) {
              tempEtcValue = {
                ...tempEtcValue,
                [item.itemCode]: item.answer?.find(
                  (x) => !item.selections?.includes(x),
                ),
              };
            }

            //  child
            if (
              item.answer &&
              item.answer[0] &&
              item.itemType !== 'multiple' &&
              Object.keys(item.childList).length > 0
            ) {
              Object.values(item.childList)
                .flat(1)
                ?.forEach((childItem) => {
                  // child value
                  tempChildValue = {
                    ...tempChildValue,
                    [childItem.itemCode]: childItem.answer ||
                      (childItem.itemType?.includes('multiple') && []) || [''],
                  };
                  // child etc value
                  if (
                    childItem.answer &&
                    childItem.answer[0] &&
                    childItem.selectionEtcTitle &&
                    childItem.itemType !== 'email' &&
                    childItem.answer?.filter(
                      (x) =>
                        !childItem.selections?.find(
                          (el) => el.selectionCode === x.selectionCode,
                        ),
                    ).length > 0
                  ) {
                    tempEtcValue = {
                      ...tempEtcValue,
                      [childItem.itemCode]: childItem.answer?.find(
                        (x) => !childItem.selections?.includes(x),
                      ),
                    };
                  }

                  // grandChild
                  if (
                    childItem.answer &&
                    childItem.answer[0] &&
                    childItem.itemType !== 'multiple' &&
                    Object.keys(childItem.childList).length > 0
                  ) {
                    Object.values(childItem.childList)
                      .flat(1)
                      .forEach((grandChild) => {
                        // grandChild value
                        tempChildValue = {
                          ...tempChildValue,
                          [grandChild.itemCode]: grandChild.answer || [],
                        };

                        // etc value
                        if (
                          grandChild.answer &&
                          grandChild.answer[0] &&
                          grandChild.selectionEtcTitle &&
                          grandChild.itemType !== 'email' &&
                          grandChild.answer?.filter(
                            (x) =>
                              !grandChild.selections?.find(
                                (el) => el.selectionCode === x.selectionCode,
                              ),
                          ).length > 0
                        ) {
                          tempEtcValue = {
                            ...tempEtcValue,
                            [grandChild.itemCode]: grandChild.answer?.find(
                              (x) => !grandChild.selections?.includes(x),
                            ),
                          };
                        }
                      });
                  }
                });
            }
          });
      });

      const totalValue = { ...tempValue, ...tempChildValue };
      setDetail(info?.result);
      setEtcValues((!isEmptyObject(tempEtcValue) && { ...tempEtcValue }) || '');
      setTargetSession({ ...list[0] });
      setValues({ ...totalValue });
      setSessionList([...list]);
      setLoading(false);
    } else if (error) {
      setLoading(false);
      setError(error);
    }
  };

  const [payInfo, setPayInfo] = useState();
  const getPeymentInfo = async (registrationCode) => {
    const [info] = await registrationApi.paymentInfo(registrationCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setPayInfo({ ...info?.result });
    }
  };

  const changeTargetSession = (newSession) => {
    setTargetSession(newSession);
  };

  const cancel = async () => {
    setLoading(true);
    const [info] = await registrationApi.registrationCancel(registrationCode, {
      ...DEFAULT_PARAMS,
      confirmType: detail.confirmType,
    });
    if (info) {
      setLoading(false);
      setAlert({
        title: t('alert_title'),
        content: t('cancel_done'),
        confirm: () => languageNavigate('/'),
      });
    } else {
      setLoading(false);
    }
  };

  const cancelWarning = async () => {
    setAlert({
      title: t('alert_title'),
      content: t('cancel_warning'),
      confirm: cancel,
      cancel: () => setAlert(''),
    });
  };

  return (
    (loading && <Loading position="relative" height="height" />) || (
      <>
        {(module && (ready || !detail?.isCompleted) && (
          <RegistrationMessage
            title={t('unregistered_title')}
            content={t('unregistered_content')}
          />
        )) ||
          ''}
        {(module &&
          detail &&
          detail?.isCompleted &&
          searchParams.get('edit') !== 'true' && (
            <RegistrationCheckPresenter
              t={t}
              module={module}
              detail={detail}
              targetSession={targetSession}
              changeTargetSession={changeTargetSession}
              sessionList={sessionList}
              values={values}
              etcValues={etcValues}
              setEtcValues={setEtcValues}
              cancel={cancelWarning}
              payInfo={payInfo}
            />
          )) ||
          (module &&
            detail &&
            detail?.isCompleted &&
            searchParams.get('edit') === 'true' && (
              <RegistrationEdit
                registrationCode={registrationCode}
                detail={detail}
                sessionList={sessionList}
                module={module}
                originValues={JSON.parse(JSON.stringify(values))}
                originEtcValues={JSON.parse(JSON.stringify(etcValues))}
              />
            )) ||
          ''}
        {alert && <Alert {...alert} />}
      </>
    )
  );
}

export default RegistrationCheckContainer;
