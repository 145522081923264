import { Div, Payinfo, RegistrationListReadonly } from 'components';
import React from 'react';

function RegistrationCheckPresenter({
  t,
  detail,
  targetSession,
  changeTargetSession,
  sessionList,
  activeSession,
  values,
  etcValues,
  setEtcValues,
  cancel,
  payInfo,
}) {
  return (
    <Div width="100%">
      <RegistrationListReadonly
        t={t}
        isCanceled={detail?.isCanceled}
        isPaid={detail?.isPaid}
        confirmType={detail?.confirmType}
        isCompleted={detail?.isCompleted}
        isSubmit={detail?.isSubmit}
        isModified={detail?.isModified}
        activeSession={activeSession}
        targetSession={targetSession}
        changeTargetSession={changeTargetSession}
        sessionList={sessionList}
        values={values}
        etcValues={etcValues}
        setEtcValues={setEtcValues}
        cancel={cancel}
      />
      {payInfo && (
        <Payinfo t={t} payInfo={payInfo} detail={detail} cancel={cancel} />
      )}
    </Div>
  );
}

export default RegistrationCheckPresenter;
