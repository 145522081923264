import {
  HeaderLanguage,
  HeaderLogo,
  Row,
  Icon,
  Text,
  HeaderProfile,
  HeaderTypeA,
  HeaderTypeB,
} from 'components';
import { useLanguageNavigate } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HeaderStyle } from 'styles/HeaderStyle';
import { linkTypeClick } from 'utils/linkType';

export function Header({
  t,
  bucket,
  headerOption,
  menuCode,
  children,
  ...props
}) {
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  const { header, logoImg, menuInfo, enableLogin, languages } = bucket;
  const headerDesignType = parseInt(
    bucket.headerOption.headerType.replace(/[^0-9]/g, ''),
    10,
  );
  const [headerHeight, setHeaderHeight] = useState(60);
  const [headerFont, setHeaderFont] = useState(14);

  const { languageNavigate, currentLanguage } = useLanguageNavigate();

  const goMenu = (selectPageCode) => {
    languageNavigate(`/${selectPageCode}`);
  };

  const goLinkType = (type, menu) => {
    linkTypeClick(
      type,
      menu.url,
      (menu.withInfo === 1 && { key: 'user', value: user.name }) || '',
    );
  };

  const goLogin = () => {
    languageNavigate(`/login`);
  };

  const [backgroundColor, setBackgroundColor] = useState();
  const [fontColor, setFontColor] = useState();

  const [currentScroll, setCurrentScroll] = useState(0);
  const [throttle, setThrottle] = useState(false);
  const changeHeaderColor = () => {
    setCurrentScroll(window.scrollY);
    if (throttle) return;
    if (!throttle) {
      setThrottle(true);
      setTimeout(async () => {
        if (window.scrollY > 5) {
          setBackgroundColor(
            headerOption.scrolledHeaderBgColor || 'backgroundColor',
          );
          setFontColor(headerOption.scrolledHeaderFontColor || 'g800');
        } else {
          setBackgroundColor(headerOption.headerBgColor);
          setFontColor(headerOption.headerFontColor);
        }
        setThrottle(false);
      }, 200);
    }
  };

  const initColor = () => {
    setBackgroundColor(
      headerOption.headerBgColor ||
        bucket.headerOption.headerBgColor ||
        'backgroundColor',
    );
    setFontColor(
      headerOption.headerFontColor ||
        bucket.headerOption.headerFontColor ||
        'g800',
    );
  };

  useEffect(() => {
    if (header && headerOption) {
      setHeaderHeight(
        Number(bucket?.headerOption?.headerHeight) ||
          Number(headerOption?.headerHeight) ||
          60,
      );
      setHeaderFont(
        Number(bucket?.headerOption?.headerFontSize) ||
          Number(headerOption?.headerFontSize) ||
          14,
      );
      setTimeout(() => {
        initColor();
      }, 300);
    }

    if (
      header !== 'scroll' &&
      (headerOption?.scrolledHeaderBgColor ||
        headerOption?.scrolledHeaderFontColor)
    ) {
      window.addEventListener('scroll', changeHeaderColor);
    }

    return () => {
      if (
        header !== 'scroll' &&
        (headerOption?.scrolledHeaderBgColor ||
          headerOption?.scrolledHeaderFontColor)
      ) {
        window.removeEventListener('scroll', changeHeaderColor);
      }
    };
  }, [bucket, headerOption]);

  const hoverBgColor = () => {
    if (
      (headerOption?.scrolledHeaderBgColor ||
        headerOption?.scrolledHeaderFontColor ||
        bucket?.headerOption?.scrolledHeaderBgColor ||
        bucket?.headerOption?.scrolledHeaderFontColor) &&
      currentScroll < 6
    ) {
      setBackgroundColor(
        headerOption?.scrolledHeaderBgColor ||
          bucket?.headerOption.scrolledHeaderBgColor,
      );
      setFontColor(
        headerOption?.scrolledHeaderFontColor ||
          bucket?.headerOption.scrolledHeaderFontColor,
      );
    }
  };

  const mouseLeaveBgColor = () => {
    if (headerOption?.isHeaderOverlap === 1 && currentScroll < 6) {
      initColor();
    }
  };

  return (
    header && (
      <HeaderStyle
        position={
          (header.includes('fixed') && 'fixed') ||
          (headerOption?.isHeaderOverlap === 1 && 'absolute') ||
          ''
        }
        top="0"
        left="0"
        height={headerHeight}
        width="100vw"
        borderBottom={
          headerOption?.isHeaderOverlap !== 1 && {
            color: 'g200',
          }
        }
        backgroundColor={backgroundColor}
        onMouseEnter={hoverBgColor}
        onMouseLeave={mouseLeaveBgColor}
        transition="all 150ms"
        zIndex={9000}
        {...props}
      >
        <Row
          width="100%"
          height="100%"
          maxWidth={(headerDesignType === 2 && 1440) || ''}
          padding="0 20px"
          margin="0 auto"
          alignItems="center"
          justifyContent="space-between"
          gap={40}
        >
          <HeaderLogo bucket={bucket} src={logoImg} />
          {menuInfo && headerDesignType === 1 && (
            <HeaderTypeA
              menus={menuInfo[currentLanguage]}
              goMenu={goMenu}
              goLinkType={goLinkType}
              menuCode={menuCode}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              headerHeight={headerHeight}
              headerFont={headerFont}
            />
          )}
          {menuInfo && headerDesignType === 2 && (
            <HeaderTypeB
              menus={menuInfo[currentLanguage]}
              goMenu={goMenu}
              goLinkType={goLinkType}
              menuCode={menuCode}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              headerHeight={headerHeight}
              headerFont={headerFont}
            />
          )}
          <Row id="header-side" alignItems="center" gap={20}>
            {languages?.length > 1 && (
              <HeaderLanguage
                type={headerDesignType}
                bucket={bucket}
                fontColor={fontColor}
                backgroundColor={backgroundColor}
                headerHeight={headerHeight}
              />
            )}
            {enableLogin === 1 && !user.name && (
              <>
                {headerDesignType === 1 && (
                  <Row
                    gap={6}
                    padding="10px"
                    height="100%"
                    hover
                    cursor="pointer"
                    onClick={goLogin}
                  >
                    <Icon name="login" size={21} fill={fontColor} />
                    <Text whiteSpace="nowrap" color={fontColor}>
                      {t('login')}
                    </Text>
                  </Row>
                )}
                {headerDesignType === 2 && (
                  <Row
                    gap={6}
                    padding="6px 28px"
                    height="100%"
                    hover
                    cursor="pointer"
                    onClick={goLogin}
                    border={{ color: 'keyColor', width: '2px' }}
                    borderRadius="3px"
                    ml={30}
                  >
                    <Text type="b1" whiteSpace="nowrap" color="keyColor">
                      {t('login')}
                    </Text>
                  </Row>
                )}
              </>
            )}
            {user?.name && (
              <HeaderProfile
                type={headerDesignType}
                user={user}
                bucket={bucket}
                fontColor={fontColor}
                backgroundColor={backgroundColor}
                headerHeight={headerHeight}
              />
            )}
          </Row>
        </Row>
      </HeaderStyle>
    )
  );
}
