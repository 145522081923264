import { ChildAlert, Radio, RegistrationCard, Row, Text } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function EventPaymentWithPassCodeAlert({
  alert,
  setAlert,
  setValue,
  value,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <ChildAlert title={t('select_registration')} width={500} {...props}>
      <Row gap={6} justifyContent="center">
        <RegistrationCard padding="0" active={value === 'passCode'}>
          <Radio
            flexDirection="column-reverse"
            gap={20}
            checkStyles={{ size: 30 }}
            checked={value === 'passCode'}
            value="passCode"
            onChange={() => setValue('passCode')}
            width="100%"
            height="100%"
            padding="30px 20px"
          >
            <Text
              type="h6"
              minWidth={87}
              textAlign="center"
              color={(value === 'passCode' && 'g800') || 'g500'}
            >
              {t('passcode_confirm')}
            </Text>
          </Radio>
        </RegistrationCard>
        <RegistrationCard padding="0" active={value === 'payment'}>
          <Radio
            flexDirection="column-reverse"
            gap={20}
            checkStyles={{ size: 30 }}
            checked={value === 'payment'}
            value="payment"
            onChange={() => setValue('payment')}
            width="100%"
            height="100%"
            padding="30px 20px"
          >
            <Text
              type="h6"
              minWidth={87}
              textAlign="center"
              color={(value === 'payment' && 'g800') || 'g500'}
            >
              {t('payment')}
            </Text>
          </Radio>
        </RegistrationCard>
      </Row>
    </ChildAlert>
  );
}
