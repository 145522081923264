import React from 'react';
import { Div, Image, Row, Text } from 'components';
import { useLanguageNavigate } from 'hooks';

export function HeaderLogo({ bucket, setRef, src, loadingCallback }) {
  const { languageNavigate } = useLanguageNavigate();

  const goHome = () => {
    languageNavigate(`/`);
  };

  return (
    <Row
      alignItems="center"
      height="100%"
      padding="0 5px"
      id="header-logo"
      setRef={setRef}
      onClick={goHome}
      cursor="pointer"
    >
      {(src && (
        <Image height="100%" src={src} loadingCallback={loadingCallback} />
      )) || (
        <Text type="h4" color="keyColor">
          DIFFERMICE
        </Text>
      )}
    </Row>
  );
}
