import {
  Badge,
  HTMLWrap,
  Row,
  Text,
  BadgeButton,
  Col,
  Div,
  Icon,
  PopupB,
  ProfileImage,
  LabelBadge,
  Bookmark,
} from 'components';
import moment from 'moment';
import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'styles/css/swiper.css';
import { isMobile } from 'utils/isMobile';
import { useWindowSize } from 'hooks';
import DefaultWrite from '../Board/DefaultWrite';

export function BoardDetailCard({
  t,
  item,
  logging,
  targetEditItem,
  setTargetEditItem,
  deleting,
  setError,
  getDetail,
  setFilters,
  type,
}) {
  const windowSize = useWindowSize();

  return (
    item && (
      <>
        <Col gap={15}>
          {((item?.isViewedUserName ||
            item?.isViewedUserThumbnail ||
            item?.isViewedContentCreatedDt ||
            (!isMobile() && item?.isMine === 1)) && (
            <Row justifyContent="space-between" alignItems="center">
              {((item?.isViewedUserName ||
                item?.isViewedUserThumbnail ||
                item?.isViewedContentCreatedDt) && (
                <Row alignItems="center" gap={10}>
                  {(item?.isViewedUserThumbnail && item?.userThumbnail && (
                    <ProfileImage size={40} thumbnail={item?.userThumbnail} />
                  )) ||
                    ''}
                  <Div>
                    {(item?.isViewedUserName && (
                      <Text type="h7">{item?.userName}</Text>
                    )) ||
                      ''}
                    {(item?.isViewedContentCreatedDt && (
                      <Text color="g400" type="b2">
                        {moment(item?.createdDt).format('YYYY-MM-DD HH:mm')}
                      </Text>
                    )) ||
                      ''}
                  </Div>
                </Row>
              )) ||
                ''}
              {(item?.isMine === 1 && (
                <PopupB
                  name={item?.itemCode}
                  items={
                    <>
                      <Text
                        hover="background"
                        cursor="pointer"
                        onClick={() => setTargetEditItem(item)}
                        whiteSpace="nowrap"
                        padding="4px 10px"
                      >
                        {t('edit')}
                      </Text>
                      <Text
                        hover="background"
                        cursor="pointer"
                        onClick={() => deleting(item)}
                        whiteSpace="nowrap"
                        padding="4px 10px"
                      >
                        {t('delete')}
                      </Text>
                    </>
                  }
                >
                  <Icon
                    name="more"
                    size={28}
                    cursor="pointer"
                    hover="circle"
                    transform="rotate(90deg)"
                    fill="g500"
                  />
                </PopupB>
              )) ||
                ''}
            </Row>
          )) ||
            ''}

          {((item?.isFixedTop === 1 ||
            !!item?.title ||
            (item?.isViewedContentCategory && item?.category) ||
            item?.isViewedContentNewLabel ||
            item?.isViewedContentStatusLabel ||
            item?.isViewedContentBookmarkBtn) && (
            <Row
              gap={60}
              justifyContent="space-between"
              alignItems="flex-start"
              mGap={10}
              mFlexDirection="column"
              mJustifyContent="normal"
            >
              {((item?.isFixedTop === 1 ||
                !!item?.title ||
                (item?.isViewedContentCategory && item?.category)) && (
                <Row gap={10} alignItems="baseline">
                  {(!isMobile() && item?.isFixedTop === 1 && (
                    <Icon name="pin" fill="keyColor" />
                  )) ||
                    ''}
                  {((!!item?.title ||
                    (item?.isViewedContentCategory && item?.category)) && (
                    <Div>
                      <Text
                        type="h4"
                        display="flex"
                        alignItems="center"
                        gap={5}
                      >
                        {!!item?.isSecretPost && (
                          <Icon name="unlock" fill="keyColor" size={24} />
                        )}
                        {(item?.isViewedContentCategory &&
                          item?.category &&
                          `[${item?.category}] `) ||
                          ''}
                        {item?.title}
                      </Text>
                    </Div>
                  )) ||
                    ''}
                </Row>
              )) ||
                ''}
              {(!isMobile() &&
                (item?.isViewedContentNewLabel ||
                  item?.isViewedContentStatusLabel ||
                  item?.isViewedContentBookmarkBtn) && (
                  <Row gap={10} alignItems="center" mMl="auto">
                    {(item?.isViewedContentNewLabel && (
                      <LabelBadge
                        type="h10"
                        backgroundColor="white"
                        color="keyColor"
                        border={{ color: 'key500' }}
                      >
                        New
                      </LabelBadge>
                    )) ||
                      ''}
                    {(item?.isViewedContentStatusLabel && (
                      <LabelBadge
                        type="h10"
                        backgroundColor={
                          (item?.status === 'completed' && 'done200') || 'g100'
                        }
                        color={
                          (item?.status === 'completed' && 'done') || 'g400'
                        }
                        padding="0 10px"
                      >
                        {item?.statusLabels[item?.status]}
                      </LabelBadge>
                    )) ||
                      ''}
                    {(item?.isViewedContentBookmarkBtn && (
                      <Bookmark
                        size={32}
                        isBookmark={item?.isBookmark}
                        backgroundColor={
                          (item?.isBookmark === 1 && 'key200') || 'g100'
                        }
                        fill={item?.isBookmark === 1 && 'keyColor'}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          logging('bookmark', item);
                        }}
                      />
                    )) ||
                      ''}
                  </Row>
                )) ||
                ''}
            </Row>
          )) ||
            ''}

          {(!!item?.content && (
            <HTMLWrap
              mb={15}
              pt={20}
              content={
                windowSize.width > 1279
                  ? item?.content
                  : (item?.mobileContent && item?.mobileContent) ||
                    item?.content
              }
            />
          )) ||
            ''}

          {(item?.isViewedContentTags && item?.tags?.length > 0 && (
            <Text color="g400" type="b4" display="flex" gap={6} flexWrap="wrap">
              {item?.tags?.map((tag) => (
                <Badge key={`${tag}${Math.random()}`} color="g400" type="b4">
                  {tag}
                </Badge>
              ))}
            </Text>
          )) ||
            ''}

          {((item?.isViewedContentLikeBtn ||
            item?.isViewedContentClickCount ||
            item?.isViewedContentCommentBtn) && (
            <Row gap={10}>
              {(item?.isViewedContentLikeBtn && (
                <BadgeButton
                  name="like"
                  active={item?.isLike === 1}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    logging('like', item);
                  }}
                >
                  {(item?.isViewedContentLikeCount &&
                    ((item?.likes > 100 && '100+') ||
                      item?.likes?.toString())) ||
                    ''}
                </BadgeButton>
              )) ||
                ''}
              {(item?.isViewedContentClickCount && (
                <BadgeButton name="view" fill="g500" stroke="transparent">
                  {(item?.clicks > 100 && '100+') ||
                    item?.clicks?.toString() ||
                    ''}
                </BadgeButton>
              )) ||
                ''}
              {(item?.isViewedContentCommentBtn && (
                <BadgeButton name="comment" point={item?.isNewComment === 1}>
                  {(item?.isViewedContentCommentCount &&
                    ((Number(item?.comments) > 100 && '100+') ||
                      item?.comments?.toString())) ||
                    ''}
                </BadgeButton>
              )) ||
                ''}
            </Row>
          )) ||
            ''}
        </Col>

        <DefaultWrite
          module={item}
          type={type}
          display="none"
          targetEditItem={targetEditItem}
          setTargetEditItem={setTargetEditItem}
          setError={setError}
          getModules={getDetail}
          setFilters={setFilters}
        />
      </>
    )
  );
}
