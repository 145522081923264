/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiError,
  useApiParams,
  useLanguageNavigate,
  useBucketReset,
} from 'hooks';
import { registrationApi } from 'api';
import {
  Alert,
  Input,
  ChildAlert,
  Loading,
  EventPaymentWithPassCodeAlert,
} from 'components';
import { isEmptyObject } from 'utils/isEmptyObject';
import { objectArrayParamsCleansing } from 'utils/apiCleansing';
import { useSearchParams } from 'react-router-dom';
import RegistrationEdit from 'modules/RegistrationCheck/RegistrationEdit';
import { useDispatch } from 'react-redux';
import { setModule } from 'store/module';
import RegistrationPresenter from './RegistrationPresenter';

function RegistrationContainer({
  bucket,
  menu,
  module,
  isTemporaryRegistration,
}) {
  const { DEFAULT_PARAMS } = useApiParams();
  const [searchParams] = useSearchParams();
  const { languageNavigate } = useLanguageNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState();
  const [term, setTerm] = useState([]);
  const [partType, setPartType] = useState();
  const [prevPartCode, setPrevPartCode] = useState();
  const [isPartCodeChanged, setIsPartCodeChanged] = useState(false);

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      const partCheck = module.isUsedPartType;
      const termCheck = module.terms && module.terms.length > 0 ? 1 : 0;
      setProgress({
        value:
          module.isSubmit === 1 || isTemporaryRegistration
            ? partCheck + termCheck
            : 0,
        max: partCheck + termCheck,
      });

      if (module.isSubmit === 1 || isTemporaryRegistration) {
        getDetail(module.registrationCode);
        setRegistrationCode(module.registrationCode);
      } else {
        setPartType(partCheck ? {} : module.partTypes[0]);
      }
    }
  }, [module, isTemporaryRegistration]);

  const { alert, setAlert, setError } = useApiError();

  const [loading, setLoading] = useState(false);
  const [registrationCode, setRegistrationCode] = useState();
  const getRegistrationCode = async () => {
    setLoading(true);
    const [info, error] = await registrationApi.registrationCode(
      module.moduleCode,
      {
        ...DEFAULT_PARAMS,
        isNewRegistration: 1,
        partCode: partType?.partCode,
        terms: term,
      },
    );
    if (info) {
      setRegistrationCode(info.result.registrationCode);
      getDetail(info.result.registrationCode);
    } else if (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    setPrevPartCode(partType?.partCode);
    setIsPartCodeChanged(prevPartCode !== partType?.partCode);

    if (
      module &&
      isTemporaryRegistration === false &&
      partType?.partCode &&
      progress?.value === progress?.max
    ) {
      getRegistrationCode();
    }
  }, [
    module,
    isTemporaryRegistration,
    partType?.partCode,
    progress?.value,
    progress?.max,
  ]);

  useEffect(() => {
    if (isTemporaryRegistration && isPartCodeChanged) {
      getRegistrationCode();
    }
  }, [isPartCodeChanged]);

  // registration list
  const [sessionList, setSessionList] = useState();
  const [targetSession, setTargetSession] = useState();
  const [values, setValues] = useState();
  const [invalids, setInvalids] = useState();
  const [etcValues, setEtcValues] = useState();
  const [detail, setDetail] = useState();
  const [callTemp, setCallTemp] = useState(false);
  const getDetail = async (registrationCode, target) => {
    const [info, error] = await registrationApi.registrationInfo(
      registrationCode,
      {
        ...DEFAULT_PARAMS,
      },
    );

    if (info?.resCode === 200) {
      if (info?.result.isPaid === 1) {
        getPeymentInfo(registrationCode);
      }

      let tempValue = {};
      let tempEtcValue = {};
      let tempChildValue = {};
      let lastSession = '';
      let lastSessionIndex = 0;
      const { list } = info.result;
      list?.forEach((session, index) => {
        session?.itemList
          .filter((el) => el.itemType !== 'content')
          .forEach((item) => {
            // value
            tempValue = {
              ...tempValue,
              [item.itemCode]: item.answer ||
                (item.itemType.includes('multiple') && []) || [''],
            };

            // etc value
            if (
              item.answer &&
              item.selectionEtcTitle &&
              item.itemType !== 'email' &&
              item.answer?.filter(
                (x) =>
                  !item.selections?.find(
                    (el) => el.selectionCode === x.selectionCode,
                  ),
              ).length > 0
            ) {
              tempEtcValue = {
                ...tempEtcValue,
                [item.itemCode]: item.answer?.find(
                  (x) => !item.selections?.includes(x),
                ),
              };
            }

            //  child
            if (
              item.answer &&
              item.answer[0] &&
              item.itemType !== 'multiple' &&
              Object.keys(item.childList).length > 0
            ) {
              Object.values(item.childList)
                .flat(1)
                ?.forEach((childItem) => {
                  // child value
                  tempChildValue = {
                    ...tempChildValue,
                    [childItem.itemCode]: childItem.answer ||
                      (childItem.itemType?.includes('multiple') && []) || [''],
                  };
                  // child etc value
                  if (
                    childItem.answer &&
                    childItem.answer[0] &&
                    childItem.selectionEtcTitle &&
                    childItem.itemType !== 'email' &&
                    childItem.answer?.filter(
                      (x) =>
                        !childItem.selections?.find(
                          (el) => el.selectionCode === x.selectionCode,
                        ),
                    ).length > 0
                  ) {
                    tempEtcValue = {
                      ...tempEtcValue,
                      [childItem.itemCode]: childItem.answer?.find(
                        (x) => !childItem.selections?.includes(x),
                      ),
                    };
                  }

                  // grandChild
                  if (
                    childItem.answer &&
                    childItem.answer[0] &&
                    childItem.itemType !== 'multiple' &&
                    Object.keys(childItem.childList).length > 0
                  ) {
                    Object.values(childItem.childList)
                      .flat(1)
                      .forEach((grandChild) => {
                        // grandChild value
                        tempChildValue = {
                          ...tempChildValue,
                          [grandChild.itemCode]: grandChild.answer || [],
                        };

                        // etc value
                        if (
                          grandChild.answer &&
                          grandChild.answer[0] &&
                          grandChild.selectionEtcTitle &&
                          grandChild.itemType !== 'email' &&
                          grandChild.answer?.filter(
                            (x) =>
                              !grandChild.selections?.find(
                                (el) => el.selectionCode === x.selectionCode,
                              ),
                          ).length > 0
                        ) {
                          tempEtcValue = {
                            ...tempEtcValue,
                            [grandChild.itemCode]: grandChild.answer?.find(
                              (x) => !grandChild.selections?.includes(x),
                            ),
                          };
                        }
                      });
                  }
                });
            }

            if (item.answer) {
              lastSession = session;
              lastSessionIndex = index;
            }
          });
      });

      setEtcValues((!isEmptyObject(tempEtcValue) && { ...tempEtcValue }) || '');
      setValues({ ...tempValue, ...tempChildValue });

      if (target) {
        // session  변경 진입
        const findIndex = list.findIndex(
          (el) => el.sessionCode === target.sessionCode,
        );
        setCallTemp({ lastSession: target, lastSessionIndex: findIndex });
      } else {
        // 첫 진입
        setDetail(info?.result);
        if (list?.length === 1) {
          setTargetSession(list[0]);
          setInvalids();
          setActiveSession();
        } else if (lastSession && info?.result?.isCompleted === 0) {
          setTargetSession(lastSession);
          setCallTemp({ lastSession, lastSessionIndex });
        } else {
          setTargetSession({ ...info.result.list[0] });
          setInvalids();
          setActiveSession();
        }
      }

      setSessionList([...list]);
      setLoading(false);
    } else if (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onChange = (value, name) => {
    setValues({ ...values, [name]: [value] });
  };

  const onEtcChange = (value, name) => {
    setEtcValues({
      ...etcValues,
      [name]: { ...etcValues[name], title: value },
    });
  };

  const onMutipleChange = (value, name, index) => {
    const valueArray = [...(values[name] || [])];
    const valueIndex = value.selectionCode
      ? valueArray.findIndex((val) => val.selectionCode === value.selectionCode)
      : valueArray.indexOf(value);

    if (value) {
      if (valueIndex !== -1) {
        valueArray.splice(valueIndex, 1);
      } else {
        valueArray.push(value);
      }
      setValues({ ...values, [name]: valueArray });
    } else {
      valueArray.splice(index, 1);
      setValues({ ...values, [name]: valueArray });
    }
  };

  const onCheckChange = (value, name, checked) => {
    if (checked) {
      setValues({ ...values, [name]: [...(values[name] || ''), value] });
    } else {
      setValues({
        ...values,
        [name]: values[name].filter(
          (el) => el.selectionCode !== value.selectionCode,
        ),
      });
    }
  };

  const filterRequiredArray = (i) => {
    if (!loading) {
      const itemList = sessionList[i]?.itemList || [];

      const requiredArray = itemList?.filter((item) => item.isRequired === 1);
      const hasChildItemList = [
        ...itemList.filter(
          (item) =>
            Object.keys(item?.childList)?.length > 0 &&
            values[item.itemCode] &&
            item?.childList[
              values[item.itemCode][0].countryCode ||
                values[item.itemCode][0].selectionCode
            ],
        ),
      ];

      let requiredChildArray = [];
      const childArray = hasChildItemList
        .map((el) => {
          if (
            Object.keys(el.childList).length > 0 &&
            values[el.itemCode] &&
            values[el.itemCode][0]
          )
            return el.childList[
              values[el.itemCode][0].countryCode ||
                values[el.itemCode][0].selectionCode
            ];
        })
        .flat(2)
        .filter((requiredEl) => requiredEl?.isRequired === 1);

      requiredChildArray =
        childArray.length > 0 && childArray[0] !== undefined ? childArray : [];

      const hasGrandChildArray =
        requiredChildArray.length > 0 && requiredChildArray[0] !== undefined
          ? requiredChildArray.filter(
              (childItem) =>
                Object.keys(childItem.childList).length > 0 &&
                childItem?.childList[
                  values[childItem.itemCode] &&
                    (values[childItem.itemCode][0]?.countryCode ||
                      values[childItem.itemCode][0]?.selectionCode)
                ],
            )
          : [];

      let requiredGrandChildArray = [];
      const grandChildArray = hasGrandChildArray
        .map((el) => {
          if (
            Object.keys(el.childList).length > 0 &&
            values[el.itemCode] &&
            values[el.itemCode][0]
          )
            return el.childList[
              values[el.itemCode][0].countryCode ||
                values[el.itemCode][0].selectionCode
            ];
        })
        .flat(2)
        .filter((requiredEl) => requiredEl?.isRequired === 1);

      requiredGrandChildArray =
        grandChildArray.length > 0 && grandChildArray[0] !== undefined
          ? grandChildArray
          : [];

      return [
        ...requiredArray,
        ...requiredChildArray,
        ...requiredGrandChildArray,
      ];
    }
  };

  const emptyCheck = (i) => {
    let tempInvalid = {};

    const totalRequiredArray = filterRequiredArray(i);

    if (totalRequiredArray?.length > 0) {
      for (const requiredElem of totalRequiredArray) {
        if (
          sessionList.length === 1 &&
          (!values[requiredElem.itemCode] ||
            !values[requiredElem.itemCode][0] ||
            (etcValues[requiredElem.itemCode] &&
              !etcValues[requiredElem.itemCode]?.domain &&
              !etcValues[requiredElem.itemCode]?.title))
        ) {
          // session 한개일때 invalid check
          tempInvalid = {
            ...tempInvalid,
            [requiredElem.itemCode]: { type: 'error' },
          };
        } else if (
          sessionList.length > 1 &&
          activeSession &&
          Object.keys(activeSession).includes(targetSession.sessionCode) &&
          (!values[requiredElem.itemCode] ||
            !values[requiredElem.itemCode][0] ||
            (etcValues[requiredElem.itemCode] &&
              !etcValues[requiredElem.itemCode]?.domain &&
              !etcValues[requiredElem.itemCode]?.title))
        ) {
          // session 여러개일때 invalid check
          tempInvalid = {
            ...tempInvalid,
            [requiredElem.itemCode]: { type: 'error' },
          };
        } else {
          tempInvalid = {
            ...tempInvalid,
            [requiredElem.itemCode]: { type: '' },
          };
        }
      }
    }

    return tempInvalid;
  };

  const sessionCheck = (i) => {
    let tempActiveSession = {};
    const totalRequiredArray = filterRequiredArray(i);
    if (totalRequiredArray.length > 0) {
      let result = true;
      for (const requiredElem of totalRequiredArray) {
        if (
          !values[requiredElem.itemCode] ||
          !values[requiredElem.itemCode][0] ||
          (etcValues[requiredElem.itemCode] &&
            !etcValues[requiredElem.itemCode]?.domain &&
            !etcValues[requiredElem.itemCode]?.title)
        ) {
          result = false;
        }
      }

      tempActiveSession = {
        ...tempActiveSession,
        [sessionList[i].sessionCode]: result,
      };
    } else {
      tempActiveSession = {
        [sessionList[i].sessionCode]: true,
      };
    }

    return tempActiveSession;
  };
  const [activeSession, setActiveSession] = useState();
  useEffect(() => {
    if (detail && sessionList && callTemp) {
      let tempSessionActive;
      for (let i = 0; i < callTemp.lastSessionIndex; i += 1) {
        tempSessionActive = { ...tempSessionActive, ...sessionCheck(i) };
      }
      setTargetSession(callTemp.lastSession);
      setActiveSession({ ...activeSession, ...tempSessionActive });
    }
  }, [detail, sessionList, callTemp]);

  useEffect(() => {
    // 빈 값 유효성 체크
    if (values && sessionList && sessionList?.length > 0 && targetSession) {
      const currentIndex = sessionList.findIndex(
        (session) => session.sessionCode === targetSession.sessionCode,
      );
      if (sessionList?.length > 1) {
        setInvalids({ ...emptyCheck(currentIndex) });
      } else if (sessionList?.length === 1 && activeSession) {
        setInvalids({ ...emptyCheck(0) });
      }
    }
  }, [sessionList, targetSession, values, etcValues]);

  const changeTargetSession = (newSession) => {
    if (targetSession) {
      const prevSessionIndex = sessionList.findIndex(
        (session) => session.sessionCode === targetSession.sessionCode,
      );
      if (prevSessionIndex === sessionList.length - 1) {
        const tempActiveSession = sessionCheck(prevSessionIndex);
        setActiveSession({ ...activeSession, ...tempActiveSession });
      }
    }
    tempConfirm(newSession);
  };

  const getEventPaymentCode = async () => {
    setLoading(true);
    const [info, error] = await registrationApi.paymentCode(registrationCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      // event payment로 인링크 이동
      window.location.replace(
        `${process.env.REACT_APP_EVENTPAYMENT_URL}/orderSheet/${info.result.paymentCode}`,
      );
    } else if (error) {
      setLoading(false);
      setError(error);
    }
  };

  const [passCodeAlert, setPassCodeAlert] = useState();
  const [passCode, setPassCode] = useState('');

  const [eventpaymentWithPassCodeAlert, setEventpaymentWithPassCodeAlert] =
    useState();
  const [eventpaymentWithPassCode, setEventpaymentWithPassCode] = useState('');

  const confirmActiveCheck = () => {
    if (sessionList.length === 1) {
      const resultActiveSession = sessionCheck(0);
      setActiveSession(resultActiveSession);
      return Object.values(resultActiveSession)[0];
    } else {
      const totalAtiveSession = {
        ...activeSession,
        ...sessionCheck(sessionList.length - 1),
      };

      return (
        Object.keys(totalAtiveSession).length === sessionList.length &&
        !Object.values(totalAtiveSession).includes(false)
      );
    }
  };

  const makeEtcValue = () => {
    if (etcValues) {
      Object.keys(etcValues).forEach((etcKey) => {
        for (const session of sessionList) {
          let originResult = {};
          const originItem = session?.itemList?.find(
            (el) => el.itemCode === etcKey,
          );

          let childOriginItem;
          session?.itemList?.forEach((el) => {
            if (Object.keys(el.childList).length > 0)
              childOriginItem = Object.values(el.childList)
                .flat(1)
                .find((childEl) => childEl.itemCode === etcKey);
          });

          let grandChildOriginItem;
          session?.itemList?.forEach((el) => {
            if (Object.keys(el.childList).length > 0)
              Object.values(el.childList)
                .flat(1)
                .forEach((childEl) => {
                  if (Object.keys(childEl.childList).length > 0)
                    grandChildOriginItem = Object.values(childEl.childList)
                      .flat(1)
                      .find((grandChildEl) => grandChildEl.itemCode === etcKey);
                });
          });

          originResult = originItem || childOriginItem || grandChildOriginItem;

          const changeIndex = values[etcKey].findIndex(
            (etc) =>
              etc.selectionCode ===
              originResult?.selectionEtcTitle.selectionCode,
          );

          if (changeIndex !== -1) {
            values[etcKey].splice(changeIndex, 1, etcValues[etcKey]);
          }
        }
      });
    }
  };

  const confirm = async () => {
    makeEtcValue();
    const active = confirmActiveCheck();
    if (active) {
      const [info] = await registrationApi.registration(registrationCode, {
        ...DEFAULT_PARAMS,
        answers: { ...objectArrayParamsCleansing(values) },
      });
      if (info) {
        // success
        const { confirmType } = info.result;
        if (confirmType === 'none') {
          registration();
        }
        if (confirmType === 'admin') {
          setAlert({
            title: t('alert_title'),
            content: t('admin_type_success'),
            confirm: () => languageNavigate(`/`),
          });
        }
        if (confirmType === 'eventpayment') {
          getEventPaymentCode();
        }
        if (confirmType === 'eventpaymentWithPassCode') {
          setEventpaymentWithPassCodeAlert(true);
        }
        if (confirmType === 'passCode') {
          setPassCodeAlert(true);
        }
      }
    } else {
      if (sessionList.length === 1) {
        setInvalids({ ...emptyCheck(0) });
      }
      // 빈 항목 체크
      setAlert({
        title: t('alert_title'),
        content: t('invalid_alert'),
        confirm: () => setAlert(),
      });
    }
  };

  const tempConfirm = async (sessionChange) => {
    setLoading(true);
    makeEtcValue();
    if (
      (detail?.isCompleted !== 1 || detail?.isUsedResult !== 1) &&
      Object.keys(values).length > 0
    ) {
      const [info] = await registrationApi.registration(registrationCode, {
        ...DEFAULT_PARAMS,
        answers: { ...objectArrayParamsCleansing(values) },
        isTemporary: 1,
      });
      if (info) {
        // success
        if (sessionChange.sessionCode) {
          getDetail(registrationCode, sessionChange);
        } else {
          setAlert({
            title: t('alert_title'),
            content: t('temp_save_success'),
            confirm: () => setAlert(''),
          });
          setLoading(false);
        }
      }
    } else if (sessionChange.sessionCode) {
      getDetail(registrationCode, sessionChange);
    }
  };

  const registration = async () => {
    const { confirmType } = detail;
    const [info, error] = await registrationApi.confirm(registrationCode, {
      ...DEFAULT_PARAMS,
      passCode,
      confirmType,
    });
    if (info) {
      if (confirmType.toLowerCase().includes('passcode')) {
        setPassCode();
        setPassCodeAlert();
        if (info.result?.paymentCode) {
          window.location.replace(
            `${process.env.REACT_APP_EVENTPAYMENT_URL}/orderSheet/${info.result.paymentCode}`,
          );
        } else {
          setAlert({
            title: t('alert_title'),
            content: t('registration_success'),
            confirm: () =>
              languageNavigate(
                `/${module.moduleCode}/registration/result`,
                null,
                null,
                { isSuccess: true },
              ),
          });
        }
      } else {
        // success
        setAlert({
          title: t('alert_title'),
          content: t('registration_success'),
          confirm: () =>
            languageNavigate(
              `/${module.moduleCode}/registration/result`,
              null,
              null,
              { isSuccess: true },
            ),
        });
      }
    } else if (
      error?.resCode === 4569 &&
      confirmType.toLowerCase().includes('passcode')
    ) {
      setPassCode();
      setPassCodeAlert();
      setAlert({
        title: t('alert_title'),
        content: t('passcode_already'),
        confirm: () => setAlert(),
      });
    } else if (
      (error?.resCode === 4563 || error?.resCode === 4565) &&
      confirmType.toLowerCase().includes('passcode')
    ) {
      setPassCode();
      setPassCodeAlert();
      setAlert({
        title: t('alert_title'),
        content: t('passcode_fail'),
        confirm: () => setAlert(),
      });
    }
  };

  const { logout } = useBucketReset('/login');

  const cancel = async () => {
    setLoading(true);
    const [info] = await registrationApi.registrationCancel(registrationCode, {
      ...DEFAULT_PARAMS,
      confirmType: detail.confirmType,
    });
    if (info) {
      setLoading(false);
      setAlert({
        title: t('alert_title'),
        content: t('cancel_done'),
        confirm: () => languageNavigate('/'),
      });
    } else {
      setLoading(false);
    }
  };

  const cancelWarning = async () => {
    setAlert({
      title: t('alert_title'),
      content: t('cancel_warning'),
      confirm: cancel,
      cancel: () => setAlert(''),
    });
  };

  const [payInfo, setPayInfo] = useState();
  const getPeymentInfo = async (registrationCode) => {
    const [info] = await registrationApi.paymentInfo(registrationCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setPayInfo({ ...info?.result });
    }
  };

  return (
    <>
      {(module && searchParams.get('edit') !== 'true' && (
        <RegistrationPresenter
          t={t}
          moduleTop={
            Number(
              (menu?.headerOption?.isHeaderOverlap !== 1 &&
                (bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
                (Number(menu?.headerOption?.headerHeight) ||
                  Number(bucket.headerOption.headerHeight) ||
                  60) + Number(menu?.styles.paddingTop)) ||
                menu?.styles.paddingTop,
            ) + Number(module?.styles?.paddingTop)
          }
          mModuleTop={
            (menu?.headerOption?.isHeaderOverlap !== 1 &&
              (bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
              (Number(menu?.headerOption?.headerHeight) ||
                Number(bucket.headerOption.headerHeight) ||
                60)) ||
            '0'
          }
          setProgress={setProgress}
          setTerm={setTerm}
          setPartType={setPartType}
          module={module}
          progress={progress}
          partType={partType}
          term={term}
          detail={detail}
          targetSession={targetSession}
          changeTargetSession={changeTargetSession}
          sessionList={sessionList}
          invalids={invalids}
          values={values}
          etcValues={etcValues}
          setEtcValues={setEtcValues}
          onChange={onChange}
          onEtcChange={onEtcChange}
          onMutipleChange={onMutipleChange}
          onCheckChange={onCheckChange}
          activeSession={activeSession}
          tempConfirm={tempConfirm}
          confirm={confirm}
          getEventPaymentCode={getEventPaymentCode}
          logout={logout}
          cancel={cancelWarning}
          setPassCodeAlert={setPassCodeAlert}
          setEventpaymentWithPassCodeAlert={setEventpaymentWithPassCodeAlert}
          payInfo={payInfo}
        />
      )) ||
        ''}
      {(module && searchParams.get('edit') === 'true' && values && (
        <RegistrationEdit
          registrationCode={registrationCode}
          detail={detail}
          sessionList={sessionList}
          module={module}
          originValues={JSON.parse(JSON.stringify(values))}
          originEtcValues={JSON.parse(JSON.stringify(etcValues))}
        />
      )) ||
        ''}
      {alert && <Alert {...alert} />}
      {eventpaymentWithPassCodeAlert && (
        <EventPaymentWithPassCodeAlert
          confirm={() => {
            setEventpaymentWithPassCodeAlert();
            if (eventpaymentWithPassCode === 'passCode') {
              setPassCodeAlert(true);
            } else {
              getEventPaymentCode();
            }
          }}
          cancel={() => {
            setEventpaymentWithPassCode();
            setEventpaymentWithPassCodeAlert();
          }}
          value={eventpaymentWithPassCode}
          setValue={setEventpaymentWithPassCode}
        />
      )}
      {passCodeAlert && (
        <ChildAlert
          title={t('passcode_label')}
          content={t('passcode_content')}
          cancel={() => {
            setPassCode();
            setPassCodeAlert();
          }}
          confirm={() => registration('passCode')}
        >
          <Input
            placehold={t('passcode_content')}
            value={passCode}
            onChange={(val) => setPassCode(val)}
          />
        </ChildAlert>
      )}
      {loading && <Loading />}
    </>
  );
}

export default RegistrationContainer;
