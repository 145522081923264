import { Div, HTMLWrap } from 'components';
import { useWindowSize } from 'hooks';
import React from 'react';

export function LoginTypeA({ info, header, children }) {
  const windowSize = useWindowSize();
  return (
    <Div
      padding="0"
      backgroundColor={info?.backgroundColor}
      backgroundImage={info?.backgroundImg}
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      height={windowSize.height}
      position="relative"
    >
      {header}
      <Div
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
        width={402}
        mMaxWidth={362}
        mWidth="calc(100% - 40px)"
      >
        <HTMLWrap
          width="100%"
          maxHeight="20vh"
          overflowY="hidden"
          content={
            windowSize.width > 1279
              ? info?.content1
              : (info?.mobileContent1 && info?.mobileContent1) || info?.content1
          }
          mb={20}
        />
        {children}
        <HTMLWrap
          mt={20}
          width="100%"
          maxHeight="20vh"
          overflowY="hidden"
          content={
            windowSize.width > 1279
              ? info?.content2
              : (info?.mobileContent2 && info?.mobileContent2) || info?.content2
          }
        />
      </Div>
    </Div>
  );
}
