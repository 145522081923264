import {
  LoginTypeA,
  LoginBox,
  LoginHeader,
  LoginVerified,
  LoginTypeB,
  LoginPassword,
  LoginIdPassword,
  LoginCustomPassword,
} from 'components';
import React from 'react';

const verifiedType = ['email', 'phone'];
const password = ['emailPassword', 'phonePassword'];
const idPassword = ['userIdPasswordEmail', 'userIdPasswordPhone'];

function LoginPresenter({
  bucket,
  info,
  termList,
  terms,
  onTermChange,
  viewTerm,
  loginToMain,
  setAlert,
}) {
  return (
    <>
      {info?.designType === 'design1' && (
        <LoginTypeA
          info={info}
          bucket={bucket}
          header={<LoginHeader bucket={bucket} />}
        >
          <LoginBox>
            {verifiedType.includes(bucket.loginMethod) && (
              <LoginVerified
                loginToMain={loginToMain}
                termList={termList}
                terms={terms}
                onTermChange={onTermChange}
                viewTerm={viewTerm}
                loginMethod={bucket.loginMethod}
                setAlert={setAlert}
              />
            )}
            {password.includes(bucket.loginMethod) && (
              <LoginPassword
                loginToMain={loginToMain}
                loginMethod={bucket.loginMethod.replace('Password', '')}
                setAlert={setAlert}
                isUsedForgotPWBtn={info.isUsedForgotPWBtn}
                isUsedSignUpBtn={info.isUsedSignUpBtn}
              />
            )}
            {idPassword.includes(bucket.loginMethod) && (
              <LoginIdPassword
                loginToMain={loginToMain}
                label={info.userIdTitle}
                placeholder={info.userIdTitlePlaceholder}
                setAlert={setAlert}
                isUsedForgotPWBtn={info.isUsedForgotPWBtn}
                isUsedSignUpBtn={info.isUsedSignUpBtn}
              />
            )}
            {bucket.loginMethod === 'customKey' && (
              <LoginCustomPassword
                loginToMain={loginToMain}
                info={info}
                setAlert={setAlert}
              />
            )}
          </LoginBox>
        </LoginTypeA>
      )}
      {info?.designType === 'design2' && (
        <LoginTypeB
          info={info}
          bucket={bucket}
          header={
            <LoginHeader
              bucket={bucket}
              position="absolute"
              zIndex={800}
              width="100vw"
            />
          }
        >
          <LoginBox>
            {verifiedType.includes(bucket.loginMethod) && (
              <LoginVerified
                loginToMain={loginToMain}
                termList={termList}
                terms={terms}
                onTermChange={onTermChange}
                viewTerm={viewTerm}
                loginMethod={bucket.loginMethod}
                setAlert={setAlert}
              />
            )}
            {password.includes(bucket.loginMethod) && (
              <LoginPassword
                loginToMain={loginToMain}
                loginMethod={bucket.loginMethod.replace('Password', '')}
                setAlert={setAlert}
                isUsedForgotPWBtn={info.isUsedForgotPWBtn}
                isUsedSignUpBtn={info.isUsedSignUpBtn}
              />
            )}
          </LoginBox>
        </LoginTypeB>
      )}
    </>
  );
}

export default LoginPresenter;
