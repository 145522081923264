import { Button, Row } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export function RegistrationCheckButtonBox({
  isCompleted,
  isModified,
  cancel,
  confirmType,
  sessionList,
  ...props
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    sessionList?.length > 0 && (
      <Row gap={10} alignItems="center" justifyContent="center" {...props}>
        {(isCompleted && (
          <>
            {(confirmType && (
              <Button
                active
                onClick={cancel}
                border={{ color: 'keyColor', width: '1px' }}
                backgroundColor="keyFontColor"
                color="keyColor"
              >
                {(confirmType.includes('eventpayment') &&
                  t('refund_registration')) ||
                  t('registration_cancel')}
              </Button>
            )) ||
              ''}
            {(isModified && (
              <Button
                active
                onClick={() => {
                  searchParams.append('edit', true);
                  setSearchParams(searchParams);
                }}
              >
                {t('edit')}
              </Button>
            )) ||
              ''}
          </>
        )) ||
          ''}
      </Row>
    )
  );
}
