import { Div, Row, Text } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function RegistrationButtonBox({
  isEdit,
  isSubmit,
  isCompleted,
  cancel,
  confirmType,
  tempConfirm,
  sessionList,
  targetSession,
  changeTargetSession,
  getEventPaymentCode,
  setEventpaymentWithPassCodeAlert,
  setPassCodeAlert,
  confirm,
  ...props
}) {
  const { t } = useTranslation();
  return (
    sessionList?.length > 0 && (
      <Row gap={10} alignItems="center" justifyContent="flex-end" {...props}>
        {(isSubmit && !isCompleted && (
          <Div
            padding="5px 15px"
            borderRadius="999px"
            border={{ color: 'keyColor' }}
            onClick={cancel}
            cursor="pointer"
            hover
          >
            <Text type="h6" mType="h8" color="keyColor">
              {t('cancel')}
            </Text>
          </Div>
        )) ||
          ''}
        {(isCompleted && (
          <Div
            padding="5px 15px"
            borderRadius="999px"
            border={{ color: 'keyColor' }}
            onClick={cancel}
            cursor="pointer"
            hover
          >
            <Text type="h6" mType="h8" color="red">
              {(confirmType.includes('eventpayment') &&
                t('refund_registration')) ||
                t('cancel')}
            </Text>
          </Div>
        )) ||
          ''}
        {(!isSubmit && !isCompleted && !isEdit && (
          <Div
            padding="5px 15px"
            borderRadius="999px"
            border={{ color: 'keyColor' }}
            hover
            cursor="pointer"
            onClick={tempConfirm}
          >
            <Text type="h6" mType="h8" color="keyColor">
              {t('temp_save')}
            </Text>
          </Div>
        )) ||
          ''}
        {targetSession &&
          sessionList?.findIndex(
            (session) => session.sessionCode === targetSession?.sessionCode,
          ) !== 0 && (
            <Div
              padding="5px 15px"
              borderRadius="999px"
              border={{ color: 'keyColor' }}
              onClick={() => {
                window.scrollTo(0, 0);
                changeTargetSession(
                  sessionList[
                    sessionList?.findIndex(
                      (session) =>
                        session.sessionCode === targetSession.sessionCode,
                    ) - 1
                  ],
                );
              }}
              hover
              cursor="pointer"
            >
              <Text type="h6" mType="h8" color="keyColor">
                {t('before')}
              </Text>
            </Div>
          )}
        {targetSession &&
          sessionList?.findIndex(
            (session) => session.sessionCode === targetSession?.sessionCode,
          ) !==
            sessionList?.length - 1 && (
            <Div
              padding="5px 15px"
              borderRadius="999px"
              border={{ color: 'keyColor' }}
              onClick={() => {
                window.scrollTo(0, 0);
                changeTargetSession(
                  sessionList[
                    sessionList?.findIndex(
                      (session) =>
                        session?.sessionCode === targetSession?.sessionCode,
                    ) + 1
                  ],
                );
              }}
              hover
              cursor="pointer"
            >
              <Text type="h6" mType="h8" color="keyColor">
                {t('next')}
              </Text>
            </Div>
          )}
        {(isSubmit && !isCompleted && confirmType === 'eventpayment' && (
          <Div
            padding="5px 15px"
            borderRadius="999px"
            border={{ color: 'keyColor' }}
            cursor="pointer"
            hover
            onClick={getEventPaymentCode}
          >
            <Text type="h6" mType="h8" color="keyColor">
              {t('payment')}
            </Text>
          </Div>
        )) ||
          ''}
        {(isSubmit &&
          !isCompleted &&
          confirmType === 'eventpaymentWithPassCode' && (
            <Div
              padding="5px 15px"
              borderRadius="999px"
              border={{ color: 'keyColor' }}
              backgroundColor="keyColor"
              cursor="pointer"
              hover
              onClick={() => {
                setEventpaymentWithPassCodeAlert(true);
              }}
            >
              <Text type="h6" mType="h8" color="keyFontColor">
                {t('submit')}
              </Text>
            </Div>
          )) ||
          ''}
        {(isSubmit && !isCompleted && confirmType === 'passCode' && (
          <Div
            padding="5px 15px"
            borderRadius="999px"
            border={{ color: 'keyColor' }}
            backgroundColor="keyColor"
            cursor="pointer"
            hover
            onClick={() => {
              setPassCodeAlert(true);
            }}
          >
            <Text type="h6" mType="h8" color="keyFontColor">
              {t('passcode_confirm')}
            </Text>
          </Div>
        )) ||
          ''}
        {!isEdit &&
          !isSubmit &&
          !isCompleted &&
          sessionList?.findIndex(
            (session) => session?.sessionCode === targetSession?.sessionCode,
          ) ===
            sessionList?.length - 1 && (
            <Div
              padding="5px 15px"
              borderRadius="999px"
              border={{ color: 'keyColor' }}
              backgroundColor="keyColor"
              onClick={confirm}
              hover
              cursor="pointer"
            >
              <Text type="h6" mType="h8" color="keyFontColor">
                {t('submit')}
              </Text>
            </Div>
          )}
      </Row>
    )
  );
}
