import { moduleApi } from 'api';
import {
  Icon,
  Row,
  Alert,
  Div,
  Span,
  Text,
  Comment,
  CommentInput,
  Recomment,
} from 'components';
import { useApiParams, useApiError } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export function CommentList({ item, module, commentLengthCallback, ...props }) {
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { alert, setAlert, setError } = useApiError();
  const { DEFAULT_PARAMS } = useApiParams();
  const { itemCode } = useParams();
  const [pages, setPages] = useState();
  const [filters, setFilters] = useState({
    page: 1,
    lastRowNum: 0,
    perPage: 30,
  });

  const [recommentPages, setRecommentPages] = useState();
  const [recommentFilters, setRecommentFilters] = useState({});

  const [comment, setComment] = useState({});
  const [recomment, setRecomment] = useState({});
  const getModuleLogInfo = async (parentCommentCode) => {
    setLoading(true);
    const params = (parentCommentCode && {
      ...DEFAULT_PARAMS,
      parentCommentCode,
      page: recommentFilters[parentCommentCode]?.page || 1,
      lastRowNum: recommentFilters[parentCommentCode]?.lastRowNum || 0,
      perPage: recommentFilters[parentCommentCode]?.perPage || 30,
    }) || {
      ...DEFAULT_PARAMS,
      ...filters,
    };
    const [info] = await moduleApi.moduleLogInfo(
      'comment',
      item.moduleCode,
      item.itemCode || itemCode,
      { ...params },
    );
    if (info?.resCode === 200) {
      const { result } = info;

      if (
        parentCommentCode &&
        result.currentCount === 0 &&
        Number(recommentFilters[parentCommentCode]?.page) !== 1
      ) {
        // 답글 삭제 후 페이지에 아이템이 없을 경우 이전 페이지로
        setRecommentFilters({
          ...recommentFilters,
          [parentCommentCode]: {
            page: recommentFilters[parentCommentCode].page - 1,
            lastRowNum: 30 * (recommentFilters[parentCommentCode].page - 2),
            perPage: 30,
          },
        });
      } else if (parentCommentCode) {
        setRecommentPages({
          ...recommentPages,
          [parentCommentCode]: { ...result },
        });
        setRecomment({
          ...recomment,
          [parentCommentCode]: (Number(
            recommentFilters[parentCommentCode].page,
          ) !== 1 && [...recomment[parentCommentCode], ...result.list]) || [
            ...result.list,
          ],
        });
      } else if (result.currentCount === 0 && Number(filters.page) !== 1) {
        // 댓글 삭제 후 페이지에 아이템이 없을 경우 이전 페이지로
        setFilters({
          page: filters.page - 1,
          lastRowNum: 30 * (filters.page - 2),
          perPage: 30,
        });
      } else {
        setPages({ ...result });
        setComment({
          ...result,
          list: (Number(filters.page) !== 1 && [
            ...comment.list,
            ...result.list,
          ]) || [...result.list],
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filters) {
      getModuleLogInfo();
    }
  }, [filters]);

  const openRecomment = (parentCode) => {
    if (recomment[parentCode]) {
      // close recomment
      delete recomment[parentCode];
      delete recommentFilters[parentCode];

      setRecomment({ ...recomment });
      setRecommentFilters({ ...recommentFilters });
    } else {
      recommentChangePage(1, parentCode);
    }
  };

  const changeRecommentPage = (parentItem) => {
    if (
      recommentPages[parentItem.commentCode]?.totalPages >
      recommentFilters[parentItem.commentCode]?.page
    ) {
      const i = recommentFilters[parentItem.commentCode].page + 1;
      setRecommentFilters({
        ...recommentFilters,
        [parentItem.commentCode]: {
          page: i,
          lastRowNum:
            recommentFilters[parentItem.commentCode].perPage * (i - 1),
          perPage: recommentFilters[parentItem.commentCode].perPage,
        },
      });
    }
  };

  const [targetRecomment, setTargetRecomment] = useState('');
  useEffect(() => {
    if (targetRecomment && recommentFilters[targetRecomment]) {
      getModuleLogInfo(targetRecomment);
    }
  }, [recommentFilters, targetRecomment]);

  const recommentChangePage = (i, parentCode) => {
    setTargetRecomment(parentCode);
    setRecommentFilters({
      ...recommentFilters,
      [parentCode]: {
        page: i,
        lastRowNum: 30 * (i - 1),
        perPage: 30,
      },
    });
  };

  const [viewMore, setViewMore] = useState(false);

  const createComment = async (content, parentCommentCode) => {
    if (!alert && !loading && content) {
      setLoading(true);
      const params = (parentCommentCode && { content, parentCommentCode }) || {
        content,
      };
      const [info, error] = await moduleApi.moduleLogging(
        'comment',
        item.moduleCode,
        item.itemCode || itemCode,
        { ...DEFAULT_PARAMS, ...params },
      );
      if (info?.resCode === 200) {
        if (parentCommentCode) {
          // 답글 달기 시 parentComment count 갱신
          const parentIndex = comment.list.findIndex(
            (parentComment) => parentComment.commentCode === parentCommentCode,
          );
          comment.list[parentIndex] = {
            ...comment.list[parentIndex],
            recomments: comment.list[parentIndex].recomments + 1,
          };
          setComment({ ...comment });
          recommentChangePage(1, parentCommentCode);
        } else {
          setFilters({
            page: 1,
            lastRowNum: 0,
            perPage: 30,
          });
          if (commentLengthCallback) {
            commentLengthCallback(item.comments);
          }
        }
        return true;
      } else if (error) {
        setError(error);
        setLoading(false);
        return false;
      } else {
        setLoading(false);
        return false;
      }
    } else {
      return false;
    }
  };

  const editModuleLog = async (commentCode, content, parentCommentCode) => {
    if (!alert && !loading && content) {
      setLoading(true);
      const [info] = await moduleApi.moduleLogEdit(
        'comment',
        item.moduleCode,
        item.itemCode || itemCode,
        commentCode,
        { ...DEFAULT_PARAMS, content },
      );
      if (info?.resCode === 200) {
        if (parentCommentCode) {
          //
          const findEditIndex = recomment[parentCommentCode]?.findIndex(
            (el) => el.commentCode === commentCode,
          );
          recomment[parentCommentCode][findEditIndex] = {
            ...recomment[parentCommentCode][findEditIndex],
            content,
          };
          setRecomment({
            ...recomment,
            [parentCommentCode]: [...recomment[parentCommentCode]],
          });
        } else {
          const findEditIndex = comment.list.findIndex(
            (el) => el.commentCode === commentCode,
          );
          comment.list[findEditIndex] = {
            ...comment.list[findEditIndex],
            content,
          };
          setComment({ ...comment, list: [...comment.list] });
        }
        setLoading(false);
      } else {
        setLoading(false);
        setAlert({
          title: t('alert_title'),
          content: t('edit_comment_error'),
          confirm: () => setAlert(),
          close: () => setAlert(),
        });
      }
    }
  };

  const deleteModuleLog = async (commentCode, parentCommentCode) => {
    if (!loading) {
      setLoading(true);
      const [info] = await moduleApi.moduleLogDelete(
        'comment',
        item.moduleCode,
        item.itemCode || itemCode,
        commentCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setLoading(false);
        setAlert();
        if (parentCommentCode) {
          // 답글 달기 시 parentComment count 갱신
          const parentIndex = comment.list.findIndex(
            (parentComment) => parentComment.commentCode === parentCommentCode,
          );
          comment.list[parentIndex] = {
            ...comment.list[parentIndex],
            recomments: comment.list[parentIndex].recomments - 1,
          };
          setComment({ ...comment });
          setRecomment({
            ...recomment,
            [parentCommentCode]: recomment[parentCommentCode].filter(
              (el) => el.commentCode !== commentCode,
            ),
          });
        } else {
          const deletedList = comment.list.filter(
            (el) => el.commentCode !== commentCode,
          );
          setComment({
            ...comment,
            totalCount: comment.totalCount - 1,
            list: [...deletedList],
          });
        }
      } else {
        setLoading(false);
        setAlert({
          title: t('alert_title'),
          content: t('delete_comment_error'),
          confirm: () => setAlert(),
          close: () => setAlert(),
        });
      }
    }
  };

  return (
    <Div
      position="relative"
      mt={20}
      pt={20}
      transition="all 0.2s"
      padding="20px 20px 0 20px"
      {...props}
    >
      <Text type="h7" mb={20}>
        {t('comment')}
        {item.isViewedCommentCount && (
          <Span type="b1" ml={6} color="keyColor">
            {comment?.totalCount}
          </Span>
        )}
      </Text>
      <CommentInput
        name="comment"
        placeholder={t('input_placeholder')}
        send={createComment}
        loading={loading}
        isViewedCommentUserThumbnail={item?.isViewedCommentUserThumbnail}
        isViewedCommentUserName={item?.isViewedCommentUserName}
      />
      {comment?.list?.map((commentItem) => (
        <Div key={commentItem.commentCode} borderBottom={{ color: 'g200' }}>
          <Comment
            module={module}
            item={item}
            user={user}
            comment={commentItem}
            recomment={recomment}
            setRecomment={setRecomment}
            createReComment={createComment}
            openRecomment={() => openRecomment(commentItem.commentCode)}
            editing={editModuleLog}
            deleting={() => {
              setAlert({
                title: t('alert_title'),
                content: t('delete_warning'),
                confirm: () => deleteModuleLog(commentItem.commentCode),
                cancel: () => setAlert(),
              });
            }}
          />
          {recomment[commentItem.commentCode] && (
            <Recomment
              t={t}
              module={module}
              list={recomment[commentItem.commentCode]}
              item={item}
              user={user}
              viewMore={viewMore}
              setViewMore={setViewMore}
              editModuleLog={editModuleLog}
              setAlert={setAlert}
              deleteModuleLog={deleteModuleLog}
              recommentPages={recommentPages}
              comment={commentItem}
              recommentFilters={recommentFilters}
              changeRecommentPage={changeRecommentPage}
            />
          )}
        </Div>
      ))}
      {pages?.totalPages > pages?.currentPage && (
        <Row
          padding="20px"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          hover
          onClick={() => {
            const i = pages?.currentPage + 1;
            setFilters({
              ...filters,
              page: i,
              lastRowNum: filters.perPage * (i - 1),
              perPage: filters.perPage,
            });
          }}
        >
          <Text type="b2" color="g500">
            {t('more')}
          </Text>
          <Icon name="arrow" fill="g500" />
        </Row>
      )}
      {alert && !loading && <Alert {...alert} />}
    </Div>
  );
}
