import React from 'react';
import { Div, ProfileImage } from 'components';

export function HeaderProfileImage({ type, thumbnail, size, ...props }) {
  return (
    <>
      <Div
        position="relative"
        border={(type === '1' && { color: 'g300' }) || ''}
        borderRadius="50%"
        padding="2px"
        {...props}
      >
        <ProfileImage size={size} thumbnail={thumbnail} />
      </Div>
    </>
  );
}
