import React from 'react';
import { TitleInputStyles } from 'styles/InputStyles';
import { Icon, Row, Text } from 'components';
import { useTranslation } from 'react-i18next';

export function TitleInput({
  setRef,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  isSecretPost,
  wrapStyles,
  ...props
}) {
  const { t } = useTranslation();
  const onKeyDownHandler = (event) => {
    if (onKeyDown && onKeyDownActive) {
      if (event.key === 'Enter') {
        onKeyDown();
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    onChange(value, name);
  };

  return (
    <Row
      gap={10}
      alignItems="center"
      justifyContent="space-between"
      pb={15}
      borderBottom={{ color: 'g200' }}
      cursor="pointer"
      {...wrapStyles}
    >
      <Row alignItems="center" gap={5} width="100%">
        {isSecretPost && <Icon name="lock" fill="keyColor" size={24} />}
        {!value && <Icon fill="g400" name="tag-add" size={30} />}
        <TitleInputStyles
          ref={setRef}
          name={name}
          value={value || ''}
          onChange={onChangeHandler}
          onKeyPress={onKeyDownHandler}
          placeholder={t('add_title')}
          {...props}
        />
      </Row>
      {value && (
        <Row
          alignItems="center"
          gap={5}
          hover="background"
          padding="2px 5px"
          onClick={() => onChange('', name)}
        >
          <Icon
            fill="keyColor"
            name="tag-add"
            size={23}
            transform="rotate(45deg)"
          />
          {/* <Text type="h8" color="keyColor" whiteSpace="nowrap">
            {t('skip_title')}
          </Text> */}
        </Row>
      )}
    </Row>
  );
}

export function GalleryTitleInput({
  setRef,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  wrapStyles,
  ...props
}) {
  const { t } = useTranslation();
  const onKeyDownHandler = (event) => {
    if (onKeyDown && onKeyDownActive) {
      if (event.key === 'Enter') {
        onKeyDown();
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    onChange(value, name);
  };

  return (
    <Row
      gap={10}
      alignItems="center"
      justifyContent="space-between"
      pb={15}
      borderBottom={{ color: 'g200' }}
      cursor="pointer"
      {...wrapStyles}
    >
      <Row alignItems="center" gap={10} width="100%">
        <TitleInputStyles
          ref={setRef}
          name={name}
          value={value || ''}
          onChange={onChangeHandler}
          onKeyPress={onKeyDownHandler}
          placeholder={t('add_title')}
          {...props}
        />
      </Row>
      {value && (
        <Row
          alignItems="center"
          gap={5}
          hover="background"
          padding="2px 5px"
          onClick={() => onChange('', name)}
        >
          <Icon
            fill="keyColor"
            name="tag-add"
            size={23}
            transform="rotate(45deg)"
          />
        </Row>
      )}
    </Row>
  );
}
