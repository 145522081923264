import { css } from 'styled-components';

export const textElement = css`
  font-family: ${({ theme }) => theme.fontFamily || 'inherit'};
  font-size: ${({ fontSize, type, theme }) =>
    (fontSize && `${fontSize / 10}rem`) ||
    (type && type !== 'inherit' && `${theme.textType[type] / 10}rem`) ||
    'inherit'};
  font-weight: ${({ fontWeight, type }) =>
    fontWeight ||
    (type?.includes('h') && 700) ||
    (type?.includes('b') && 400) ||
    'inherit'};
  color: ${({ color, theme }) =>
    (color && theme.colors[color]) ||
    color ||
    theme.colors.fontColor ||
    'inherit'};
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
  ${({ whiteSpace }) => (whiteSpace && `white-space: ${whiteSpace};`) || ''};
  ${({ letterSpace }) =>
    (letterSpace && `letter-spacing: ${letterSpace};`) || ''};
  ${({ textAlign }) => (textAlign && `text-align: ${textAlign};`) || ''};
  ${({ wordBreak }) => (wordBreak && `word-break: ${wordBreak};`) || ''};
  ${({ wordWrap }) => (wordWrap && `word-wrap: ${wordWrap};`) || ''};

  ${({ maxLine }) =>
    maxLine &&
    `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLine};
  -webkit-box-orient: vertical;
`};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ mFontSize, mType, theme }) =>
      (mFontSize && `font-size: ${mFontSize / 10}rem;`) ||
      (mType && `font-size: ${theme.textType[mType] / 10}rem;`) ||
      ''};
    ${({ mFontWeight, mType }) =>
      (mFontWeight && `font-weight: ${mFontWeight};`) ||
      (mType?.includes('h') && `font-weight: 700;`) ||
      (mType?.includes('b') && `font-weight: 400;`) ||
      ''};
    ${({ mColor, theme }) =>
      (mColor && ` color: ${theme.colors[mColor] || mColor};`) || ''};
    ${({ mLineHeight }) =>
      (mLineHeight && `line-height: ${mLineHeight};`) || ''};
    ${({ mWhiteSpace }) =>
      (mWhiteSpace && `white-space: ${mWhiteSpace};`) || ''};
    ${({ mLetterSpace }) =>
      (mLetterSpace && `letter-spacing: ${mLetterSpace};`) || ''};
    ${({ mTextAlign }) => (mTextAlign && `text-align: ${mTextAlign};`) || ''};
    ${({ mWordBreak }) => (mWordBreak && `word-break: ${mWordBreak};`) || ''}

    ${({ mMaxLine }) =>
      mMaxLine &&
      `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${mMaxLine};
        -webkit-box-orient: vertical;
        `};
  }
`;
