import {
  Button,
  CommentList,
  Div,
  EnumerationDetailEmptyCard,
  EnumerationDetailTopCard,
  EnumerationDetailRightCard,
  EnumerationDetailSpeakerCard,
  Row,
  Dim,
  ReservationInfo,
  ReservationList,
  Icon,
  ReservationCheck,
  BoardDetailCard,
  BoardDetailGalleryCard,
  DetailSubNav,
  DetailBoardGallerySubNav,
} from 'components';
import React, { useState } from 'react';
import { isMobile } from 'utils/isMobile';

function DetailPresenter({
  type,
  t,
  isUsedTabMenu,
  bucket,
  page,
  module,
  item,
  logging,
  commentLengthCallback,
  commentLength,
  goList,
  openReservation,
  setOpenReservation,
  reservationItem,
  reservationCheck,
  reservation,
  reservationCancel,
  reservationValues,
  setReservationValues,
  reservationClick,
  setReservationCheck,
  reservationDone,
  done,
  getDetail,
  deleting,
  targetEditItem,
  setTargetEditItem,
  setError,
  setFilters,
  ...props
}) {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <Row
      gap={55}
      minHeight="height"
      maxWidth={(page?.isFullWidth === 1 && '100%') || 1280}
      margin="0 auto"
      pt={
        type === 'modal' ||
        !(bucket?.header === 'fixed' || bucket?.header === 'drawer')
          ? 20
          : (Number(page?.headerOption?.headerHeight) ||
              Number(bucket.headerOption.headerHeight) ||
              60) + 20
      }
      pl={20}
      pr={20}
      pb={20}
      // pt={
      //   type === 'modal' ||
      //   !(bucket?.header === 'fixed' || bucket?.header === 'drawer')
      //     ? module
      //       ? module?.styles.paddingTop
      //       : page?.styles.paddingTop
      //     : (Number(page?.headerOption?.headerHeight) ||
      //         Number(bucket.headerOption.headerHeight) ||
      //         60) +
      //       Number(module ? module?.styles.paddingTop : page?.styles.paddingTop)
      // }
      // pl={module ? module?.styles.paddingLeft : page?.styles.paddingLeft}
      // pr={module ? module?.styles.paddingRight : page?.styles.paddingRight}
      // pb={module ? module?.styles.paddingBottom : page?.styles.paddingBottom}
      mPb={reservationItem && item?.isViewedReservationBtn && 65}
      mb={
        (type === 'modal' &&
          isMobile() &&
          Number(page?.styles.paddingBottom) + 84) ||
        '0'
      }
      alignItems="flex-start"
      mDisplay="block"
      {...props}
    >
      <Div width="100%">
        {(isMobile() && type !== 'modal' && item?.designType !== 'gallery1' && (
          <DetailSubNav
            item={item}
            type={type}
            bucket={bucket}
            page={page}
            logging={logging}
          />
        )) ||
          ''}
        {(isMobile() && type !== 'modal' && item?.designType === 'gallery1' && (
          <DetailBoardGallerySubNav
            item={item}
            type={type}
            bucket={bucket}
            page={page}
            logging={logging}
            deleting={deleting}
            setTargetEditItem={setTargetEditItem}
            setIsEdit={setIsEdit}
          />
        )) ||
          ''}
        {(item?.designType === 'default-empty' ||
          item?.designType === 'default-image-left') && (
          <EnumerationDetailEmptyCard
            designType={item?.designType}
            t={t}
            item={item}
            logging={logging}
            commentLength={commentLength}
            type={type}
          />
        )}
        {item?.designType === 'default-image-top' && (
          <EnumerationDetailTopCard
            t={t}
            item={item}
            logging={logging}
            commentLength={commentLength}
            type={type}
          />
        )}
        {item?.designType === 'default-image-right' && (
          <EnumerationDetailRightCard
            t={t}
            item={item}
            logging={logging}
            commentLength={commentLength}
            type={type}
          />
        )}
        {item?.designType === 'speaker1' && (
          <EnumerationDetailSpeakerCard
            designType={item?.designType}
            t={t}
            item={item}
            logging={logging}
            commentLength={commentLength}
            type={type}
          />
        )}

        {item?.moduleType === 'board' && item?.designType !== 'gallery1' && (
          <BoardDetailCard
            t={t}
            item={item}
            logging={logging}
            targetEditItem={targetEditItem}
            setTargetEditItem={setTargetEditItem}
            deleting={deleting}
            commentLength={commentLength}
            type={type}
            setError={setError}
            getDetail={getDetail}
            setFilters={setFilters}
          />
        )}
        {item?.moduleType === 'board' && item?.designType === 'gallery1' && (
          <BoardDetailGalleryCard
            t={t}
            item={item}
            logging={logging}
            targetEditItem={targetEditItem}
            setTargetEditItem={setTargetEditItem}
            deleting={deleting}
            commentLength={commentLength}
            type={type}
            setError={setError}
            getDetail={getDetail}
            setFilters={setFilters}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        )}

        {item?.isUsedComment === 1 && (
          <CommentList
            item={item}
            commentLengthCallback={commentLengthCallback}
          />
        )}
        {type !== 'modal' && (
          <Row
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt={50}
            mb={100}
          >
            <Button active onClick={goList}>
              {t('list')}
            </Button>
          </Row>
        )}
      </Div>
      {openReservation && (
        <Dim
          display="none"
          mDiplay="block"
          zIndex={400}
          onClick={() => setOpenReservation(false)}
          backgroundColor="transparent"
        />
      )}
      {(reservationItem && item?.isViewedReservationBtn && (
        <Div
          position="sticky"
          width={300}
          minWidth={300}
          mMinWidth="unset"
          borderRadius="12px"
          top={
            ((bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
              (Number(page?.headerOption?.headerHeight) ||
                Number(bucket.headerOption.headerHeight) ||
                60) + Number(page?.styles.paddingTop)) ||
            page?.styles.paddingTop
          }
          mPosition="fixed"
          mWidth={type === 'modal' ? '100%' : '100vw'}
          mBottom={isUsedTabMenu && type !== 'modal' ? '50' : '0'}
          mLeft="0"
          mTop={reservationCheck ? 0 : 'unset'}
          border={{ color: 'g200' }}
          backgroundColor="backgroundColor"
          padding="40px 20px"
          mBorder={{ color: 'transparent' }}
          mPadding={(openReservation && '0 0 15px 0') || '15px 0'}
          mBorderRadius={
            type === 'modal' ? '10px' : openReservation && '10px 10px 0 0'
          }
          boxShadow="header"
          mBoxShadow="fixed"
          zIndex={5}
        >
          <ReservationInfo
            item={item}
            logging={logging}
            commentLength={commentLength}
            reservation={reservation}
            display={(reservationCheck && 'none') || ''}
          />
          {openReservation && (
            <>
              <Row
                alignItems="center"
                justifyContent="center"
                padding="10px 0"
                display="none"
                mDisplay="flex"
              >
                <Div
                  backgroundColor="g200"
                  width={30}
                  height={5}
                  borderRadius="100px"
                  cursor="pointer"
                  onClick={() => setOpenReservation(!openReservation)}
                />
              </Row>
              {(reservationItem?.isAlreadyReservation !== 1 ||
                reservationItem?.isConfirmedReservation !== 1) && (
                <ReservationList
                  item={reservationItem}
                  values={reservationValues}
                  setValues={setReservationValues}
                />
              )}
            </>
          )}
          <Row
            display={(reservationCheck && 'none') || ''}
            mPadding="0 25px"
            gap={20}
          >
            {reservationItem?.isAlreadyReservation === 1 &&
              reservationItem?.isConfirmedReservation === 1 && (
                <Button
                  maxWidth="unset"
                  buttonType="full"
                  border={{ color: 'keyColor' }}
                  backgroundColor="white"
                  color="keyColor"
                  onClick={reservationCancel}
                >
                  {t('reservation_cancel')}
                </Button>
              )}
            <Button
              maxWidth="unset"
              buttonType="large"
              backgroundColor={
                (reservationItem?.isAlreadyReservation === 1 &&
                  reservationItem?.isConfirmedReservation === 1 &&
                  'g500') ||
                'keyColor'
              }
              color={
                (reservationItem?.isAlreadyReservation === 1 &&
                  reservationItem?.isConfirmedReservation === 1 &&
                  'white') ||
                'keyFontColor'
              }
              active={
                reservationItem?.isAlreadyReservation === 0 ||
                reservationItem?.isConfirmedReservation === 0
              }
              onClick={() => {
                if (
                  reservationItem?.isAlreadyReservation !== 1 ||
                  reservationItem?.isConfirmedReservation !== 1
                ) {
                  reservationClick();
                }
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={5}
            >
              {(reservationItem?.isAlreadyReservation === 1 &&
                reservationItem?.isConfirmedReservation === 1 && (
                  <Icon name="check-done" stroke="white" size={18} />
                )) ||
                ''}
              {(reservationItem?.isAlreadyReservation === 1 &&
                reservationItem?.isConfirmedReservation === 1 &&
                t('reservation_done_label')) ||
                t('reservation')}
            </Button>
          </Row>
          {reservationCheck && (
            <ReservationCheck
              reservationItem={reservationItem}
              reservationValues={reservationValues}
              close={() => {
                setReservationCheck(false);
                setReservationValues();
                setOpenReservation(true);
              }}
              reservation={reservationDone}
              done={done}
              getDetail={getDetail}
              display="flex"
              mDisplay="none"
            />
          )}
        </Div>
      )) ||
        ''}
    </Row>
  );
}

export default DetailPresenter;
