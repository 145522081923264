import {
  Div,
  FilterListB,
  Grid,
  Row,
  StreamingCard,
  Text,
  Search,
} from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function PlayListB({
  module,
  list,
  user,
  changeStreaming,
  filters,
  setFilters,
  tagList,
}) {
  const { t } = useTranslation();
  const gridRef = useRef();
  const [currentHeight, setCurrentHeight] = useState();
  const [maxHeight, setMaxHeight] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (gridRef?.current) {
      setCurrentHeight(220);
      setMaxHeight(gridRef.current.clientHeight);
    }
  }, [gridRef]);

  useEffect(() => {
    if (filters.tags?.length > 0 || filters.keyword) {
      setCurrentHeight('auto');
    } else if (currentHeight === 'auto') {
      // filter 초기화 시 원래대로
      setCurrentHeight(220);
    }
  }, [filters.tags, filters.keyword]);

  const getMore = (newPage) => {
    setPage(newPage);
    setCurrentHeight(220 * newPage + 25 * (newPage - 1));
  };

  return (
    <Div mt={25} padding="25px 0 100px 0" borderTop={{ color: 'g200' }}>
      <Row
        alignItems="flex-start"
        justifyContent="space-between"
        gap={135}
        mb={25}
      >
        {module?.designType === 'list2' && (
          <FilterListB
            module={module}
            list={tagList}
            filters={filters}
            setFilters={setFilters}
            borderBottom={{ width: 0 }}
            pb={0}
            width="100%"
            wrapStyles={{ mb: 0, width: 'calc(100% - 359px)' }}
          />
        )}
        <Search filters={filters} setFilters={setFilters} minWidth={224} />
      </Row>
      <Div height={currentHeight} maxHeight={maxHeight} overflowY="hidden">
        <Grid
          setRef={gridRef}
          cols="1fr 1fr 1fr"
          sCols="1fr"
          justifyContent="flex-start"
          colGap={33}
          rowGap={25}
        >
          {list?.map((item) => (
            <StreamingCard
              type="list2"
              key={item.itemCode}
              user={user}
              item={item}
              onClick={() => changeStreaming(item)}
            />
          ))}
        </Grid>
      </Div>
      {currentHeight < maxHeight && currentHeight !== 'auto' && (
        <Row
          mt={50}
          width={200}
          height={64}
          alignItems="center"
          justifyContent="center"
          border={{ color: 'keyColor', width: '2px' }}
          borderRadius="10px"
          margin="0 auto"
          cursor="pointer"
          hover
          onClick={() => getMore(page + 2)}
        >
          <Text type="h5" color="keyColor">
            {t('more')}
          </Text>
        </Row>
      )}
    </Div>
  );
}
