import React, { useEffect, useState } from 'react';
import {
  RegistrationCard,
  Row,
  Checkbox,
  Div,
  Text,
  Button,
  PopupB,
  HTMLWrap,
  Modal,
} from 'components';

export function Term({ t, list, setTerm, setProgress, progress, initValue }) {
  const [active, setActive] = useState();
  const [agreeTerms, setAgreeTerms] = useState([]);
  const onTermsHandler = (value, name, checked) => {
    if (value === 'all' && checked) {
      setAgreeTerms([...list.map((el) => el.key)]);
    } else if (value === 'all' && !checked) {
      setAgreeTerms([]);
    } else if (checked) {
      setAgreeTerms([...agreeTerms, value]);
    } else if (!checked) {
      setAgreeTerms([...agreeTerms.filter((term) => term !== value)]);
    }
  };

  useEffect(() => {
    if (initValue?.length > 0) {
      setAgreeTerms(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    let result = false;
    if (!list.find((el) => el.isRequired === 1)) {
      result = true;
    } else {
      result = !list
        .filter((el) => el.isRequired === 1)
        ?.find((el) => !agreeTerms.includes(el.key));
    }
    setActive(result);
  }, [agreeTerms]);

  const onClick = () => {
    if (active) {
      setTerm(agreeTerms);
      setProgress({ ...progress, value: progress.value + 1 });
    }
  };

  const [popup, setPopup] = useState();

  return (
    <>
      <RegistrationCard maxWidth={676} margin="0 auto 40px auto" padding="40px">
        <Row
          gap={10}
          alignItems="flex-start"
          pb={20}
          mb={20}
          borderBottom={{ color: 'g200' }}
        >
          <Checkbox
            alignItems="flex-start"
            checkStyles={{ size: 24 }}
            name="term"
            value="all"
            checked={agreeTerms.length === list?.length}
            onChange={onTermsHandler}
          >
            <Div>
              <Text type="h7" color="g800" mb={6}>
                {t('all_agreement')}
              </Text>
              {/* 약관 전체 동의 문구 주석 처리 */}
              {/* <Text color="g500">{t('all_agreement_desc')}</Text> */}
            </Div>
          </Checkbox>
        </Row>
        {list.map((term, index) => (
          <Row
            key={term.key}
            padding="10px 0"
            gap={6}
            justifyContent="space-between"
            alignItems="center"
            mb={(list?.length - 1 !== index && 10) || ''}
          >
            <Checkbox
              checkStyles={{ size: 24 }}
              name="term"
              value={term.key}
              checked={
                (agreeTerms &&
                  agreeTerms?.find((agree) => agree === term.key)) ||
                false
              }
              onChange={onTermsHandler}
            >
              <Text type="b1" color="g800">
                {term.title}
                {(term.isRequired && `(${t('required')})`) ||
                  `(${t('option')})`}
              </Text>
            </Checkbox>
            <Button
              buttonType="text"
              type="b2"
              color="keyColor"
              padding="0rem 1.2rem"
              onClick={() => {
                if (term.content) {
                  setPopup(term.content);
                } else {
                  setTimeout(() => {
                    window.open(term.url, '_blank');
                  }, []);
                }
              }}
            >
              {t('view_term')}
            </Button>
          </Row>
        ))}
      </RegistrationCard>
      <Button
        display="block"
        width="100%"
        margin="0 auto"
        maxWidth={320}
        onClick={onClick}
        active={active}
      >
        {t('next')}
      </Button>
      {popup && (
        <Modal close={() => setPopup()}>
          <HTMLWrap content={popup} />
        </Modal>
      )}
    </>
  );
}
