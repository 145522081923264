import { Div, RegistrationMessage } from 'components';
import { useWindowSize } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

function PaymentResultPresenter({ count, isSuccess, goHome }) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  return (
    <Div pt={100} minHeight={windowSize.height}>
      <RegistrationMessage
        title={(isSuccess && t('payment_success')) || t('payment_fail')}
        content={t('payment_result_count', { count })}
        onClick={goHome}
      />
    </Div>
  );
}

export default PaymentResultPresenter;
