import React from 'react';
import { IconStyles } from 'styles/IconStyles';

// tab
import { ReactComponent as ChatActiveIcon } from 'assets/tab/active/chat.svg';
import { ReactComponent as ChatInactiveIcon } from 'assets/tab/inactive/chat.svg';
import { ReactComponent as HomeActiveIcon } from 'assets/tab/active/home.svg';
import { ReactComponent as HomeInactiveIcon } from 'assets/tab/inactive/home.svg';
import { ReactComponent as BoardActiveIcon } from 'assets/tab/active/board1.svg';
import { ReactComponent as BoardInactiveIcon } from 'assets/tab/inactive/board1.svg';
import { ReactComponent as Board2ActiveIcon } from 'assets/tab/active/board2.svg';
import { ReactComponent as Board2InactiveIcon } from 'assets/tab/inactive/board2.svg';
import { ReactComponent as NotiActiveIcon } from 'assets/tab/active/noti.svg';
import { ReactComponent as NotiInactiveIcon } from 'assets/tab/inactive/noti.svg';
import { ReactComponent as SearchActiveIcon } from 'assets/tab/active/search.svg';
import { ReactComponent as SearchInactiveIcon } from 'assets/tab/inactive/search.svg';
import { ReactComponent as TimeActiveIcon } from 'assets/tab/active/time.svg';
import { ReactComponent as TimeInactiveIcon } from 'assets/tab/inactive/time.svg';
import { ReactComponent as UserActiveIcon } from 'assets/tab/active/user.svg';
import { ReactComponent as UserInactiveIcon } from 'assets/tab/inactive/user.svg';
import { ReactComponent as MoreIcon } from 'assets/icon/menu-b.svg';

function TabIcon({
  name,
  position,
  display,
  size,
  active,
  children,
  ...props
}) {
  return (
    <IconStyles
      position={position || 'relative'}
      display={display || 'inline-flex'}
      minWidth={size}
      minHeight={size}
      width={size}
      height={size}
      maxWidth={size}
      maxHeight={size}
      fill={(active && 'keyColor') || 'g500'}
      {...props}
    >
      {name === 'chat' && (
        <>{(active && <ChatActiveIcon />) || <ChatInactiveIcon />}</>
      )}
      {name === 'home' && (
        <>{(active && <HomeActiveIcon />) || <HomeInactiveIcon />}</>
      )}
      {name === 'board1' && (
        <>{(active && <BoardActiveIcon />) || <BoardInactiveIcon />}</>
      )}
      {name === 'board2' && (
        <>{(active && <Board2ActiveIcon />) || <Board2InactiveIcon />}</>
      )}
      {(name === 'noti' || name === 'notice') && (
        <>{(active && <NotiActiveIcon />) || <NotiInactiveIcon />}</>
      )}
      {name === 'search' && (
        <>{(active && <SearchActiveIcon />) || <SearchInactiveIcon />}</>
      )}
      {name === 'time' && (
        <>{(active && <TimeActiveIcon />) || <TimeInactiveIcon />}</>
      )}
      {name === 'user' && (
        <>{(active && <UserActiveIcon />) || <UserInactiveIcon />}</>
      )}
      {name === 'more' && <MoreIcon />}
      {children}
    </IconStyles>
  );
}

export default TabIcon;
