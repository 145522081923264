import { Div, Col, Icon } from 'components';
import React, { useEffect, useState } from 'react';

export function SideBar({
  list1,
  list2,
  side1TargetCallback,
  side2TargetCallback,
  sideOpenCallback,
}) {
  const strokeIcons = ['streaming', 'board', 'enumeration', 'wysiwyg'];
  const fillIcons = ['streaming', 'chat', 'userList', 'memo'];
  const icons = {
    chat: 'side-chat',
    userList: 'side-userlist',
    memo: 'side-memo',
    streaming: 'side-play',
    board: 'side-board',
    enumeration: 'side-board',
    wysiwyg: 'side-wysiwyg',
  };
  const [tabOpen, setTabOpen] = useState(true);
  const [select1Tab, setSelect1Tab] = useState();
  const [select2Tab, setSelect2Tab] = useState();

  useEffect(() => {
    if (list1?.length > 0) {
      side1TargetCallback(list1[0]);
      setSelect1Tab(list1[0]);
    }
  }, [list1]);

  useEffect(() => {
    if (list2?.length > 0) {
      side2TargetCallback(list2[0]);
      setSelect2Tab(list2[0]);
    }
  }, [list2]);

  return (
    <Col
      width={60}
      minWidth={60}
      backgroundColor="key100"
      padding="10px"
      alignItems="center"
    >
      <Icon
        name="arrow-open"
        cursor="pointer"
        transform={(tabOpen && 'rotate(180deg)') || 'rotate(0)'}
        onClick={() => {
          setTabOpen(!tabOpen);
          sideOpenCallback(!tabOpen);
        }}
        mb={30}
      />
      {list1?.map((item) => (
        <Div
          key={item.moduleCode}
          padding="5px"
          borderRadius="9px"
          backgroundColor={
            (select1Tab?.moduleCode === item.moduleCode && 'key300') || 'g200'
          }
          hover
          cursor="pointer"
          mb={20}
          onClick={() => {
            setSelect1Tab(item);
            side1TargetCallback(item);
          }}
        >
          <Icon
            name={icons[item.moduleType]}
            fill={
              (fillIcons.includes(item.moduleType) &&
                ((select1Tab?.moduleCode === item.moduleCode && 'keyColor') ||
                  'g500')) ||
              ''
            }
            stroke={
              (strokeIcons.includes(item.moduleType) &&
                ((select1Tab?.moduleCode === item.moduleCode && 'keyColor') ||
                  'g500')) ||
              ''
            }
          />
        </Div>
      ))}
      {list2?.map((item) => (
        <Div
          key={item.moduleCode}
          padding="5px"
          borderRadius="9px"
          backgroundColor={
            (select2Tab?.moduleCode === item.moduleCode && 'key300') || 'g200'
          }
          hover
          cursor="pointer"
          mb={20}
          onClick={() => {
            setSelect2Tab(item);
            side2TargetCallback(item);
          }}
        >
          <Icon
            name={icons[item.moduleType]}
            fill={
              (fillIcons.includes(item.moduleType) &&
                ((select2Tab?.moduleCode === item.moduleCode && 'keyColor') ||
                  'g500')) ||
              ''
            }
            stroke={
              (strokeIcons.includes(item.moduleType) &&
                ((select2Tab?.moduleCode === item.moduleCode && 'keyColor') ||
                  'g500')) ||
              ''
            }
          />
        </Div>
      ))}
    </Col>
  );
}
