import React from 'react';
import {
  CategoryList1,
  CategoryList2,
  Col,
  Div,
  Row,
  Search,
} from 'components';
import { TagFilters } from './TagFilters';

export function Filters({
  module,
  categoryList,
  tagList,
  filters,
  setFilters,
}) {
  return (
    (module?.isViewedSearchBar === 1 ||
      module?.isUsedCategory === 1 ||
      module?.isViewedTagFilter === 1) && (
      <Col gap={20} mb={20}>
        {(module?.isViewedSearchBar === 1 && (
          <Row justifyContent="flex-end">
            <Search
              width="100%"
              maxWidth={224}
              mMaxWidth="unset"
              filters={filters}
              setFilters={setFilters}
            />
          </Row>
        )) ||
          ''}
        {module?.isUsedCategory === 1 &&
          categoryList?.length > 0 &&
          module?.categoryDesignType === 'design1' && (
            <CategoryList1
              module={module}
              list={categoryList}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        {module?.isUsedCategory === 1 &&
          categoryList?.length > 0 &&
          module?.categoryDesignType === 'design2' && (
            <CategoryList2
              module={module}
              list={categoryList}
              filters={filters}
              setFilters={setFilters}
            />
          )}

        {(module?.isUsedCategory === 1 &&
          module?.isViewedTagFilter === 1 &&
          categoryList?.length > 0 &&
          tagList?.length > 0 &&
          module?.categoryDesignType === 'design2' && (
            <Div width="100%" height={1} backgroundColor="g200" />
          )) ||
          ''}
        {(module?.isViewedTagFilter === 1 && tagList?.length > 0 && (
          <TagFilters
            module={module}
            list={tagList}
            filters={filters}
            setFilters={setFilters}
          />
        )) ||
          ''}
      </Col>
    )
  );
}
