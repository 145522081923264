import { css } from 'styled-components';
import { isObject } from 'utils/isObject';
import { hexToRGB } from 'utils/css/hexToRGB';

export const common = css`
  box-sizing: border-box;
  ${({ display }) => (display && `display: ${display};`) || ''};
  ${({ visibility }) => (visibility && `visibility: ${visibility}; `) || ''};
  ${({ position }) => (position && `position: ${position};`) || ''};
  ${({ float }) => (float && `float: ${float};`) || ''};
  ${({ cursor }) => (cursor && `cursor: ${cursor || 'pointer'};`) || ''};
  ${({ aspectRatio }) =>
    (aspectRatio && `aspect-ratio: ${aspectRatio};`) || ''};
  ${({ overflowX }) =>
    (overflowX && `overflow-x:${overflowX || 'overlay'};`) || ''};
  ${({ overflowY }) =>
    (overflowY && `overflow-y:${overflowY || 'overlay'};`) || ''};
  ${({ opacity }) =>
    ((opacity === 0 || opacity) && `opacity: ${opacity};`) || ''};
  ${({ isFullWidth }) =>
    (isFullWidth === 1 &&
      css`
        width: 100vw;
        margin-left: calc(-50vw + 50%);
      `) ||
    ''}
  ${({ backgroundColor, theme }) =>
    (backgroundColor &&
      `background-color: ${
        (isObject(backgroundColor) &&
          hexToRGB(
            theme.colors[backgroundColor.color] || backgroundColor.color,
            backgroundColor.opacity,
          )) ||
        theme.colors[backgroundColor] ||
        backgroundColor
      };`) ||
    ''};
  ${({ backgroundPosition }) =>
    (backgroundPosition && `background-position: ${backgroundPosition};`) ||
    ''};
  ${({ backgroundSize }) =>
    (backgroundSize && `background-size: ${backgroundSize};`) || ''};
  ${({ backgroundRepeat }) =>
    (backgroundRepeat &&
      `background-repeat: ${backgroundRepeat || 'no-repeat'};`) ||
    ''};
  ${({ backgroundImage }) =>
    (backgroundImage && `background-image: url("${backgroundImage}");`) || ''};
  ${({ backdropFilter }) =>
    (backdropFilter && `backdrop-filter:${backdropFilter};`) || ''};
  ${({ background }) => (background && `background:${background};`) || ''};
  ${({ boxShadow, theme }) =>
    (boxShadow && `box-shadow: ${theme.shadows[boxShadow] || boxShadow};`) ||
    ''};
  ${({ transition }) => (transition && `transition: ${transition};`) || ''};
  ${({ transitionDelay }) =>
    (transitionDelay && `transition-delay: ${transitionDelay};`) || ''};
  ${({ transformOrigin }) =>
    (transformOrigin && `transform-origin: ${transformOrigin};`) || ''};
  ${({ animation }) =>
    (animation &&
      css`
        animation: ${animation};
        -webkit-animation: ${animation};
      `) ||
    ''}
  ${({ hover, theme }) =>
    (hover &&
      `
    @media (hover: hover) and (pointer: fine) {
      transition: all 150ms;
      &:hover{
        ${
          (hover === 'circle' &&
            `
              border-radius: 50%;
              background-color: ${theme.colors.key100};
            `) ||
          (hover === 'background' &&
            `
            border-radius: 5px;
            background-color: ${theme.colors.g100};`) ||
          `
            opacity: 0.5;
          `
        }
      }
    }`) ||
    ''};

  ${({ overflowX, overflowY, theme }) =>
    (overflowX || overflowY) &&
    css`
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.g300};
        border-radius: 20px;
      }
    `};

  ${({ scrollTransparent }) =>
    scrollTransparent &&
    css`
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 20px;
      }
    `};

  //mobile
  @media ${({ theme }) => theme.device.tablet} {
    ${({ mDisplay }) => (mDisplay && `display: ${mDisplay};`) || ''};
    ${({ mVisibility }) =>
      (mVisibility && `visibility: ${mVisibility}; `) || ''};
    ${({ mPosition }) => (mPosition && `position: ${mPosition};`) || ''};
    ${({ mFloat }) => (mFloat && `float: ${mFloat};`) || ''};
    ${({ mAspectRatio }) =>
      (mAspectRatio && `aspect-ratio: ${mAspectRatio};`) || ''};
    ${({ mOverflowX }) =>
      (mOverflowX && `overflow-x:${mOverflowX || 'overlay'};`) || ''};
    ${({ mOverflowY }) =>
      (mOverflowY && `overflow-y:${mOverflowY || 'overlay'};`) || ''};
    ${({ mBackgroundColor, theme }) =>
      (mBackgroundColor &&
        `background-color: ${
          (isObject(mBackgroundColor) &&
            hexToRGB(
              theme.colors[mBackgroundColor.color] || mBackgroundColor.color,
              mBackgroundColor.opacity,
            )) ||
          theme.colors[mBackgroundColor] ||
          mBackgroundColor
        };`) ||
      ''};
    ${({ mBackgroundPosition }) =>
      (mBackgroundPosition && `background-position: ${mBackgroundPosition};`) ||
      ''};
    ${({ mBackgroundSize }) =>
      (mBackgroundSize && `background-size: ${mBackgroundSize};`) || ''};
    ${({ mBackgroundRepeat }) =>
      (mBackgroundRepeat &&
        `background-repeat: ${mBackgroundRepeat || 'no-repeat'};`) ||
      ''};
    ${({ mBackgroundImage }) =>
      (mBackgroundImage && `background-image: url(${mBackgroundImage});`) ||
      ''};
    ${({ mBackground }) => (mBackground && `background:${mBackground};`) || ''};
    ${({ mBoxShadow, theme }) =>
      (mBoxShadow &&
        `box-shadow: ${theme.shadows[mBoxShadow] || mBoxShadow};`) ||
      ''};
    ${({ mTransition }) =>
      (mTransition && `transition: ${mTransition};`) || ''};
    ${({ mTransformOrigin }) =>
      (mTransformOrigin && `transform-origin: ${mTransformOrigin};`) || ''};
  }
`;
