import {
  Button,
  Div,
  HeaderLogin,
  ProfileImageEdit,
  ProfileInputCard,
} from 'components';
import React from 'react';

function ProfilePresenter({
  t,
  bucket,
  list,
  values,
  invalids,
  onChange,
  currentLanguage,
  confirm,
  active,
}) {
  return (
    <>
      {bucket?.header !== 'none' && <HeaderLogin bucket={bucket} />}
      <Div
        minHeight="height"
        position="relative"
        padding={
          (bucket.header === 'none' && '60px 20px') || '120px 20px 60px 20px'
        }
        maxWidth={375}
        margin="0 auto"
      >
        <ProfileImageEdit
          display="block"
          width="fit-content"
          margin="0 auto 30px auto"
          cursor="pointer"
          name="thumbnail"
          value={values?.thumbnail}
          onChange={onChange}
        />
        {list?.map(
          (item) =>
            item.itemType !== 'thumbnail' && (
              <ProfileInputCard
                key={item.itemType}
                title={item?.title[currentLanguage]}
                item={item}
                name={item.itemType}
                value={values[item.itemType] || ''}
                onChange={onChange}
                invalid={invalids[item.itemType]}
              />
            ),
        )}
        <Button
          buttonType="large"
          margin="0 auto"
          onClick={confirm}
          display="block"
          active={active}
        >
          {t('confirm')}
        </Button>
      </Div>
    </>
  );
}

export default ProfilePresenter;
