import React from 'react';
import { DivStyle } from 'styles/DivStyle';

export function Div({ setRef, children, ...props }) {
  return (
    <DivStyle ref={setRef} {...props}>
      {children}
    </DivStyle>
  );
}

export function Grid({ setRef, display, mDisplay, children, ...props }) {
  return (
    <DivStyle
      ref={setRef}
      display={display || 'grid'}
      mDisplay={mDisplay || display || 'grid'}
      {...props}
    >
      {children}
    </DivStyle>
  );
}

export function Row({
  setRef,
  display,
  mDisplay,
  flexDirection,
  mFlexDirection,
  children,
  ...props
}) {
  return (
    <DivStyle
      ref={setRef}
      display={display || 'flex'}
      mDisplay={mDisplay || display || 'flex'}
      flexDirection={flexDirection || 'row'}
      mFlexDirection={mFlexDirection || flexDirection || 'row'}
      {...props}
    >
      {children}
    </DivStyle>
  );
}

export function Col({
  setRef,
  display,
  mDisplay,
  flexDirection,
  mFlexDirection,
  children,
  ...props
}) {
  return (
    <DivStyle
      ref={setRef}
      display={display || 'flex'}
      mDisplay={mDisplay || display || 'flex'}
      flexDirection={flexDirection || 'column'}
      mFlexDirection={mFlexDirection || flexDirection || 'column'}
      {...props}
    >
      {children}
    </DivStyle>
  );
}
