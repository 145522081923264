import { Button, Col, Div, Icon, Row, Text } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReservationCheck({
  type,
  reservationItem,
  reservationValues,
  close,
  reservation,
  done,
  getDetail,
  ...props
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const makeGrandChildItem = (item) => {
    let resultItem = {};
    reservationItem?.list?.forEach((reserve) => {
      resultItem =
        Object.values(reserve?.childList)
          ?.flat(1)
          .find(
            (childReserve) => Object.keys(childReserve.childList).length > 0,
          )?.childList &&
        Object.values(
          Object.values(reserve?.childList)
            ?.flat(1)
            .find(
              (childReserve) => Object.keys(childReserve.childList).length > 0,
            )?.childList,
        )
          .flat(2)
          .find((grandChildReserve) => grandChildReserve.itemCode === item[0]);
    });
    return resultItem;
  };
  return (
    <Col
      width="calc(100% + 40px)"
      height="calc(100% + 80x)"
      ml="-20px"
      mMl="unset"
      mt="-40px"
      mMt="unset"
      borderRadius="12px"
      mBorderRadius="0"
      mPosition="fixed"
      overflowX="hidden"
      overflowY="hidden"
      mWidth={type === 'modal' ? '100%' : '100vw'}
      mHeight={type === 'modal' ? '100%' : '100vh'}
      backgroundColor="g100"
      top="0"
      left="0"
      zIndex={9999}
      mPt={done ? 0 : 60}
      {...props}
    >
      {!done && (
        <>
          <Row
            width="100%"
            height={50}
            position="relative"
            mPosition="fixed"
            top="0"
            left="0"
            zIndex={9999}
            backgroundColor="backgroundColor"
            padding="0 25px"
            alignItems="center"
            justifyContent="center"
            mb={10}
            mMb="0"
            minHeight={50}
          >
            <Icon
              name="arrow"
              fill="fontColor"
              size={24}
              transform="translateY(-50%) rotate(90deg)"
              onClick={close}
              cursor="pointer"
              hover="background"
              position="absolute"
              left="25px"
              top="50%"
            />
            <Text>{t('reservation')}</Text>
          </Row>
          <Div height="100%">
            <Div backgroundColor="backgroundColor">
              <Row
                padding="10px 20px"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={{ color: 'g200' }}
              >
                <Text type="h7" color="keyColor">
                  ➊ {t('reservation_info')}
                </Text>
                <Icon
                  name="arrow"
                  fill="g500"
                  cursor="pointer"
                  transform={(open && 'rotate(-180deg)') || 'rotate(0)'}
                  onClick={() => setOpen(!open)}
                />
              </Row>
              {open &&
                reservationValues &&
                Object.entries(reservationValues).map((item) => (
                  <Row
                    key={item[0]}
                    padding="20px 24px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text type="h7">
                      {reservationItem?.list?.find((reserve) =>
                        reserve.selections?.find(
                          (selection) => selection.selectionCode === item[1],
                        ),
                      )?.title ||
                        (reservationItem?.list?.find((reserve) =>
                          Object.values(reserve?.childList)
                            ?.flat(1)
                            ?.find(
                              (childReserve) =>
                                childReserve.itemCode === item[0],
                            ),
                        )?.childList &&
                          Object.values(
                            reservationItem?.list?.find((reserve) =>
                              Object.values(reserve?.childList)
                                ?.flat(1)
                                ?.find(
                                  (childReserve) =>
                                    childReserve.itemCode === item[0],
                                ),
                            )?.childList,
                          )
                            ?.flat(1)
                            ?.find(
                              (childReserve) =>
                                childReserve.itemCode === item[0],
                            )?.title) ||
                        makeGrandChildItem(item)?.title ||
                        ''}
                    </Text>
                    <Text type="b1">
                      {reservationItem?.list
                        ?.find((reserve) =>
                          reserve.selections?.find(
                            (selection) => selection.selectionCode === item[1],
                          ),
                        )
                        ?.selections?.find(
                          (selection) => selection.selectionCode === item[1],
                        ).selection ||
                        (reservationItem?.list?.find((reserve) =>
                          Object.values(reserve.childList)
                            .flat(1)
                            .find(
                              (childReserve) =>
                                childReserve.itemCode === item[0],
                            ),
                        ) &&
                          Object.values(
                            reservationItem?.list?.find((reserve) =>
                              Object.values(reserve.childList)
                                .flat(1)
                                .find(
                                  (childReserve) =>
                                    childReserve.itemCode === item[0],
                                ),
                            ).childList,
                          )
                            .flat(1)
                            .find(
                              (childReserve) =>
                                childReserve.itemCode === item[0],
                            )
                            ?.selections?.find(
                              (selection) =>
                                selection.selectionCode === item[1],
                            ).selection) ||
                        makeGrandChildItem(item)?.selections?.find(
                          (grandSelection) =>
                            grandSelection.selectionCode === item[1],
                        ).selection ||
                        ''}
                    </Text>
                  </Row>
                ))}
            </Div>
          </Div>
          <Div
            padding="15px 25px 0 25px"
            mPadding="15px 25px"
            backgroundColor="backgroundColor"
          >
            <Button
              maxWidth="unset"
              buttonType="large"
              active
              onClick={reservation}
            >
              {t('reservation')}
            </Button>
          </Div>
        </>
      )}
      {done && (
        <>
          <Div
            height="100%"
            backgroundColor="backgroundColor"
            position="relative"
            pt={40}
            mPt="0"
          >
            <Col
              gap={10}
              mPosition="absolute"
              mTop="50%"
              mLeft="50%"
              mTransform="translate(-50%,-50%)"
              alignItems="center"
            >
              <Icon name="check-done" stroke="keyColor" />
              <Text type="h6" mt={10} whiteSpace="nowrap">
                {t('reservation_done')}
              </Text>
            </Col>
          </Div>
          <Div
            padding="15px 25px 0 25px"
            mPadding="15px 25px"
            backgroundColor="backgroundColor"
          >
            <Button
              maxWidth="unset"
              buttonType="large"
              active
              onClick={() => {
                close();
                getDetail();
              }}
            >
              {t('confirm')}
            </Button>
          </Div>
        </>
      )}
    </Col>
  );
}
