import { Text, Button } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function RegistrationMessage({
  title,
  content,
  onClick,
  buttonLabel,
  cancel,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Text type="h2" textAlign="center" mb={10}>
        {title}
      </Text>
      <Text type="b1" color="g500" textAlign="center">
        {content}
      </Text>
      {onClick && (
        <Button
          buttonType="full"
          active
          margin="0 auto"
          display="block"
          onClick={onClick}
          mt={50}
        >
          {buttonLabel || t('confirm')}
        </Button>
      )}
      {cancel && (
        <Button
          buttonType="full"
          active
          margin="0 auto"
          display="block"
          onClick={cancel}
          mt={10}
          backgroundColor="keyFontColor"
          border={{ color: 'keyColor', width: '1px' }}
          color="keyColor"
        >
          {t('cancel')}
        </Button>
      )}
    </>
  );
}
