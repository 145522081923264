import { menuApi } from 'api';
import { Loading } from 'components';
import { useApiParams, useBucketReset, useLanguageNavigate } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPage } from 'store/page';
import { useParams } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { makeTheme } from 'utils/css/makeTheme';
import { isMobile } from 'utils/isMobile';
import MenuPresenter from './MenuPresenter';

function MenuContainer({ bucket, socket, headerCallback }) {
  const { languageNavigate, currentLanguage } = useLanguageNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { menuCode } = useParams();
  const { logout, reload, needLogin, needProfile } = useBucketReset();
  const { DEFAULT_PARAMS } = useApiParams();
  const theme = useTheme();
  const [innerTheme, setInnerTheme] = useState({});
  const [menu, setMenu] = useState();
  const [side1List, setSide1List] = useState();
  const [side2List, setSide2List] = useState();
  const [customScript, setCustomScript] = useState([]);

  const getMenuInfo = async () => {
    setLoading(true);
    const [info, error] = await menuApi.menuInfo(menuCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      const { result } = info;
      if (result.isNecessaryInit === 1) {
        logout();
      } else if (result.isNecessaryReload === 1) {
        reload();
      } else if (result.isNecessaryLogin === 1) {
        needLogin();
      } else if (result.isNecessaryProfile === 1) {
        needProfile();
      } else {
        connectRoom(result.pageCode);
        setMenu(result);
        if (!isMobile()) setSide2List(result.streamingSide2Structure);
        dispatch(setPage({ ...result, menuCode }));
        headerCallback(result?.headerOption);
        const { styles } = result;
        const userTheme = { ...makeTheme(styles, theme) };
        setInnerTheme({
          ...theme,
          colors: {
            ...theme.colors,
            ...userTheme,
          },
        });
        if (
          result.customScript &&
          Array.isArray(result.customScript) &&
          result.customScript.length > 0
        ) {
          setCustomScript(result.customScript);
        }
        setLoading(false);
      }
    } else if (error.resCode === 454) {
      // error page undefind
      languageNavigate('/');
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bucket && menuCode) {
      getMenuInfo();
    }
  }, [bucket, menuCode]);

  useEffect(() => {
    const scripts = [];
    if (menu && customScript.length > 0) {
      customScript.forEach((item) => {
        const script = document.createElement('script');
        script.src = item.src;
        script.innerHTML = `${item.textContent}`;
        item.attributes?.forEach((attribute) => {
          script[attribute.key] = attribute.value;
        });

        document.body.appendChild(script);
        scripts.push(script);
      });
    }

    return () => {
      if (menu && customScript.length > 0) {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
        setCustomScript([]);
      }
    };
  }, [menu, customScript]);

  const [streamingItem, setStreamingItem] = useState();
  const sidePlayListCallback = useCallback(
    (target) => {
      if (target && menu) {
        setStreamingItem(target);
        if (target.playlistOption === 'right' && target.list?.length > 0) {
          setSide1List(
            (isMobile() && [
              target,
              ...menu.streamingSide1Structure,
              ...menu.streamingSide2Structure,
            ]) || [target, ...menu.streamingSide1Structure],
          );
        } else {
          setSide1List(
            (isMobile() && [
              ...menu.streamingSide1Structure,
              ...menu.streamingSide2Structure,
            ]) || [...menu.streamingSide1Structure],
          );
        }
      }
    },
    [menu],
  );

  const streamingItemCallback = useCallback((target) => {
    if (target) {
      setStreamingItem(target);
    }
  }, []);

  useEffect(
    () =>
      // clean up

      () => {
        if (menu && socket) {
          disconnectRoom();
        }
      },
    [menu],
  );

  // socket
  const connectRoom = (pageCode) => {
    socket.emit('join room', { roomId: pageCode });
  };

  const disconnectRoom = () => {
    socket.emit('exit room', { roomId: menu.pageCode });
  };

  return (
    <ThemeProvider theme={innerTheme}>
      {(loading && (
        <Loading
          position="relative"
          height="height"
          mt={Number(bucket?.headerOption?.headerHeight) || 60}
        />
      )) || (
        <MenuPresenter
          menu={menu}
          currentLanguage={currentLanguage}
          side1List={side1List}
          side2List={side2List}
          socket={socket}
          bucket={bucket}
          sidePlayListCallback={sidePlayListCallback}
          streamingItem={streamingItem}
          streamingItemCallback={streamingItemCallback}
        />
      )}
    </ThemeProvider>
  );
}

export default MenuContainer;
