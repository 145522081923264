import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from 'react-router-dom';
import { LANGUAGE } from 'data/keyFile';

function useLanguageNavigate(props) {
  const disabledHeaderPath = ['login', 'profile', 'signup', 'findpassword'];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const currentLanguage = searchParams.get(LANGUAGE);

  const languageNavigate = (pathname, lang, state, search) => {
    if (pathname === -1) {
      navigate(-1);
    } else {
      const stateTemp = ((pathname.includes('login') ||
        pathname.includes('profile')) &&
        !disabledHeaderPath.includes(location.pathname.split('/')[1]) && {
          ...state,
          prevLocation: location,
        }) || { ...state };
      navigate(
        {
          pathname,
          search: createSearchParams({
            ...search,
            [LANGUAGE]: lang || currentLanguage,
          }).toString(),
        },
        { state: { ...stateTemp } },
      );
    }
  };

  return { languageNavigate, currentLanguage };
}

export default useLanguageNavigate;
