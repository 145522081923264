import { Label } from 'components';
import styled from 'styled-components';

export const CheckboxStyles = styled(Label)`
  & > .hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0px;
  }

  & > .styled-checkbox {
    transition: all 150ms ease;
    position: relative;
    width: 18px;
    height: 18px;
    background-color: ${({ checked, theme }) =>
      theme.colors[(checked && 'keyColor') || 'g200']};

    & > svg {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }
`;
