import React from 'react';
import { TextStyles } from 'styles/TextStyle';

export function Text({ setRef, type, mType, children, whiteSpace, ...props }) {
  return (
    <>
      {(children &&
        children.toString().includes('\n') &&
        whiteSpace !== 'pre-line' &&
        children?.split('\n').map(
          (text, index) =>
            (text && (
              <TextStyles
                key={`${text[0]}${Math.random()}${index}`}
                type={type || 'b2'}
                mType={mType}
                {...props}
              >
                {text}
              </TextStyles>
            )) || <br key={`${text[0]}${Math.random()}${index}`} />,
        )) ||
        (children &&
          children.toString().includes('\\n') &&
          whiteSpace !== 'pre-line' &&
          children?.split('\\n').map(
            (text, index) =>
              (text && (
                <TextStyles
                  key={`${text[0]}${Math.random()}${index}`}
                  type={type || 'b2'}
                  mType={mType}
                  {...props}
                >
                  {text}
                </TextStyles>
              )) || <br key={`${text[0]}${Math.random()}${index}`} />,
          )) || (
          <TextStyles
            ref={setRef}
            whiteSpace={whiteSpace}
            type={type || 'b2'}
            mType={mType}
            {...props}
          >
            {children}
          </TextStyles>
        )}
    </>
  );
}
