import React, { useEffect, useState } from 'react';
import { Row, Input, Dropdown, Invalid, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { DEFAULT_INVALID_KEYS } from 'utils/invalids';

export function Email({
  onChange,
  name,
  invalid,
  initValue,
  selectionEtcTitle,
  selections,
  disabled,
  ...props
}) {
  const { t } = useTranslation();
  const [defaultInvalid, setDefaultInvalid] = useState({ type: '', text: '' });
  const [email, setEmail] = useState({ id: '', domain: '' });
  const [init, setInit] = useState(false);
  const onEmailChangeHandler = (val, type) => {
    setInit(true);
    setEmail({ ...email, [type]: val });
  };

  useEffect(() => {
    if (init && email.id && email.domain) {
      const resultValue = `${email.id}@${email.domain}`;
      if (!resultValue.match(DEFAULT_INVALID_KEYS.email)) {
        setDefaultInvalid({ type: 'error', text: t('invalid_email') });
      } else {
        onChange({ emailId: email.id, domain: email.domain }, name);
        setDefaultInvalid({ type: '', text: '' });
      }
    } else if (init) {
      onChange('', name);
      setDefaultInvalid({ type: '', text: '' });
    }
  }, [email, init]);

  useEffect(() => {
    if (initValue) {
      setEmail({
        id: initValue.emailId,
        domain: initValue.domain,
      });
    }
  }, [initValue]);

  return (
    <>
      <Row gap={10} alignItems="center" {...props}>
        <Input
          wrapStyles={{ flex: 1 }}
          value={email.id}
          name="id"
          invalid={{ type: invalid?.type || defaultInvalid.type }}
          onChange={onEmailChangeHandler}
          disabled={disabled}
          autoComplete="off"
        />
        <Text>@</Text>
        <Dropdown
          wrapStyles={{ flex: 1 }}
          list={
            selectionEtcTitle
              ? [...(selections || []), selectionEtcTitle].filter(
                  (el) => el !== '',
                )
              : [...(selections || [])].filter((el) => el !== '')
          }
          direct={selectionEtcTitle}
          onChange={(value) => onEmailChangeHandler(value.title, 'domain')}
          value={email.domain}
          name={`domain-${name}`}
          hiddenRemove
          invalid={{ type: invalid?.type || defaultInvalid.type }}
          disabled={disabled}
          autoComplete="off"
          customItem={(el) => el?.title}
          customKey="title"
        />
      </Row>
      <Invalid invalid={invalid || defaultInvalid} />
    </>
  );
}
