export const linkTypeClick = (type, url, info) => {
  const newUrl = new URL(url);
  const params = new URLSearchParams(newUrl.search);
  if (info) {
    params.append(info.key, info.value);
  }

  const redirectUrl =
    (info && `${newUrl.origin}${newUrl.pathname}?${params.toString()}`) ||
    newUrl;

  if (type === 'outLink') {
    window.open(redirectUrl, '_blank');
  } else if (type === 'inLink') {
    window.location.replace(redirectUrl);
  }
};
