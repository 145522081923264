import { Button, Div, Editor, Icon, Row } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MemoWrite({ save, moduleCode }) {
  const { t } = useTranslation();
  const [writing, setWriting] = useState(false);
  const [values, setValues] = useState({ content: '' });

  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Button
        active
        mt={10}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={4}
        onClick={() => setWriting(true)}
        transform={(writing && 'scaleY(0)') || 'scaleY(1)'}
        transformOrigin="top"
        transitionDelay={(!writing && '100ms') || ''}
      >
        <Icon name="add" />
        {t('memo')}
      </Button>
      <Div
        transition="all 150ms cubic-bezier(0.860, 0.000, 0.070, 1.000)"
        transitionDelay={(writing && '100ms') || ''}
        transform={(writing && 'scaleY(1)') || 'scaleY(0)'}
        transformOrigin="top"
        mt="-40px"
        width="100%"
      >
        {writing && (
          <Editor
            value={values.content}
            name="content"
            onChange={onChange}
            width="100%"
            heightMax="195px"
            heightMin="195px"
            border={{ color: 'keyColor' }}
            borderRadius="3px"
            moduleCode={moduleCode}
          />
        )}
        <Row mt={10} gap={10} justifyContent="flex-end">
          <Button minWidth={64} ghost onClick={() => setWriting(false)}>
            {t('cancel')}
          </Button>
          <Button
            active={!!values.content}
            width="100%"
            maxWidth={246}
            onClick={async () => {
              const result = await save(values);
              if (result) {
                setValues({ ...values, content: '' });
                setWriting(false);
              }
            }}
          >
            {t('save')}
          </Button>
        </Row>
      </Div>
    </>
  );
}
