import React from 'react';
import { Row, Icon } from 'components';
import { PaginationStyles } from 'styles/PaginationStyles';

export function Pagination({
  pages,
  filters,
  setFilters,
  changePageHandler,
  ...props
}) {
  const changePage = (i) => {
    if (i >= 0 && i <= pages.totalPages && pages.currentPage !== i) {
      if (changePageHandler) {
        changePageHandler(i);
      } else {
        setFilters({
          ...filters,
          page: i,
          lastRowNum: filters.perPage * (i - 1),
          perPage: filters.perPage,
        });
      }
    }
  };

  const pageNum = () => {
    const currentGroup = Math.floor((pages.currentPage - 1) / 5);
    const forArray = [];
    const lastIndex =
      pages.totalPages > currentGroup * 5 + 5
        ? currentGroup * 5 + 5
        : pages.totalPages;

    for (let i = currentGroup * 5; i < lastIndex; i += 1) {
      forArray.push(
        <PaginationStyles
          key={i}
          active={i + 1 === pages.currentPage}
          onClick={() => {
            changePage(i + 1);
          }}
        >
          {i + 1}
        </PaginationStyles>,
      );
    }

    return forArray;
  };

  return (
    (pages && pages.totalPages > 1 && (
      <Row alignItems="center" justifyContent="center" gap={4} {...props}>
        {Math.floor((pages.currentPage - 1) / 5) !== 0 && (
          <Icon
            name="arrow"
            fill="g500"
            size={28}
            padding="4px"
            transform="rotate(90deg)"
            border={{ color: 'g200' }}
            borderRadius="9px"
            onClick={() =>
              changePage((Math.floor((pages.currentPage - 1) / 5) - 1) * 5 + 1)
            }
            cursor="pointer"
            hover
          />
        )}
        {pageNum()}
        {pages.totalPages > Math.floor((pages.currentPage - 1) / 5) * 5 + 5 && (
          <Icon
            name="arrow"
            fill="g500"
            size={28}
            padding="4px"
            transform="rotate(-90deg)"
            border={{ color: 'g200' }}
            borderRadius="9px"
            cursor="pointer"
            hover
            onClick={() =>
              changePage(Math.floor((pages.currentPage - 1) / 5) * 5 + 6)
            }
          />
        )}
      </Row>
    )) || <></>
  );
}
