import React, { useEffect, useState } from 'react';
import { useApiParams, useLanguageNavigate } from 'hooks';
import { isEmptyObject } from 'utils/isEmptyObject';
import { authApi } from 'api';
import { objectArrayParamsCleansing } from 'utils/apiCleansing';
import { useLocation } from 'react-router-dom';
import ProfilePresenter from './ProfilePresenter';

function ProfileContainer({
  t,
  bucket,
  defaultProfileCategories,
  getBucketInfo,
}) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const { languageNavigate, currentLanguage } = useLanguageNavigate();
  const [values, setValues] = useState();
  const [invalids, setInvalids] = useState();
  const { DEFAULT_PARAMS } = useApiParams();

  useEffect(() => {
    if (bucket && defaultProfileCategories && currentLanguage) {
      let tempValue = {};
      let tempInvalid = {};
      defaultProfileCategories.forEach((item) => {
        tempValue = {
          ...tempValue,
          [item.itemType]: '',
        };
        tempInvalid = {
          ...tempInvalid,
          [item.itemType]: { type: '', text: '' },
        };
      });

      setValues({ ...tempValue });
      setInvalids({ ...tempInvalid });
      setLoading(false);
    } else if (!defaultProfileCategories && bucket) {
      languageNavigate('/');
    }
  }, [bucket, defaultProfileCategories, currentLanguage]);

  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
    setInvalids({
      ...invalids,
      [name]: { type: '', text: '' },
    });
  };

  const [active, setActive] = useState(false);
  useEffect(() => {
    if (values) {
      const defaultRequiredArray = defaultProfileCategories.filter(
        (profile) => profile.isRequired === 1,
      );
      let defaultResult = true;
      for (const defaultRequiredElem of defaultRequiredArray) {
        const findElem = Object.keys(values).find(
          (key) => key === defaultRequiredElem.itemType,
        );
        if (
          !values[findElem] ||
          values[findElem].length < 1 ||
          isEmptyObject(values[findElem])
        ) {
          defaultResult = false;
        }
      }
      setActive(defaultResult);
    }
  }, [values]);

  const confirm = async () => {
    if (active) {
      const [info] = await authApi.profile({
        ...DEFAULT_PARAMS,
        lang: currentLanguage,
        ...objectArrayParamsCleansing(values),
      });
      if (info) {
        // success
        getBucketInfo();
        languageNavigate(
          (location.state?.prevLocation &&
            `${location.state?.prevLocation.pathname}`) ||
            `/`,
        );
      }
    } else if (values) {
      const defaultRequiredArray = defaultProfileCategories.filter(
        (profile) => profile.isRequired === 1,
      );
      let tempInvalid = {};
      for (const defaultRequiredElem of defaultRequiredArray) {
        const findElem = Object.keys(values).find(
          (key) => key === defaultRequiredElem.itemType,
        );
        if (
          !values[findElem] ||
          values[findElem].length < 1 ||
          isEmptyObject(values[findElem])
        ) {
          tempInvalid = {
            ...tempInvalid,
            [findElem]: { type: 'error', text: '' },
          };
        }
      }
      setInvalids({
        ...tempInvalid,
      });
    }
  };

  return (
    !loading && (
      <ProfilePresenter
        t={t}
        bucket={bucket}
        list={defaultProfileCategories}
        values={values}
        invalids={invalids}
        setInvalids={setInvalids}
        onChange={onChange}
        currentLanguage={currentLanguage}
        confirm={confirm}
        active={active}
      />
    )
  );
}

export default ProfileContainer;
