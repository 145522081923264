import { Col, Div, Icon, ProfileImage, Text } from 'components';
import { useViewedItems } from 'hooks';
import React, { useEffect } from 'react';

export function UserCard({ item, close, ...props }) {
  const { setItem, isViewedUserName, isViewedUserThumbnail } = useViewedItems();

  useEffect(() => {
    if (item) {
      setItem(item);
    }
  }, [item]);

  return (
    <Div
      width="calc(100% - 40px)"
      maxWidth={280}
      borderRadius="12px"
      position="relative"
      overflowY="hidden"
      boxShadow="hover"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      {...props}
    >
      <Icon
        size={24}
        name="delete"
        cursor="pointer"
        position="absolute"
        top="6px"
        right="6px"
        hover
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          close();
        }}
      />
      <Col
        alignItems="center"
        padding="40px 20px 10px 20px"
        backgroundColor="key100"
      >
        {isViewedUserThumbnail && (
          <ProfileImage size={60} thumbnail={item?.userThumbnail} mb={10} />
        )}
        {isViewedUserName && (
          <Text type="h8" color="g800">
            {item?.userName}
          </Text>
        )}
        {/* <Text mt={4} type="b3" color="g400">
          {item?.userName}
        </Text> */}
      </Col>
      <Col backgroundColor="g0" padding="20px 30px 30px 30px" gap={10}>
        <Div>
          <Text type="b5" color="g400">
            직급
          </Text>
          <Text type="b3" color="g800">
            직급
          </Text>
        </Div>
      </Col>
    </Div>
  );
}
