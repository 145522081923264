import { stampApi } from 'api';
import {
  Col,
  Grid,
  HTMLWrap,
  Image,
  Text,
  Row,
  Button,
  Icon,
} from 'components';
import { useApiParams } from 'hooks';
import React from 'react';

export function StampList({
  windowSize,
  stamp,
  module,
  open,
  goDetail,
  reward,
}) {
  const { DEFAULT_PARAMS } = useApiParams();

  const itemClick = async (itemCode) => {
    const [info] = await stampApi.item(module.moduleCode, itemCode, {
      ...DEFAULT_PARAMS,
    });
    if (info && info.result.content) {
      goDetail(info.result);
    }
  };

  return (
    <>
      <HTMLWrap
        content={
          windowSize?.width < 1280
            ? module?.mobileContent || module?.content
            : module?.content
        }
      />
      <Grid
        mt={((module?.mobileContent || module?.content) && 20) || ''}
        cols={module?.maxColumns}
        colGap={20}
        rowGap={15}
        width="fit-content"
        margin="0 auto"
        mb={30}
      >
        {stamp?.list?.map((item) => (
          <Col
            alignItems="center"
            justifyContent="center"
            key={item.itemCode}
            onClick={() => {
              if (item.isStamped === 1) {
                itemClick(item.itemCode);
              }
            }}
          >
            <Image width={90} height={90} src={item.icon} objectFit="contain" />
            <Text mt={5} type="h9" textAlign="center">
              {item.name}
            </Text>
          </Col>
        ))}
      </Grid>
      <Row
        gap={10}
        justifyContent="center"
        maxWidth={360}
        margin="0 auto"
        mMaxWidth="unset"
        pb={30}
      >
        {module?.clearRewardGroupCode && (
          <Button
            buttonType="large"
            padding="14px 0"
            maxWidth={325}
            backgroundColor={
              (stamp?.isReceivedClearReward === 0 && 'keyColor') || 'g500'
            }
            color="keyFontColor"
            onClick={reward}
          >
            {(stamp?.isReceivedClearReward === 1 && '수령완료') ||
              '기념품 수령하기'}
          </Button>
        )}
        {stamp?.list.filter(
          (item) => item.isStamped === 1 && item.isRequired === 1,
        )?.length !==
          stamp?.list.filter((item) => item.isRequired === 1)?.length && (
          <Button
            buttonType="large"
            backgroundColor="keyFontColor"
            border={{ color: 'keyColor' }}
            color="keyColor"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={3}
            maxWidth={325}
            padding="14px 0"
            onClick={() => open('scanner')}
          >
            <Icon name="scanner" fill="keyColor" /> QR 스캔하기
          </Button>
        )}
      </Row>
    </>
  );
}
