import {
  Div,
  MypageBookmark,
  MypageNavigation,
  Row,
  Text,
  Icon,
  MypageReservation,
  MypageBoard,
  MypageComment,
  MypageInfo,
  MypageRegistration,
} from 'components';
import React from 'react';

function MypagePresenter({
  bucket,
  t,
  menus,
  user,
  languageNavigate,
  module,
  goMenu,
  menu,
  logList,
  getLog,
  goList,
}) {
  return (
    <Row
      alignItems="flex-start"
      margin="0 auto"
      maxWidth={1280}
      justifyContent="center"
      gap="min(6%, 80px)"
      mGap="0"
      mPadding="0"
    >
      <MypageNavigation
        languageNavigate={languageNavigate}
        user={user}
        menu={menu}
        menus={menus}
        module={module}
        goMenu={goMenu}
        position="sticky"
        mPosition="static"
        top={
          ((bucket?.header === 'fixed' &&
            (bucket?.headerOption.headerHeight || 60)) ||
            0) + 20
        }
        mTop="unset"
        mDisplay={(menu && 'none') || 'block'}
      />
      {menu && user.name && (
        <Div maxWidth={900} width="100%" position="relative">
          <Text type="h4" mDisplay="none">
            {menus.find((el) => el.menu === menu)?.label}
          </Text>
          <Row
            display="none"
            mDisplay={
              (menu !== 'info' &&
                menus?.filter((menuItem) => module?.[menuItem.option] === 1)
                  .length > 0 &&
                'flex') ||
              'none'
            }
            position="sticky"
            width="100vw"
            height={50}
            top={
              (bucket?.header === 'fixed' &&
                (bucket?.headerOption.headerHeight || 60)) ||
              0
            }
            left="0"
            backgroundColor="backgroundColor"
            zIndex={9999}
            padding="0 25px"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              name="arrow"
              fill="fontColor"
              size={24}
              transform="translateY(-50%) rotate(90deg)"
              onClick={goList}
              cursor="pointer"
              hover="background"
              position="absolute"
              left="25px"
              top="50%"
            />
            <Text type="h6">{menus.find((el) => el.menu === menu)?.label}</Text>
          </Row>
          <Div mPadding="0 25px">
            {menu === 'info' && (
              <MypageInfo
                bucket={bucket}
                module={module}
                menu={menu}
                goList={goList}
              />
            )}
            {menu === 'bookmark' && (
              <MypageBookmark module={module} list={logList} getLog={getLog} />
            )}
            {menu === 'reservation' && (
              <MypageReservation
                module={module}
                list={logList}
                getLog={getLog}
              />
            )}
            {menu === 'board' && (
              <MypageBoard module={module} list={logList} getLog={getLog} />
            )}
            {menu === 'comment' && (
              <MypageComment module={module} list={logList} getLog={getLog} />
            )}
            {menu === 'registration' && (
              <MypageRegistration
                module={module}
                list={logList}
                getLog={getLog}
              />
            )}
          </Div>
        </Div>
      )}
    </Row>
  );
}

export default MypagePresenter;
