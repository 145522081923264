import React, { useEffect } from 'react';
import { useIsImgLoaded } from 'hooks';
import { ImageStyles } from 'styles/ImageStyles';

export function ImageComponent({ src, loadingCallback, ...props }) {
  const { elementRef, loading } = useIsImgLoaded();

  useEffect(() => {
    if (loadingCallback) {
      loadingCallback(loading);
    }
  }, [src, loading]);

  return (
    <ImageStyles ref={elementRef} src={src} $loading={loading} {...props} />
  );
}
