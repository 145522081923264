import React, { useEffect } from 'react';
import { Div, Row, Col, Icon, Text } from 'components';

export function RegistrationSession({
  isSubmit,
  sessionList,
  targetSession,
  changeTargetSession,
  activeSession,
  ...props
}) {
  useEffect(() => {
    if (targetSession && targetSession.sessionCode && sessionList?.length > 1) {
      const targetElem = document.getElementById(
        `session-${targetSession?.sessionCode}`,
      );
      if (targetElem) {
        targetElem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [targetSession]);

  return (
    sessionList?.length > 1 && (
      <Col
        gap={80}
        width={320}
        minWidth={320}
        position="sticky"
        padding="0 35px"
        mPadding="0 0 10px 0"
        mFlexDirection="row"
        mGap="0"
        mWidth="100vw"
        mMl="calc(-50vw + 50%)"
        mMinWidth="unset"
        mAlignItems="center"
        mOverflowX="overlay"
        scrollTransparent
        zIndex={50}
        {...props}
      >
        {sessionList?.map((session, sessionIndex) => (
          <Row
            id={`session-${session?.sessionCode}`}
            key={`session-${session?.sessionCode}`}
            gap={20}
            alignItems="flex-start"
            cursor="pointer"
            onClick={() => changeTargetSession(session)}
            mFlexDirection="column"
            mAlignItems="center"
            mFlex={1}
            mPosition="relative"
            mMinWidth={78}
            mGap={10}
          >
            <Text
              display="none"
              mDisplay="block"
              visibility={
                (targetSession?.sessionCode === session?.sessionCode &&
                  'hidden') ||
                'visible'
              }
              mType={
                (targetSession?.sessionCode === session?.sessionCode && 'b3') ||
                'b5'
              }
              wordBreak="normal"
              mWordBreak="keep-all"
              mLineHeight="1.5"
              mMaxWidth={
                (targetSession?.sessionCode === session?.sessionCode &&
                  'unset') ||
                50
              }
              mTextAlign="center"
              mHeight="auto"
            >
              {session.name}
            </Text>
            <Row
              alignItems="center"
              justifyContent="center"
              width={32}
              height={32}
              mWidth={28}
              mHeight={28}
              mMinWidth={28}
              mMinHeight={28}
              borderRadius="999px"
              border={{
                width: '2px',
                color:
                  (isSubmit && 'keyColor') ||
                  (targetSession?.sessionCode === session?.sessionCode &&
                    'keyColor') ||
                  (activeSession?.[session?.sessionCode] === false && 'red') ||
                  (activeSession?.[session?.sessionCode] === true &&
                    'keyColor') ||
                  'g300',
              }}
              backgroundColor={
                (targetSession?.sessionCode === session?.sessionCode &&
                  'keyFontColor') ||
                (isSubmit && 'keyColor') ||
                (activeSession?.[session?.sessionCode] === false && 'red') ||
                (activeSession?.[session?.sessionCode] === true &&
                  'keyColor') ||
                ''
              }
              position="relative"
            >
              {(targetSession?.sessionCode === session?.sessionCode && (
                <Div
                  width={10}
                  height={10}
                  backgroundColor="keyColor"
                  borderRadius="999px"
                />
              )) ||
                (isSubmit && <Icon name="check" size={20} fill="white" />) ||
                (activeSession?.[session?.sessionCode] === false && (
                  <Icon name="fail" size={20} stroke="white" />
                )) ||
                (activeSession?.[session?.sessionCode] === true && (
                  <Icon name="check" size={20} fill="white" />
                )) || (
                  <Div
                    width={10}
                    height={10}
                    backgroundColor="g300"
                    borderRadius="999px"
                  />
                )}
              {sessionIndex !== sessionList?.length - 1 && (
                <Div
                  position="absolute"
                  width={2}
                  top="calc(100% + 2px)"
                  height={80}
                  mDisplay="none"
                  backgroundColor={
                    (isSubmit && 'keyColor') ||
                    (activeSession?.[session?.sessionCode] === false &&
                      'g300') ||
                    (activeSession?.[session?.sessionCode] === true &&
                      'keyColor') ||
                    'g300'
                  }
                ></Div>
              )}
            </Row>
            {sessionIndex !== 0 && (
              <Div
                display="none"
                position="absolute"
                mHeight={2}
                mWidth="calc(100% - 28px)"
                mTop="50%"
                mTransform="translateY(-50%)"
                mLeft="calc(-50% + 14px)"
                mDisplay="block"
                backgroundColor={
                  (isSubmit && 'keyColor') ||
                  (activeSession?.[session?.sessionCode] === false && 'g300') ||
                  (activeSession?.[session?.sessionCode] === true &&
                    'keyColor') ||
                  'g300'
                }
              ></Div>
            )}
            <Text
              type="h5"
              mType={
                (targetSession?.sessionCode === session?.sessionCode && 'b3') ||
                'b5'
              }
              wordBreak="normal"
              mWordBreak="keep-all"
              lineHeight="32px"
              mLineHeight="1.5"
              height={32}
              mMaxWidth={
                (targetSession?.sessionCode === session?.sessionCode &&
                  'unset') ||
                50
              }
              mTextAlign="center"
              mHeight="auto"
              mVisibility={
                (targetSession?.sessionCode === session?.sessionCode &&
                  'visible') ||
                'hidden'
              }
            >
              {session.name}
            </Text>
          </Row>
        ))}
      </Col>
    )
  );
}
