import { Row, Text, Div } from 'components';
import React, { useState } from 'react';

export function HeaderTypeA({
  menus,
  goMenu,
  goLinkType,
  menuCode,
  backgroundColor,
  fontColor,
  headerFont,
}) {
  const [currentHoverMenu, setCurrentHoverMenu] = useState();
  return (
    <Row alignItems="center" flex={1} height="100%">
      <Row alignItems="center" height="100%">
        <Row transition="all 0.3s ease-in-out" height="100%">
          {menus?.map((el, index) => (
            <Row
              key={el.name}
              padding="0px 20px"
              position="relative"
              alignItems="center"
              onClick={() => {
                if (el.type === 'page') goMenu(el.key);
                if (el.type === 'inLink') goLinkType('inLink', el);
                if (el.type === 'outLink') goLinkType('outLink', el);
              }}
              cursor="pointer"
              hover
              onMouseEnter={() => setCurrentHoverMenu(index)}
              onMouseLeave={() => setCurrentHoverMenu()}
            >
              <Text
                type={(menuCode === el.key && 'h8') || 'b2'}
                color={fontColor}
                minWidth={100}
                maxLine={2}
                fontSize={headerFont}
                textAlign="center"
                wordBreak="keep-all"
              >
                {el.name}
              </Text>
              <Div
                position="absolute"
                bottom="0"
                width={
                  ((menuCode === el.key ||
                    el.innerMenus
                      ?.map((innerEl) => innerEl.key)
                      .includes(menuCode)) &&
                    '100%') ||
                  '0'
                }
                height={2}
                left="0"
                backgroundColor="keyColor"
                transition="all 0.3s ease-in-out"
                transformOrigin="left"
              />
              {el.type === 'folder' && (
                <Div
                  key={`sub-${el.name}`}
                  position="absolute"
                  top="100%"
                  backgroundColor={backgroundColor}
                  onMouseEnter={() => setCurrentHoverMenu(currentHoverMenu)}
                  onMouseLeave={() => setCurrentHoverMenu()}
                  transform={
                    (currentHoverMenu === index &&
                      'scaleY(1) translateX(-50%)') ||
                    'scaleY(0)  translateX(-50%)'
                  }
                  left="50%"
                  transition="transform 0.3s ease-in-out"
                  transformOrigin="top center"
                  zIndex={9999}
                >
                  {el.innerMenus.map((subEl) => (
                    <Div
                      key={subEl.key}
                      padding="12px 20px"
                      onClick={() => {
                        if (subEl.type === 'page') goMenu(subEl.key);
                        if (subEl.type === 'inLink')
                          goLinkType('inLink', subEl);
                        if (subEl.type === 'outLink')
                          goLinkType('outLink', subEl);
                      }}
                      cursor="pointer"
                      hover
                    >
                      <Text
                        whiteSpace="nowrap"
                        textAlign="center"
                        color={
                          (subEl.key === menuCode && 'keyColor') || fontColor
                        }
                        fontSize={headerFont}
                      >
                        {subEl.name}
                      </Text>
                    </Div>
                  ))}
                </Div>
              )}
            </Row>
          ))}
        </Row>
      </Row>
    </Row>
  );
}
