import { Div, Row, Col, Icon, Text, ProfileImage, HTMLWrap } from 'components';
import { useBucketReset } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/isMobile';

export function MypageNavigation({
  languageNavigate,
  user,
  module,
  goMenu,
  menus,
  menu,
  ...props
}) {
  const { logout } = useBucketReset();
  const { t } = useTranslation();
  return (
    (menus?.filter((menuItem) => module?.[menuItem.option] === 1).length > 0 ||
      module?.isViewedProfile === 1) && (
      <Div
        width={300}
        mWidth="100%"
        boxShadow="default"
        mBoxShadow="unset"
        {...props}
      >
        <Div width="100%">
          {module?.isViewedProfile === 1 && (
            <Row
              padding="16px 25px 17px 28px"
              alignItems="center"
              justifyContent="space-between"
              cursor="pointer"
              onClick={() => goMenu('info')}
            >
              <Row gap={15} alignItems="center">
                <ProfileImage
                  size={47}
                  thumbnail={user.thumbnail || 'default'}
                />
                <Col gap={5}>
                  <Text type="h7">{user.name || t('login')}</Text>
                  {user.name && module?.isEnableEditingProfile === 1 && (
                    <Text color="g500" type="h9">
                      {t('mypage_info_editing')}
                    </Text>
                  )}
                </Col>
              </Row>
              {user.name && (
                <Icon
                  name="arrow"
                  fill="g500"
                  size={18}
                  cursor="pointer"
                  hover="background"
                  transform="rotate(-90deg)"
                />
              )}
            </Row>
          )}
          {menus.map(
            (menuItem) =>
              module?.[menuItem.option] === 1 && (
                <Div
                  padding="20px 24px"
                  borderBottom={
                    (menu !== menuItem.menu && { color: 'g200' }) || ''
                  }
                  cursor="pointer"
                  hover
                  onClick={() =>
                    menu !== menuItem.menu && goMenu(menuItem.menu)
                  }
                  position="relative"
                  key={menuItem.menu}
                >
                  <Div
                    position="absolute"
                    width={3}
                    height={(menu === menuItem.menu && '100%') || '0px'}
                    backgroundColor="keyColor"
                    top="0"
                    left="0"
                  />
                  <Text type={(menu === menuItem.menu && 'h7') || 'b1'}>
                    {menuItem.label}
                  </Text>
                </Div>
              ),
          )}
        </Div>
        {(module?.content && (
          <HTMLWrap
            content={
              isMobile() && module?.mobileContent
                ? module?.mobileContent
                : module?.content
            }
          />
        )) ||
          ''}
        {(user.name && module?.isViewedLogoutBtn === 1 && (
          <Row
            padding="20px 0"
            gap={5}
            alignItems="center"
            justifyContent="center"
            onClick={logout}
            cursor="pointer"
          >
            <Icon name="logout" stroke="g500" size={24} />
            <Text type="b2" color="g500">
              {t('logout')}
            </Text>
          </Row>
        )) ||
          ''}
      </Div>
    )
  );
}
