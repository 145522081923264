import { Div, Row, Icon, Text } from 'components';
import { useLanguageNavigate } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

export function LoginBox({ children }) {
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  const goHome = () => {
    if (!location.pathname.includes('changePassword')) {
      languageNavigate(`/`);
    } else {
      navigate(-1);
    }
  };

  return (
    <Div width="100%" maxWidth={402} mMargin="0 auto">
      <Row mb={20} gap={5} cursor="pointer" hover onClick={goHome}>
        <Icon
          name="arrow"
          fill="keyFontColor"
          transform="rotate(90deg)"
          size={24}
          borderRadius="999px"
          backgroundColor="key200"
        />
        <Text type="h7" color="keyFontColor">
          {!location.pathname.includes('changePassword')
            ? t('go_main')
            : t('go_back')}
        </Text>
      </Row>
      <Div
        width="100%"
        border={{ color: 'g200' }}
        borderRadius={12}
        padding="30px 40px"
        backgroundColor="rgba(255,255,255,0.7)"
        mPadding="40px 20px"
      >
        {children}
      </Div>
    </Div>
  );
}
