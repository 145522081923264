import { useEffect, useState } from 'react';
import { useBucketReset, useLanguageNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';

function useApiError() {
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const { logout } = useBucketReset();
  const [error, setError] = useState();
  const [alert, setAlert] = useState();

  const initToken = () => {
    setAlert('');
    logout();
  };

  // 450 : DB 접속 에러
  // 451 : 권한 관련 에러
  // 452 : 필수값 미전송 에러
  // 453 : 입력값의 오류 에러
  // 454 : 대상 데이터 조회 불가 에러
  // 455 : API 동작 실패 에러
  // 456 : 중복 관련 에러

  const alertConfirm = {
    451: { confirm: () => initToken() },
    457: {
      confirm: () => languageNavigate(`/login`),
      cancel: () => setAlert(''),
    },
  };

  useEffect(() => {
    if (error && error?.resCode !== 454) {
      const errorText =
        (error.resCode &&
          ((error.resCode === 4511 && t('api_error_4511')) ||
            (error.resCode === 451 && t('api_error_451')) ||
            (error.resCode === 457 && t('login_warning')) ||
            error.message)) ||
        t('api_error_other');

      const alertTemp = (alertConfirm[error.resCode] && {
        ...alertConfirm[error.resCode],
      }) || { confirm: () => setAlert('') };

      setAlert({
        title: t('alert_title'),
        content: errorText,
        ...alertTemp,
      });
    }
  }, [error]);

  return { alert, setError, setAlert };
}

export default useApiError;
