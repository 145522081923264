import { Div } from 'components';
import React from 'react';

function SchedulePresenter({ moduleCode }) {
  return (
    <Div backgroundColor="#1F260F" width="100%" padding="200px 50px">
      Schedule 영역 입니다.
      {moduleCode}
    </Div>
  );
}

export default SchedulePresenter;
