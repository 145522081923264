import { Col } from 'components';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
100%   {transform: rotate(360deg)}`;

const prixClipFix = keyframes`
    0%   {border-width:3px;clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

const loader10g = keyframes`
  0%{background-color: rgba(172,172,172, .2);}
  25%{background-color: rgba(172,172,172, 1);}
  50%{background-color: rgba(172,172,172, .2);}
  75%{background-color: rgba(172,172,172, .2);}
  100%{background-color: rgba(172,172,172, .2);}`;

const loader10m = keyframes`
    0%{background-color: rgba(172,172,172, .2);  }
    25%{background-color: rgba(172,172,172, .2);  }
    50%{background-color: rgba(172,172,172, 1);  }
    75%{background-color: rgba(172,172,172, .2);  }
    100%{background-color: rgba(172,172,172, .2);  }
  `;

const loader10d = keyframes`
  0%{background-color: rgba(172,172,172, .2);}
    25%{background-color: rgba(172,172,172, .2);}
    50%{background-color: rgba(172,172,172, .2);}
    75%{background-color: rgba(172,172,172, 1);}
    100%{background-color: rgba(172,172,172, .2);}`;

export const LoadingStyles = styled(Col)`
  top: 0;
  left: 0;
  content: ' ';

  ${({ type, theme, delay, keyColor }) =>
    (type === 'inside' &&
      css`
        & > .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 12px;
          height: 12px;
          border-radius: 12px;
          -webkit-animation: ${loader10m} 1.5s ease-in-out infinite;
          animation: ${loader10m} 1.5s ease-in-out infinite;

          &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: -25px;
            height: 12px;
            width: 12px;
            border-radius: 12px;
            -webkit-animation: ${loader10g} 1.5s ease-in-out infinite;
            animation: ${loader10g} 1.5s ease-in-out infinite;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 25px;
            height: 12px;
            width: 12px;
            border-radius: 12px;
            -webkit-animation: ${loader10d} 1.5s ease-in-out infinite;
            animation: ${loader10d} 1.5s ease-in-out infinite;
          }
        }
      `) ||
    css`
      & > .loader {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        animation: ${spin} 1s linear infinite;
        animation-delay: ${delay || 2}s;
      }

      & > .loader::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 0px solid ${keyColor || theme.colors.keyColor};
        animation: ${prixClipFix} 2s linear infinite;
        animation-delay: ${delay || 2}s;
      }
    `}
`;
