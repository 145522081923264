export const queryStringCleansing = (filters) => {
  let result = '';

  if (filters) {
    const cleansing = {};
    const keys = Object.keys(filters);
    keys.forEach((key) => {
      if (filters[key]) {
        cleansing[key] = filters[key];
      }
    });
    const keys2 = Object.keys(cleansing);
    result = (keys2.length > 0 && '?') || '';
    keys2.forEach((key) => {
      result += `${key}=${cleansing[key]}&`;
    });
    result.slice(0, -1);
  }

  return result;
};
export const objectParamsCleansing = (params) => {
  const cleansing = {};

  if (params) {
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (params[key] || params[key] === 0) {
        cleansing[key] = params[key];
      }
    });
  }

  return cleansing;
};

export const objectArrayParamsCleansing = (params) => {
  const cleansing = {};

  if (params) {
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (params[key][0] || params[key][0] === 0) {
        cleansing[key] = params[key];
      }
    });
  }

  return cleansing;
};

export const itemType = (value) => {
  let result = '';

  if (
    typeof value === 'object' &&
    value?.constructor.toString().indexOf('Array') === -1 &&
    value !== null
  ) {
    result = 'object';
  }

  if (Array.isArray(value)) {
    result = 'array';
  }

  if (value === null || typeof value !== 'object') {
    result = 'string';
  }

  return result;
};

export const modifiedValueCheck = (originValues, newValues) => {
  let modifiedObj = {};
  Object.keys(originValues).forEach((originKey) => {
    if (
      itemType(originValues[originKey][0]) === 'string' &&
      newValues[originKey][0] !== originValues[originKey][0]
    ) {
      modifiedObj = { ...modifiedObj, [originKey]: newValues[originKey] };
    } else if (
      originValues[originKey].length > 0 &&
      JSON.stringify(newValues[originKey]) !==
        JSON.stringify(originValues[originKey])
    ) {
      modifiedObj = { ...modifiedObj, [originKey]: newValues[originKey] };
    } else if (
      JSON.stringify(newValues[originKey][0]) !==
      JSON.stringify(originValues[originKey][0])
    ) {
      modifiedObj = { ...modifiedObj, [originKey]: newValues[originKey] };
    }
  });

  return modifiedObj;
};
export const modifiedCheck = (originValues, newValues) => {
  let modifiedObj = {};
  Object.keys(originValues).forEach((originKey) => {
    if (newValues[originKey] !== originValues[originKey]) {
      modifiedObj = { ...modifiedObj, [originKey]: newValues[originKey] };
    }
  });

  return modifiedObj;
};
