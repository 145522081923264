import React from 'react';
import { TagInputStyles } from 'styles/InputStyles';
import { Icon, Row } from 'components';
import { useTranslation } from 'react-i18next';

export function TagInput({
  setRef,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  name,
  wrapStyles,
  placeholder,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const onKeyDownHandler = (event) => {
    if (onKeyDown && onKeyDownActive) {
      if (event.key === 'Enter') {
        onKeyDown();
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    onChange(value, name);
  };

  return (
    <Row
      alignItems="center"
      gap={2}
      backgroundColor="g100"
      padding="6px 8px 6px 4px"
      borderRadius="6px"
      cursor="pointer"
      hover
      title={t('add_tag_hint')}
      {...wrapStyles}
    >
      <Icon fill="g500" name="tag-add" size={21} />
      <TagInputStyles
        ref={setRef}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        onChange={onChangeHandler}
        onKeyPress={onKeyDownHandler}
        width={
          i18n.language === 'ko'
            ? `${placeholder.length * 1.3}rem`
            : `${placeholder.length}rem`
        }
        {...props}
      />
    </Row>
  );
}
