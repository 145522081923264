import { Div, Icon, Row, Text } from 'components';
import React from 'react';

export function Progressbar({ value, max, setProgress }) {
  return (
    <Div width="100%" maxWidth={262} margin="0 auto 60px auto">
      <Text textAlign="right" type="b4" mb={5}>
        {value}/{max}
      </Text>
      <Row gap={10}>
        <Icon
          size={22}
          backgroundColor={(value > 0 && 'keyColor') || 'key300'}
          name="arrow"
          fill="keyFontColor"
          borderRadius="50%"
          transform="rotate(90deg)"
          cursor={(value > 0 && 'pointer') || 'default'}
          onClick={() => {
            if (value > 0) setProgress({ max, value: value - 1 });
          }}
        />
        <Div
          backgroundColor="g200"
          borderRadius="999px"
          width="100%"
          maxWidth={230}
          position="relative"
        >
          <Div
            position="absolute"
            left="0"
            top="0"
            width={`${Math.round((value / max) * 100)}%`}
            height="100%"
            backgroundColor="keyColor"
            borderRadius="999px"
            transition="width 500ms cubic-bezier(0.550, 0.055, 0.675, 0.190)"
          ></Div>
        </Div>
      </Row>
    </Div>
  );
}
