import { HeaderPopup, HeaderProfileImage, Icon, Text, Row } from 'components';
import { useLanguageNavigate, useBucketReset } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

const headerLanguage = (
  user,
  profileMenus,
  bucket,
  backgroundColor,
  fontColor,
  headerHeight,
) => ({
  1: (
    <HeaderPopup
      type="1"
      name="account"
      backgroundColor={backgroundColor}
      headerHeight={headerHeight}
      label={
        <Row gap={10} alignItems="center">
          <HeaderProfileImage
            type="1"
            width="32px"
            height="32px"
            thumbnail={user?.thumbnail}
          />
          <Text>{user?.name}</Text>
        </Row>
      }
      items={profileMenus.map((profileMenu) => (
        <Row
          key={profileMenu.route}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            profileMenu.onClick();
          }}
          padding="10px 0"
          gap={10}
          alignItems="center"
          hover
          cursor="pointer"
        >
          <Icon size={21} fill={fontColor} name={profileMenu.icon} />
          <Text color={fontColor}>{profileMenu.label}</Text>
        </Row>
      ))}
    >
      <HeaderProfileImage
        cursor="pointer"
        width="40px"
        height="40px"
        size={40}
        thumbnail={user.thumbnail}
      />
    </HeaderPopup>
  ),
  2: (
    <HeaderPopup
      type="2"
      name="account"
      backgroundColor={backgroundColor}
      headerHeight={headerHeight}
      label={
        <Row gap={10} alignItems="center" width={192} padding="0 10px">
          <HeaderProfileImage
            type="1"
            width="32px"
            height="32px"
            size={26}
            thumbnail={user?.thumbnail}
          />
          <Text>{user?.name}</Text>
        </Row>
      }
      items={profileMenus.map((profileMenu) => (
        <Row
          key={profileMenu.route}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            profileMenu.onClick();
          }}
          padding="10px"
          gap={10}
          alignItems="center"
          hover="background"
          cursor="pointer"
        >
          <Icon
            size={21}
            name={profileMenu.icon}
            fill={profileMenu.icon === 'users' ? fontColor : ''}
            stroke={profileMenu.icon === 'logout' ? fontColor : ''}
          />
          <Text color={fontColor} flex={1}>
            {profileMenu.label}
          </Text>
          {profileMenu.icon === 'users' && (
            <Icon
              size={21}
              fill={fontColor}
              name="arrow"
              transform="rotate(-90deg)"
            />
          )}
        </Row>
      ))}
    >
      <HeaderProfileImage
        cursor="pointer"
        width="36px"
        height="36px"
        size={32}
        thumbnail={user.thumbnail}
      />
    </HeaderPopup>
  ),
});

export function HeaderProfile({
  type,
  user,
  bucket,
  backgroundColor,
  fontColor,
  headerHeight,
}) {
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const { logout } = useBucketReset();
  const profileMenus =
    user?.accountType !== 'anonymous'
      ? bucket?.enableLogin === 1 && bucket?.myPageInfo
        ? [
            {
              route: 'mypage',
              label: t('mypage'),
              icon: 'users',
              onClick: () => languageNavigate('/mypage'),
            },
            {
              route: 'logout',
              label: t('logout'),
              icon: 'logout',
              onClick: logout,
            },
          ]
        : [
            {
              route: 'logout',
              label: t('logout'),
              icon: 'logout',
              onClick: logout,
            },
          ]
      : [];
  // user?.accountType !== 'anonymous'
  return headerLanguage(
    user,
    profileMenus,
    bucket,
    backgroundColor,
    fontColor,
    headerHeight,
  )[type];
}
