import { authApi } from 'api';
import { Row, Div, Input, CountryCode, Button } from 'components';
import { useApiParams, useLanguageNavigate } from 'hooks';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { encrypt } from 'utils/encrypt';
import { DEFAULT_INVALID_KEYS } from 'utils/invalids';
import { makePhone } from 'utils/makePhone';

export function LoginPassword({
  loginMethod,
  loginToMain,
  setAlert,
  isUsedForgotPWBtn,
  isUsedSignUpBtn,
  ...props
}) {
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const { TOKEN_PARAMS } = useApiParams();
  const [values, setValues] = useState({
    [loginMethod]: '',
    password: '',
  });
  const [invalids, setInvalids] = useState({
    [loginMethod]: '',
    password: '',
  });

  const onChange = (value, name) => {
    setValues({
      ...values,
      [name]: value,
    });
    setInvalids({
      ...invalids,
      [name]: '',
    });
  };

  const active = useMemo(() => {
    if (
      !values[loginMethod] ||
      !values[loginMethod]?.match(DEFAULT_INVALID_KEYS[loginMethod])
    )
      return false;
    if (!values.password) return false;

    return true;
  }, [values]);

  const [loading, setLoading] = useState(false);
  const login = async () => {
    // 코드 인증
    if (active && !loading) {
      setLoading(true);
      const loginValue =
        (loginMethod === 'phone' &&
          `${values.phoneCountyCode} ${makePhone(values.phone)}`) ||
        values[loginMethod];
      const [info, error] = await authApi.login({
        ...TOKEN_PARAMS,
        password: encrypt(values.password),
        [loginMethod]: loginValue,
      });
      if (info) {
        const { result } = info;
        loginToMain(result.token, result.refreshCode);
      } else if (error?.resCode === 451) {
        setAlert({
          title: t('alert_title'),
          content: t('account_alert'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      } else if (error?.resCode === 4513) {
        setAlert({
          title: t('alert_title'),
          content: t('fail_pre_registration_account'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      } else if (error?.resCode === 461) {
        setInvalids({
          ...invalids,
          password: { type: 'error', text: t('invalid_password') },
        });
        setLoading(false);
      } else if (error) {
        // 451 : 인증코드 틀림
        // setInvalids({ type: 'error', text: t('invalid_code') });
        setLoading(false);
      }
    }
  };

  const goSignup = () => {
    languageNavigate(`/signup`);
  };

  const goFindpassword = () => {
    languageNavigate(`/findpassword`);
  };

  return (
    <Div>
      {loginMethod === 'phone' && (
        <CountryCode name="phoneCountyCode" onChange={onChange} />
      )}
      <Input
        type={(loginMethod === 'phone' && 'tel') || ''}
        label={(loginMethod === 'email' && t(loginMethod)) || ''}
        placeholder={t(`${loginMethod}_placeholder`)}
        onChange={onChange}
        name={loginMethod}
        value={(values && values[loginMethod]) || ''}
        disabled={loading}
        invalid={invalids[loginMethod]}
        onKeyDown={login}
        onKeyDownActive={active}
      />
      <Input
        type="password"
        label={t('password')}
        placeholder={t('password_placeholder')}
        wrapStyles={{ mt: 10 }}
        name="password"
        onChange={onChange}
        value={values.password}
        invalid={invalids?.password}
        disabled={loading}
        onKeyDown={login}
        onKeyDownActive={active}
      />
      <Button
        buttonType="large"
        active={active}
        onClick={login}
        mt={20}
        display="block"
        margin="0 auto"
      >
        {t('login')}
      </Button>
      <Row mt={20} gap={20} alignItems="center" justifyContent="center">
        {isUsedForgotPWBtn === 1 && (
          <Button
            buttonType="text"
            padding="3px"
            type="b1"
            color="g800"
            onClick={goFindpassword}
          >
            {t('find_password')}
          </Button>
        )}
        {isUsedSignUpBtn === 1 && (
          <Button
            buttonType="text"
            padding="3px"
            type="b1"
            color="g800"
            onClick={goSignup}
          >
            {t('signup')}
          </Button>
        )}
      </Row>
    </Div>
  );
}
