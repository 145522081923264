import React, { useState } from 'react';
import { InputImageStyles } from 'styles/InputStyles';
import { Div, Icon, Text, Invalid, Loading, Span, Alert } from 'components';
import { useCookieUtil } from 'hooks';
import { API_KEY, UUID, BUCKET_VERSION } from 'data/keyFile';
import { BUCKET_CODE, moduleApi } from 'api';
import heic2any from 'heic2any';
import { useTranslation } from 'react-i18next';

export function InputImageFile({
  setRef,
  label,
  moduleCode,
  placeholder,
  onChange,
  onKeyDown,
  onKeyDownActive,
  value,
  length,
  name,
  disabled,
  rightChildren,
  position,
  wrapStyles,
  invalid,
  fileUpload,
  accept,
  ...props
}) {
  const { t } = useTranslation();
  const { getCookie } = useCookieUtil();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();

  const convertToJPEG = async (file) => {
    try {
      const result = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      });

      return result;
    } catch (error) {
      setAlert({
        title: t('convert_image'),
        content: t('convert_image_msg'),
        confirm: () => {
          setAlert();
        },
      });

      return null;
    }
  };

  const onChangeHandler = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setAlert({
          title: t('format_image'),
          content: t('format_image_msg'),
          confirm: () => {
            setAlert();
          },
        });
        return;
      }

      const fileType = file.type.split('/')[1];
      const fileName = file.name
        .substring(0, file.name.lastIndexOf('.'))
        .replace(/[^a-zA-Z0-9]/g, '');

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const convertedBlob = await convertToJPEG(file);
        file = new File([convertedBlob], `${fileName}.jpg`, {
          type: 'image/jpeg',
        });
      } else {
        file = new File([file], `${fileName}.${fileType}`, { type: file.type });
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        if (!loading) {
          setLoading(true);
          const fd = new FormData();
          fd.append('file', file);
          fd.append('apiKey', getCookie(API_KEY));
          fd.append('uuid', getCookie(UUID));
          fd.append('bucketVersion', getCookie(BUCKET_VERSION));
          if (process.env.NODE_ENV !== 'production') {
            fd.append('bucketCode', BUCKET_CODE);
          }

          const [info] = await moduleApi.fileUpload(moduleCode, fd);
          if (info) {
            onChange(info.result, name);
            setLoading(false);
            event.target.value = '';
          } else {
            setLoading(false);
            event.target.value = '';
          }
        }
      };

      if (file) reader.readAsDataURL(file);
    }
  };

  return (
    <Div {...wrapStyles}>
      {label && (
        <Text type="b3" mb={4}>
          {label}
        </Text>
      )}
      <Div position={position || 'relative'}>
        <InputImageStyles
          ref={setRef}
          htmlFor={name}
          value={value}
          disabled={disabled}
          gap={6}
          readOnly
          cursor="pointer"
          invalid={invalid?.type}
          {...props}
        >
          <input
            id={name}
            type="file"
            accept={accept}
            readOnly
            name={name}
            onChange={onChangeHandler}
            style={{ display: 'none' }}
            disabled={disabled}
          />
          {loading && <Loading position="absolute" type="inside" />}
          <Icon fill="g500" name="tag-add" size={32} />
          <Text
            type="b3"
            color="g500"
            gap={3}
            display="flex"
            padding="8px 14px"
            textAlign="center"
          >
            <Span color="keyColor" type="b3">
              {length || 0}
            </Span>
            /3
          </Text>
        </InputImageStyles>
      </Div>
      <Invalid invalid={invalid} />
      {alert && <Alert {...alert} />}
    </Div>
  );
}
