import { moduleApi } from 'api';
import {
  Alert,
  Button,
  Div,
  Image,
  Loading,
  Row,
  Text,
  Toast,
} from 'components';
import { useApiParams } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MypageBoard({ list, getLog }) {
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();

  const makeTextContent = (content) => {
    if (content?.includes('<') && content?.includes('</')) {
      const tempDocument = document.createElement('div');
      tempDocument.style.display = 'none';
      tempDocument.innerHTML = content;
      return tempDocument.textContent;
    } else {
      return content;
    }
  };

  const makeImageContent = (content) => {
    const tempDocument = document.createElement('div');
    tempDocument.style.display = 'none';
    tempDocument.innerHTML = content;
    const thumbnail = tempDocument.getElementsByTagName('img');
    if (thumbnail[0]) {
      return thumbnail[0].src;
    }
    return false;
  };

  const [alert, setAlert] = useState();
  const deleting = (item) => {
    setAlert({
      title: t('alert_title'),
      content: t('delete_warning'),
      confirm: () => deleteItem(item),
      cancel: () => setAlert(),
    });
  };

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState('');
  const deleteItem = async (item) => {
    if (!loading) {
      setAlert();
      setLoading(true);
      const [info] = await moduleApi.deleteModuleItem(
        item.moduleCode,
        item.itemCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setLoading(false);
        getLog();
        setToast('삭제가 완료되었습니다.');
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {list?.map((item) => (
        <Row
          width="min(64%, 568px)"
          mWidth="100%"
          key={item.itemCode}
          alignItems="center"
          margin="0 auto"
          padding="20px 0"
          gap={10}
          borderBottom={{ color: 'g200' }}
          justifyContent="space-between"
        >
          <Row alignItems="center" gap={10}>
            <Div flex={1}>
              <Text type="h7" maxLine={1}>
                {item.title || item.name}
              </Text>
              <Text color="g500" type="b3" maxLine={2}>
                {makeTextContent(item.content)}
              </Text>
            </Div>
            {(item.thumbnail || makeImageContent(item.content)) && (
              <Image
                borderRadius="5px"
                src={item.thumbnail || makeImageContent(item.content)}
                width={68}
                height={68}
                objectFit="cover"
              />
            )}
          </Row>
          <Row gap={10}>
            {/* <Button buttonType="text" color="g800">
              {t('edit')}
            </Button> */}
            <Button
              buttonType="text"
              color="g800"
              onClick={() => deleting(item)}
              type="b2"
            >
              {t('delete')}
            </Button>
          </Row>
        </Row>
      ))}
      {list?.length === 0 && (
        <Row
          width="100%"
          height={300}
          alignItems="center"
          justifyContent="center"
        >
          <Text color="g600" type="b1">
            {t('mypage_board_empty')}
          </Text>{' '}
        </Row>
      )}

      {alert && <Alert {...alert} />}
      {loading && <Loading position="absolute" />}
      {toast && <Toast content={toast} />}
    </>
  );
}
