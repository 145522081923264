import { Input, CountryCode } from 'components';
import React, { useState, useEffect } from 'react';
import { makePhone } from 'utils/makePhone';

export function Phone({ useCountryCode, name, onChange, initValue, disabled }) {
  const [init, setInit] = useState(false);
  const [values, setValues] = useState({
    phoneCountyCode: (!useCountryCode && '82') || '',
    phone: '',
  });

  const onChangeHandler = (value, name) => {
    if (name !== 'phoneCountyCode') {
      setInit(true);
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (values.phone && values.phoneCountyCode && init) {
      onChange(
        { countryCode: values.phoneCountyCode, phone: makePhone(values.phone) },
        name,
      );
    } else if (init) {
      onChange('', name);
    }
  }, [values, init]);

  useEffect(() => {
    if (initValue) {
      setValues({
        phoneCountyCode: (!useCountryCode && '82') || initValue.countryCode,
        phone: initValue.phone,
      });
    }
  }, [initValue]);

  return (
    <>
      {useCountryCode && (
        <CountryCode
          name="phoneCountyCode"
          onChange={onChangeHandler}
          disabled={disabled}
          initValue={initValue}
        />
      )}
      <Input
        type="tel"
        value={values.phone || ''}
        name={name}
        onChange={(val) => onChangeHandler(val, 'phone')}
        disabled={disabled}
      />
    </>
  );
}
