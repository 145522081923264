import { HeaderPopup, Icon, Text, Row } from 'components';
import { LANGUAGE } from 'data/keyFile';
import { useLanguageNavigate } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

const headerLanguage = (
  bucket,
  changeLanguage,
  currentLanguage,
  backgroundColor,
  fontColor,
  headerHeight,
  t,
) => ({
  1: (
    <HeaderPopup
      type="1"
      name="language"
      backgroundColor={backgroundColor}
      label={<Text>{t('select_language')}</Text>}
      headerHeight={headerHeight}
      items={bucket?.languages.map((language) => (
        <Row
          key={language.key}
          onClick={() => {
            changeLanguage(language.key);
          }}
          padding="10px 0"
          alignItems="center"
          justifyContent="space-between"
          hover
          cursor="pointer"
        >
          <Text color={bucket.headerOption.headerFontColor}>
            {language.label}
          </Text>
          <Icon
            size={(currentLanguage === language.key && 21) || 0}
            name="check"
            fill="keyColor"
            transition="all 0.3s ease-in-out"
          />
        </Row>
      ))}
    >
      <Icon
        name="translateA"
        cursor="pointer"
        size="40px"
        hover="circle"
        borderRadius="50%"
        fill={fontColor}
        transition="all 150ms"
      />
    </HeaderPopup>
  ),
  2: (
    <HeaderPopup
      type="2"
      name="language"
      backgroundColor={backgroundColor}
      headerHeight={headerHeight}
      items={bucket?.languages.map((language) => (
        <Row
          key={language.key}
          onClick={() => {
            changeLanguage(language.key);
          }}
          padding="10px"
          alignItems="center"
          justifyContent="space-between"
          hover="background"
          cursor="pointer"
          width={150}
        >
          <Text color={bucket.headerOption.headerFontColor}>
            {language.label}
          </Text>
          <Icon
            size={(currentLanguage === language.key && 21) || 0}
            name="check"
            fill="keyColor"
            transition="all 0.3s ease-in-out"
          />
        </Row>
      ))}
    >
      <Icon
        name="translateB"
        cursor="pointer"
        size="36px"
        hover="circle"
        borderRadius="50%"
        stroke={fontColor}
        transition="all 150ms"
      />
    </HeaderPopup>
  ),
});

export function HeaderLanguage({
  type,
  bucket,
  backgroundColor,
  fontColor,
  headerHeight,
}) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const disabledHeaderPath = ['login', 'profile'];
  const accoutPath = disabledHeaderPath.includes(
    location.pathname.split('/')[1],
  );
  const { languageNavigate, currentLanguage } = useLanguageNavigate();

  const [, setSearchParams] = useSearchParams();
  const changeLanguage = (lang) => {
    if (accoutPath) {
      setSearchParams({ [LANGUAGE]: lang });
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage(lang);
      languageNavigate(`/${bucket.homeMenu[lang].key}`, lang);
    }
  };

  return headerLanguage(
    bucket,
    changeLanguage,
    currentLanguage,
    backgroundColor,
    fontColor,
    headerHeight,
    t,
  )[type];
}
