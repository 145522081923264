import React from 'react';
import { Div } from 'components';

function TypeB({
  open,
  label,
  setOpen,
  items,
  backgroundColor,
  name,
  headerHeight,
  ...props
}) {
  return (
    <Div
      backgroundColor={backgroundColor || 'g0'}
      padding="20px 10px"
      boxShadow="default"
      borderRadius="0.6rem"
      position="absolute"
      top={
        headerHeight
          ? `calc(100% + ${(headerHeight - 36) / 2}px)`
          : 'calc(100% + 12px)'
      }
      zIndex={10}
      right="0"
      transform={(open && 'scale(1)') || 'scale(0)'}
      transition="all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550)"
      transformOrigin="top right"
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(false);
      }}
      mPosition={(name !== 'account' && 'fixed') || ''}
      mTransformOrigin="top"
      mTop={(name !== 'account' && `${headerHeight || 60}px`) || '100%'}
      mRight={(name !== 'account' && '20px') || '0'}
      mMaxWidth="calc(100vw - 40px)"
      {...props}
    >
      {label}
      {label && !!items.length && (
        <Div height="1" width="100%" backgroundColor="g200" margin="16px 0" />
      )}
      {items}
    </Div>
  );
}

export default TypeB;
