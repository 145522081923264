import { HeaderProfileImage, Icon, Text, Row } from 'components';
import { useLanguageNavigate } from 'hooks';
import React from 'react';

export function HeaderProfileMobile({ t, user, bucket, fontColor, setOpen }) {
  const { languageNavigate } = useLanguageNavigate();

  return (
    bucket?.enableLogin === 1 && (
      <Row
        gap={10}
        alignItems="center"
        padding="20px 10px"
        justifyContent="space-between"
        width="100%"
      >
        <Row gap={10} alignItems="center">
          <HeaderProfileImage
            type="1"
            width="32px"
            height="32px"
            size={26}
            thumbnail={user?.thumbnail}
          />
          <Text>{user?.name}</Text>
        </Row>
        {(bucket?.enableMyPage === 1 && bucket?.myPageInfo && (
          <Row
            onClick={(e) => {
              setOpen(false);
              languageNavigate('/mypage');
            }}
            gap={10}
            alignItems="center"
            hover="background"
            cursor="pointer"
          >
            <Icon size={21} name="users" fill={fontColor} />
            <Text color={fontColor} flex={1}>
              {t('mypage')}
            </Text>
            <Icon
              size={21}
              fill={fontColor}
              name="arrow"
              transform="rotate(-90deg)"
            />
          </Row>
        )) ||
          ''}
      </Row>
    )
  );
}
