import React from 'react';
import { Div, Icon, Span, Text } from 'components';
import styled from 'styled-components';

export function Badge({ children, ...props }) {
  return (
    <Span
      padding="0.2rem 0.6rem"
      backgroundColor="g100"
      whiteSpace="nowrap"
      borderRadius="1.0rem"
      {...props}
    >
      {children}
    </Span>
  );
}

export function CategoryBadge({ children, ...props }) {
  return (
    <Span
      padding="0.5rem 1.0rem"
      backgroundColor="rgba(255,255,255,0.9)"
      whiteSpace="nowrap"
      borderRadius="40px"
      type="h10"
      border={{ color: 'keyColor' }}
      color="keyColor"
      {...props}
    >
      {children}
    </Span>
  );
}

const Background = styled(Div)`
  overflow: hidden;
  &:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor ? theme.colors[backgroundColor] : 'unset'};
    z-index: 0;
  }
  & > p {
    z-index: 1;
  }
`;

export function LabelBadge({
  position,
  zIndex,
  backgroundColor,
  children,
  type,
  color,
  ...props
}) {
  return (
    <Background
      position={position || 'relative'}
      padding="0rem 1rem"
      whiteSpace="nowrap"
      borderRadius="0.3rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={20}
      zIndex={zIndex || 2}
      backgroundColor={backgroundColor}
      {...props}
    >
      <Text type={type} color={color}>
        {children}
      </Text>
    </Background>
  );
}

export function TagBadge({ children, ...props }) {
  return (
    <Div
      display="flex"
      alignItems="center"
      gap={2}
      backgroundColor="g100"
      padding="6px 4px 6px 8px"
      borderRadius="6px"
      cursor="pointer"
      hover
      {...props}
    >
      <Text type="b2" color="g500" whiteSpace="nowrap">
        {children}
      </Text>
      <Icon name="delete" size={21} minWidth={21} />
    </Div>
  );
}
