import { BubbleSkeleton, ChatInput, Col, CommentInput, Div } from 'components';
import React, { Suspense, lazy } from 'react';

const renderLoader = () => (
  <Col gap={30} padding="20px 20px 0 20px" height="100%">
    <BubbleSkeleton />
    <BubbleSkeleton />
    <BubbleSkeleton />
    <BubbleSkeleton />
    <BubbleSkeleton />
  </Col>
);

const ChatList = lazy(() => import('components/Modules/Chat/ChatList'));

function ChatPresenter({
  scrollRef,
  scrollHandler,
  lastRef,
  module,
  filters,
  list,
  sendMessage,
  inputFocusHandler,
  showBottomScrollButton,
  scrollBottom,
}) {
  return (
    <Col position="relative" width="100%" height="100%">
      <Suspense fallback={renderLoader()}>
        <ChatList
          scrollRef={scrollRef}
          scrollHandler={scrollHandler}
          list={list}
          module={module}
          filters={filters}
          lastRef={lastRef}
          scrollBottom={scrollBottom}
          showBottomScrollButton={showBottomScrollButton}
        />
      </Suspense>
      <Div
        width="100%"
        padding="20px"
        borderTop={(module?.designType === 'type2' && { color: 'g200' }) || ''}
      >
        {module?.designType === 'design1' && (
          <CommentInput
            send={sendMessage}
            inputFocusHandler={inputFocusHandler}
          />
        )}
        {module?.designType === 'design2' && (
          <ChatInput send={sendMessage} inputFocusHandler={inputFocusHandler} />
        )}
      </Div>
    </Col>
  );
}

export default ChatPresenter;
