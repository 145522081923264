import { Col, Div, HTMLWrap, Row } from 'components';
import { useWindowSize } from 'hooks';
import React from 'react';

export function LoginTypeB({ info, header, children }) {
  const windowSize = useWindowSize();
  return (
    <Row
      padding="0"
      backgroundColor={info?.backgroundColor}
      backgroundImage={info?.backgroundImg}
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      height={windowSize.height}
      position="relative"
      mDisplay="block"
      mHeight="auto"
      mPadding="60px 0 0 0"
      mMinHeight={windowSize.height}
    >
      {header}
      <Col
        width="100%"
        height="100vh"
        overflowY="hidden"
        alignItems="center"
        justifyContent="center"
        mHeight="auto"
      >
        <HTMLWrap
          width="100%"
          content={
            windowSize.width > 1279
              ? info?.content1
              : (info?.mobileContent1 && info?.mobileContent1) || info?.content1
          }
        />
      </Col>
      <Div width="100%" height="100%" position="relative">
        <Div
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
          mPosition="static"
          mTop="unset"
          mLeft="unset"
          mTransform="unset"
          width={402}
          mWidth="calc(100% - 40px)"
          mMaxWidth={402}
          mMargin="0 auto"
        >
          {children}
          <HTMLWrap
            mt={20}
            width="100%"
            maxHeight="20vh"
            mMaxHeight="auto"
            overflowY="hidden"
            content={
              windowSize.width > 1279
                ? info?.content2
                : (info?.mobileContent2 && info?.mobileContent2) ||
                  info?.content2
            }
            // content={info?.content2}
            // mContent={info?.mobileContent2}
          />
        </Div>
      </Div>
    </Row>
  );
}
