import { Div, Image } from 'components';
import { useWindowSize } from 'hooks';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { linkTypeClick } from 'utils/linkType';

function SlidePresenter({ module, list, loading }) {
  const windowSize = useWindowSize();

  return (
    <Div
      isFullWidth={module?.isFullWidth}
      opacity={(!loading && 1) || 0}
      transition="all 150ms ease-in"
    >
      {list && (
        <AliceCarousel
          autoWidth
          autoPlay
          autoPlayStrategy="none"
          autoPlayInterval={4000}
          animationDuration={1000}
          animationType="fadeout"
          infinite
          touchTracking={false}
          disableDotsControls
          disableButtonsControls={!module?.isViewedNavBtn}
          items={list.map((item, index) => (
            <Div
              key={`banner_${index}`}
              style={{
                width: `${
                  (windowSize.width < 1280 &&
                    Math.round(windowSize.width / module?.mobileMaxColumns)) ||
                  Math.round(
                    (module?.isFullWidth ? windowSize.width : 1280) /
                      module?.maxColumns,
                  )
                }px`,
                height: module?.heightOption,
              }}
            >
              <Image
                src={
                  windowSize.width > 1279
                    ? item.thumbnail
                    : item.mobileThumbnail || item.thumbnail
                }
                alt={item.name}
                width="calc(100% - 20px)"
                height={module?.heightOption}
                mHeight={module?.mobileHeightOption}
                objectFit={module?.objectFit || 'cover'}
                margin="0 10px"
                hover={item.linkType !== 'none' || ''}
                cursor={(item.linkType !== 'none' && 'pointer') || ''}
                onClick={() => {
                  if (item.linkType !== 'none') {
                    linkTypeClick(item.linkType, item.url);
                  }
                }}
              />
            </Div>
          ))}
        ></AliceCarousel>
      )}
    </Div>
  );
}

export default SlidePresenter;
