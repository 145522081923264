import { moduleApi } from 'api';
import { Alert, Button, Div, Loading, Row, Text, Toast } from 'components';
import { useApiParams } from 'hooks';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MypageComment({ list, getLog }) {
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();

  const [alert, setAlert] = useState();
  const deleting = (item) => {
    setAlert({
      title: t('alert_title'),
      content: t('delete_warning'),
      confirm: () => deleteItem(item),
      cancel: () => setAlert(),
    });
  };

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState('');
  const deleteItem = async (item) => {
    if (!loading) {
      setAlert();
      setLoading(true);
      const [info] = await moduleApi.moduleLogDelete(
        'comment',
        item.moduleCode,
        item.itemCode,
        item.commentCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setLoading(false);
        getLog();
        setToast('삭제가 완료되었습니다.');
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {list?.map((item) => (
        <Row
          width="min(64%, 568px)"
          mWidth="100%"
          key={item.commentCode}
          alignItems="center"
          margin="0 auto"
          padding="20px 0"
          gap={10}
          borderBottom={{ color: 'g200' }}
          justifyContent="space-between"
        >
          <Div flex={1}>
            <Text type="b1" maxLine={2}>
              {item.content}
            </Text>
            <Text color="g500" type="b2">
              {moment(item.createdDt).format('YYYY-MM-DD HH:mm')}
            </Text>
          </Div>
          <Row gap={10}>
            {/* <Button buttonType="text" color="g800">
              {t('edit')}
            </Button> */}
            <Button
              buttonType="text"
              color="g800"
              onClick={() => deleting(item)}
              type="b2"
            >
              {t('delete')}
            </Button>
          </Row>
        </Row>
      ))}
      {list?.length === 0 && (
        <Row
          width="100%"
          height={300}
          alignItems="center"
          justifyContent="center"
        >
          <Text color="g600" type="b1">
            {t('mypage_comment_empty')}
          </Text>{' '}
        </Row>
      )}

      {alert && <Alert {...alert} />}
      {loading && <Loading position="absolute" />}
      {toast && <Toast content={toast} />}
    </>
  );
}
