import { ScrollToTop, NotFoundPage } from 'components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NotFoundPageRoute, Main } from 'routes';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/*  <Route path="*" element={<NotFoundPageRoute />} />  */}
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
