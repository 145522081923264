import React, { useEffect, useState } from 'react';
import { setModule } from 'store/module';
import { useDispatch } from 'react-redux';
import SlidePresenter from './SlidePresenter';

function SlideContainer({ module }) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      const totalList = Array.isArray(module?.list) ? [...module.list] : [];
      setList(totalList);
      const images = [];
      totalList.forEach((item) => {
        const image = new Image();
        image.src = item.thumbnail;
        image.onload = () => {
          images.push(image);
          if (images.length === module.list?.length) setLoading(false);
        };
      });
    }

    return () => setLoading(true);
  }, [module]);

  return <SlidePresenter module={module} list={list} loading={loading} />;
}

export default SlideContainer;
