import {
  Icon,
  Badge,
  HTMLWrap,
  Row,
  Text,
  BadgeButton,
  Bookmark,
  LabelBadge,
  Col,
  Div,
  Image,
  CategoryBadge,
} from 'components';
import moment from 'moment';
import React, { useState } from 'react';
import fileDownloader from 'utils/fileDownloader';
import { isMobile } from 'utils/isMobile';
import { useWindowSize } from 'hooks';

export function EnumerationDetailEmptyCard({
  t,
  item,
  logging,
  designType,
  commentLength,
  type,
}) {
  const windowSize = useWindowSize();
  const [fileOpen, setFileOpen] = useState(false);
  return (
    item && (
      <>
        <Col gap={20}>
          {(item?.isViewedContentThumbnail && item?.thumbnail && (
            <Image
              maxWidth="100%"
              maxHeight="500px"
              objectFit="contain"
              src={item?.thumbnail}
            />
          )) ||
            ''}
          {((!isMobile() || type === 'modal') &&
            (item?.isViewedContentNewLabel ||
              item?.isViewedContentStatusLabel ||
              item?.isFixedTop === 1 ||
              (item?.isViewedContentCategory && item?.category) ||
              item?.isViewedContentBookmarkBtn) && (
              <Row
                gap={10}
                alignItems="center"
                justifyContent={
                  ((item?.isViewedContentNewLabel ||
                    item?.isViewedContentStatusLabel ||
                    (item?.isViewedContentCategory && item?.category) ||
                    item?.isFixedTop === 1) &&
                    'space-between') ||
                  'flex-end'
                }
              >
                {((item?.isViewedContentNewLabel ||
                  item?.isViewedContentStatusLabel ||
                  item?.isFixedTop === 1 ||
                  (item?.isViewedContentCategory && item?.category)) && (
                  <Row gap={10} alignItems="center">
                    {(item?.isFixedTop === 1 && (
                      <Icon name="pin" fill="keyColor" />
                    )) ||
                      ''}
                    {(item?.isViewedContentCategory && item?.category && (
                      <CategoryBadge backgroundColor="white">
                        {item?.category}
                      </CategoryBadge>
                    )) ||
                      ''}
                    {((item?.isViewedContentNewLabel ||
                      item?.isViewedContentStatusLabel) && (
                      <Row gap={5} alignItems="center">
                        {(item?.isViewedContentNewLabel && (
                          <LabelBadge
                            type="h10"
                            backgroundColor="white"
                            color="keyColor"
                            border={{ color: 'key500' }}
                          >
                            New
                          </LabelBadge>
                        )) ||
                          ''}
                        {(item?.isViewedContentStatusLabel && (
                          <LabelBadge
                            type="h10"
                            backgroundColor={
                              (item.status === 'completed' && 'done200') ||
                              'g100'
                            }
                            color={
                              (item.status === 'completed' && 'done') || 'g400'
                            }
                            padding="0 10px"
                          >
                            {item?.statusLabels[item?.status]}
                          </LabelBadge>
                        )) ||
                          ''}
                      </Row>
                    )) ||
                      ''}
                  </Row>
                )) ||
                  ''}
                {(item?.isViewedContentBookmarkBtn && (
                  <Bookmark
                    size={28}
                    isBookmark={item?.isBookmark}
                    backgroundColor={
                      (item?.isBookmark === 1 && 'key200') || 'g100'
                    }
                    fill={(item?.isBookmark === 1 && 'keyColor') || ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      logging('bookmark', item);
                    }}
                  />
                )) ||
                  ''}
              </Row>
            )) ||
            ''}
          {((item?.isViewedContentLabel ||
            item?.isViewedContentName ||
            (item.isViewedContentDescription &&
              !designType.includes('empty')) ||
            (item?.isViewedContentTags && item.tags?.length > 0) ||
            item?.isViewedContentCreatedDt ||
            item?.isViewedContentLikeBtn ||
            item?.isViewedContentClickCount ||
            item?.isViewedContentCommentBtn ||
            (item?.isViewedContentAttachmentFileBtn &&
              item?.attachmentFiles?.length > 0)) && (
            <Col gap={15}>
              {((item?.isViewedContentLabel ||
                item?.isViewedContentName ||
                (item.isViewedContentDescription &&
                  !designType.includes('empty'))) && (
                <Col gap={5}>
                  {(item?.isViewedContentLabel && (
                    <Text type="b2" color="g500">
                      {item?.label}
                    </Text>
                  )) ||
                    ''}
                  {(item?.isViewedContentName && (
                    <Text type="h5">{item?.name}</Text>
                  )) ||
                    ''}
                  {(item.isViewedContentDescription &&
                    !designType.includes('empty') && (
                      <Text type="b1" color="g500">
                        {item.description}
                      </Text>
                    )) ||
                    ''}
                </Col>
              )) ||
                ''}
              {(item?.isViewedContentTags && item.tags?.length > 0 && (
                <Text
                  color="g400"
                  type="b4"
                  display="flex"
                  gap={6}
                  flexWrap="wrap"
                >
                  {item.tags?.map((tag) => (
                    <Badge
                      key={`${tag}${Math.random()}`}
                      color="g400"
                      type="b4"
                    >
                      {tag}
                    </Badge>
                  ))}
                </Text>
              )) ||
                ''}
              <Row gap={10} alignItems="center">
                {(item?.isViewedContentCreatedDt && (
                  <Text color="g400" type="b2">
                    {moment(item.createdDt).format('YYYY-MM-DD HH:mm')}
                  </Text>
                )) ||
                  ''}
                {(item?.isViewedContentCreatedDt &&
                  (item?.isViewedContentLikeBtn ||
                    item?.isViewedContentClickCount ||
                    item?.isViewedContentCommentBtn) && (
                    <Div width={1} height={15} backgroundColor="g200" />
                  )) ||
                  ''}
                {((item?.isViewedContentLikeBtn ||
                  item?.isViewedContentClickCount ||
                  item?.isViewedContentCommentBtn) && (
                  <Row gap={5} alignItems="center">
                    {(item?.isViewedContentLikeBtn && (
                      <BadgeButton
                        name="like"
                        active={item?.isLike === 1}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          logging('like', item);
                        }}
                      >
                        {(item?.isViewedContentLikeCount &&
                          ((item?.likes > 100 && '100+') ||
                            item?.likes?.toString())) ||
                          ''}
                      </BadgeButton>
                    )) ||
                      ''}
                    {(item?.isViewedContentClickCount && (
                      <BadgeButton name="view" fill="g500" stroke="transparent">
                        {(item?.clicks > 100 && '100+') ||
                          item?.clicks?.toString() ||
                          ''}
                      </BadgeButton>
                    )) ||
                      ''}
                    {(item?.isViewedContentCommentBtn && (
                      <BadgeButton
                        name="comment"
                        point={item?.isNewComment === 1}
                      >
                        {(item?.isViewedContentCommentCount &&
                          ((Number(item?.comments) > 100 && '100+') ||
                            item?.comments?.toString())) ||
                          ''}
                      </BadgeButton>
                    )) ||
                      ''}
                  </Row>
                )) ||
                  ''}
              </Row>
            </Col>
          )) ||
            ''}
          {(item?.isViewedContentAttachmentFileBtn &&
            item?.attachmentFiles?.length > 0 && (
              <Row
                alignItems="flex-start"
                gap={20}
                mFlexDirection="column"
                mGap={15}
              >
                <Row
                  gap={5}
                  alignItems="center"
                  hover
                  cursor="pointer"
                  onClick={() => setFileOpen(!fileOpen)}
                >
                  <Icon
                    name="arrow"
                    fill="g500"
                    transform={(fileOpen && 'rotate(-180deg)') || 'rotate(0)'}
                    transition="all 150ms"
                  />
                  <Text type="b2" mType="b3">
                    {t('file_count', {
                      count: item?.attachmentFiles?.length,
                    })}
                  </Text>
                </Row>
                {fileOpen && (
                  <Col
                    border={{ color: 'g200' }}
                    borderRadius="6px"
                    padding="15px 20px"
                    gap={10}
                    width="100%"
                  >
                    {item?.attachmentFiles.map((file) => (
                      <Row
                        key={file.fileUrl}
                        onClick={() =>
                          fileDownloader(file.fileUrl, file.fileName)
                        }
                        gap={10}
                        alignItems="center"
                        cursor="pointer"
                        hover="background"
                        justifyContent="space-between"
                      >
                        <Text maxLine={1} style={{ wordBreak: 'break-word' }}>
                          {file.fileName}
                        </Text>
                        <Icon name="download" fill="g800" />
                      </Row>
                    ))}
                  </Col>
                )}
              </Row>
            )) ||
            ''}
        </Col>
        {item?.content && (
          <HTMLWrap
            pt={20}
            mb={15}
            content={
              windowSize.width > 1279
                ? item?.content
                : (item?.mobileContent && item?.mobileContent) || item?.content
            }
            style={{ display: 'block' }}
            display="block"
          />
        )}
      </>
    )
  );
}
