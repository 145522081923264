import { API_KEY, BUCKET_VERSION, UUID } from 'data/keyFile';
import useCookieUtil from './useCookieUtil';

function useApiParams(props) {
  const { getCookie } = useCookieUtil();

  const TOKEN_PARAMS = {
    apiKey: getCookie(API_KEY),
    uuid: getCookie(UUID),
    browserInfo: navigator.userAgent,
  };

  const DEFAULT_PARAMS = {
    apiKey: getCookie(API_KEY),
    uuid: getCookie(UUID),
    bucketVersion: getCookie(BUCKET_VERSION),
  };

  return { TOKEN_PARAMS, DEFAULT_PARAMS };
}

export default useApiParams;
