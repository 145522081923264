import { useEffect, useState } from 'react';

import { useIsElementInViewport } from 'hooks';

function useIsImgLoaded() {
  const { elementRef, isVisible } = useIsElementInViewport();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading || !isVisible) {
      setLoading(true);
    }

    setLoading(false);
  }, [isVisible]);

  return { elementRef, loading };
}

export default useIsImgLoaded;
