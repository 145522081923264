import {
  Button,
  Checkbox,
  Col,
  Dim,
  Editor,
  Icon,
  Loading,
  Row,
  TagBadge,
  TagInput,
  Text,
  TitleInput,
} from 'components';
import { isMobile } from 'utils/isMobile';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingButtonStyle } from 'styles/ButtonStyle';
import { animations } from 'styles/css';
import { moduleApi } from 'api';
import { useApiParams } from 'hooks';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

function DefaultWrite({
  module,
  moduleCode,
  getModules,
  setFilters,
  setError,
  targetEditItem,
  setTargetEditItem,
  type,
  ...props
}) {
  const windowSize = useWindowSize();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { DEFAULT_PARAMS } = useApiParams();
  const { t } = useTranslation();
  const [tag, setTag] = useState('');

  const [values, setValues] = useState({
    title: '',
    content: '',
    tags: [],
    isSecretPost: 0,
  });

  const onChange = (value, name) => {
    if (name === 'tags') {
      setValues({ ...values, [name]: [...values.tags, value] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const removeTags = (value) => {
    setValues({ ...values, tags: values.tags.filter((tag) => tag !== value) });
  };

  const [editing, setEditing] = useState(false);

  const openEditing = () => {
    if (!editing && user?.name) {
      setEditing(true);
    } else if (!editing && !user?.name) {
      setError({ resCode: 457 });
    }
  };

  const closeEditing = () => {
    setEditing(false);
    setValues({ title: '', content: '', tags: [], isSecretPost: 0 });
    if (targetEditItem) {
      setTargetEditItem();
    }
  };

  const [createLoading, setCreateLoading] = useState(false);
  const createItem = async () => {
    if (
      !createLoading && !!values?.content && module?.isUsedTitle === 1
        ? !!values.title
        : true
    ) {
      setCreateLoading(true);
      const [info] = await moduleApi.createModuleItem(module.moduleCode, {
        ...values,
        ...DEFAULT_PARAMS,
      });
      if (info) {
        setFilters({
          page: 1,
          lastRowNum: 0,
          perPage: 30,
        });
        setCreateLoading(false);
        setEditing(false);
        setValues({ title: '', content: '', tags: [], isSecretPost: 0 });
      } else {
        setCreateLoading(false);
      }
    }
  };

  const [editLoading, setEditLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const editItem = async () => {
    if (
      !editLoading &&
      values.content &&
      values.itemCode &&
      module?.isUsedTitle === 1
        ? !!values.title
        : true
    ) {
      setEditLoading(true);
      const [info] = await moduleApi.editModuleItem(
        module.moduleCode,
        values.itemCode,
        {
          title: values.title,
          content: values.content,
          tags: values.tags,
          isSecretPost: values.isSecretPost,
          ...DEFAULT_PARAMS,
        },
      );
      if (info) {
        setEditLoading(false);
        setEditing(false);
        setValues({ title: '', content: '', tags: [], isSecretPost: 0 });
        getModules();
        if (type === 'modal') {
          setFilters({
            page: searchParams.get('page') || 1,
            lastRowNum: searchParams.get('lastRowNum') || 0,
            perPage: 12,
          });
        }
      } else {
        setEditLoading(false);
      }
    }
  };

  useEffect(() => {
    if (targetEditItem) {
      setValues({
        itemCode: targetEditItem.itemCode,
        title: targetEditItem.title,
        content: targetEditItem.content,
        tags: targetEditItem.tags,
        isSecretPost: targetEditItem.isSecretPost,
      });
      openEditing();
    }
  }, [targetEditItem]);

  return (
    <>
      <FloatingButtonStyle
        editing={editing}
        zIndex={7000}
        position="sticky"
        bottom="70px"
        left="100%"
        cursor="pointer"
        width={64}
        maxWidth={64}
        height={64}
        borderRadius="50%"
        boxShadow="floating"
        backgroundColor="keyColor"
        onClick={openEditing}
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        <Icon name="write" size={40} float="right" fill="keyFontColor" />
      </FloatingButtonStyle>
      {editing && <Dim overflowHidden />}
      {createLoading && <Loading position="absolute" />}
      {(editing && (
        <Col
          position="fixed"
          zIndex={9999}
          backgroundColor="backgroundColor"
          top="50%"
          left="50%"
          borderRadius="1.2rem"
          padding="62px 77px"
          animation={animations.slideUp()}
          width={1024}
          height="72vh"
          mPadding="20px"
          mWidth="100%"
          mHeight={windowSize?.height}
          mBorderRadius="0"
        >
          {(module?.isViewedContentTitle === 1 && (
            <TitleInput
              value={values.title}
              name="title"
              isSecretPost={!!values.isSecretPost}
              onChange={onChange}
            />
          )) ||
            ''}
          <Editor
            value={values.content}
            name="content"
            onChange={onChange}
            flex={1}
            moduleCode={moduleCode}
            heightMax="calc(72vh - 300px)"
          />
          <Row
            className="board-writer-tags"
            pt={10}
            alignItems="flex-end"
            justifyContent="space-between"
            width={(isMobile() && 'calc(100% - 40px)') || 'calc(100% - 60px)'}
            borderTop={{ color: 'g200' }}
            ml="30px"
            mFlexDirection={(isMobile() && 'column') || ''}
            mMl={(isMobile() && '20px') || ''}
            gap={10}
          >
            <Row
              height="100%"
              gap={6}
              flexWrap="wrap"
              mWidth="100%"
              mOverflowX="overlay"
              mFlexWrap="nowrap"
            >
              <TagInput
                placeholder={t('add_tag')}
                value={tag || ''}
                onChange={(value) => setTag(value)}
                onKeyDown={() => {
                  onChange(tag, 'tags');
                  setTag('');
                }}
                onKeyDownActive={tag}
              />
              {values.tags.map((tag, index) => (
                <TagBadge
                  key={`tag_${tag}_${index}`}
                  onClick={() => removeTags(tag)}
                >
                  {tag}
                </TagBadge>
              ))}
            </Row>
            <Row gap={10} alignItems="center">
              {module?.isUsedSecretPost === 1 && (
                <Checkbox
                  flex={1}
                  onChange={() =>
                    setValues({
                      ...values,
                      isSecretPost: values.isSecretPost ? 0 : 1,
                    })
                  }
                  value={values && values.isSecretPost}
                  checked={values && values.isSecretPost}
                  name="isSecretPost"
                >
                  <Text fontWeight={500} color="g500">
                    {t('secret_post')}
                  </Text>
                </Checkbox>
              )}
              <Button
                buttonType="small"
                ghost
                onClick={() => {
                  closeEditing();
                  setTag();
                }}
              >
                {t('cancel')}
              </Button>
              {(!targetEditItem && (
                <Button
                  buttonType="small"
                  onClick={createItem}
                  active={
                    !!values.content && module?.isUsedTitle === 1
                      ? !!values.title
                      : true
                  }
                >
                  {t('confirm')}
                </Button>
              )) || (
                  <Button
                    buttonType="small"
                    onClick={editItem}
                    active={
                      !!values.content && module?.isUsedTitle === 1
                        ? !!values.title
                        : true
                    }
                  >
                    {t('edit')}
                  </Button>
                ) ||
                ''}
            </Row>
          </Row>
        </Col>
      )) ||
        ''}
    </>
  );
}

export default DefaultWrite;
