import { stampApi } from 'api';
import { useApiParams } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModule } from 'store/module';
import StampPresenter from './StampPresenter';

function StampContainer({ module }) {
  const { DEFAULT_PARAMS } = useApiParams();
  const [stamp, setStamp] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const getList = async () => {
    const [info] = await stampApi.list(module.moduleCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setStamp(info.result);
    }
  };

  useEffect(() => {
    if (module) {
      dispatch(setModule({ ...module }));
      getList();
    }
  }, [module]);

  const open = (type) => {
    searchParams.append(type, true);
    setSearchParams(searchParams);
  };

  const close = (type) => {
    searchParams.delete(type);
    setSearchParams(searchParams);
  };

  const goDetail = (item) => {
    sessionStorage.setItem('qrDetail', JSON.stringify(item));
    searchParams.delete('scanner');
    searchParams.append('content', true);
    setSearchParams(searchParams);
  };

  const stamping = async (item, type) => {
    const [info] = await stampApi.stampConfirm(
      module.moduleCode,
      item.itemCode,
      { ...DEFAULT_PARAMS },
    );
    if (info) {
      getList();
      doneStamping(type);
    }
  };

  const [childAlert, setChildAlert] = useState();
  const doneStamping = (type) => {
    setChildAlert({
      confirmLabel: '다른 스탬프 획득하러 가기',
      confirm: () => {
        close(type);
        setChildAlert();
      },
      close: () => {
        close(type);
        setChildAlert();
      },
      buttonStyles: { buttonType: 'large' },
    });
  };

  const [rewardItem, setRewardItem] = useState();
  const rewardAlert = async () => {
    if (stamp?.isReceivedClearReward === 0) {
      const [info] = await stampApi.reward(module.moduleCode, {
        ...DEFAULT_PARAMS,
      });
      if (info) {
        setRewardItem(info.result);
        setChildAlert({
          type: 'reward',
          confirmLabel: '직원 확인',
          confirm: () => {
            reward();
          },
          cancel: () => {
            setChildAlert();
          },
        });
      }
    }
  };

  const [toast, setToast] = useState();
  const reward = async () => {
    const [info] = await stampApi.rewardConfirm(module.moduleCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setChildAlert();
      getList();
      setToast('기념품 수령이 완료되었습니다.');
    }
  };

  return (
    <StampPresenter
      getList={getList}
      module={module}
      stamp={stamp}
      searchParams={searchParams}
      open={open}
      close={close}
      goDetail={goDetail}
      stamping={stamping}
      doneStamping={doneStamping}
      childAlert={childAlert}
      reward={rewardAlert}
      rewardItem={rewardItem}
      toast={toast}
    />
  );
}

export default StampContainer;
