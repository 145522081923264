import {
  LoginTypeA,
  LoginBox,
  LoginHeader,
  LoginTypeB,
  FindpasswordVerified,
} from 'components';
import React from 'react';

function FindpasswordPresenter({
  bucket,
  info,
  termList,
  terms,
  onTermChange,
  viewTerm,
  loginToMain,
  setAlert,
}) {
  return (
    <>
      {info?.designType === 'design1' && (
        <LoginTypeA
          info={info}
          bucket={bucket}
          header={<LoginHeader bucket={bucket} />}
        >
          <LoginBox>
            <FindpasswordVerified
              loginToMain={loginToMain}
              termList={termList}
              terms={terms}
              onTermChange={onTermChange}
              viewTerm={viewTerm}
              loginMethod={
                bucket.loginMethod.toLowerCase().includes('email')
                  ? 'email'
                  : 'phone'
              }
              setAlert={setAlert}
              isUsedSignUpBtn={info.isUsedSignUpBtn}
            />
          </LoginBox>
        </LoginTypeA>
      )}
      {info?.designType === 'design2' && (
        <LoginTypeB
          info={info}
          bucket={bucket}
          header={
            <LoginHeader
              bucket={bucket}
              position="absolute"
              zIndex={800}
              width="100vw"
            />
          }
        >
          <LoginBox>
            <FindpasswordVerified
              loginToMain={loginToMain}
              termList={termList}
              terms={terms}
              onTermChange={onTermChange}
              viewTerm={viewTerm}
              loginMethod={
                bucket.loginMethod.toLowerCase().includes('email')
                  ? 'email'
                  : 'phone'
              }
              setAlert={setAlert}
              isUsedSignUpBtn={info.isUsedSignUpBtn}
            />
          </LoginBox>
        </LoginTypeB>
      )}
    </>
  );
}

export default FindpasswordPresenter;
