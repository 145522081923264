export const hideCodeRefStyles = (sendEmailDone) =>
  (sendEmailDone && {
    wrapStyles: {
      mt: 10,
      width: '100%',
      height: 'auto',
    },
    width: '100%',
    padding: '15px 12px',
  }) || {
    wrapStyles: {
      width: '0px',
      height: '0px',
    },
    border: { width: 0 },
    width: '0px',
    height: '0px !important',
    padding: '0px !important',
  };
