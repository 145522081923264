import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useApiParams, useCookieUtil, useLanguageNavigate } from 'hooks';
import { userListChatApi } from 'api';
import ChatPresenter from './ChatPresenter';

export function ChatContainer({
  bucket,
  module,
  socket,
  item,
  back,
  ...props
}) {
  const { currentLanguage } = useLanguageNavigate();
  const { setCookies } = useCookieUtil();
  const scrollRef = useRef();
  const { DEFAULT_PARAMS } = useApiParams();

  const [roomCode, setRoomCode] = useState();
  const [addChat, setAddChat] = useState();
  const [list, setList] = useState([]);

  const getList = async (roomCode) => {
    const [info] = await userListChatApi.getList(module.moduleCode, roomCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setRoomCode(roomCode);
      setList([...info.result]);
    }
  };

  const getRoomCode = async () => {
    const [info] = await userListChatApi.roomCode(module.moduleCode, {
      ...DEFAULT_PARAMS,
      itemCode: item.itemCode,
    });
    if (info) {
      getList(info.result.roomCode);
    }
  };

  useEffect(() => {
    if (module && item) {
      if (item.roomCode) {
        getList(item.roomCode);
      } else {
        getRoomCode();
      }
    }

    return () => {
      setList([]);
    };
  }, [item, module]);

  // socket
  useEffect(() => {
    if (socket && roomCode) {
      connectRoom();
      receivedMessage();
    }

    return () => {
      if (socket && roomCode) {
        disconnectRoom();
      }
    };
  }, [socket, roomCode]);

  const sendMessage = (content) => {
    let result;
    try {
      const data = {
        moduleCode: module.moduleCode,
        roomCode,
        content,
      };
      socket.emit('userList - send chat message', { ...data });
    } catch {
      result = false;
    } finally {
      result = true;
    }

    scrollBottom();
    setPrevScrollHeight(null);
    return result;
  };

  const receivedMessage = () => {
    socket.on(`${roomCode} - new chat message`, (data) => {
      setAddChat({ ...data });
    });
  };

  useEffect(() => {
    if (addChat) {
      setList([...list, addChat]);
    }
  }, [addChat]);

  const [scrolling, setScrolling] = useState(true);
  const scrollBottom = () => {
    setScrolling(true);
    // 채팅 스크롤로 인하여 윈도우 스크롤이 bottom으로 향하는 것을 방지하기 위하여 bottomElem.scrollIntoView를 사용하지 않음
    if (scrollRef.current) {
      setTimeout(() => {
        if (list?.length < 13) {
          // 첫 스크롤 시 스크롤 애니메이션 없음
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        } else {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
        setScrolling(false);
      }, [200]);
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      if (prevScrollHeight) {
        scrollRef.current.scrollTop =
          scrollRef.current.scrollHeight - prevScrollHeight;
        return setPrevScrollHeight(null);
      } else if (upScrolling) {
        // scroll handle 중 새 chatting이 울렸을때 delay
        setScrolling(true);
        setTimeout(() => {
          setScrolling(false);
        }, [200]);
      } else {
        scrollBottom();
      }

      if (item.itemCode) {
        setCookies(item.itemCode, list?.length);
      }
    }
  }, [list]);

  let previousY = 0;
  const [throttle, setThrottle] = useState(false);
  const [upScrolling, setUpScrolling] = useState(false);
  const [prevScrollHeight, setPrevScrollHeight] = useState();
  const [showBottomScrollButton, setShowBottomScrollButton] = useState(false);
  const handleScroll = (event) => {
    const current = event.target.scrollTop;
    if (current < previousY) {
      setPrevScrollHeight(event.target.scrollHeight);
    }
    previousY = current;

    if (throttle) return;
    if (!throttle) {
      setThrottle(true);
      setTimeout(async () => {
        setUpScrolling(
          Math.abs(
            event.target.scrollHeight -
              event.target.scrollTop -
              event.target.clientHeight,
          ) > 1,
        );
        setShowBottomScrollButton(
          Math.abs(
            event.target.scrollHeight -
              event.target.scrollTop -
              event.target.clientHeight,
          ) > 1500,
        );
        setThrottle(false);
      }, 300);
    }
  };

  const inputFocusHandler = useCallback(
    (target) => {
      if (target) {
        if (module?.designType === 'type1') {
          scrollRef.current.scrollTop += 94;
        } else if (module?.designType === 'type2') {
          scrollRef.current.scrollTop += 18;
        }
      }
    },
    [module],
  );

  // socket
  const connectRoom = () => {
    socket.emit('join room', { roomId: roomCode });
  };

  const disconnectRoom = () => {
    socket.emit('exit room', { roomId: roomCode });
  };

  // let prevVisualViewport = 0;

  // function handleVisualViewportResize() {
  //   const currentVisualViewport = window.visualViewport.height;

  //   if (
  //     prevVisualViewport - 30 > currentVisualViewport &&
  //     prevVisualViewport - 100 < currentVisualViewport
  //   ) {
  //     const { scrollHeight } = window.document.scrollingElement;
  //     const scrollTop = scrollHeight - window.visualViewport.height;

  //     window.scrollTo(0, scrollTop); // 입력창이 키보드에 가려지지 않도록 조절
  //   }

  //   prevVisualViewport = window.visualViewport.height;
  // }

  // useEffect(() => {
  //   window.visualViewport.onresize = handleVisualViewportResize;
  // }, []);

  return (
    <ChatPresenter
      bucket={bucket}
      currentLanguage={currentLanguage}
      scrollRef={scrollRef}
      scrollHandler={handleScroll}
      showBottomScrollButton={showBottomScrollButton}
      scrollBottom={scrollBottom}
      module={module}
      item={item}
      list={list}
      sendMessage={sendMessage}
      inputFocusHandler={inputFocusHandler}
      back={back}
      {...props}
    />
  );
}

export default ChatContainer;
