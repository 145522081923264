import { bucketApi } from 'api';
import {
  Div,
  Dim,
  Text,
  Row,
  Icon,
  HeaderLogo,
  HeaderLanguage,
  HeaderProfileMobile,
  Modal,
  Col,
} from 'components';
import {
  useApiParams,
  useBucketReset,
  useLanguageNavigate,
  useWindowSize,
} from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HeaderStyle } from 'styles/HeaderStyle';
import { isMobile } from 'utils/isMobile';
import { linkTypeClick } from 'utils/linkType';

export function HeaderDrawer({ t, bucket, headerOption, menuCode, ...props }) {
  const { DEFAULT_PARAMS } = useApiParams();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));

  const { header, logoImg, menuInfo, enableLogin, languages } = bucket;
  const headerDesignType = parseInt(
    bucket.headerOption.headerType.replace(/[^0-9]/g, ''),
    10,
  );

  const { languageNavigate, currentLanguage } = useLanguageNavigate();
  const { logout } = useBucketReset();

  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const [openFolderMenu, setOpenFolderMenu] = useState();

  const close = () => {
    setOpen(false);
    setOpenFolderMenu();
  };

  useEffect(() => {
    if (open && menuCode) {
      const folderMenus = menuInfo[currentLanguage]?.filter(
        (el) => el.innerMenus,
      );
      const findFolder = folderMenus?.find((el) =>
        el.innerMenus?.find((innerEl) => innerEl.key === menuCode),
      );
      setOpenFolderMenu((findFolder && findFolder.name) || '');
    } else if (!open) {
      setOpenFolderMenu();
    }
  }, [open, menuCode]);

  const goMenu = (selectPageCode) => {
    languageNavigate(`/${selectPageCode}`);
  };

  const goLinkType = (type, menu) => {
    linkTypeClick(
      type,
      menu.url,
      (menu.withInfo === 1 && { key: 'user', value: user.name }) || '',
    );
  };

  const goLogin = () => {
    languageNavigate(`/login`);
  };

  const [backgroundColor, setBackgroundColor] = useState();
  const [fontColor, setFontColor] = useState();

  const [throttle, setThrottle] = useState(false);
  const changeHeaderColor = () => {
    if (throttle) return;
    if (!throttle) {
      setThrottle(true);
      setTimeout(async () => {
        if (window.scrollY > 5) {
          setBackgroundColor(
            headerOption.scrolledHeaderBgColor || 'backgroundColor',
          );
          setFontColor(headerOption.scrolledHeaderFontColor || 'g800');
        } else {
          setBackgroundColor(headerOption.headerBgColor);
          setFontColor(headerOption.headerFontColor);
        }
        setThrottle(false);
      }, 200);
    }
  };

  const initColor = () => {
    setBackgroundColor(
      headerOption.headerBgColor ||
        bucket.headerOption.headerBgColor ||
        'backgroundColor',
    );
    setFontColor(
      headerOption.headerFontColor ||
        bucket.headerOption.headerFontColor ||
        'g800',
    );
  };

  useEffect(() => {
    if (header && headerOption) {
      setTimeout(() => {
        initColor();
      }, 300);
    }

    if (headerOption?.isHeaderOverlap === 1) {
      window.addEventListener('scroll', changeHeaderColor);
    }

    return () => {
      if (headerOption?.isHeaderOverlap === 1) {
        window.removeEventListener('scroll', changeHeaderColor);
      }
    };
  }, [bucket, headerOption]);

  const [alarmModal, setAlarmModal] = useState();
  const [alarmList, setAlarmList] = useState([]);

  const getAlarmList = async () => {
    const [info] = await bucketApi.alarm({ ...DEFAULT_PARAMS });
    if (info) {
      setAlarmList([...info?.result]);
    }
  };

  return (
    header && (
      <>
        <>
          {open && (
            <Dim
              overflowHidden
              zIndex={8000}
              backgroundColor="transparent"
              onClick={() => {
                setOpen(false);
                setOpenFolderMenu();
              }}
            />
          )}
          <Div
            width="100%"
            maxWidth={(!isMobile() && '300px') || 'unset'}
            height={windowSize.height}
            position="fixed"
            top="0"
            pt={60}
            backgroundColor={
              headerOption?.scrolledHeaderBgColor ||
              bucket?.headerOption?.scrolledHeaderBgColor
            }
            zIndex={9000}
            boxShadow="default"
            right={open ? '0px' : (!isMobile() && '-300px') || '-100%'}
            transition="all 0.3s ease-in-out"
            transformOrigin="right"
            overflowY="overlay"
          >
            {enableLogin === 1 && !user.name && (
              <Div padding="20px">
                {headerDesignType === 1 && (
                  <Row
                    display="inline-flex"
                    gap={6}
                    padding="10px"
                    hover
                    cursor="pointer"
                    onClick={goLogin}
                  >
                    <Icon
                      name="login"
                      fill={
                        headerOption?.headerFontColor ||
                        bucket?.styles?.keyColor
                      }
                      size={21}
                    />
                    <Text
                      whiteSpace="nowrap"
                      color={
                        headerOption?.headerFontColor ||
                        bucket?.styles?.keyColor
                      }
                    >
                      {t('login')}
                    </Text>
                  </Row>
                )}
                {headerDesignType === 2 && (
                  <Row
                    gap={6}
                    padding="6px 28px"
                    height="100%"
                    hover
                    cursor="pointer"
                    onClick={goLogin}
                    border={{ color: 'keyColor', width: '2px' }}
                    borderRadius="3px"
                    display="inline-flex"
                  >
                    <Text type="b1" whiteSpace="nowrap" color="keyColor">
                      {t('login')}
                    </Text>
                  </Row>
                )}
              </Div>
            )}
            {user?.name && user?.accountType !== 'anonymous' && (
              <Row padding="20px">
                <HeaderProfileMobile
                  t={t}
                  user={user}
                  bucket={bucket}
                  fontColor={
                    headerOption?.scrolledHeaderFontColor ||
                    bucket?.headerOption?.scrolledHeaderFontColor
                  }
                  setOpen={setOpen}
                />
              </Row>
            )}
            {menuInfo[currentLanguage]?.map((el) => (
              <Div key={el.name}>
                <Row
                  position="relative"
                  padding="20px 20px 20px 30px"
                  onClick={() => {
                    if (el.type === 'page') goMenu(el.key);
                    if (el.type === 'inLink') goLinkType('inLink', el);
                    if (el.type === 'outLink') goLinkType('outLink', el);
                    if (el.type === 'folder') {
                      setOpenFolderMenu(
                        (openFolderMenu !== el.name && el.name) || '',
                      );
                    } else {
                      close();
                    }
                  }}
                  cursor="pointer"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={10}
                  hover
                >
                  <Div
                    position="absolute"
                    width={2}
                    height="100%"
                    backgroundColor="keyColor"
                    left="0"
                    borderRadius="0rem 0.6rem 0rem 0.6rem"
                    transform={
                      ((menuCode === el.key ||
                        el.innerMenus
                          ?.map((innerEl) => innerEl.key)
                          .includes(menuCode)) &&
                        'scaleY(1)') ||
                      'scaleY(0)'
                    }
                    transition="transform 0.3s ease-in-out"
                    transformOrigin="top"
                  />
                  <Text
                    type="b2"
                    fontColor={headerOption?.scrolledHeaderFontColor}
                    position="relative"
                  >
                    {el.name}
                  </Text>
                  {el.type === 'folder' && (
                    <Icon
                      name="arrow"
                      fill={headerOption?.scrolledHeaderFontColor}
                      minWidth={21}
                      transform={
                        (openFolderMenu === el.name && 'rotate(-180deg)') ||
                        'rotate(0)'
                      }
                      transition="all 0.3s ease-in-out"
                    />
                  )}
                </Row>
                {el.type === 'folder' && (
                  <Div
                    width="100%"
                    left="0"
                    transform={
                      (openFolderMenu === el.name && 'scaleY(1)') || 'scaleY(0)'
                    }
                    transition="transform 0.3s ease-in-out"
                    transformOrigin="top center"
                  >
                    {openFolderMenu === el.name &&
                      el.innerMenus.map((subEl) => (
                        <Div
                          key={subEl.key}
                          onClick={() => {
                            goMenu(subEl.key);
                            close();
                          }}
                          padding="14px 20px 14px 40px"
                          cursor="pointer"
                          hover
                        >
                          <Text
                            whiteSpace="nowrap"
                            color={
                              (subEl.key === menuCode && 'keyColor') || 'g600'
                            }
                          >
                            {subEl.name}
                          </Text>
                        </Div>
                      ))}
                  </Div>
                )}
              </Div>
            ))}
            {user?.name && user?.accountType !== 'anonymous' && (
              <Row
                onClick={logout}
                padding="20px 30px"
                gap={10}
                alignItems="center"
                hover="background"
                cursor="pointer"
              >
                <Icon size={21} name="logout" stroke={fontColor} />
                <Text color={fontColor} flex={1}>
                  {t('logout')}
                </Text>
              </Row>
            )}
          </Div>
        </>
        <HeaderStyle
          display="flex"
          position="fixed"
          top="0"
          left="0"
          height={60}
          width="100vw"
          borderBottom={
            headerOption?.isHeaderOverlap !== 1 && {
              color: 'g200',
            }
          }
          backgroundColor={backgroundColor}
          transition="all 150ms"
          alignItems="center"
          justifyContent="space-between"
          padding="0 20px"
          zIndex={9000}
          {...props}
        >
          <HeaderLogo bucket={bucket} src={logoImg} zIndex={9999} />
          <Row gap={20} alignItems="center">
            <Row gap={10} alignItems="center">
              {bucket?.isViewedAlarmBtn === 1 && (
                <Icon
                  size={36}
                  name="alarm"
                  cursor="pointer"
                  onClick={() => {
                    setAlarmModal(true);
                    getAlarmList();
                  }}
                />
              )}
              {languages?.length > 1 && (
                <HeaderLanguage
                  type={headerDesignType}
                  bucket={bucket}
                  fontColor={
                    (open && headerOption?.scrolledHeaderFontColor) || fontColor
                  }
                  backgroundColor={backgroundColor}
                />
              )}
            </Row>
            {menuInfo[currentLanguage]?.length > 0 && (
              <Icon
                name={(headerDesignType === 2 && 'menu-b') || 'menu'}
                fill={
                  (open && headerOption?.scrolledHeaderFontColor) || fontColor
                }
                stroke={
                  (open && headerOption?.scrolledHeaderFontColor) || fontColor
                }
                size={32}
                cursor="pointer"
                onClick={() => setOpen(!open)}
                hover="circle"
                borderRadius="50%"
                backgroundColor={(open && 'key100') || ''}
                transition="all 150ms"
                zIndex={9999}
              />
            )}
          </Row>
        </HeaderStyle>
        {alarmModal && (
          <Modal
            close={() => setAlarmModal(false)}
            childrenProps={{ padding: '20px', mPadding: '20px' }}
          >
            <Text type="h5">{t('alarm')}</Text>
            <Col gap={15}>
              {alarmList?.map((alarm, index) => (
                <Div
                  key={`alarm-${alarm.createdDt}-${index}`}
                  pb={15}
                  borderBottom={{ color: 'g200' }}
                >
                  <Text type="h7">{alarm.title}</Text>
                  <Text type="b2">{alarm.message}</Text>
                  <Text type="b3" color="g600" textAlign="right" mt={5}>
                    {moment(alarm.createdDt).format('YYYY-MM-DD HH:mm')}
                  </Text>
                </Div>
              ))}
            </Col>
          </Modal>
        )}
      </>
    )
  );
}
