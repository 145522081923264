import { authApi } from 'api';
import {
  Div,
  Input,
  Text,
  CountryCode,
  RoundButton,
  Button,
  LoginTermBox,
} from 'components';
import { useApiParams, useVerificationTime } from 'hooks';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideCodeRefStyles } from 'utils/hideCodeRefStyles';
import { DEFAULT_INVALID_KEYS } from 'utils/invalids';
import { makePhone } from 'utils/makePhone';

export function LoginVerified({
  termList,
  terms,
  onTermChange,
  loginMethod,
  viewTerm,
  loginToMain,
  setAlert,
  ...props
}) {
  const { t } = useTranslation();
  const codeRef = useRef();
  const { TOKEN_PARAMS, DEFAULT_PARAMS } = useApiParams();
  const [values, setValues] = useState({ [loginMethod]: '', code: '' });
  const [invalid, setInvalid] = useState({
    type: '',
    text: '',
  });

  const initFocus = () => {
    // timer가 만료되었거나 code 가 아닌 항목을 재입력했을 시 code 초기화
    if (invalid?.type !== 'success') {
      setInvalid({
        type: '',
        text: '',
      });
      setValues({
        ...values,
        code: '',
      });
      setToken();
      setRefreshCode();
      document.getElementsByTagName('input')[loginMethod].focus();
    }
  };

  const { minutes, seconds, isRunning, setIsRunning, initTimer } =
    useVerificationTime(initFocus);

  const onChange = (value, name) => {
    if (name === loginMethod || name === 'phoneCountyCode') {
      initTimer();
      setValues({
        ...values,
        [name]: value,
        code: '',
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
    setInvalid({
      type: '',
      text: '',
    });
  };

  const timerActive = useMemo(
    () => Boolean((isRunning && minutes < 2) || (!isRunning && minutes === 3)),
    [isRunning, minutes],
  );

  const sendCodeActive = useMemo(
    () =>
      Boolean(
        values[loginMethod]?.match(DEFAULT_INVALID_KEYS[loginMethod]) &&
          timerActive,
      ),
    [values[loginMethod], timerActive],
  );

  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const sendCode = async () => {
    if (sendCodeActive) {
      setIsRunning(true);
      initTimer();
      // 인증번호 보내기
      setSendCodeLoading(true);
      const params = { ...DEFAULT_PARAMS };
      delete params.bucketVersion;

      const loginValue =
        (loginMethod === 'phone' &&
          `${values.phoneCountyCode} ${makePhone(values.phone)}`) ||
        values[loginMethod];
      const [info, error] = await authApi.verificationSend({
        ...params,
        [loginMethod]: loginValue,
      });
      if (info) {
        // success
        setSendCodeLoading(false);
        setIsRunning(true);
        if (codeRef.current) {
          codeRef.current.focus();
        }
      } else if (error) {
        setSendCodeLoading(false);
        setAlert({
          title: 'ERROR',
          content: t('error_verification'),
          confirm: () => {
            initTimer();
            setAlert();
          },
        });
      }
    } else if (!timerActive) {
      setInvalid({ type: 'error', text: t('invalid_request') });
      setTimeout(() => {
        setInvalid();
      }, [1500]);
    }
  };

  const checkCodeActive = useMemo(
    () => Boolean(isRunning && values.code),
    [isRunning, values],
  );

  const [checkCodeLoading, setCheckCodeLoading] = useState(false);
  const [token, setToken] = useState();
  const [refreshCode, setRefreshCode] = useState();
  const checkCode = async () => {
    // 코드 인증
    if (checkCodeActive) {
      setCheckCodeLoading(true);
      const loginValue =
        (loginMethod === 'phone' &&
          `${values.phoneCountyCode} ${makePhone(values.phone)}`) ||
        values[loginMethod];
      const [info, error] = await authApi.login({
        ...TOKEN_PARAMS,
        code: values.code,
        [loginMethod]: loginValue,
      });
      if (info) {
        const { result } = info;
        if (termList?.length === 0) {
          loginToMain(result.token, result.refreshCode);
        } else {
          setToken(result.token);
          setRefreshCode(result.refreshCode);
          setInvalid({ type: 'success', text: t('success_verification') });
          setCheckCodeLoading(false);
        }
      } else if (error) {
        // 451 : 인증코드 틀림
        setInvalid({ type: 'error', text: t('invalid_code') });
        setCheckCodeLoading(false);
      }
    }
  };

  const termsActive = useMemo(() => {
    for (const term in terms) {
      if (
        termList.find((el) => el.key === term)?.isRequired === 1 &&
        !terms[term]
      )
        return false;
    }
    if (invalid?.type !== 'success') return false;

    return true;
  }, [terms, invalid]);

  const login = () => {
    loginToMain(token, refreshCode);
  };

  return (
    <Div>
      {loginMethod === 'phone' && (
        <CountryCode name="phoneCountyCode" onChange={onChange} />
      )}
      <Input
        type={(loginMethod === 'phone' && 'tel') || ''}
        label={(loginMethod === 'email' && t(loginMethod)) || ''}
        placeholder={t(`${loginMethod}_placeholder`)}
        onChange={onChange}
        name={loginMethod}
        value={(values && values[loginMethod]) || ''}
        onKeyDown={sendCode}
        onKeyDownActive={sendCodeActive || !sendCodeLoading}
        disabled={sendCodeLoading}
        rightChildren={
          invalid?.type !== 'success' && (
            <RoundButton
              className="input-disabled-click"
              buttonType="text"
              borderRadius="999px"
              type="b3"
              padding="0.5rem 1.2rem"
              active={!sendCodeLoading && sendCodeActive}
              loading={sendCodeLoading}
              onClick={sendCode}
            >
              {(isRunning && t('verification_re')) || t('verification')}
            </RoundButton>
          )
        }
      />
      <Input
        type="tel"
        setRef={codeRef}
        value={(values && values?.code) || ''}
        name="code"
        placeholder={t('code_placeholder')}
        autoComplete="off"
        onChange={onChange}
        invalid={invalid}
        onKeyDown={checkCode}
        onKeyDownActive={checkCodeActive || !checkCodeLoading}
        disabled={checkCodeLoading || invalid?.type === 'success'}
        rightChildren={
          termList?.length > 0 &&
          invalid?.type !== 'success' &&
          isRunning && (
            <>
              <Text type="b3" color="red" className="input-disabled-click">
                {`0${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
              </Text>
              {checkCode && (
                <RoundButton
                  className="input-disabled-click"
                  buttonType="text"
                  borderRadius="999px"
                  type="b3"
                  padding="0.5rem 1.2rem"
                  active={!checkCodeLoading && checkCodeActive}
                  loading={checkCodeLoading}
                  onClick={checkCode}
                >
                  {t('confirm')}
                </RoundButton>
              )}
            </>
          )
        }
        {...hideCodeRefStyles(
          !sendCodeLoading && isRunning && values[loginMethod],
        )}
      />
      <LoginTermBox
        list={termList}
        values={terms}
        onChange={onTermChange}
        onClick={viewTerm}
      />
      {/* 약관 없을때 로그인 */}
      {termList?.length === 0 && (
        <Button
          buttonType="large"
          active={!checkCodeLoading && checkCodeActive}
          loading={checkCodeLoading}
          onClick={checkCode}
          mt={20}
          display="block"
          margin="0 auto"
        >
          {t('login')}
        </Button>
      )}
      {/* 약관 있을때 로그인 */}
      {termList?.length > 0 && (
        <Button
          buttonType="large"
          active={termsActive}
          onClick={login}
          display="block"
          margin="0 auto"
          mt={20}
        >
          {t('login')}
        </Button>
      )}
    </Div>
  );
}
