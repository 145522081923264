import React from 'react';
import {
  WYSIWYGTitle,
  Input,
  Div,
  Invalid,
  Birthday,
  CountryCode,
} from 'components';
import { useTranslation } from 'react-i18next';

export function ProfileInputCard({
  currentLanguage,
  title,
  item,
  name,
  value,
  onChange,
  invalid,
  children,
  initValue,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <Div {...props} mb={30}>
      <WYSIWYGTitle title={title} item={item} />
      {(item.itemType === 'country' && (
        <CountryCode
          type="country"
          name={name}
          currentLanguage={currentLanguage}
          initValue={{ countryCode: initValue }}
          onChange={(value, name, countryName) => onChange(value, 'country')}
        />
      )) ||
        (item.itemType === 'birthdate' && (
          <Birthday
            onChange={(value) =>
              onChange(`${value.year}-${value.month}-${value.date}`, name)
            }
            name={name}
            initValue={
              value
                ? {
                    year: value.split('-')[0],
                    month: value.split('-')[1],
                    date: value.split('-')[2],
                  }
                : null
            }
            invalid={invalid}
          />
        )) || (
          <Input
            name={name}
            value={value}
            onChange={onChange}
            invalid={invalid}
            type={(item.itemType === 'age' && 'number') || 'text'}
          />
        )}
      <Invalid invalid={{ ...invalid, text: t('invalid_item') }} />
      {children}
    </Div>
  );
}
