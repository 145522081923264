import { Div, SurveyList, SurveyStart } from 'components';
import React from 'react';

function SurveyPresenter({
  t,
  module,
  groupCode,
  getGroupcode,
  list,
  values,
  etcValues,
  setEtcValues,
  onEtcChange,
  onChange,
  onMutipleChange,
  onCheckChange,
  goBack,
  confirm,
  active,
}) {
  return (
    <Div width="100%">
      {!groupCode && <SurveyStart onClick={getGroupcode} />}
      {groupCode && (
        <SurveyList
          t={t}
          list={list}
          values={values}
          etcValues={etcValues}
          setEtcValues={setEtcValues}
          onChange={onChange}
          onEtcChange={onEtcChange}
          onMutipleChange={onMutipleChange}
          onCheckChange={onCheckChange}
          active={active}
          goBack={goBack}
          confirm={confirm}
        />
      )}
    </Div>
  );
}

export default SurveyPresenter;
