/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Input, Icon, Div, Text } from 'components';
import { useTranslation } from 'react-i18next';

export function Dropdown({
  label,
  list,
  direct,
  value,
  name,
  invalid,
  customItem,
  customKey,
  onChange,
  disabled,
  position,
  inputType,
  wrapStyles,
  readOnly,
  hiddenRemove,
  autoComplete,
  multiple,
  ...props
}) {
  const wrapRef = useRef();
  const { t } = useTranslation();
  const itemRef = useRef([]);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState();
  const [selected, setSeledted] = useState();
  const [hovered, setHovered] = useState();

  const onFocus = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const onBlur = () => {
    if (!disabled) {
      setOpen(false);
    }
  };

  const [timer, setTimer] = useState(0); // 디바운싱 타이머
  const debouncing = (func) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(
      () =>
        (...args) => {
          func(...args);
        },
      150,
    );
    setTimer(newTimer);
  };

  const makeSearchList = (originKeyword) => {
    const keyword = originKeyword.replace(/ /g, '').toLowerCase();
    if (keyword) {
      const searchList = JSON.parse(JSON.stringify(list));

      if (direct) {
        searchList.pop();
      }

      const filterList = searchList.filter((el) =>
        String((customItem && customItem(el)) || el)
          ?.replace(/ /g, '')
          .toLowerCase()
          .includes(keyword),
      );
      const findItem = searchList.find(
        (el) =>
          String((customItem && customItem(el)) || el)
            ?.replace(/ /g, '')
            .toLowerCase() === keyword,
      );

      if (direct) {
        onChange(
          (customItem && { ...direct, [customKey]: keyword }) || keyword,
          name,
        );
        setOptions([...filterList, direct]);
        setSeledted(keyword ? findItem || direct : '');
      } else if (findItem) {
        setSeledted(findItem);
        onChange(findItem, name);
        setOptions(filterList);
      } else if (!value) {
        setSeledted();
        onChange('', name);
        setOptions(filterList);
      }
    } else {
      setSeledted();
      onChange('', name);
      setOptions();
    }
  };

  const onChangeInputHandler = (value) => {
    setInputValue(value);
    setHovered();
    debouncing(makeSearchList(value));
  };

  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Enter'
    ) {
      setKeyPressed(event.key);
    }
  };

  const upHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setKeyPressed(false);
  };

  const changeHover = () => {
    const changeIndex =
      keyPressed === 'Enter' ? 0 : keyPressed === 'ArrowUp' ? -1 : 1;
    const searchList = JSON.parse(JSON.stringify(options || list));

    const findIndex = searchList?.findIndex(
      (el) =>
        ((customItem && customItem(el)) || el)
          ?.replace(/ /g, '')
          .toLowerCase() ===
        ((customItem && customItem(value)) || value)
          ?.replace(/ /g, '')
          .toLowerCase(),
    );
    const hoverIndex =
      hovered || hovered === 0
        ? hovered + changeIndex
        : findIndex !== -1
        ? findIndex + changeIndex
        : 0;

    if (hoverIndex < searchList.length && hoverIndex > -1) {
      if (keyPressed === 'Enter') {
        if (direct) {
          if (
            (hovered || hovered === 0) &&
            searchList[hoverIndex][customKey] === direct[customKey]
          ) {
            onChangeHandler('');
          } else if ((hovered || hovered === 0) && searchList[hoverIndex]) {
            onChangeHandler(searchList[hoverIndex]);
          } else if (inputValue) {
            onChangeHandler(inputValue);
          }
        } else {
          onChangeHandler(searchList[hoverIndex] || value || '');
        }
        const inputElem = document.getElementById(`dropdown-system-${name}`);
        inputElem.blur();
      } else {
        setHovered(hoverIndex);
        setTimeout(() => {
          itemRef.current[hoverIndex].scrollIntoView({
            behavior: 'instant',
            block: 'nearest',
            inline: 'nearest',
          });
        }, []);
      }
    }
  };

  useEffect(() => {
    if (keyPressed) {
      debouncing(changeHover());
    }
  }, [keyPressed]);

  useEffect(() => {
    if (!disabled) {
      wrapRef?.current?.addEventListener('keydown', downHandler);
      wrapRef?.current?.addEventListener('keyup', upHandler);
    }

    return () => {
      wrapRef?.current?.removeEventListener('keydown', downHandler);
      wrapRef?.current?.removeEventListener('keyup', upHandler);
    };
  }, [wrapRef?.current]);

  useEffect(() => {
    if (!open && value && !inputValue) {
      setInputValue((customItem && customItem(value)) || value);
    }

    if (!open) {
      if (direct) {
        const searchList = JSON.parse(JSON.stringify(list));
        searchList.pop();
        const findItem = searchList?.find(
          (el) =>
            ((customItem && customItem(el)) || el)
              .replace(/ /g, '')
              .toLowerCase() ===
            (customItem(value) || value)?.replace(/ /g, '').toLowerCase(),
        );
        setSeledted(
          (customItem && customItem(findItem || (value && direct))) ||
            findItem ||
            (value && direct) ||
            '',
        );
      } else {
        setInputValue((customItem && customItem(value)) || value || '');
        setSeledted((customItem && customItem(value)) || value || '');
      }
      setTimeout(() => {
        setOptions();
        setHovered();
      }, [150]);
    }
  }, [open, value, inputValue]);

  const onChangeHandler = (selectItem, index) => {
    onChange(selectItem, name, index);
    setInputValue((customItem && customItem(selectItem)) || selectItem);
  };

  useEffect(() => {
    if (
      open &&
      itemRef &&
      list &&
      value &&
      !readOnly &&
      (list?.length > 50 || (options && options.length > 50))
    ) {
      const selectedIndex = (options || list).findIndex(
        (el) =>
          (customItem && customItem(el) === customItem(value)) || el === value,
      );
      if (selectedIndex !== -1) {
        setTimeout(() => {
          itemRef.current[selectedIndex].scrollIntoView({
            behavior: 'instant',
            block: 'nearest',
            inline: 'nearest',
          });
        }, [200]);
      }
    }
  }, [open, itemRef, list, options, !readOnly]);

  return (
    <Div setRef={wrapRef} {...wrapStyles}>
      {label && (
        <Text type="b3" mb={4}>
          {label}
        </Text>
      )}
      <Div position={position || 'relative'} open={open} {...props}>
        <label htmlFor={`dropdown-system-${name}`}>
          <Input
            id={`dropdown-system-${name}`}
            onFocus={onFocus}
            onBlur={onBlur}
            value={
              Array.isArray(inputValue) && typeof inputValue[0] === 'object'
                ? inputValue.map((item) => item.title)
                : inputValue || ''
            }
            rightChildren={<Icon name="arrow" fill="g300" />}
            onChange={onChangeInputHandler}
            hiddenRemove
            readOnly={readOnly || multiple}
            invalid={invalid}
            cursor={(disabled && 'default') || (readOnly && 'pointer') || ''}
            autoComplete={autoComplete || 'off'}
            disabled={disabled}
            onKeyDownActive
            onKeyDown={() => {
              setOpen(false);
            }}
          />
        </label>
        <Div
          width="100%"
          maxHeight={224}
          border={{ color: 'g200' }}
          backgroundColor="g0"
          borderRadius="0.3rem"
          overflowY="overlay"
          position="absolute"
          zIndex={10}
          transform={
            (open && (options || list)?.length > 0 && 'scaleY(1)') ||
            'scaleY(0)'
          }
          transition="all 150ms ease-in-out"
          transformOrigin="top center"
        >
          {!hiddenRemove && !options && !multiple && (
            <Div
              className="dropdown-item"
              padding="8px"
              borderRadius="0.2rem"
              cursor="pointer"
              onMouseDown={() => onChangeHandler('')}
            >
              <Text>{t('select')}</Text>
            </Div>
          )}
          {(options || list)?.map((item, index) => (
            <Div
              setRef={(el) => (itemRef.current[index] = el)}
              className="dropdown-item"
              key={`${name}-${index}`}
              padding="8px"
              borderRadius="0.2rem"
              backgroundColor={
                (hovered === index && 'key100') ||
                (((customItem && selected === customItem(item)) ||
                  selected === item) &&
                  'key200') ||
                'g0'
              }
              onMouseEnter={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setHovered(index);
              }}
              onMouseLeave={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setHovered();
              }}
              transition="all 150ms"
              cursor="pointer"
              onMouseDown={() =>
                onChangeHandler(direct !== item ? item : '', index)
              }
            >
              <Text>{(customItem && customItem(item)) || item}</Text>
            </Div>
          ))}
        </Div>
      </Div>
    </Div>
  );
}
