import {
  Badge,
  HTMLWrap,
  Row,
  Text,
  BadgeButton,
  Bookmark,
  LabelBadge,
  Col,
  Div,
  Image,
  Icon,
} from 'components';
import React, { useState } from 'react';
import fileDownloader from 'utils/fileDownloader';
import { isMobile } from 'utils/isMobile';
import { useWindowSize } from 'hooks';

export function EnumerationDetailSpeakerCard({ t, item, logging, type }) {
  const [fileOpen, setFileOpen] = useState(false);
  const windowSize = useWindowSize();

  return (
    item && (
      <>
        <Row
          gap={30}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="flex-start"
          mFlexDirection="column"
          mAlignItems="center"
        >
          {(item?.isViewedThumbnail && item?.thumbnail && (
            <Image
              flex={4}
              width="100%"
              maxWidth={300}
              aspectRatio="3 / 4"
              objectFit="cover"
              borderRadius={10}
              src={item?.thumbnail}
            />
          )) ||
            ''}
          {((item?.isFixedTop === 1 ||
            item?.isViewedContentNewLabel ||
            item?.isViewedContentStatusLabel ||
            item?.isViewedContentBookmarkBtn ||
            (item?.isViewedContentLabel && item?.label) ||
            (item?.isViewedContentCategory && item?.category) ||
            (item?.isViewedContentName && item?.name) ||
            (item?.isViewedContentDescription && item?.description) ||
            (item?.isViewedContentTags && item?.tags?.length > 0) ||
            item?.isViewedContentLikeBtn ||
            item?.isViewedContentClickCount ||
            item?.isViewedContentCommentBtn ||
            (item?.isViewedContentAttachmentFileBtn &&
              item?.attachmentFiles?.length > 0)) && (
            <Col gap={50} flex={6} width="100%">
              {((item?.isFixedTop === 1 ||
                item?.isViewedContentNewLabel ||
                item?.isViewedContentStatusLabel ||
                item?.isViewedContentBookmarkBtn ||
                (item?.isViewedContentLabel && item?.label) ||
                (item?.isViewedContentCategory && item?.category) ||
                (item?.isViewedContentName && item?.name) ||
                (item?.isViewedContentDescription && item?.description)) && (
                <Col gap={10}>
                  {((!isMobile() || type === 'modal') &&
                    (item?.isFixedTop === 1 ||
                      item?.isViewedContentNewLabel ||
                      item?.isViewedContentStatusLabel ||
                      item?.isViewedContentBookmarkBtn) && (
                      <Row gap={30} justifyContent="space-between">
                        {((item?.isFixedTop === 1 ||
                          item?.isViewedContentNewLabel ||
                          item?.isViewedContentStatusLabel) && (
                          <Row gap={10} alignItems="center">
                            {(item?.isFixedTop === 1 && (
                              <Icon name="pin" fill="keyColor" />
                            )) ||
                              ''}
                            {(item?.isViewedContentNewLabel && (
                              <LabelBadge
                                type="h10"
                                backgroundColor="white"
                                color="keyColor"
                                border={{ color: 'key500' }}
                              >
                                New
                              </LabelBadge>
                            )) ||
                              ''}
                            {(item?.isViewedContentStatusLabel && (
                              <LabelBadge
                                type="h10"
                                backgroundColor={
                                  (item?.status === 'completed' && 'done200') ||
                                  'g100'
                                }
                                color={
                                  (item?.status === 'completed' && 'done') ||
                                  'g400'
                                }
                                padding="0 10px"
                              >
                                {item?.statusLabels[item?.status]}
                              </LabelBadge>
                            )) ||
                              ''}
                          </Row>
                        )) ||
                          ''}
                        {(item?.isViewedContentBookmarkBtn && (
                          <Bookmark
                            size={32}
                            isBookmark={item?.isBookmark}
                            backgroundColor={
                              (item?.isBookmark === 1 && 'key200') || 'g100'
                            }
                            fill={item?.isBookmark === 1 && 'keyColor'}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              logging('bookmark', item);
                            }}
                          />
                        )) ||
                          ''}
                      </Row>
                    )) ||
                    ''}

                  {(item?.isViewedContentLabel && item?.label && (
                    <Text
                      type="h5"
                      mt={10}
                      padding="2px 10px"
                      width="fit-content"
                      color="keyFontColor"
                      backgroundColor="keyColor"
                      borderRadius={5}
                    >
                      {item?.label}
                    </Text>
                  )) ||
                    ''}
                  {(((item?.isViewedContentCategory && item?.category) ||
                    (item?.isViewedContentName && item?.name)) && (
                    <Text type="h5">
                      {(item?.isViewedContentCategory &&
                        item?.category &&
                        `[${item?.category}]`) ||
                        ''}{' '}
                      {item?.isViewedContentName && `${item?.name}`}
                    </Text>
                  )) ||
                    ''}
                  {(item?.isViewedContentDescription && item?.description && (
                    <Text color="g500" type="b2">
                      {item?.description}
                    </Text>
                  )) ||
                    ''}
                </Col>
              )) ||
                ''}

              {(((item?.isViewedContentTags && item?.tags?.length > 0) ||
                item?.isViewedContentLikeBtn ||
                item?.isViewedContentClickCount ||
                item?.isViewedContentCommentBtn ||
                (item?.isViewedContentAttachmentFileBtn &&
                  item?.attachmentFiles?.length > 0) ||
                item?.content) && (
                <Col gap={10}>
                  {(((item?.isViewedContentTags && item?.tags?.length > 0) ||
                    item?.isViewedContentLikeBtn ||
                    item?.isViewedContentClickCount ||
                    item?.isViewedContentCommentBtn) && (
                    <Row gap={10} flexWrap="wrap">
                      {(item?.isViewedContentTags && item?.tags?.length > 0 && (
                        <Text
                          color="g400"
                          type="b4"
                          display="flex"
                          gap={6}
                          flexWrap="wrap"
                        >
                          {item?.tags?.map((tag) => (
                            <Badge
                              key={`${tag}${Math.random()}`}
                              color="g400"
                              type="b4"
                            >
                              {tag}
                            </Badge>
                          ))}
                        </Text>
                      )) ||
                        ''}

                      {((item?.isViewedContentLikeBtn ||
                        item?.isViewedContentClickCount ||
                        item?.isViewedContentCommentBtn) && (
                        <Row gap={10} ml="auto">
                          {(item?.isViewedContentLikeBtn && (
                            <BadgeButton
                              name="like"
                              active={item?.isLike === 1}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                logging('like', item);
                              }}
                            >
                              {(item?.isViewedContentLikeCount &&
                                ((item?.likes > 100 && '100+') ||
                                  item?.likes?.toString())) ||
                                ''}
                            </BadgeButton>
                          )) ||
                            ''}
                          {(item?.isViewedContentClickCount && (
                            <BadgeButton
                              name="view"
                              fill="g500"
                              stroke="transparent"
                            >
                              {(item?.clicks > 100 && '100+') ||
                                item?.clicks?.toString()}
                            </BadgeButton>
                          )) ||
                            ''}
                          {(item?.isViewedContentCommentBtn && (
                            <BadgeButton
                              name="comment"
                              point={item?.isNewComment === 1}
                            >
                              {(item?.isViewedContentCommentCount &&
                                ((Number(item?.comments) > 100 && '100+') ||
                                  item?.comments?.toString())) ||
                                ''}
                            </BadgeButton>
                          )) ||
                            ''}
                        </Row>
                      )) ||
                        ''}
                    </Row>
                  )) ||
                    ''}

                  {(item?.isViewedContentAttachmentFileBtn &&
                    item?.attachmentFiles?.length > 0 && (
                      <Div>
                        <Row
                          alignItems="center"
                          hover
                          cursor="pointer"
                          onClick={() => setFileOpen(!fileOpen)}
                          padding="4px 0"
                        >
                          <Icon
                            name="arrow"
                            fill="g500"
                            transform={
                              (fileOpen && 'rotate(-180deg)') || 'rotate(0)'
                            }
                            transition="all 150ms"
                          />
                          <Text type="b2">
                            {t('file_count', {
                              count: item?.attachmentFiles.length,
                            })}
                          </Text>
                        </Row>
                        {(fileOpen && (
                          <Col
                            border={{ color: 'g200' }}
                            borderRadius={6}
                            padding="15px 20px"
                            gap={10}
                            width="100%"
                          >
                            {item?.attachmentFiles.map((file) => (
                              <Row
                                key={file.fileUrl}
                                onClick={() =>
                                  fileDownloader(file.fileUrl, file.fileName)
                                }
                                gap={30}
                                alignItems="center"
                                cursor="pointer"
                                hover="background"
                                justifyContent="space-between"
                              >
                                <Text
                                  maxLine={1}
                                  style={{ wordBreak: 'break-word' }}
                                >
                                  {file.fileName}
                                </Text>
                                <Icon name="download" fill="g800" />
                              </Row>
                            ))}
                          </Col>
                        )) ||
                          ''}
                      </Div>
                    )) ||
                    ''}

                  {(item?.content && (
                    <Div
                      mt={20}
                      padding="20px"
                      maxHeight={type === 'modal' && '400px'}
                      backgroundColor="g100"
                      borderRadius={10}
                      overflowY="auto"
                    >
                      <HTMLWrap
                        content={
                          windowSize.width > 1279
                            ? item?.content
                            : (item?.mobileContent && item?.mobileContent) ||
                              item?.content
                        }
                      />
                    </Div>
                  )) ||
                    ''}
                </Col>
              )) ||
                ''}
            </Col>
          )) ||
            ''}
        </Row>
      </>
    )
  );
}
