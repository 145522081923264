import { Div, HTMLWrap, Row, Text } from 'components';
import { useWindowSize } from 'hooks';
import React from 'react';
import fileDownloader from 'utils/fileDownloader';

function WYSIWYGPresenter({ module }) {
  const windowSize = useWindowSize();
  return (
    <>
      <HTMLWrap
        width="100%"
        content={
          windowSize.width > 1279
            ? module?.content
            : (module?.mobileContent && module?.mobileContent) ||
              module?.content
        }
      />
      {module?.attachmentFiles?.length > 0 && module?.attachmentFiles[0] && (
        <Row
          flexDirection={
            (module?.attachmentFilesOrientation === 'vertical' && 'column') ||
            'row'
          }
          justifyContent={
            module?.attachmentFilesOrientation === 'horizontal'
              ? (module.attachmentFilesAlign === 'left' && 'flex-start') ||
                (module.attachmentFilesAlign === 'center' && 'center') ||
                (module.attachmentFilesAlign === 'right' && 'flex-end')
              : 'center'
          }
          alignItems={
            module?.attachmentFilesOrientation === 'vertical'
              ? (module.attachmentFilesAlign === 'left' && 'flex-start') ||
                (module.attachmentFilesAlign === 'center' && 'center') ||
                (module.attachmentFilesAlign === 'right' && 'flex-end')
              : 'center'
          }
          gap={module?.attachmentFilesIntermediate}
        >
          {module?.attachmentFiles?.map((el, index) => (
            <Div
              key={`${module?.moduleCode}-button-${index}`}
              backgroundColor={el.backgroundColor}
              pt={el.paddingTop}
              pb={el.paddingBottom}
              pr={el.paddingRight}
              pl={el.paddingLeft}
              borderRadius={el.borderRadius}
              boxShadow={el.boxShadow}
              style={{ border: el.border }}
              cursor="pointer"
              hover
              onClick={() => fileDownloader(el.fileUrl, el.fileName)}
            >
              <Text fontSize={Number(el.fontSize)} color={el.fontColor}>
                {el.label}
              </Text>
            </Div>
          ))}
        </Row>
      )}
    </>
  );
}

export default WYSIWYGPresenter;
