import React, { Suspense, lazy } from 'react';
import { Image, Skeleton } from 'components';

const CardComponent = lazy(() => import('./Icon'));

const renderLoader = (size) => (
  <Skeleton width={size || 40} height={size || 40} borderRadius="999px" />
);

export function ProfileImage({ thumbnail, size, ...props }) {
  const profileBackgroundColor = [
    '#D4E4FB',
    '#FFEACB',
    '#FBF7D4',
    '#E5FBD4',
    '#D4FBF4',
    '#DAD4FB',
    '#EAD4FB',
    '#F5D4FB',
    '#FCCEDE',
    '#FDDADC',
    '#E9EBED',
  ];
  return (
    <Suspense fallback={renderLoader(size)}>
      {(thumbnail?.length === 1 || thumbnail === 'default') && (
        <CardComponent
          name="profile"
          fill={
            profileBackgroundColor[
              thumbnail === 'default' ? 10 : Number(thumbnail)
            ]
          }
          backgroundColor="white"
          borderRadius="999px"
          size={size}
          {...props}
        />
      )}
      {thumbnail?.length !== 1 && thumbnail !== 'default' && (
        <Image
          src={thumbnail}
          backgroundColor="white"
          borderRadius="999px"
          width={size || '100%'}
          height={size || '100%'}
          aspectRatio="1 / 1"
          objectFit="cover"
          {...props}
        />
      )}
    </Suspense>
  );
}
