import {
  Div,
  PlayList,
  Streaming,
  SidePlayList,
  StreamingB,
  PlayListB,
} from 'components';
import React from 'react';

function StreamingPresenter({
  type,
  streamingItem,
  changeStreaming,
  module,
  user,
  filters,
  setFilters,
  tagList,
  contentView,
  setContentView,
}) {
  return (
    <>
      {type === 'main' && (
        <>
          {module?.designType === 'design1' && (
            <Div width="100%" padding="40px 0">
              <Streaming
                item={streamingItem}
                user={user}
                contentView={contentView}
                setContentView={setContentView}
              />
              {module?.list?.length > 0 &&
                streamingItem?.playlistOption === 'bottom' && (
                  <PlayList
                    list={module?.list}
                    user={user}
                    changeStreaming={changeStreaming}
                  />
                )}
            </Div>
          )}
          {module?.designType === 'design2' && (
            <Div width="100%">
              <StreamingB
                item={streamingItem}
                user={user}
                contentView={contentView}
                setContentView={setContentView}
              />
              {streamingItem?.playlistOption === 'bottom' &&
                module?.list?.length > 0 && (
                  <PlayListB
                    list={module?.list}
                    user={user}
                    changeStreaming={changeStreaming}
                    module={module}
                    filters={filters}
                    setFilters={setFilters}
                    tagList={tagList}
                  />
                )}
            </Div>
          )}
          {module?.designType === 'design3' && (
            <Div width="100%">
              <StreamingB
                item={streamingItem}
                user={user}
                contentView={contentView}
                setContentView={setContentView}
              />
              {streamingItem?.playlistOption === 'bottom' &&
                module?.list?.length > 0 && (
                  <PlayListB
                    list={module?.list}
                    user={user}
                    changeStreaming={changeStreaming}
                    module={module}
                    filters={filters}
                    setFilters={setFilters}
                    tagList={tagList}
                  />
                )}
            </Div>
          )}
        </>
      )}
      {module?.list?.length > 0 &&
        type === 'side' &&
        streamingItem?.playlistOption === 'right' && (
          <SidePlayList
            list={module?.list}
            user={user}
            changeStreaming={changeStreaming}
          />
        )}
    </>
  );
}

export default StreamingPresenter;
