import { Span } from 'components';
import styled from 'styled-components';
import { hexToRGB } from 'utils/css/hexToRGB';
import { elementSize } from './css';

export const IconStyles = styled(Span)`
  vertical-align: middle;
  & > svg {
    ${elementSize}
    ${({ theme, fill }) =>
      (fill && `fill: ${hexToRGB(theme.colors[fill] || fill, 1)};`) || ''};
    ${({ theme, stroke }) =>
      (stroke && `stroke: ${hexToRGB(theme.colors[stroke] || stroke, 1)};`) ||
      ''};
    ${({ fillOpacity }) =>
      (fillOpacity && `fill-opacity:${fillOpacity};`) || ''}
  }
`;
