import { useState } from 'react';
import { useInterval } from 'react-use';

function useVerificationTime(init) {
  // timer
  const delay = 1000;
  const [minutes, setMinutes] = useState(parseInt(3, 10));
  const [seconds, setSeconds] = useState(parseInt(0, 10));
  const [isRunning, setIsRunning] = useState(false);

  const initTimer = (running) => {
    setIsRunning(running || false);
    setMinutes(parseInt(3, 10));
    setSeconds(parseInt(0, 10));
    init();
  };

  useInterval(
    () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // 초기화
          initTimer();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    },
    isRunning ? delay : null,
  );

  return { minutes, seconds, isRunning, setIsRunning, initTimer };
}

export default useVerificationTime;
