import { BubbleSkeleton, ChatInput, Col, Text, Div, Icon } from 'components';
import { useWindowSize } from 'hooks';
import React, { Suspense, lazy } from 'react';

const renderLoader = () => (
  <Col gap={30} padding="20px 20px 0 20px" height="100%">
    <BubbleSkeleton />
    <BubbleSkeleton />
    <BubbleSkeleton />
    <BubbleSkeleton />
    <BubbleSkeleton />
  </Col>
);

const ChatList = lazy(() => import('components/Modules/Chat/UserChatList'));

function ChatPresenter({
  bucket,
  currentLanguage,
  scrollRef,
  scrollHandler,
  showBottomScrollButton,
  scrollBottom,
  module,
  item,
  list,
  sendMessage,
  inputFocusHandler,
  back,
  ...props
}) {
  const windowSize = useWindowSize();
  return (
    <Col
      position="fixed"
      width="100%"
      height="height"
      top="60px"
      left="0"
      zIndex={7000}
      backgroundColor="g100"
      mHeight={
        windowSize.height -
        (bucket?.tabMenuInfo[currentLanguage]?.length > 0 ? 120 : 60)
      }
      {...props}
    >
      <Div
        width="100%"
        padding="9px 25px"
        backgroundColor="white"
        position="relative"
      >
        <Icon
          name="arrow"
          fill="fontColor"
          size={24}
          transform="rotate(90deg)"
          onClick={back}
          cursor="pointer"
          hover="background"
        />
        <Text
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          type="h7"
        >
          {item?.userName}
        </Text>
      </Div>
      <Suspense fallback={renderLoader()}>
        <ChatList
          scrollRef={scrollRef}
          scrollHandler={scrollHandler}
          list={list}
          module={module}
          scrollBottom={scrollBottom}
          showBottomScrollButton={showBottomScrollButton}
        />
      </Suspense>
      <Div
        width="100%"
        padding="20px"
        backgroundColor="white"
        borderTop={{ color: 'g200' }}
      >
        <ChatInput send={sendMessage} inputFocusHandler={inputFocusHandler} />
      </Div>
    </Col>
  );
}

export default ChatPresenter;
