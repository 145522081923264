import React, { useEffect, useState } from 'react';
import { Div, Input, Row, WYSIWYGTitle } from 'components';
import { isEmptyObject } from 'utils/isEmptyObject';

export function Name({
  item,
  onChange,
  name,
  invalid,
  initValue,
  disabled,
  ...props
}) {
  const [init, setInit] = useState(false);
  const { itemCode, itemType, selections } = item;

  const names = {
    name_detail_1: { lastName: '', firstName: '' },
    name_detail_2: { firstName: '', lastName: '' },
    name_detail_3: { lastName: '', middleName: '', firstName: '' },
    name_detail_4: { firstName: '', middleName: '', lastName: '' },
  };
  const [nameValue, setNameValue] = useState({ ...names[itemType] });

  const onChangeHandler = (val, nameType) => {
    setInit(true);
    setNameValue({ ...nameValue, [nameType]: val });
  };

  useEffect(() => {
    const tempNameValue = JSON.parse(JSON.stringify(nameValue));
    delete tempNameValue.middleName;

    if (init && !isEmptyObject(tempNameValue)) {
      if (nameValue.middleName) {
        onChange({ ...nameValue }, name);
      } else {
        onChange({ ...tempNameValue }, name);
      }
    } else if (init) {
      onChange('', name);
    }
  }, [nameValue, init]);

  useEffect(() => {
    if (initValue) {
      setNameValue({
        ...initValue,
      });
    }
  }, [initValue]);

  return (
    item && (
      <Row gap={20}>
        {Object.keys(names[itemType]).map((nameType, index) => (
          <Div flex={1} key={`${itemCode}-${nameType}`}>
            <WYSIWYGTitle
              title={selections[index].title || nameType}
              item={{
                ...item,
                isRequired: nameType === 'middleName' ? false : item.isRequired,
              }}
            />
            <Input
              value={nameValue[nameType] || ''}
              onChange={(value) => onChangeHandler(value, nameType)}
              name={`${name}-${nameType}`}
              invalid={nameType === 'middleName' ? null : invalid}
              disabled={disabled}
            />
          </Div>
        ))}
      </Row>
    )
  );
}
