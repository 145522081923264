import React, { useEffect, useState } from 'react';
import { Div, Input } from 'components';
import { useDaumPostcodePopup } from 'react-daum-postcode';

export function Address({ type, onChange, name, initValue, disabled }) {
  const [init, setInit] = useState(false);
  const [values, setValues] = useState();

  const open = useDaumPostcodePopup();

  const handleComplete = (data) => {
    setInit(true);
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    const params =
      (type === 'post_number' && { postNumber: data.zonecode }) ||
      (type === 'address' && { address: fullAddress }) ||
      (type === 'full_address' && {
        postNumber: data.zonecode,
        address: fullAddress,
      });
    setValues({ ...values, ...params });
  };

  const handleClick = () => {
    if (!disabled) {
      open({ onComplete: handleComplete });
    }
  };

  useEffect(() => {
    if (type) {
      setValues(
        (type === 'post_number' && { postNumber: '' }) ||
          (type === 'address' && { address: '', addressDetail: '' }) ||
          (type === 'full_address' && {
            postNumber: '',
            address: '',
            addressDetail: '',
          }),
      );
    }
  }, [type]);

  useEffect(() => {
    if (initValue) {
      setValues({ ...initValue });
    }
  }, [initValue]);

  useEffect(() => {
    if (values && name && init) {
      onChange(
        (((type === 'post_number' && values.postNumber) ||
          ((type === 'address' || type === 'full_address') &&
            values.address)) && { ...values }) ||
          '',
        name,
      );
    }
  }, [values, type, name, init]);

  return (
    <Div>
      {(type === 'post_number' || type === 'full_address') && (
        <Input
          name="postNumber"
          readOnly
          cursor="pointer"
          onClick={handleClick}
          value={values?.postNumber || ''}
          disabled={disabled}
        />
      )}
      {(type === 'full_address' || type === 'address') && (
        <Input
          name="address"
          autoComplete="off"
          readOnly
          cursor="pointer"
          onClick={handleClick}
          value={values?.address || ''}
          wrapStyles={{ margin: '10px 0' }}
          disabled={disabled}
        />
      )}
      {(type === 'full_address' || type === 'address') && (
        <Input
          name="addressDetail"
          value={values?.addressDetail || ''}
          onChange={(val) => {
            setInit(true);
            setValues({ ...values, addressDetail: val });
          }}
          disabled={disabled}
        />
      )}
    </Div>
  );
}
