import React from 'react';
import { Row, Comment, Text, Icon } from 'components';

export function Recomment({
  t,
  module,
  list,
  item,
  user,
  viewMore,
  setViewMore,
  editModuleLog,
  setAlert,
  deleteModuleLog,
  recommentPages,
  comment,
  recommentFilters,
  changeRecommentPage,
}) {
  return (
    <>
      {list.map((reItem) => (
        <Comment
          padding="0 20px 20px 60px"
          module={module}
          type="recomment"
          key={reItem.commentCode}
          item={item}
          user={user}
          comment={reItem}
          viewMore={viewMore}
          setViewMore={setViewMore}
          editing={editModuleLog}
          deleting={() =>
            setAlert({
              title: t('alert_title'),
              content: t('delete_warning'),
              confirm: () =>
                deleteModuleLog(reItem.commentCode, reItem.parentCommentCode),
              cancel: () => setAlert(),
            })
          }
        />
      ))}
      {recommentPages[comment.commentCode]?.totalPages >
        recommentFilters[comment.commentCode]?.page && (
        <Row
          padding="0 20px 20px 20px"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          hover
          onClick={() => {
            changeRecommentPage(comment);
          }}
        >
          <Text type="b2" color="g500">
            {t('recomment-more')}
          </Text>
          <Icon name="arrow" fill="g500" />
        </Row>
      )}
    </>
  );
}
