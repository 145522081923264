import CryptoJS from 'crypto-js';

export const base64Encoding = (plainText) => {
  const text = CryptoJS.enc.Utf8.parse(plainText);
  return CryptoJS.enc.Base64.stringify(text);
};

export const encrypt = (plainText) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTION_INIT_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTION_INIT_IV);
  return base64Encoding(
    CryptoJS.AES.encrypt(plainText, key, {
      iv,
    }).toString(),
  );
};
