import { useEffect, useRef, useState } from 'react';

function useIsElementInViewport(threshold = 0.1) {
  const elementRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const callback = (entries, observer) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(true);
      observer.unobserve(entry.target);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, { threshold });
    if (elementRef.current) observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [elementRef.current]);

  return { elementRef, isVisible };
}

export default useIsElementInViewport;
