import { Col, Dim, Div, Icon, Image, Row, TabIcon, Text } from 'components';
import { useLanguageNavigate, useWindowSize } from 'hooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { linkTypeClick } from 'utils/linkType';

export function Tab({ t, bucket, menuCode }) {
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const { tabMenuInfo } = bucket;

  const { languageNavigate, currentLanguage } = useLanguageNavigate();
  const windowSize = useWindowSize();

  const goMenu = (selectPageCode) => {
    languageNavigate(`/${selectPageCode}`);
  };

  const goLinkType = (type, menu) => {
    linkTypeClick(
      type,
      menu.url,
      (menu.withInfo === 1 && { key: 'user', value: user.name }) || '',
    );
  };

  const [more, setMore] = useState(false);

  return (
    <>
      {tabMenuInfo[currentLanguage]?.length > 0 && (
        <Row
          width="100vw"
          backgroundColor="backgroundColor"
          position="fixed"
          transition="all 150ms"
          zIndex={9000}
          left="0"
          bottom="0"
          height={55}
          pb={5}
        >
          {tabMenuInfo[currentLanguage]?.map(
            (tab, index) =>
              index < 5 && (
                <Col
                  key={tab.name}
                  alignItems="center"
                  justifyContent="center"
                  flex={1}
                  pt={7}
                  gap={4}
                  cursor="pointer"
                  position="relative"
                  onClick={() => {
                    if (
                      tabMenuInfo[currentLanguage].length > 5 &&
                      index === 4
                    ) {
                      setMore(true);
                    } else {
                      setMore(false);
                      if (tab.type === 'page') goMenu(tab.key);
                      if (tab.type === 'inLink') goLinkType('inLink', tab);
                      if (tab.type === 'outLink') goLinkType('outLink', tab);
                    }
                  }}
                >
                  {(tabMenuInfo[currentLanguage].length > 5 && index === 4 && (
                    <>
                      <TabIcon
                        name="more"
                        stroke={(more && 'keyColor') || 'g500'}
                      />
                      {tabMenuInfo[currentLanguage][index - 1]?.name && (
                        <Text type="b4" color={(more && 'keyColor') || 'g500'}>
                          {t('more')}
                        </Text>
                      )}
                    </>
                  )) || (
                    <>
                      {tab.isActionMenu === 1 && (
                        <Div
                          backgroundColor="backgroundColor"
                          width={58}
                          height={58}
                          minWidth={58}
                          minHeight={58}
                          borderRadius="999px"
                          padding="6px"
                          position="absolute"
                          top="-17px"
                        >
                          <Col
                            width={46}
                            height={46}
                            minWidth={46}
                            minHeight={46}
                            backgroundColor="keyColor"
                            borderRadius="999px"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {!tab.icon[0].includes('http') && (
                              <TabIcon name={tab.icon[0]} fill="keyFontColor" />
                            )}
                          </Col>
                        </Div>
                      )}
                      {(!tab.icon[0].includes('http') && (
                        <TabIcon
                          name={tab.icon[0]}
                          zIndex={(tab.isActionMenu === 1 && -1) || 'unset'}
                          active={!more && menuCode === tab.key}
                        />
                      )) || (
                        <Image
                          src={
                            (!more && menuCode === tab.key && tab.icon[1]) ||
                            tab.icon[0]
                          }
                          width={24}
                          height={24}
                          objectFit="contain"
                        />
                      )}
                      {tab.name && (
                        <Text
                          type="b4"
                          color={
                            (!more && menuCode === tab.key && 'keyColor') ||
                            'g500'
                          }
                          zIndex={(tab.isActionMenu === 1 && 2) || 'unset'}
                        >
                          {tab.name}
                        </Text>
                      )}
                    </>
                  )}
                </Col>
              ),
          )}
        </Row>
      )}
      {more && (
        <Dim overflowHidden zIndex={5000} backgroundColor="transparent" />
      )}
      <Div
        width="100%"
        height={windowSize.height}
        position="fixed"
        top="0"
        backgroundColor="backgroundColor"
        zIndex={9001}
        boxShadow="default"
        right={more ? '0px' : '-100%'}
        transition="all 0.3s ease-in-out"
        transformOrigin="right"
        overflowY="overlay"
      >
        <Row padding="9px 25px" alignItems="center" justifyContent="flex-end">
          <Icon name="delete-simple" onClick={() => setMore(false)} />
        </Row>
        {tabMenuInfo[currentLanguage]?.slice(4)?.map((el) => (
          <Div key={el.name}>
            <Row
              position="relative"
              padding="20px 20px 20px 30px"
              onClick={() => {
                if (el.type === 'page') goMenu(el.key);
                if (el.type === 'inLink') goLinkType('inLink', el);
                if (el.type === 'outLink') goLinkType('outLink', el);

                setMore(false);
              }}
              cursor="pointer"
              alignItems="center"
              justifyContent="space-between"
              gap={10}
              hover
            >
              <Div
                position="absolute"
                width={2}
                height="100%"
                backgroundColor="keyColor"
                left="0"
                borderRadius="0rem 0.6rem 0rem 0.6rem"
                transform={
                  ((menuCode === el.key ||
                    el.innerMenus
                      ?.map((innerEl) => innerEl.key)
                      .includes(menuCode)) &&
                    'scaleY(1)') ||
                  'scaleY(0)'
                }
                transition="transform 0.3s ease-in-out"
                transformOrigin="top"
              />
              <Text type="b2" position="relative">
                {el.name}
              </Text>
            </Row>
          </Div>
        ))}
      </Div>
    </>
  );
}
