import React from 'react';
import { ButtonStyle, RoundButtonStyle } from 'styles/ButtonStyle';
import { Div, Row, Icon, Loading, Text } from 'components';
import { useTranslation } from 'react-i18next';

export function Button({
  setRef,
  onClick,
  buttonType,
  type,
  loading,
  disabled,
  position,
  fontWeight,
  textAlign,
  active,
  keyColor,
  keyFontColor,
  ghost,
  children,
  ...props
}) {
  return (
    <ButtonStyle
      ref={setRef}
      buttonType={buttonType || 'default'}
      type={type || 'h7'}
      onClick={onClick}
      backgroundColor={
        ((buttonType?.includes('text') || ghost) && 'transparent') ||
        (active && keyColor) ||
        (active && 'keyColor') ||
        'g200'
      }
      color={
        (loading && 'transparent') ||
        (active && keyFontColor) ||
        (active && 'keyFontColor') ||
        'g500'
      }
      disabled={loading || disabled}
      textAlign={textAlign || 'center'}
      position={position || 'relative'}
      $loading={loading}
      active={active}
      borderRadius={(buttonType === 'small' && '0.9rem') || '1.2rem'}
      {...props}
    >
      {loading && (
        <Loading
          position="absolute"
          keyColor={keyColor}
          backgroundColor={{ color: 'g200', opacity: 0.4 }}
          delay="0"
        />
      )}
      {children || 'button'}
    </ButtonStyle>
  );
}

export function RoundButton({
  setRef,
  onClick,
  buttonType,
  type,
  loading,
  disabled,
  position,
  fontWeight,
  textAlign,
  active,
  keyColor,
  keyFontColor,
  ghost,
  children,
  ...props
}) {
  return (
    <RoundButtonStyle
      ref={setRef}
      buttonType={buttonType || 'default'}
      type={type || 'h7'}
      onClick={onClick}
      backgroundColor={
        ((buttonType?.includes('text') || ghost) && 'transparent') ||
        (active && keyFontColor) ||
        (active && 'keyFontColor') ||
        'white'
      }
      color={(active && keyColor) || (active && 'keyColor') || 'g300'}
      border={{ color: (active && 'keyColor') || 'transparent' }}
      disabled={loading || disabled}
      textAlign={textAlign || 'center'}
      position={position || 'relative'}
      $loading={loading}
      active={active}
      borderRadius="999px"
      {...props}
    >
      {loading && (
        <Loading
          position="absolute"
          keyColor={keyColor}
          backgroundColor={{ color: 'g200', opacity: 0.4 }}
          delay="0"
        />
      )}
      {children || 'button'}
    </RoundButtonStyle>
  );
}

export function BadgeButton({
  size,
  name,
  active,
  children,
  fill,
  stroke,
  type,
  onClick,
  color,
  ...props
}) {
  return (
    <Row
      borderRadius="0.6rem"
      alignItems="center"
      cursor={onClick ? 'pointer' : 'default'}
      hover={!!onClick}
      position="relative"
      gap={5}
      onClick={onClick}
      {...props}
    >
      <Icon
        name={name}
        fill={fill || (active && 'keyColor') || ''}
        stroke={stroke || (active && 'keyColor') || 'g500'}
        size={size || 20}
      />
      {children && (
        <Text type={type || 'b2'} color={color || 'g400'}>
          {children}
        </Text>
      )}
    </Row>
  );
}

export function Download({ size, ...props }) {
  const { t } = useTranslation();
  return (
    <Row
      border={{ color: 'keyColor' }}
      borderRadius="0.6rem"
      backgroundColor="g0"
      alignItems="center"
      padding={(size === 'large' && '5px 8px 4px 4px') || '1px 4px 1px 0'}
      cursor="pointer"
      hover
      {...props}
    >
      <Icon name="attach" size={20} fill="keyColor" />
      <Text
        type={(size === 'large' && 'b2') || 'b3'}
        color="keyColor"
        whiteSpace="nowrap"
      >
        {t('download')}
      </Text>
    </Row>
  );
}

export function Bookmark({
  isBookmark,
  size,
  backgroundColor,
  fill,
  ...props
}) {
  return (
    <Div backgroundColor="white" borderRadius="0.6rem">
      <Icon
        name={(isBookmark === 1 && 'bookmark-done') || 'bookmark'}
        backgroundColor={(isBookmark === 1 && 'key300') || 'g200'}
        fill={(isBookmark === 1 && 'keyColor') || ''}
        size={size || 24}
        borderRadius="0.6rem"
        cursor="pointer"
        hover
        {...props}
      />{' '}
    </Div>
  );
}
