import React, { useEffect, useState } from 'react';
import { moduleApi } from 'api';
import { Alert, Div, Loading, Pagination } from 'components';
import {
  useApiError,
  useApiParams,
  useBucketReset,
  useLanguageNavigate,
  useScrollPagination,
} from 'hooks';
import { modules } from 'modules';
import { useSearchParams } from 'react-router-dom';
import {
  scrollPaginationModules,
  buttonPaginationModules,
  fullPageModules,
} from 'data/layout';
import { useTranslation } from 'react-i18next';

const perPageType = {
  board: 30,
  enumeration: 30,
};

function Modules({
  bucket,
  menu,
  type,
  moduleCode,
  moduleType,
  socket,
  sidePlayListCallback,
  streamingItem,
  streamingItemCallback,
  ...props
}) {
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pages, setPages] = useState();
  const [filters, setFilters] = useState();
  const { logout, reload, needLogin, needProfile } = useBucketReset();
  const { languageNavigate } = useLanguageNavigate();
  const { alert, setAlert, setError } = useApiError();
  const [isTemporaryRegistration, setIsTemporaryRegistration] = useState();

  useEffect(() => {
    if (pages) {
      searchParams.set('page', filters.page);
      searchParams.set('lastRowNum', filters.lastRowNum);

      setSearchParams(searchParams);
    }
  }, [pages]);

  useEffect(() => {
    if (moduleCode && moduleType && !filters) {
      setFilters({
        page: searchParams.get('page') || 1,
        lastRowNum: searchParams.get('lastRowNum') || 0,
        perPage: perPageType[moduleType] || 12,
      });
    }
  }, [moduleCode, moduleType, filters]);

  const [loading, setLoading] = useState(true);

  const [module, setModule] = useState();

  const getModules = async () => {
    setLoading(true);
    const [info, error] = await moduleApi.moduleInfo(moduleCode, {
      ...DEFAULT_PARAMS,
      ...filters,
    });
    if (info) {
      const { result } = info;
      if (result.isNecessaryInit === 1) {
        logout();
      } else if (result.isNecessaryReload === 1) {
        reload();
      } else if (result.isNecessaryLogin === 1) {
        needLogin();
      } else if (result.isNecessaryProfile === 1) {
        needProfile();
      } else {
        if (
          result.isTemporary === 1 &&
          (result.isCompleted === 0 || !result.isCompleted)
        ) {
          setModule({ ...result });
          setAlert({
            title: t('alert_title'),
            content: t('temp_load_message'),
            confirm: () => {
              setIsTemporaryRegistration(true);
              setAlert();
              setLoading(false);
            },
            cancel: () => {
              setIsTemporaryRegistration(false);
              setAlert();
              setLoading(false);
            },
          });
        } else if (scrollPaginationModules.includes(result.moduleType)) {
          const resultList = (Number(filters.page) !== 1 && [
            ...module.list,
            ...result.list,
          ]) || [...result.list];
          setModule({
            ...result,
            list: resultList,
          });
          setLoading(false);
        } else if (
          buttonPaginationModules.includes(result.moduleType) &&
          result.currentCount === 0 &&
          Number(filters.page) !== 1
        ) {
          // 1페이지 이상이고 페이지에 아이템이 없을 경우 이전 페이지로
          setFilters({
            page: filters.page - 1,
            lastRowNum: (perPageType[moduleType] || 12) * (filters.page - 2),
            perPage: perPageType[moduleType] || 12,
          });
        } else if (buttonPaginationModules.includes(result.moduleType)) {
          setPages(result);
          setModule({ ...result });
          setLoading(false);
        } else {
          setIsTemporaryRegistration(false);
          setModule({ ...result });
          setLoading(false);
        }

        if (moduleType === 'streaming' && sidePlayListCallback) {
          sidePlayListCallback(result);
        }
      }
    } else if (error?.resCode === 451) {
      // TokenExpiredError
      logout();
    } else if (error?.resCode === 457) {
      languageNavigate(`/login`);
    } else {
      setError(error);
      setLoading(false);
    }
  };

  // scrollpagination
  const changePage = () => {
    if (module?.totalPages > filters?.page) {
      const i = filters.page + 1;
      setFilters({
        ...filters,
        page: i,
        lastRowNum: filters.perPage * (i - 1),
        perPage: filters.perPage,
      });
    }
  };

  const { loadingRef, setScrollPaginationLoading } =
    useScrollPagination(changePage);

  useEffect(() => {
    if (scrollPaginationModules.includes(moduleType)) {
      setScrollPaginationLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (filters) {
      getModules();
    }

    return () => setLoading(true);
  }, [filters]);

  useEffect(() => {
    if (moduleCode && moduleType) {
      connectRoom();
    }

    return () => {
      if (moduleCode && moduleType) {
        disconnectRoom();
        setFilters();
        setModule();
      }
    };
  }, [moduleCode, moduleType]);

  // socket
  const connectRoom = () => {
    socket.emit('join room', { roomId: moduleCode });
  };

  const disconnectRoom = () => {
    socket.emit('exit room', { roomId: moduleCode });
  };

  return (
    <>
      {loading && <Loading delay="0" />}
      <Div
        className="module-wrap"
        position="relative"
        height={
          (type === 'side' && '100%') ||
          (fullPageModules.includes(moduleType) && 'height') ||
          '100%'
        }
        overflowY={
          (!fullPageModules.includes(moduleType) &&
            type === 'side' &&
            'overlay') ||
          ''
        }
        maxWidth={(module?.isFullWidth === 1 && '100%') || 1280}
        pt={module?.styles?.paddingTop}
        pl={module?.styles?.paddingLeft}
        pr={module?.styles?.paddingRight}
        pb={module?.styles?.paddingBottom}
        backgroundColor={module?.styles?.backgroundColor}
        backgroundImage={module?.styles?.backgroundImg}
        backgroundSize="cover"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        borderRadius={module?.styles?.borderRadius}
        boxShadow={module?.styles?.boxShadow || ''}
        {...props}
      >
        {
          modules(
            bucket,
            menu,
            type,
            module,
            filters,
            setFilters,
            getModules,
            setModule,
            socket,
            loadingRef,
            loading,
            streamingItem,
            streamingItemCallback,
            isTemporaryRegistration,
          )[moduleType]
        }
        {moduleType !== 'memo' && (
          <Pagination
            pages={pages}
            filters={filters}
            setFilters={setFilters}
            margin="40px 0"
          />
        )}
      </Div>
      {alert && <Alert {...alert} />}
    </>
  );
}

export default Modules;
