import { Dropdown, Invalid, Row } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Birthday({
  onChange,
  name,
  invalid,
  initValue,
  isOnlyAdult,
  disabled,
  ...props
}) {
  const [init, setInit] = useState(false);
  const { t } = useTranslation();
  const [defaultInvalid, setDefaultInvalid] = useState({ type: '', text: '' });
  const [date, setDate] = useState({ year: '', month: '', date: '' });

  function range(start, end) {
    const array = [];
    for (let i = start; i < end; i += 1) {
      array.push(i);
    }
    return array;
  }

  const today = new Date();
  const years = range(1924, today.getFullYear() + 1).reverse();
  const onlyAdultYears = range(1924, today.getFullYear() - 18).reverse();
  const months = range(1, 13);
  const dates = range(1, 32);

  const onDateChangeHandler = (val, type) => {
    setInit(true);
    const newDate = { ...date, [type]: val };
    setDate(newDate);

    if (newDate.year && newDate.month && newDate.date) {
      const resultDate = new Date(
        `${newDate.year}-${newDate.month
          ?.toString()
          ?.padStart(2, '0')}-${newDate.date?.toString()?.padStart(2, '0')}`,
      );
      if (!newDate.year || resultDate.toString() === 'Invalid Date') {
        setDefaultInvalid({ type: 'error', text: t('invalid_date') });
      } else if (resultDate > new Date()) {
        setDefaultInvalid({ type: 'error', text: t('invalid_birthdate') });
      } else {
        setDefaultInvalid({ type: '', text: '' });
        onChange(
          { year: newDate.year, month: newDate.month, date: newDate.date },
          name,
        );
      }
    }
  };

  useEffect(() => {
    if (init) {
      setDefaultInvalid({ type: '', text: '' });
    }
  }, [init]);

  useEffect(() => {
    if (initValue) {
      setDate({
        year: initValue.year,
        month: initValue.month,
        date: initValue.date,
      });
    }
  }, [initValue]);

  return (
    <>
      <Row gap={10}>
        <Dropdown
          name="year"
          value={date.year}
          list={(isOnlyAdult && onlyAdultYears) || years}
          invalid={{ type: invalid?.type || defaultInvalid.type }}
          onChange={onDateChangeHandler}
          disabled={disabled}
        />
        <Dropdown
          name="month"
          value={date.month}
          list={months}
          invalid={{ type: invalid?.type || defaultInvalid.type }}
          onChange={onDateChangeHandler}
          disabled={disabled}
        />
        <Dropdown
          name="date"
          value={date.date}
          list={dates}
          invalid={{ type: invalid?.type || defaultInvalid.type }}
          onChange={onDateChangeHandler}
          disabled={disabled}
        />
      </Row>
      <Invalid invalid={(invalid?.type && invalid) || defaultInvalid} />
    </>
  );
}
