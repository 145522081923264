import React from 'react';
import { Row, Skeleton, Div } from 'components';

export function BubbleSkeleton({ ...props }) {
  return (
    <Row gap={10} {...props}>
      <Skeleton width={40} height={40} borderRadius="999px" />
      <Div>
        <Skeleton width={77} height={12} borderRadius="6px" mb={4} />
        <Skeleton width={200} height={49} />
        <Skeleton width={30} height={10} borderRadius="6px" mt={4} />
      </Div>
    </Row>
  );
}
