import { css } from 'styled-components';
import { checkPixel } from 'utils/css/checkPixel';

export const flexContainer = css`
  ${({ flexDirection }) =>
    (flexDirection && `flex-direction: ${flexDirection};`) || ''};
  ${({ flexWrap }) => (flexWrap && `flex-wrap: ${flexWrap};`) || ''};
  ${({ flexFlow }) => (flexFlow && `flex-flox: ${flexFlow};`) || ''};
  ${({ justifyContent }) =>
    (justifyContent && `justify-content: ${justifyContent};`) || ''};
  ${({ alignItems }) => (alignItems && `align-items: ${alignItems};`) || ''};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent};`};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ mFlexDirection }) =>
      (mFlexDirection && `flex-direction: ${mFlexDirection};`) || ''};
    ${({ mFlexWrap }) => (mFlexWrap && `flex-wrap: ${mFlexWrap};`) || ''};
    ${({ mFlexFlow }) => (mFlexFlow && `flex-flox :${mFlexFlow};`) || ''};
    ${({ mJustifyContent }) =>
      (mJustifyContent && `justify-content: ${mJustifyContent};`) || ''};
    ${({ mAlignItems }) =>
      (mAlignItems && `align-items: ${mAlignItems};`) || ''};
    ${({ mAlignContent }) =>
      (mAlignContent && `align-content: ${mAlignContent};`) || ''};
  }
`;

export const flexElement = css`
  ${({ order }) => (order && `order: ${order};`) || ''};
  ${({ flexGrow }) => (flexGrow && `flex-grow : ${flexGrow};`) || ''};
  ${({ flexShrink }) => (flexShrink && `flex-shrink: ${flexShrink};`) || ''};
  ${({ flex }) => (flex && `flex: ${flex};`) || ''};
  ${({ flexBasis }) => (flexBasis && `flex-basis: ${flexBasis};`) || ''};
  ${({ alignSelf }) => (alignSelf && `align-self: ${alignSelf};`) || ''};
  ${({ gap }) => ((gap === 0 || gap) && `gap : ${checkPixel(gap)};`) || ''};
  ${({ rowGap }) =>
    ((rowGap === 0 || rowGap) && `row-gap : ${checkPixel(rowGap)};`) || ''};
  ${({ colGap }) =>
    ((colGap === 0 || colGap) && `column-gap : ${checkPixel(colGap)};`) || ''};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ mOrder }) => (mOrder && `order: ${mOrder};`) || ''}
    ${({ mFlexGrow }) => (mFlexGrow && `flex-grow : ${mFlexGrow};`) || ''}
    ${({ mFlexShrink }) =>
      (mFlexShrink && `flex-shrink: ${mFlexShrink};`) || ''}
    ${({ mFlex }) => (mFlex && `flex: ${mFlex};`) || ''}
    ${({ mFlexBasis }) => (mFlexBasis && `flex-basis: ${mFlexBasis};`) || ''}
    ${({ mAlignSelf }) => (mAlignSelf && `align-self: ${mAlignSelf};`) || ''}  
    ${({ mGap }) =>
      ((mGap === 0 || mGap) && `gap : ${checkPixel(mGap)};`) || ''}
    ${({ mRowGap }) =>
      ((mRowGap === 0 || mRowGap) && `row-gap : ${checkPixel(mRowGap)};`) || ''}
    ${({ mColGap }) =>
      ((mColGap === 0 || mColGap) && `column-gap : ${checkPixel(mColGap)};`) ||
      ''}
  }
`;
