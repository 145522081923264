// theme
const colors = {
  g900: '#191919',
  g800: '#2D2F34',
  g700: '#44494D',
  g600: '#606469',
  g500: '#85898E',
  g400: '#9FA4A9',
  g300: '#CACDD2',
  g200: '#E9EBED',
  g100: '#F7F8F9',
  g0: '#ffffff',

  red: '#FF334B',
  yellow: '#F9BE26',
  blue: '#0A6CF9',
  done: '#79CB94',
  done200: 'rgba(121, 203, 148, 0.2)',
  dim: 'rgba(45, 47, 52, 0.56)',

  new: '#16CE55',
  label4: '#4E03EE',
  label5: '#DB05C5',
  label6: '#FDA33A',
  label7: '#1ADFBC',
  label8: '#F26552',

  m1: '#F1FFF7',
  m2: '#FAFFEF',
  m3: '#FFFBF0',
  m4: '#EFFBFF',
  m5: '#F3EFFF',

  layeredBackgroundColor: '#ffffff',
  backgroundColor: '#ffffff',
  fontColor: '#000',
  keyColor: '#7380ff',
  key100: 'rgba(115, 128, 255, 0.04)',
  key200: 'rgba(115, 128, 255, 0.1)',
  key300: 'rgba(115, 128, 255, 0.2)',
  key500: 'rgba(115, 128, 255, 0.5)',
  keyFontColor: '#ffffff',

  yellow300: 'rgba(249, 190, 38, 0.2)',
};

const shadows = {
  default: '0px 6px 20px rgba(68, 73, 77, 0.06)',
  header: '0px 6px 14px rgba(68, 73, 77, 0.02)',
  banner: '0px 6px 26px rgba(68, 73, 77, 0.1)',
  comment: '0px 6px 26px rgba(68, 73, 77, 0.04)',
  toggle: '0px -1px 4px rgba(68, 73, 77, 0.12)',
  hover: '0px 4px 24px rgba(68, 73, 77, 0.06)',
  floating: '0px 4px 12px rgba(68, 73, 77, 0.26)',
  fixed: '0px -5px 10px 0px rgba(0, 0, 0, 0.05)',
};

const textType = {
  h1: 60,
  h2: 48,
  h3: 36,
  h4: 24,
  h5: 21,
  h6: 18,
  h7: 16,
  h8: 14,
  h9: 12,
  h10: 10,
  b1: 16,
  b2: 14,
  b3: 12,
  b4: 11,
  b5: 10,
};

const fontFamily = 'Pretendard';

const windowSize = {
  height: '100vh',
};

const deviceSize = {
  mobile: '576px',
  tablet: '1280px',
  maxDesktop: '1440px',
};

const device = {
  mobile: `only screen and (max-width: ${deviceSize.mobile})`,
  tablet: `only screen and (max-width: ${deviceSize.tablet})`,
  maxDesktop: `only screen and (max-width: ${deviceSize.maxDesktop})`,
  ratio: `only screen and (max-width: 1280px) and (min-aspect-ratio: 16/9),
    screen and (max-width: 1900px) and (min-aspect-ratio: 2/1)`,
};

export const theme = {
  textType,
  deviceSize,
  colors,
  shadows,
  device,
  windowSize,
  fontFamily,
};
