import { useLanguageNavigate } from 'hooks';
import React, { useEffect } from 'react';
import MypagePresenter from './MypagePresenter';

function MypageContainer({ t, bucket, headerCallback, socket }) {
  const { currentLanguage } = useLanguageNavigate();
  useEffect(() => {
    if (bucket) {
      headerCallback('mypage');
    }
  }, [bucket]);
  return (
    <MypagePresenter
      bucket={bucket}
      socket={socket}
      currentLanguage={currentLanguage}
    />
  );
}

export default MypageContainer;
