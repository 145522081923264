import { useCookies } from 'react-cookie';

function useCookieUtil() {
  const [cookie, setCookie, removeCookie] = useCookies();

  const setCookies = (keyParams, value, expiresParams, path) => {
    const expires = new Date();
    expires.setTime(
      expires.getTime() + 1000 * 60 * 60 * 24 * (expiresParams || 7),
    );

    const key =
      (keyParams.includes('uuid') && keyParams) ||
      (keyParams.includes('bucket') && `${keyParams}`) ||
      `${keyParams}`;

    setCookie(key, encodeURI(value), {
      path: path || '/',
      expires,
    });
  };

  const getCookie = (keyParams) => {
    const key =
      (keyParams.includes('uuid') && keyParams) ||
      (keyParams.includes('bucket') && `${keyParams}`) ||
      `${keyParams}`;
    return (cookie[key] && decodeURI(cookie[key])) || null;
  };

  const removeCookies = (keyParams, path) => {
    const key =
      (keyParams.includes('uuid') && keyParams) ||
      (keyParams.includes('bucket') && `${keyParams}`) ||
      `${keyParams}`;

    return removeCookie(key, {
      path: path || '/',
    });
  };
  return { getCookie, setCookies, removeCookies };
}

export default useCookieUtil;
