import { css, keyframes } from 'styled-components';

const fadeInAnim = keyframes`
0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;

const slideUpAnim = (position) => keyframes`
0% { 
  transform: translateX(-50%) translateY(20px);
}100%{ 
  transform: translate(-50%,${(position === 'bottom' && '0') || '-50%'});
}`;

export const jelloAnim = keyframes`
0% {
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}
30% {
  -webkit-transform: scale3d(1.25, 0.75, 1);
          transform: scale3d(1.25, 0.75, 1);
}
40% {
  -webkit-transform: scale3d(0.75, 1.25, 1);
          transform: scale3d(0.75, 1.25, 1);
}
50% {
  -webkit-transform: scale3d(1.15, 0.85, 1);
          transform: scale3d(1.15, 0.85, 1);
}
65% {
  -webkit-transform: scale3d(0.95, 1.05, 1);
          transform: scale3d(0.95, 1.05, 1);
}
75% {
  -webkit-transform: scale3d(1.05, 0.95, 1);
          transform: scale3d(1.05, 0.95, 1);
}
100% {
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}`;

export const animations = {
  fadeIn: css`
    ${fadeInAnim} 0.35s cubic-bezier(0.39, 0.575, 0.565, 1) both
  `,
  slideUp: (position) => css`
    ${slideUpAnim(position)} 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards
  `,
  jello: css`
    ${jelloAnim} 0.9s both
  `,
};
