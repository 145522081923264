import { Button, HTMLWrap, Div, Text, Row } from 'components';
import React, { useState } from 'react';

export function StampContent({ stamp, stamping, close }) {
  const [scrollBottom, setScrollBottom] = useState(false);
  const scrollHandler = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const totalScrollTop = scrollHeight - clientHeight;
    if (!scrollBottom && totalScrollTop - scrollTop < 10) {
      setScrollBottom(true);
    }
  };
  return (
    sessionStorage.getItem('qrDetail') && (
      <Div
        height="height"
        overflowY="overlay"
        position="relative"
        onScroll={scrollHandler}
        width="100vw"
        ml="calc(-50vw + 50%)"
      >
        <HTMLWrap
          content={JSON.parse(sessionStorage.getItem('qrDetail'))?.content}
        />
        <Div position="sticky" bottom="30px">
          <Row
            justifyContent="flex-end"
            mb={
              (JSON.parse(sessionStorage.getItem('qrDetail'))?.isStamped !==
                1 &&
                10) ||
              0
            }
            pr={25}
          >
            <Row
              borderRadius="12px"
              cursor="pointer"
              hover
              backgroundColor="keyColor"
              width={50}
              height={50}
              alignItems="center"
              justifyContent="center"
              position="relative"
              boxShadow="floating"
              onClick={() => close('content')}
            >
              {stamp?.list.filter((item) => item.isStamped === 1)?.length >
                0 && (
                <Div
                  borderRadius="999px"
                  border={{ width: '2px', color: 'keyFontColor' }}
                  width={20}
                  height={20}
                  position="absolute"
                  right={3}
                  top="-6px"
                  backgroundColor="keyColor"
                >
                  <Text
                    fontSize={10}
                    color="keyFontColor"
                    textAlign="center"
                    lineHeight="16px"
                  >
                    {stamp?.list.filter((item) => item.isStamped === 1)?.length}
                  </Text>
                </Div>
              )}
              <Text type="h9" color="keyFontColor">
                STAMP
              </Text>
            </Row>
          </Row>
          {JSON.parse(sessionStorage.getItem('qrDetail'))?.isStamped !== 1 && (
            <Button
              buttonType="large"
              width="100%"
              maxWidth={325}
              margin="0 auto"
              display="block"
              active={scrollBottom}
              onClick={() => {
                if (scrollBottom) {
                  stamping(
                    JSON.parse(sessionStorage.getItem('qrDetail')),
                    'content',
                  );
                }
              }}
            >
              스탬프 획득
            </Button>
          )}
        </Div>
      </Div>
    )
  );
}
