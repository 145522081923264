import {
  LoginTypeA,
  LoginBox,
  LoginHeader,
  LoginTypeB,
  ChangepasswordVerified,
} from 'components';
import React from 'react';

function ChangePasswordPresenter({ bucket, info, setAlert }) {
  return (
    <>
      {info?.designType === 'design1' && (
        <LoginTypeA
          info={info}
          bucket={bucket}
          header={<LoginHeader bucket={bucket} />}
        >
          <LoginBox>
            <ChangepasswordVerified
              loginMethod={
                bucket.loginMethod.toLowerCase().includes('email')
                  ? 'email'
                  : 'phone'
              }
              setAlert={setAlert}
              isUsedSignUpBtn={0}
            />
          </LoginBox>
        </LoginTypeA>
      )}
      {info?.designType === 'design2' && (
        <LoginTypeB
          info={info}
          bucket={bucket}
          header={
            <LoginHeader
              bucket={bucket}
              position="absolute"
              zIndex={800}
              width="100vw"
            />
          }
        >
          <LoginBox>
            <ChangepasswordVerified
              loginMethod={
                bucket.loginMethod.toLowerCase().includes('email')
                  ? 'email'
                  : 'phone'
              }
              setAlert={setAlert}
              isUsedSignUpBtn={0}
            />
          </LoginBox>
        </LoginTypeB>
      )}
    </>
  );
}

export default ChangePasswordPresenter;
