import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './lang/en.json';
import ko from './lang/ko.json';

const resources = {
  en: { translation: en },
  ko: { translation: ko },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['querystring'],
      lookupQuerystring: 'dmice-language',
    },
    fallbackLng: ['ko', 'en'],
    interpolation: { escapeValue: false },
  });

export default i18n;
