import { Col, Row, Checkbox, Text, Button } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function LoginTermBox({ list, values, onChange, onClick }) {
  const { t } = useTranslation();

  return (
    list?.length > 0 && (
      <Col gap={10} mt={20}>
        {list?.map(
          (term, index) =>
            term && (
              <Row
                key={`${term.title}${index}`}
                padding="10px"
                gap={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Checkbox
                  flex={1}
                  onChange={onChange}
                  value={values && values[term.key]}
                  checked={(values && values[term.key]) || false}
                  name={term.key}
                >
                  <Text>
                    {term.title} (
                    {(term.isRequired === 1 && t('required')) || t('option')})
                  </Text>
                </Checkbox>
                <Button
                  buttonType="text"
                  type="b3"
                  color="keyColor"
                  padding="0rem 1.2rem"
                  onClick={() => onClick(term)}
                >
                  {t('view_term')}
                </Button>
              </Row>
            ),
        )}
      </Col>
    )
  );
}
