import { Col, Row } from 'components';
import styled from 'styled-components';

export const EmptyCardStyles = styled(Col)`
  border-radius: 1.2rem;
  ${({ isUsedOptions }) =>
    isUsedOptions &&
    `
  position:relative;
  &::before{
    border-radius: 1.2rem;
    content : ' ';
    position:absolute;
    width:100%;
    height:100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index:1;
  }
  `}
`;

export const CardStyles = styled(Col)`
  @media (hover: hover) and (pointer: fine) {
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    & .thumbnail {
      transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
      transform: scale(1);
    }
    &:hover {
      & .thumbnail {
        transform: scale(1.15);
      }
    }
  }
`;

export const StreamingCardStyles = styled(Col)`
  position: relative;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    transform: scale(1);
  }

  & > .streaming-desc-box {
    height: auto;
    padding: 10px 20px 20px 20px;
    transition: all 0.35s ease-in;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &:before {
        transform: scale(1.15);
      }

      & > .streaming-action-box {
        display: none;
      }

      & > .streaming-desc-box {
        height: 100%;
        padding: 20px;
      }
    }
  }
`;

export const StreamingCardBStyles = styled(Col)`
  position: relative;

  & > .streaming-desc-box {
    width: 100%;
    height: auto;
    padding: 10px 20px 20px 20px;
    position: relative;

    & > * {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(30px);
      transition: all 150ms;
    }

    & > .hover-display,
    .hover-webkit {
      display: none;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & > .streaming-desc-box {
        position: static;
        padding: 0px 20px 20px 20px;
        &::before {
          top: 0;
          height: 220px;
        }

        & > .hover-display {
          display: block;
        }

        & > .hover-webkit {
          display: -webkit-box;
        }
      }
    }
  }
`;

export const UserlistCardStyles = styled(Row)`
  padding: 6px 0;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      padding: 8px 0;
      opacity: 0.7;
    }
  }
`;
