import { Icon, Row, PopupB, Text } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'utils/isMobile';

export function DetailBoardGallerySubNav({
  item,
  bucket,
  page,
  setTargetEditItem,
  deleting,
  setIsEdit,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    (
      <Row
        position="sticky"
        top={
          ((bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
            (Number(page?.headerOption?.headerHeight) ||
              Number(bucket.headerOption.headerHeight) ||
              60)) ||
          0
        }
        zIndex={1}
        alignItems="center"
        justifyContent="space-between"
        mt={-page?.styles.paddingTop}
        pt={10}
        pb={10}
        backgroundColor="backgroundColor"
      >
        <Icon
          name="arrow"
          fill="fontColor"
          size={24}
          transform="rotate(90deg)"
          onClick={() => navigate(-1)}
          cursor="pointer"
          hover="background"
        />
        {(item?.isMine === 1 && (
          <PopupB
            name={item.itemCode}
            items={
              <>
                <Text
                  hover="background"
                  cursor="pointer"
                  onClick={() => {
                    setTargetEditItem(item);
                    setIsEdit(true);
                  }}
                  whiteSpace="nowrap"
                  padding="4px 10px"
                >
                  {t('edit')}
                </Text>
                <Text
                  hover="background"
                  cursor="pointer"
                  onClick={() => deleting(item)}
                  whiteSpace="nowrap"
                  padding="4px 10px"
                >
                  {t('delete')}
                </Text>
              </>
            }
          >
            <Icon
              name="more"
              size={21}
              cursor="pointer"
              hover="circle"
              transform="rotate(90deg)"
              fill="g500"
            />
          </PopupB>
        )) ||
          ''}
      </Row>
    ) || ''
  );
}
