import {
  Col,
  Div,
  Icon,
  Loading,
  Row,
  Text,
  Toggle,
  UserCard,
  UserlistCard,
  UserlistChatCard,
  UserlistDim,
} from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

function UserlistPresenter({
  user,
  module,
  loadingRef,
  filters,
  onMyInfo,
  setOnMyInfo,
  onClickCard,
  targetCard,
  setTargetCard,
  getCookie,
}) {
  const { t } = useTranslation();
  return (
    (module?.designType === 'design2' && (
      <Col height="100%" width="100%">
        <Div height="100%" overflowY="overlay" position="relative">
          <UserlistChatCard thumbnail={user.thumbnail} name="Me">
            <Row alignItems="center" gap={10}>
              <Text type="h9" color={(onMyInfo && 'keyColor') || 'g500'}>
                {t('disclosure_my_info')}
              </Text>
              <Toggle
                checked={onMyInfo}
                onClick={() => {
                  setOnMyInfo(!onMyInfo);
                  setTargetCard();
                }}
              />
            </Row>
          </UserlistChatCard>
          {module && module?.isUsedAllChat === 1 && (
            <>
              <Div padding="10px 25px">
                <Text type="b3" color="keyColor">
                  All
                </Text>
              </Div>
              <UserlistChatCard
                thumbnail="all"
                name="All User Chat"
                onClick={() =>
                  onClickCard({
                    roomCode: module?.allChatRoomCode,
                    itemCode: module?.moduleCode,
                  })
                }
              >
                {Number(getCookie(module?.moduleCode) || 0) <
                  module.allChatSize && (
                  <Div
                    backgroundColor="red"
                    width={8}
                    height={8}
                    minWidth={8}
                    minHeight={8}
                    borderRadius="999px"
                  />
                )}
              </UserlistChatCard>
            </>
          )}
          <Div padding="10px 25px">
            <Text type="b3" color="keyColor">
              User
            </Text>
          </Div>
          {onMyInfo &&
            module &&
            module.list?.map((item, index) => (
              <UserlistChatCard
                key={`${item.itemCode}-${index}`}
                item={item}
                onClick={() => {
                  if (module?.isUsedPrivateChat) onClickCard(item);
                }}
              >
                {Number(getCookie(item.itemCode) || 0) < item.chatSize && (
                  <Div
                    backgroundColor="red"
                    width={8}
                    height={8}
                    minWidth={8}
                    minHeight={8}
                    borderRadius="999px"
                  />
                )}
              </UserlistChatCard>
            ))}
          <Div setRef={loadingRef}>
            {module?.totalPages > filters?.page && (
              <Loading
                position="relative"
                width="100%"
                height="200px"
                delay="0"
              />
            )}
          </Div>
          <UserlistDim top="70px" height="calc(100% - 70px)" open={!onMyInfo}>
            <Icon size={38} name="caution" fill="yellow" />
            <Text type="h8" color="g500" mt={6}>
              {t('disclosure_my_info')} OFF
            </Text>
          </UserlistDim>
        </Div>
      </Col>
    )) || (
      <Col height="100%" width="100%">
        <Row
          alignItems="center"
          justifyContent="space-between"
          pb={10}
          borderBottom={{ color: 'g200' }}
        >
          <Text type="h7">{t('userlist')}</Text>
          <Row alignItems="center" gap={10}>
            <Text type="h9" color={(onMyInfo && 'keyColor') || 'g500'}>
              {t('disclosure_my_info')}
            </Text>
            <Toggle
              checked={onMyInfo}
              onClick={() => {
                setOnMyInfo(!onMyInfo);
                setTargetCard();
              }}
            />
          </Row>
        </Row>
        <Div mt={10} height="100%" overflowY="overlay" position="relative">
          {module &&
            module.list?.map((item, index) => (
              <UserlistCard
                key={`${item.itemCode}-${index}`}
                item={item}
                onClick={() => onClickCard(item)}
              />
            ))}
          <Div setRef={loadingRef}>
            {module?.totalPages > filters?.page && (
              <Loading
                position="relative"
                width="100%"
                height="200px"
                delay="0"
              />
            )}
          </Div>
          <UserlistDim open={targetCard} onClick={() => setTargetCard()}>
            <UserCard item={targetCard} close={() => setTargetCard()} />
          </UserlistDim>
          <UserlistDim open={!onMyInfo}>
            <Icon size={38} name="caution" fill="yellow" />
            <Text type="h8" color="g500" mt={6}>
              {t('disclosure_my_info')} OFF
            </Text>
          </UserlistDim>
        </Div>
      </Col>
    )
  );
}

export default UserlistPresenter;
