import { Div } from 'components';
import React from 'react';

function CustomPresenter({ moduleCode }) {
  return (
    <Div backgroundColor="#F2CEAE" width="100%" padding="200px 50px">
      Custom 영역 입니다.
      {moduleCode}
    </Div>
  );
}

export default CustomPresenter;
