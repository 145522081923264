/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError, useApiParams, useLanguageNavigate } from 'hooks';
import { registrationApi } from 'api';
import { Alert, Loading } from 'components';
import { isEmptyObject } from 'utils/isEmptyObject';
import { modifiedValueCheck } from 'utils/apiCleansing';
import { useSearchParams } from 'react-router-dom';
import RegistrationEditPresenter from './RegistrationEditPresenter';

function RegistrationEditContainer({
  registrationCode,
  detail,
  sessionList,
  module,
  originValues,
  originEtcValues,
}) {
  const { DEFAULT_PARAMS } = useApiParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { languageNavigate } = useLanguageNavigate();
  const { t } = useTranslation();

  const { alert, setAlert, setError } = useApiError();

  const [loading, setLoading] = useState(true);

  // registration list
  const [targetSession, setTargetSession] = useState();
  const [values, setValues] = useState();
  const [invalids, setInvalids] = useState();
  const [etcValues, setEtcValues] = useState();

  useEffect(() => {
    if (module && detail && sessionList) {
      let tempActiveSession = {};
      sessionList.forEach((el) => {
        tempActiveSession = { ...tempActiveSession, [el.sessionCode]: true };
      });
      setActiveSession({ ...tempActiveSession });
      setTargetSession({ ...sessionList[0] });
      setEtcValues({ ...originEtcValues });
      setValues({ ...originValues });
      setLoading(false);
    }
  }, [module, detail, sessionList]);

  const onChange = (value, name) => {
    setValues({ ...values, [name]: [value] });
  };

  const onEtcChange = (value, name) => {
    setEtcValues({
      ...etcValues,
      [name]: { ...etcValues[name], title: value },
    });
  };

  const onMutipleChange = (value, name, index) => {
    const valueArray = [...(values[name] || [])];
    const valueIndex = value.selectionCode
      ? valueArray.findIndex((val) => val.selectionCode === value.selectionCode)
      : valueArray.indexOf(value);

    if (value) {
      if (valueIndex !== -1) {
        valueArray.splice(valueIndex, 1);
      } else {
        valueArray.push(value);
      }
      setValues({ ...values, [name]: valueArray });
    } else {
      valueArray.splice(index, 1);
      setValues({ ...values, [name]: valueArray });
    }
  };

  const onCheckChange = (value, name, checked) => {
    if (checked) {
      setValues({ ...values, [name]: [...(values[name] || ''), value] });
    } else {
      setValues({
        ...values,
        [name]: values[name].filter(
          (el) => el.selectionCode !== value.selectionCode,
        ),
      });
    }
  };

  const filterRequiredArray = (i) => {
    if (!loading) {
      const requiredArray = sessionList[i]?.itemList?.filter(
        (item) => item.isRequired === 1,
      );
      const hasChildItemList = [
        ...sessionList[i]?.itemList.filter(
          (item) =>
            Object.keys(item?.childList)?.length > 0 &&
            values[item.itemCode] &&
            item?.childList[
              values[item.itemCode][0].countryCode ||
                values[item.itemCode][0].selectionCode
            ],
        ),
      ];

      let requiredChildArray = [];
      const childArray = hasChildItemList
        .map((el) => {
          if (
            Object.keys(el.childList).length > 0 &&
            values[el.itemCode] &&
            values[el.itemCode][0]
          )
            return el.childList[
              values[el.itemCode][0].countryCode ||
                values[el.itemCode][0].selectionCode
            ];
        })
        .flat(2)
        .filter((requiredEl) => requiredEl?.isRequired === 1);

      requiredChildArray =
        childArray.length > 0 && childArray[0] !== undefined ? childArray : [];

      const hasGrandChildArray =
        requiredChildArray.length > 0 && requiredChildArray[0] !== undefined
          ? requiredChildArray.filter(
              (childItem) =>
                Object.keys(childItem.childList).length > 0 &&
                childItem?.childList[
                  values[childItem.itemCode] &&
                    (values[childItem.itemCode][0]?.countryCode ||
                      values[childItem.itemCode][0]?.selectionCode)
                ],
            )
          : [];

      let requiredGrandChildArray = [];
      const grandChildArray = hasGrandChildArray
        .map((el) => {
          if (
            Object.keys(el.childList).length > 0 &&
            values[el.itemCode] &&
            values[el.itemCode][0]
          )
            return el.childList[
              values[el.itemCode][0].countryCode ||
                values[el.itemCode][0].selectionCode
            ];
        })
        .flat(2)
        .filter((requiredEl) => requiredEl?.isRequired === 1);

      requiredGrandChildArray =
        grandChildArray.length > 0 && grandChildArray[0] !== undefined
          ? grandChildArray
          : [];

      return [
        ...requiredArray,
        ...requiredChildArray,
        ...requiredGrandChildArray,
      ];
    }
  };

  const emptyCheck = (i) => {
    let tempInvalid = {};

    const totalRequiredArray = filterRequiredArray(i);

    if (totalRequiredArray?.length > 0) {
      for (const requiredElem of totalRequiredArray) {
        if (
          sessionList.length === 1 &&
          (!values[requiredElem.itemCode] ||
            !values[requiredElem.itemCode][0] ||
            (etcValues[requiredElem.itemCode] &&
              !etcValues[requiredElem.itemCode]?.domain &&
              !etcValues[requiredElem.itemCode]?.title))
        ) {
          // session 한개일때 invalid check
          tempInvalid = {
            ...tempInvalid,
            [requiredElem.itemCode]: { type: 'error' },
          };
        } else if (
          sessionList.length > 1 &&
          activeSession &&
          Object.keys(activeSession).includes(targetSession.sessionCode) &&
          (!values[requiredElem.itemCode] ||
            !values[requiredElem.itemCode][0] ||
            (etcValues[requiredElem.itemCode] &&
              !etcValues[requiredElem.itemCode]?.domain &&
              !etcValues[requiredElem.itemCode]?.title))
        ) {
          // session 여러개일때 invalid check
          tempInvalid = {
            ...tempInvalid,
            [requiredElem.itemCode]: { type: 'error' },
          };
        } else {
          tempInvalid = {
            ...tempInvalid,
            [requiredElem.itemCode]: { type: '' },
          };
        }
      }
    }

    return tempInvalid;
  };

  const sessionCheck = (i) => {
    let tempActiveSession = {};
    const totalRequiredArray = filterRequiredArray(i);
    if (totalRequiredArray.length > 0) {
      let result = true;
      for (const requiredElem of totalRequiredArray) {
        if (
          !values[requiredElem.itemCode] ||
          !values[requiredElem.itemCode][0] ||
          (etcValues[requiredElem.itemCode] &&
            !etcValues[requiredElem.itemCode]?.domain &&
            !etcValues[requiredElem.itemCode]?.title)
        ) {
          result = false;
        }
      }
      tempActiveSession = {
        ...tempActiveSession,
        [sessionList[i].sessionCode]: result,
      };
    } else {
      tempActiveSession = {
        [sessionList[i].sessionCode]: true,
      };
    }

    return tempActiveSession;
  };

  const allSessionCheck = () => {
    let tempActiveSession = {};
    for (let i = 0; i < sessionList.length; i += 1) {
      const totalRequiredArray = filterRequiredArray(i);
      if (totalRequiredArray.length > 0) {
        let result = true;
        for (const requiredElem of totalRequiredArray) {
          if (
            !values[requiredElem.itemCode] ||
            !values[requiredElem.itemCode][0] ||
            (etcValues[requiredElem.itemCode] &&
              !etcValues[requiredElem.itemCode]?.domain &&
              !etcValues[requiredElem.itemCode]?.title)
          ) {
            result = false;
          }
        }
        tempActiveSession = {
          ...tempActiveSession,
          [sessionList[i].sessionCode]: result,
        };
      } else {
        tempActiveSession = {
          ...tempActiveSession,
          [sessionList[i].sessionCode]: true,
        };
      }
    }

    return tempActiveSession;
  };

  const [activeSession, setActiveSession] = useState();
  useEffect(() => {
    // 빈 값 유효성 체크
    if (values && sessionList && sessionList?.length > 0 && targetSession) {
      const currentIndex = sessionList.findIndex(
        (session) => session.sessionCode === targetSession.sessionCode,
      );
      if (sessionList?.length > 1) {
        setInvalids({ ...emptyCheck(currentIndex) });
      } else if (sessionList?.length === 1 && activeSession) {
        setInvalids({ ...emptyCheck(0) });
      }
    }
  }, [sessionList, targetSession, values, etcValues]);

  const changeTargetSession = (newSession) => {
    if (targetSession) {
      const prevSessionIndex = sessionList.findIndex(
        (session) => session.sessionCode === targetSession.sessionCode,
      );
      const tempActiveSession = sessionCheck(prevSessionIndex);
      setActiveSession({ ...activeSession, ...tempActiveSession });
    }
    setTargetSession(newSession);
  };

  const confirmActiveCheck = () => {
    if (sessionList.length === 1) {
      const resultActiveSession = sessionCheck(0);
      setActiveSession(resultActiveSession);
      return Object.values(resultActiveSession)[0];
    } else {
      const totalAtiveSession = {
        ...allSessionCheck(),
      };

      return (
        Object.keys(totalAtiveSession).length === sessionList.length &&
        !Object.values(totalAtiveSession).includes(false)
      );
    }
  };

  const makeEtcValue = () => {
    if (etcValues) {
      Object.keys(etcValues).forEach((etcKey) => {
        for (const session of sessionList) {
          let originResult = {};
          const originItem = session?.itemList?.find(
            (el) => el.itemCode === etcKey,
          );

          let childOriginItem;
          session?.itemList?.forEach((el) => {
            if (Object.keys(el.childList).length > 0)
              childOriginItem = Object.values(el.childList)
                .flat(1)
                .find((childEl) => childEl.itemCode === etcKey);
          });

          let grandChildOriginItem;
          session?.itemList?.forEach((el) => {
            if (Object.keys(el.childList).length > 0)
              Object.values(el.childList)
                .flat(1)
                .forEach((childEl) => {
                  if (Object.keys(childEl.childList).length > 0)
                    grandChildOriginItem = Object.values(childEl.childList)
                      .flat(1)
                      .find((grandChildEl) => grandChildEl.itemCode === etcKey);
                });
          });

          originResult = originItem || childOriginItem || grandChildOriginItem;

          const changeIndex = values[etcKey].findIndex(
            (etc) =>
              etc.selectionCode ===
              originResult?.selectionEtcTitle.selectionCode,
          );

          if (changeIndex !== -1) {
            values[etcKey].splice(changeIndex, 1, etcValues[etcKey]);
          }
        }
      });
    }
  };

  const confirm = async () => {
    makeEtcValue();
    const active = confirmActiveCheck();
    const params = modifiedValueCheck(originValues, values);

    if (active && !isEmptyObject(params)) {
      const [info] = await registrationApi.registration(registrationCode, {
        ...DEFAULT_PARAMS,
        answers: { ...params },
        isModify: 1,
      });
      if (info) {
        // success
        setAlert({
          title: t('alert_title'),
          content: t('edit_done'),
          confirm: () => languageNavigate(`/`),
        });
      }
    } else if (isEmptyObject(params)) {
      setAlert({
        title: t('alert_title'),
        content: t('change_warning'),
        confirm: () => setAlert(),
      });
    } else {
      if (sessionList.length === 1) {
        setInvalids({ ...emptyCheck(0) });
      }
      // 빈 항목 체크
      setAlert({
        title: t('alert_title'),
        content: t('invalid_alert'),
        confirm: () => setAlert(),
      });
    }
  };

  return (
    <>
      {(loading && <Loading position="relative" height="height" />) || (
        <RegistrationEditPresenter
          t={t}
          module={module}
          detail={detail}
          targetSession={targetSession}
          changeTargetSession={changeTargetSession}
          sessionList={sessionList}
          activeSession={activeSession}
          invalids={invalids}
          values={values}
          setValues={setValues}
          etcValues={etcValues}
          setEtcValues={setEtcValues}
          onChange={onChange}
          onEtcChange={onEtcChange}
          onMutipleChange={onMutipleChange}
          onCheckChange={onCheckChange}
          confirm={confirm}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
      {alert && <Alert {...alert} />}
    </>
  );
}

export default RegistrationEditContainer;
