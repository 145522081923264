import { authApi } from 'api';
import {
  Button,
  Div,
  Icon,
  Toast,
  Loading,
  ProfileImageEdit,
  ProfileInputCard,
  Row,
  Text,
} from 'components';
import countryCodes from 'country-codes-list';
import { useApiParams, useLanguageNavigate } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { modifiedValueCheck } from 'utils/apiCleansing';
import { isEmptyObject } from 'utils/isEmptyObject';

export function MypageInfo({ bucket, module, menu, goList }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLanguage } = useLanguageNavigate();
  const { loginMethod, loginInfo } = bucket;
  const { languageNavigate } = useLanguageNavigate();

  const [values, setValues] = useState();
  const [invalids, setInvalids] = useState();
  const { DEFAULT_PARAMS } = useApiParams();
  const [list, setList] = useState();
  const [originValues, setOriginValues] = useState();
  const [countryList, setCountryList] = useState([]);

  const getProfile = async () => {
    const [info] = await authApi.profileInfo({ ...DEFAULT_PARAMS });
    if (info) {
      const { defaultProfiles } = info.result;
      setList(defaultProfiles);
      let tempValue = {};
      let tempInvalid = {};

      defaultProfiles.forEach((item) => {
        tempValue = {
          ...tempValue,
          [item.itemType]: item.answer || '',
        };

        tempInvalid = {
          ...tempInvalid,
          [item.itemType]: { type: '', text: '' },
        };
      });

      const allCountryCodes = Object.values(
        countryCodes.customList(
          'countryCode',
          '{"label" : "{countryNameEn} ", "value": "{countryCallingCode}", "lang": "{officialLanguageCode}", "empty":"{countryNameLocal}", "enName": "{countryNameEn}"}',
        ),
      )
        .sort()
        .map((el) => JSON.parse(el));

      setCountryList(allCountryCodes);
      setOriginValues({ ...tempValue });
      setValues({ ...tempValue });
      setInvalids({ ...tempInvalid });
    }
  };

  useEffect(() => {
    if (module && menu) {
      getProfile();
    }
  }, [module, menu]);

  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
    setInvalids({
      ...invalids,
      [name]: { type: '', text: '' },
    });
    if (name === 'thumbnail') {
      profileChange(value);
    }
  };

  const [loading, setLoading] = useState(false);
  const profileChange = async (thumbnail) => {
    setLoading(true);
    const [info] = await authApi.profile({
      ...DEFAULT_PARAMS,
      lang: currentLanguage,
      thumbnailUrl: thumbnail || '',
    });
    if (info) {
      // success
      setLoading(false);
    }
  };

  const [active, setActive] = useState(false);
  useEffect(() => {
    if (values) {
      const defaultRequiredArray = list.filter(
        (profile) => profile.isRequired === 1,
      );
      let defaultResult = true;
      for (const defaultRequiredElem of defaultRequiredArray) {
        const findElem = Object.keys(values).find(
          (key) => key === defaultRequiredElem.itemType,
        );
        if (
          !values[findElem] ||
          values[findElem].length < 1 ||
          isEmptyObject(values[findElem])
        ) {
          defaultResult = false;
        }
      }
      setActive(defaultResult);
    }
  }, [values]);

  const [toast, setToast] = useState('');
  const edit = async () => {
    const params = modifiedValueCheck(originValues, values);
    if (active && Object.keys(params).length !== 0) {
      const [info] = await authApi.profile({
        ...DEFAULT_PARAMS,
        lang: currentLanguage,
        ...values,
      });
      if (info) {
        // success
        setToast(t('done_edit_profile'));
        getProfile();
        searchParams.delete('edit');
        setSearchParams(searchParams);
      }
    } else if (isEmptyObject(params)) {
      setToast(t('change_warning'));
    } else if (values) {
      const defaultRequiredArray = list.filter(
        (profile) => profile.isRequired === 1,
      );
      let tempInvalid = {};
      for (const defaultRequiredElem of defaultRequiredArray) {
        const findElem = Object.keys(values).find(
          (key) => key === defaultRequiredElem.itemType,
        );
        if (
          !values[findElem] ||
          values[findElem].length < 1 ||
          isEmptyObject(values[findElem])
        ) {
          tempInvalid = {
            ...tempInvalid,
            [findElem]: { type: 'error', text: '' },
          };
        }
      }
      setInvalids({
        ...tempInvalid,
      });
    }
  };

  return (
    <>
      <Row
        display="none"
        mDisplay="flex"
        position="sticky"
        width="100vw"
        height={50}
        top={
          (bucket?.header === 'fixed' &&
            (bucket?.headerOption.headerHeight || 60)) ||
          0
        }
        left="0"
        ml="calc(-50vw + 50%)"
        backgroundColor="backgroundColor"
        zIndex={5000}
        padding="0 25px"
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          name="arrow"
          fill="fontColor"
          size={24}
          transform="translateY(-50%) rotate(90deg)"
          onClick={() => {
            if (searchParams.get('edit')) {
              searchParams.delete('edit');
              setSearchParams(searchParams);
            } else {
              goList();
            }
          }}
          cursor="pointer"
          hover="background"
          position="absolute"
          left="25px"
          top="50%"
        />
        <Text type="h6">
          {searchParams.get('edit') ? t('mypage_info_edit') : t('mypage_info')}
        </Text>
        {module?.isEnableEditingProfile === 1 && !searchParams.get('edit') && (
          <Button
            onClick={() => {
              searchParams.append('edit', true);
              setSearchParams(searchParams);
            }}
            transform="translateY(-50%)"
            cursor="pointer"
            position="absolute"
            right="25px"
            top="50%"
            borderRadius="10px"
            minWidth={45}
            width={45}
            padding="0 10px"
            lineHeight="32px"
            height={32}
            type="b2"
            color="g800"
            backgroundColor="g100"
          >
            {t('edit')}
          </Button>
        )}
      </Row>
      <Div
        position="relative"
        maxWidth={375}
        margin="0 auto"
        backgroundColor="backgroundColor"
      >
        {list?.find((item) => item.itemType === 'thumbnail') && (
          <ProfileImageEdit
            display="block"
            width="fit-content"
            margin="0 auto 30px auto"
            cursor="pointer"
            name="thumbnail"
            value={values?.thumbnail}
            onChange={onChange}
          />
        )}
        {loading && <Loading position="absolute" />}
        {list?.map(
          (item) =>
            (item.itemType !== 'thumbnail' && !searchParams.get('edit') && (
              <Row
                key={item.itemType}
                padding="20px 25px 20px 24px"
                alignItems="center"
                justifyContent="space-between"
                gap={20}
              >
                <Text type="h7">{item?.title[currentLanguage] || ''}</Text>
                <Text textAlign="right" flex={1}>
                  {(item.itemType === 'country' &&
                    countryList.find((el) => el.value === item?.answer)
                      ?.enName) ||
                    item?.answer ||
                    '-'}
                </Text>
              </Row>
            )) ||
            (item.itemType !== 'thumbnail' &&
              searchParams.get('edit') === 'true' && (
                <ProfileInputCard
                  key={item.itemType}
                  title={item?.title[currentLanguage]}
                  item={item}
                  name={item.itemType}
                  value={values[item.itemType] || ''}
                  onChange={onChange}
                  invalid={invalids[item.itemType]}
                  initValue={originValues[item.itemType]}
                />
              )),
        )}
        {module?.isEnableEditingProfile === 1 && !searchParams.get('edit') && (
          <Button
            buttonType="large"
            maxWidth={325}
            margin="0 auto"
            display="block"
            mt={20}
            active
            border={{ color: 'keyColor' }}
            color="keyColor"
            backgroundColor="keyFontColor"
            onClick={() => {
              searchParams.append('edit', true);
              setSearchParams(searchParams);
            }}
            mDisplay="none"
          >
            {t('editing')}
          </Button>
        )}
        {module?.isEnableEditingProfile === 1 &&
          searchParams.get('edit') === 'true' && (
            <>
              <Button
                buttonType="large"
                maxWidth={325}
                margin="0 auto"
                display="block"
                mt={20}
                active={active}
                onClick={edit}
              >
                {t('editing')}
              </Button>
              <Button
                buttonType="large"
                maxWidth={325}
                margin="0 auto"
                display="block"
                mt={10}
                active
                border={{ color: 'keyColor' }}
                color="keyColor"
                backgroundColor="keyFontColor"
                onClick={() => {
                  searchParams.delete('edit');
                  setSearchParams(searchParams);
                }}
              >
                {t('cancel')}
              </Button>
            </>
          )}
      </Div>
      {loginMethod.includes('Password') &&
        !!loginInfo[currentLanguage].isUsedForgotPWBtn && (
          <>
            <Div mt={56} width="100%" height={16} backgroundColor="g100" />
            <Div maxWidth={375} margin="0 auto">
              <Row
                padding="20px 25px 20px 24px"
                cursor="pointer"
                hover
                alignItems="center"
                justifyContent="space-between"
                onClick={() => languageNavigate('/changePassword')}
              >
                <Text type="h7">비밀번호 변경</Text>
                <Icon
                  name="arrow"
                  fill="g500"
                  size={18}
                  cursor="pointer"
                  hover="background"
                  transform="rotate(-90deg)"
                />
              </Row>
              {/* <Row
                padding="20px 25px 20px 24px"
                cursor="pointer"
                hover
                alignItems="center"
                justifyContent="space-between"
              >
                <Text type="h7">회원 탈퇴하기</Text>
                <Icon
                  name="arrow"
                  fill="g500"
                  size={18}
                  cursor="pointer"
                  hover="background"
                  transform="rotate(-90deg)"
                />
              </Row> */}
            </Div>
          </>
        )}
      {toast && <Toast content={toast} />}
    </>
  );
}
