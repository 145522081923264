import React, { useState } from 'react';
import { Dim, Div } from 'components';

export function PopupA({ name, children, label, items, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <Dim
          top="0"
          left="0"
          backgroundColor="transparent"
          zIndex="1"
          onClick={() => setOpen(false)}
        />
      )}
      <Div position="relative" {...props}>
        <Div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(!open);
          }}
          position="relative"
          zIndex={(open && 5) || '0'}
        >
          {children}
        </Div>
        <Div
          width={232}
          border={{ color: 'g200' }}
          backgroundColor="g0"
          padding="20px"
          borderRadius="0.6rem"
          position="absolute"
          zIndex={10}
          boxShadow="default"
          right="0"
          transform={(open && 'scale(1)') || 'scale(0)'}
          transition="all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550)"
          transformOrigin="top right"
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
        >
          {label}
          {label && (
            <Div
              height="1"
              width="100%"
              backgroundColor="g200"
              margin="16px 0"
            />
          )}
          {items}
        </Div>
      </Div>
    </>
  );
}
