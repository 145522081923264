import { Div, HTMLWrap, MainStructure, ModalStructure } from 'components';
import React, { useState } from 'react';

function MenuPresenter({
  bucket,
  currentLanguage,
  menu,
  side1List,
  side2List,
  socket,
  sidePlayListCallback,
  streamingItemCallback,
  streamingItem,
}) {
  const [modalTarget, setModalTarget] = useState(
    (menu &&
      menu?.modalStructure.length > 0 &&
      menu.modalStructure[menu.modalStructure.length - 1]) ||
      '',
  );
  const [minModals, setMinModals] = useState([]);

  return (
    <Div
      className="page-wrap"
      position="relative"
      minHeight={
        ((bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
          '100vh') ||
        'height'
      }
      backgroundColor="backgroundColor"
      backgroundImage={menu?.styles?.backgroundImg}
      backgroundRepeat="no-repeat"
      backgroundSize="100% auto"
      backgroundPosition="top left"
      pt={
        (menu?.headerOption?.isHeaderOverlap !== 1 &&
          (bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
          (Number(menu?.headerOption?.headerHeight) ||
            Number(bucket.headerOption.headerHeight) ||
            60) + Number(menu?.styles.paddingTop)) ||
        menu?.styles.paddingTop
      }
      pl={menu?.styles.paddingLeft}
      pr={menu?.styles.paddingRight}
      pb={
        menu?.styles.paddingBottom +
          bucket?.tabMenuInfo?.[currentLanguage]?.length >
        0
          ? 60
          : 0
      }
      mPt={
        (menu?.headerOption?.isHeaderOverlap !== 1 &&
          (bucket?.header === 'fixed' || bucket?.header === 'drawer') &&
          (Number(menu?.headerOption?.headerHeight) ||
            Number(bucket.headerOption.headerHeight) ||
            60) + Number(menu?.styles.paddingTop)) ||
        menu?.styles.paddingTop
      }
    >
      {menu?.styles?.backgroundContent && (
        <HTMLWrap
          content={menu?.styles?.backgroundContent}
          position="absolute"
          top="0"
          left="0"
          zIndex={0}
          height="100%"
        />
      )}
      <Div
        className="page-layered-wrap"
        backgroundColor="layeredBackgroundColor"
        backgroundImage={
          menu?.styles?.layeredBackgroundImg ||
          bucket?.styles?.layeredBackgroundImg
        }
        backgroundSize="cover"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        width={
          ((menu?.styles?.layeredBackgroundColor ||
            bucket?.styles.layeredBackgroundColor) &&
            'fit-content') ||
          '100%'
        }
        mWidth="100%"
        margin="0 auto"
        borderRadius={menu?.styles?.borderRadius}
        pt={menu?.styles.layeredPaddingTop}
        pl={menu?.styles.layeredPaddingLeft}
        pr={menu?.styles.layeredPaddingRight}
        pb={menu?.styles.layeredPaddingBottom}
        zIndex={1}
      >
        {menu &&
          menu?.mainStructure.map((module, index) => (
            <MainStructure
              key={`${module.moduleType}${index}`}
              bucket={bucket}
              module={module}
              socket={socket}
              menu={menu}
              side1List={side1List}
              side2List={side2List}
              sidePlayListCallback={sidePlayListCallback}
              streamingItem={streamingItem}
              streamingItemCallback={streamingItemCallback}
            />
          ))}
      </Div>
      {menu && menu?.modalStructure.length > 0 && (
        <>
          {menu?.modalStructure.map((module, index) => (
            <ModalStructure
              key={`${module.moduleType}${index}`}
              bucket={bucket}
              module={module}
              socket={socket}
              menu={menu}
              target={modalTarget.moduleCode === module.moduleCode}
              setTarget={setModalTarget}
              minModals={minModals}
              setMinModals={setMinModals}
            />
          ))}
        </>
      )}
    </Div>
  );
}

export default MenuPresenter;
