import { moduleApi } from 'api';
import {
  Alert,
  Bookmark,
  Div,
  Image,
  Loading,
  Row,
  Text,
  Toast,
} from 'components';
import { useApiParams } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MypageBookmark({ list, getLog }) {
  const { t } = useTranslation();
  const { DEFAULT_PARAMS } = useApiParams();

  const makeTextContent = (content) => {
    if (content?.includes('<') && content?.includes('</')) {
      const tempDocument = document.createElement('div');
      tempDocument.style.display = 'none';
      tempDocument.innerHTML = content;
      return tempDocument.textContent;
    } else {
      return content;
    }
  };

  const makeImageContent = (content) => {
    const tempDocument = document.createElement('div');
    tempDocument.style.display = 'none';
    tempDocument.innerHTML = content;
    const thumbnail = tempDocument.getElementsByTagName('img');
    if (thumbnail[0]) {
      return thumbnail[0].src;
    }
    return false;
  };

  const [alert, setAlert] = useState();
  const undo = (item) => {
    setAlert({
      title: t('bookmark_tag'),
      content: t('mypage_bookmark_warning'),
      cancel: () => setAlert(),
      confirm: () => logging(item),
    });
  };

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState('');
  const logging = async (item) => {
    setAlert();
    setLoading(true);
    const [info] = await moduleApi.moduleLogging(
      'bookmark',
      item.moduleCode,
      item.itemCode,
      DEFAULT_PARAMS,
    );
    if (info) {
      setLoading(false);
      getLog();
      setToast(t('unbookmark'));
    }
  };

  return (
    <>
      {list?.map((item) => (
        <Row
          width="min(54%, 492px)"
          mWidth="100%"
          key={item.itemCode}
          alignItems="center"
          margin="0 auto"
          padding="20px 0"
          gap={10}
          borderBottom={{ color: 'g200' }}
        >
          <Bookmark isBookmark={1} onClick={() => undo(item)} />
          <Div width="min(77%, 380px)">
            <Text type="h7" maxLine={1}>
              {item.title || item.name}
            </Text>
            <Text color="g500" type="b3" maxLine={3}>
              {makeTextContent(item.content)}
            </Text>
          </Div>
          {(item.thumbnail || makeImageContent(item.content)) && (
            <Image
              borderRadius="5px"
              src={item.thumbnail || makeImageContent(item.content)}
              width={68}
              height={68}
              objectFit="cover"
            />
          )}
        </Row>
      ))}
      {list?.length === 0 && (
        <Row
          width="100%"
          height={300}
          alignItems="center"
          justifyContent="center"
        >
          <Text color="g600" type="b1">
            {t('mypage_bookmark_empty')}
          </Text>{' '}
        </Row>
      )}
      {alert && <Alert {...alert} />}
      {loading && <Loading position="absolute" />}
      {toast && <Toast content={toast} />}
    </>
  );
}
