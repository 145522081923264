import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: {},
};

const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setModule: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { setModule } = moduleSlice.actions;

export default moduleSlice;
