import React, { useState } from 'react';
import {
  Div,
  Icon,
  ProfileImage,
  Row,
  Text,
  Button,
  PopupB,
  CommentInput,
} from 'components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export function Comment({
  type,
  module,
  item,
  user,
  comment,
  recomment,
  setRecomment,
  createReComment,
  openRecomment,
  deleting,
  editing,
  loading,
  ...props
}) {
  const { t } = useTranslation();
  const [editOpen, setEditOpen] = useState(false);
  const [recommentOpen, setRecommentOpen] = useState(false);

  const isViewedCommentUserName = !!(
    item.isViewedCommentUserName === 1 && comment.userName
  );

  const isUsedEditingComment = item.isUsedEditingComment === 1;
  const isUsedDeletingComment = item.isUsedDeletingComment === 1;

  return (
    <Div padding="20px" {...props}>
      <Row gap={10} alignItems="center" mb={10}>
        {item?.isViewedCommentUserThumbnail === 1 && (
          <ProfileImage
            size={32}
            thumbnail={
              comment.isOwnAdmin && !comment.userThumbnail
                ? '0'
                : comment.userThumbnail
            }
          />
        )}
        <Div flex={1} minHeight={21}>
          {comment.isOwnAdmin ? (
            <Text type="h8" flex={1}>
              {comment.isOwnAdmin && !comment.userName
                ? 'Admin'
                : comment.userName}
            </Text>
          ) : (
            !!isViewedCommentUserName && (
              <Text type="h8" flex={1}>
                {comment.userName || ''}
              </Text>
            )
          )}
          <Text color="g400">
            {moment(comment.createdDt).format('YYYY-MM-DD HH:mm')}
          </Text>
        </Div>
        {(isUsedEditingComment || isUsedDeletingComment) &&
          comment.isMine === 1 && (
            <PopupB
              name={comment.commentCode}
              items={
                <>
                  {isUsedEditingComment && (
                    <Text
                      hover="background"
                      cursor="pointer"
                      onClick={() => {
                        setEditOpen(true);
                      }}
                      whiteSpace="nowrap"
                      padding="4px 10px"
                    >
                      {t('edit')}
                    </Text>
                  )}
                  {isUsedDeletingComment && (
                    <Text
                      hover="background"
                      cursor="pointer"
                      onClick={deleting}
                      whiteSpace="nowrap"
                      padding="4px 10px"
                    >
                      {t('delete')}
                    </Text>
                  )}
                </>
              }
            >
              <Icon
                name="more"
                size={32}
                cursor="pointer"
                hover="background"
                transform="rotate(90deg)"
                fill="g500"
              />
            </PopupB>
          )}
      </Row>
      <Div>
        {!editOpen && (
          <Text color="g800" padding="10px">
            {comment.content}
          </Text>
        )}
        {editOpen && (
          <>
            <CommentInput
              initValue={comment.content}
              loading={loading}
              cancel={() => setEditOpen(false)}
              send={(value) => {
                editing(comment.commentCode, value, comment.parentCommentCode);
                setEditOpen(false);
              }}
              placeholder={t('input_placeholder')}
              wrapStyles={{ mb: 10 }}
            />
          </>
        )}
        {type !== 'recomment' && item?.isUsedRecomment === 1 && (
          <Row>
            {comment?.recomments > 0 && (
              <Button
                type="b3"
                buttonType="text-small"
                active
                keyFontColor="keyColor"
                display="flex"
                alignItems="center"
                onClick={openRecomment}
              >
                {t('recomment')} {comment?.recomments}
                <Icon
                  size={18}
                  fill="keyColor"
                  name="arrow-triangle"
                  transform={
                    (recomment[comment.commentCode] && 'rotate(180deg)') || ''
                  }
                />
              </Button>
            )}
            <Button
              type="b3"
              buttonType="text-small"
              onClick={() => setRecommentOpen(!recommentOpen)}
            >
              {t('reply')}
            </Button>
          </Row>
        )}
        {recommentOpen && (
          <CommentInput
            initFocus
            isOwnAdmin={!!comment.isOwnAdmin}
            isViewedCommentUserThumbnail={
              item?.isViewedCommentUserThumbnail === 1
            }
            isViewedCommentUserName={isViewedCommentUserName}
            cancel={() => setRecommentOpen(false)}
            send={(value) => {
              createReComment(value, comment.commentCode);
              setRecommentOpen(false);
            }}
            placeholder={t('input_placeholder')}
            loading={loading}
            wrapStyles={{ mt: 20, ml: 30, width: 'calc(100% - 30px)' }}
          />
        )}
      </Div>
    </Div>
  );
}
