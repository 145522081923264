import React, { Suspense, lazy } from 'react';

const GalleryWrite1 = lazy(() => import('./GalleryWrite1'));

export function BoardGalleryWrite1({ module, ...props }) {
  return (
    <Suspense fallback={<></>}>
      <GalleryWrite1 module={module} {...props} />
    </Suspense>
  );
}

const DefaultWrite = lazy(() => import('./DefaultWrite'));

export function BoardDefaultWrite({ module, ...props }) {
  return (
    <Suspense fallback={<></>}>
      <DefaultWrite module={module} {...props} />
    </Suspense>
  );
}
