import { Row, Div, HeaderLanguage } from 'components';
import React from 'react';

export function LoginHeader({ bucket, ...props }) {
  return (
    bucket?.languages?.length > 1 && (
      <Row
        height={60}
        alignItems="center"
        justifyContent="flex-end"
        padding="0 20px"
        top="0"
        {...props}
      >
        <Div
          backgroundColor="rgba(255,255,255,0.7)"
          borderRadius="50%"
          padding="4px"
        >
          <HeaderLanguage
            type={parseInt(
              bucket.headerOption.headerType.replace(/[^0-9]/g, ''),
              10,
            )}
            bucket={bucket}
            fontColor={bucket.headerOption.headerFontColor}
            backgroundColor={bucket.headerOption.headerBgColor}
          />
        </Div>
      </Row>
    )
  );
}
