import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Row, Text } from 'components';

export function TagFilters({ module, list, filters, setFilters }) {
  const { t } = useTranslation();
  const filterClick = (item, checked) => {
    const tags = (checked && filters.tags.filter((el) => el !== item)) || [
      ...(filters.tags || []),
      item,
    ];
    setFilters({
      ...filters,
      page: 1,
      lastRowNum: 0,
      tags,
    });
  };
  return (
    <Row rowGap={13} colGap={20} flexWrap="wrap">
      {module?.isViewedAllTagBtn === 1 && (
        <Row
          padding="0 10px"
          alignItems="center"
          height={40}
          borderRadius="999px"
          backgroundColor={
            ((!filters?.tags || filters.tags.length === 0) &&
              'layeredBackgroundColor') ||
            'g100'
          }
          border={{
            color:
              ((!filters?.tags || filters.tags.length === 0) && 'key500') ||
              'g100',
          }}
          cursor="pointer"
          onClick={() => {
            delete filters.tags;
            delete filters.myBookmark;
            setFilters({ ...filters });
          }}
        >
          <Text
            type={
              ((!filters?.tags || filters.tags.length === 0) && 'h8') || 'b2'
            }
            color={
              ((!filters?.tags || filters.tags.length === 0) && 'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {t('all_tag')}
          </Text>
        </Row>
      )}
      {module?.isViewedBookmarkTagBtn === 1 && (
        <Row
          padding="0 10px"
          alignItems="center"
          height={40}
          borderRadius="999px"
          backgroundColor={
            (filters?.myBookmark === 1 && 'layeredBackgroundColor') || 'g100'
          }
          border={{
            color: (filters?.myBookmark === 1 && 'keyColor') || 'transparent',
          }}
          cursor="pointer"
          onClick={() => {
            setFilters({
              ...filters,
              myBookmark: filters?.myBookmark === 1 ? 0 : 1,
            });
          }}
        >
          <Icon
            name="bookmark-done"
            fill={(filters?.myBookmark === 1 && 'keyColor') || 'g500'}
            size={16}
          />
          <Text
            type={(filters?.myBookmark === 1 && 'h8') || 'b2'}
            color={(filters?.myBookmark === 1 && 'keyColor') || 'g500'}
            whiteSpace="nowrap"
          >
            {t('bookmark_tag')}
          </Text>
        </Row>
      )}
      {list?.map((item) => (
        <Row
          padding="0 10px"
          alignItems="center"
          height={40}
          key={item}
          borderRadius="999px"
          backgroundColor={
            (filters?.tags &&
              filters.tags.includes(item) &&
              'layeredBackgroundColor') ||
            'g100'
          }
          border={{
            color:
              (filters?.tags && filters.tags.includes(item) && 'key500') ||
              'g100',
          }}
          cursor="pointer"
          onClick={() =>
            filterClick(item, filters?.tags && filters.tags.includes(item))
          }
        >
          <Text
            type={
              (filters?.tags && filters.tags.includes(item) && 'h8') || 'b2'
            }
            color={
              (filters?.tags && filters.tags.includes(item) && 'keyColor') ||
              'g400'
            }
            whiteSpace="nowrap"
          >
            {item}
          </Text>
        </Row>
      ))}
    </Row>
  );
}
