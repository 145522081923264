import { hexToRGB, rgbaToHex } from './hexToRGB';

export const makeTheme = (colorObj, theme) => {
  const newTheme = {
    backgroundColor: colorObj.backgroundColor || theme.colors.backgroundColor,
    fontColor: colorObj.fontColor || theme.colors.fontColor,
    keyColor: rgbaToHex(colorObj.keyColor || theme.colors.keyColor),
    key100: hexToRGB(
      rgbaToHex(rgbaToHex(colorObj.keyColor || theme.colors.keyColor)),
      0.04,
    ),
    key200: hexToRGB(
      rgbaToHex(colorObj.keyColor || theme.colors.keyColor),
      0.1,
    ),
    key300: hexToRGB(
      rgbaToHex(colorObj.keyColor || theme.colors.keyColor),
      0.2,
    ),
    key500: hexToRGB(
      rgbaToHex(colorObj.keyColor || theme.colors.keyColor),
      0.5,
    ),
    keyFontColor: colorObj.keyFontColor || theme.colors.keyFontColor,
    layeredBackgroundColor:
      colorObj.layeredBackgroundColor || theme.colors.layeredBackgroundColor,
    layeredFontColor:
      colorObj.layeredFontColor || theme.colors.layeredFontColor,
    subColor: colorObj.subColor || theme.colors.subColor,
    subFontColor: colorObj.subFontColor || theme.colors.subFontColor,
  };

  return newTheme;
};
