import { css } from 'styled-components';
import { checkPixel } from 'utils/css/checkPixel';

export const space = css`
  ${({ margin }) => (margin && `margin: ${margin};`) || ''};
  ${({ mb }) =>
    ((mb === 0 || mb) && `margin-bottom: ${checkPixel(mb)};`) || ''};
  ${({ mt }) => ((mt === 0 || mt) && `margin-top: ${checkPixel(mt)};`) || ''};
  ${({ mr }) => ((mr === 0 || mr) && `margin-right: ${checkPixel(mr)};`) || ''};
  ${({ ml }) => ((ml === 0 || ml) && `margin-left: ${checkPixel(ml)};`) || ''};

  ${({ padding }) => (padding && `padding: ${padding};`) || ''};
  ${({ pb }) =>
    ((pb === 0 || pb) && `padding-bottom: ${checkPixel(pb)};`) || ''};
  ${({ pt }) => ((pt === 0 || pt) && `padding-top: ${checkPixel(pt)};`) || ''};
  ${({ pr }) =>
    ((pr === 0 || pr) && `padding-right: ${checkPixel(pr)};`) || ''};
  ${({ pl }) => ((pl === 0 || pl) && `padding-left: ${checkPixel(pl)};`) || ''};

  ${({ top }) => ((top === 0 || top) && `top: ${checkPixel(top)};`) || ''};
  ${({ left }) =>
    ((left === 0 || left) && `left : ${checkPixel(left)};`) || ''};
  ${({ right }) =>
    ((right === 0 || right) && `right : ${checkPixel(right)};`) || ''};
  ${({ bottom }) =>
    ((bottom === 0 || bottom) && `bottom : ${checkPixel(bottom)};`) || ''};
  ${({ transform }) => (transform && `transform : ${transform};`) || ''};
  ${({ zIndex }) => (zIndex && `z-index: ${zIndex};`) || ''};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ mMargin }) => (mMargin && `margin: ${mMargin};`) || ''};
    ${({ mMb }) =>
      ((mMb === 0 || mMb) && `margin-bottom: ${checkPixel(mMb)};`) || ''};
    ${({ mMt }) =>
      ((mMt === 0 || mMt) && `margin-top: ${checkPixel(mMt)};`) || ''};
    ${({ mMr }) =>
      ((mMr === 0 || mMr) && `margin-right: ${checkPixel(mMr)};`) || ''};
    ${({ mMl }) =>
      ((mMl === 0 || mMl) && `margin-left: ${checkPixel(mMl)};`) || ''};

    ${({ mPadding }) => (mPadding && `padding: ${mPadding};`) || ''};
    ${({ mPb }) =>
      ((mPb === 0 || mPb) && `padding-bottom: ${checkPixel(mPb)};`) || ''};
    ${({ mPt }) =>
      ((mPt === 0 || mPt) && `padding-top: ${checkPixel(mPt)};`) || ''};
    ${({ mPr }) =>
      ((mPr === 0 || mPr) && `padding-right: ${checkPixel(mPr)};`) || ''};
    ${({ mPl }) =>
      ((mPl === 0 || mPl) && `padding-left: ${checkPixel(mPl)};`) || ''};

    ${({ mTop }) =>
      ((mTop === 0 || mTop) && `top: ${checkPixel(mTop)};`) || ''};
    ${({ mLeft }) =>
      ((mLeft === 0 || mLeft) && `left : ${checkPixel(mLeft)};`) || ''};
    ${({ mRight }) =>
      ((mRight === 0 || mRight) && `right : ${checkPixel(mRight)};`) || ''};
    ${({ mBottom }) =>
      ((mBottom === 0 || mBottom) && `bottom : ${checkPixel(mBottom)};`) || ''};
    ${({ mTransform }) => (mTransform && `transform : ${mTransform};`) || ''};
    ${({ mZIndex }) => (mZIndex && `z-index: ${mZIndex};`) || ''};
  }
`;
