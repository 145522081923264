import styled from 'styled-components';
import {
  common,
  space,
  borderElement,
  elementSize,
  flexContainer,
  flexElement,
  gridContainer,
} from './css';

export const LabelStyle = styled.label`
  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}
  ${gridContainer}
`;
