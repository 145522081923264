import { Row, Text, HTMLWrap } from 'components';
import React from 'react';

export function WYSIWYGTitle({ item, title }) {
  return (
    (title !== '' || title !== null) && (
      <Row gap={6} mb={10}>
        {item?.itemType !== 'content' && item?.isRequired === 1 && (
          <Text color="keyColor">*</Text>
        )}
        <HTMLWrap content={title} />
      </Row>
    )
  );
}
