export const makeVideoUrl = (url, autoPlay) => {
  let returnUrl = '';
  // if (url) {
  //   if (url.includes('youtube')) {
  //     const id = url.split('watch?v=')[1];
  //     returnUrl = `https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=1&playsinline=1`;
  //   } else if (url.includes('youtu.be')) {
  //     const id = url.split('youtu.be/')[1];
  //     returnUrl = `https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=1&playsinline=1`;
  //   } else if (url.includes('vimeo.com')) {
  //     if (url.includes('vimeo.com/event')) {
  //       const id = url.split('vimeo.com/event/')[1];
  //       returnUrl = `https://vimeo.com/event/${id}/embed?byline=0&title=0&portrait=0&autoplay=1`;
  //     } else if (url.includes('vimeo.com/video')) {
  //       const id = url.split('vimeo.com/video/')[1];
  //       returnUrl = `https://player.vimeo.com/video/${id}?byline=0&title=0&portrait=0&autoplay=1`;
  //     } else if (url.includes('vimeo.com')) {
  //       const id = url.split('vimeo.com/')[1];
  //       returnUrl = `https://player.vimeo.com/video/${id}?byline=0&title=0&portrait=0&autoplay=1`;
  //     } else {
  //       returnUrl = url;
  //     }
  //   }
  if (url) {
    if (url.includes('youtube')) {
      const id = url.split('watch?v=')[1];
      returnUrl = `https://www.youtube.com/embed/${id}?modestbranding=1&playsinline=1&autoplay=${autoPlay}&mute=${autoPlay}`;
    } else if (url.includes('youtu.be')) {
      const id = url.split('youtu.be/')[1];
      returnUrl = `https://www.youtube.com/embed/${id}?modestbranding=1&playsinline=1&autoplay=${autoPlay}&mute=${autoPlay}`;
    } else if (url.includes('vimeo.com')) {
      if (url.includes('vimeo.com/event')) {
        const id = url.split('vimeo.com/event/')[1];
        returnUrl = `https://vimeo.com/event/${id}/embed?byline=0&title=0&portrait=0&autoplay=${autoPlay}&muted=${autoPlay}`;
      } else if (url.includes('vimeo.com/video')) {
        const id = url.split('vimeo.com/video/')[1];
        returnUrl = `https://player.vimeo.com/video/${id}?byline=0&title=0&portrait=0&autoplay=${autoPlay}&muted=${autoPlay}`;
      } else if (url.includes('vimeo.com')) {
        const id = url.split('vimeo.com/')[1];
        returnUrl = `https://player.vimeo.com/video/${id}?byline=0&title=0&portrait=0&autoplay=${autoPlay}&muted=${autoPlay}`;
      }
    } else {
      returnUrl = url;
    }
  }

  return returnUrl;
};
