import React, { useEffect, useState } from 'react';
import { Icon, Row } from 'components';
import { SearchInputStyles } from 'styles/InputStyles';
import { useTranslation } from 'react-i18next';

export function Search({ filters, setFilters, name, ...props }) {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');

  const [timer, setTimer] = useState(0); // 디바운싱 타이머
  const searching = (event) => {
    const { value } = event.target;
    setKeyword(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      try {
        if (name) {
          setFilters({
            ...filters,
            page: 1,
            lastRowNum: 0,
            [name]: value,
          });
        } else {
          setFilters({ ...filters, page: 1, lastRowNum: 0, keyword: value });
        }
      } catch (e) {
        console.error('error', e);
      }
    }, 800);
    setTimer(newTimer);
  };

  useEffect(() => {
    if (filters?.keyword) {
      setKeyword(filters?.keyword);
    }
  }, [filters]);
  return (
    <Row
      alignItems="center"
      gap={6}
      borderRadius="999px"
      backgroundColor="g100"
      padding="10px"
      width={280}
      mWidth="100%"
      {...props}
    >
      <Icon name="search" size={21} />
      <SearchInputStyles
        name={name || 'keyword'}
        placeholder={t('search_placeholder')}
        value={keyword}
        onChange={searching}
      />
    </Row>
  );
}
