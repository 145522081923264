import { Button, Div, Text } from 'components';
import moment from 'moment';
import React from 'react';

export function Payinfo({ t, payInfo, detail, cancel }) {
  return (
    <Div
      width="100%"
      margin="40px auto 0 auto"
      maxWidth={920}
      minWidth={820}
      mMinWidth="unset"
    >
      <Text type="h4" mb={30}>
        {t('payment_info_title')}
      </Text>
      <Div mb={40}>
        <Text type="h6">{payInfo.info1Name}</Text>
        <Text padding="10px" color="g600">
          {payInfo.info1}
        </Text>
      </Div>
      <Div mb={40}>
        <Text type="h6">{payInfo.info2Name}</Text>
        <Text padding="10px" color="g600">
          {payInfo.info2}
        </Text>
      </Div>
      <Div mb={40}>
        <Text type="h6">{t('payment_amout')}</Text>
        <Text padding="10px" color="g600">
          {payInfo.price}
        </Text>
      </Div>
      <Div mb={40}>
        <Text type="h6">{t('payment_date')}</Text>
        <Text padding="10px" color="g600">
          {moment(payInfo.paidDt).format('YYYY.MM.DD HH:mm')}
        </Text>
      </Div>
      <Div mb={40}>
        <Text type="h6">{t('payment_detail')}</Text>
        <Text padding="10px" color="g600">
          {payInfo.payInfo.replaceAll('null', '')}
        </Text>
      </Div>
      {detail?.isCanceled === 0 && (
        <Button margin="0 auto" active onClick={cancel} display="block">
          {t('refund_registration')}
        </Button>
      )}
      {detail?.isCanceled === 1 && (
        <>
          <Text textAlign="center">{t('registration_cancel_success')}</Text>
          <Text textAlign="center">{t('registration_cancel_content')}</Text>
        </>
      )}
    </Div>
  );
}
