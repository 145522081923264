import {
  Badge,
  BadgeButton,
  Bookmark,
  Div,
  Icon,
  LabelBadge,
  Row,
  Text,
} from 'components';
import { useViewedItems } from 'hooks';
import React, { useEffect } from 'react';

export function ReservationInfo({
  type,
  item,
  commentLength,
  logging,
  ...props
}) {
  const {
    setItem,
    isUsedActionBar,
    isViewedLabel,
    isViewedClickCount,
    isViewedCommentBtn,
    isViewedTags,
    isViewedBookmarkBtn,
    isViewedLikeBtn,
    isViewedNew,
    isViewedStatus,
  } = useViewedItems();

  useEffect(() => {
    if (item) {
      setItem(item);
    }
  }, [item]);
  return (
    <Div mDisplay="none" {...props}>
      <Row>
        <Row
          gap={10}
          justifyContent={
            ((isViewedNew || isViewedStatus) && 'space-between') || 'flex-end'
          }
          width="100%"
          mb={10}
        >
          {(isViewedNew || isViewedStatus) && (
            <Row gap={10}>
              {isViewedNew && (
                <LabelBadge
                  type="h10"
                  backgroundColor="keyFontcolor"
                  color="keyColor"
                  border={{ color: 'key500' }}
                >
                  New
                </LabelBadge>
              )}
              {isViewedStatus && item?.statusLabels && (
                <LabelBadge
                  type="h10"
                  backgroundColor={
                    (item.status === 'completed' && 'done200') || 'g100'
                  }
                  color={(item.status === 'completed' && 'done') || 'g400'}
                >
                  {item?.statusLabels[item?.status]}
                </LabelBadge>
              )}
            </Row>
          )}
          {isViewedBookmarkBtn && (
            <Row gap={10}>
              <Bookmark
                isBookmark={item?.isBookmark}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  logging('bookmark', item);
                }}
              />
            </Row>
          )}
        </Row>
      </Row>
      {isViewedLabel && (
        <Text type="b2" mb={10}>
          {item?.label}
        </Text>
      )}
      {(item.title || item.name) && (
        <Text type="h4" mb={30}>
          {item.title || item.name}
        </Text>
      )}
      {isViewedTags && item.tags?.length > 0 && (
        <Text color="g400" type="b4" display="flex" gap={6} flexWrap="wrap">
          {item.tags?.map((tag) => (
            <Badge key={`${tag}${Math.random()}`} color="g400" type="b4">
              {tag}
            </Badge>
          ))}
        </Text>
      )}
      {isUsedActionBar && (
        <Row gap={10} mt={20} mb={30}>
          {isViewedLikeBtn && (
            <BadgeButton
              name="like"
              active={item?.isLike === 1}
              size={14}
              type="b5"
              gap={3}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                logging('like', item);
              }}
            >
              {(item?.isViewedLikeCount &&
                ((item?.likes > 100 && '100+') || item?.likes?.toString())) ||
                ''}
            </BadgeButton>
          )}
          {isViewedClickCount && (
            <BadgeButton
              name="view"
              stroke="transparent"
              fill="g500"
              hover={false}
              cursor="default"
              size={14}
              type="b5"
              gap={3}
            >
              {(item?.isViewedLikeCount &&
                ((item?.contentClicks > 100 && '100+') ||
                  item?.contentClicks?.toString())) ||
                ''}
            </BadgeButton>
          )}
          {isViewedCommentBtn && (
            <BadgeButton
              name="comment"
              point={item?.isNewComment === 1}
              size={14}
              type="b5"
              gap={3}
            >
              {(item?.isViewedCommentCount &&
                ((commentLength &&
                  ((Number(commentLength) > 100 && '100+') ||
                    commentLength?.toString())) ||
                  (Number(item?.comments) > 100 && '100+') ||
                  item?.comments?.toString())) ||
                ''}
            </BadgeButton>
          )}
        </Row>
      )}
    </Div>
  );
}
