import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle` 
    html { font-size: 62.5% !important; }  
    body{
      padding: 0;
      margin: 0;
      font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
      font-weight: 400; 
      font-size: 1.4rem;
      line-height: 1.5; 
      overflow-wrap: break-word;
      word-break: keep-all;
      overflow-x: hidden;
      overflow-y: overlay; 
      color: #2D2F34;
    };
    body::-webkit-scrollbar {
      width: 1vw;
      max-width: 6px;
      background: transparent;
    };
    body::-webkit-scrollbar-thumb {
      background-color: hsla(0, 0%, 42%, 0.49);
      border-radius: 100px;
    };
    button{
      all: unset; 
    }
    button:not(:disabled){
      cursor:pointer;
    }
    html,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend, 
    caption, 
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit; 
    }
    table {
     border-collapse: collapse; 
    } 
    input:focus {
     outline: none;
    }
    textarea:focus {
      outline: none;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    a:hover, a:active {text-decoration: none; color:inherit;}
    strong{
      font-weight:700;
    };
    input::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    } 
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
      transition: background-color 5000s;
      -webkit-text-fill-color: #transparent !important;
    }
`;
