import React from 'react';
import { SpanStyles } from 'styles/TextStyle';

export function Span({ setRef, type, mType, whiteSpace, children, ...props }) {
  return (
    <>
      {(children &&
        children.toString().includes('\n') &&
        whiteSpace !== 'pre-line' &&
        children?.split('\n').map(
          (text, index) =>
            (text && (
              <SpanStyles
                key={`${text[0]}${Math.random()}${index}`}
                type={type || 'b2'}
                mType={mType}
                {...props}
              >
                {text}
              </SpanStyles>
            )) || <br key={`${text[0]}${Math.random()}${index}`} />,
        )) || (
        <SpanStyles
          type={type || 'b2'}
          whiteSpace={whiteSpace}
          mType={mType}
          {...props}
        >
          {children}
        </SpanStyles>
      )}
    </>
  );
}
