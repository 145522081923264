import { Col, Div } from 'components';
import styled, { css } from 'styled-components';
import { hexToRGB } from 'utils/css/hexToRGB';
import {
  common,
  space,
  borderElement,
  elementSize,
  flexContainer,
  flexElement,
  textElement,
} from './css';
import { jelloAnim } from './css/Animation';

const buttonPadding = {
  small: '0.8rem 1.8rem',
  medium: '0.8rem 1rem',
  large: '14px 40px',
};

const buttonSize = {
  'text-small': css`
    padding: 0.4rem;
  `,
  text: css`
    padding: ${buttonPadding.medium};
  `,
  small: css`
    min-width: 64px;
    padding: ${buttonPadding.small};
  `,
  default: css`
    width: 154px;
    padding: ${buttonPadding.medium};
  `,
  full: css`
    width: 100%;
    max-width: 320px;
    padding: ${buttonPadding.medium};
  `,
  large: css`
    width: 100%;
    padding: ${buttonPadding.large};
  `,
};

export const RoundButtonStyle = styled.button`
  transition: all 100ms;
  overflow: hidden;
  display: inline-block;
  ${({ buttonType }) => buttonSize[buttonType]};
  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}
  ${textElement}

  ${({ $loading, active, theme }) =>
    ($loading &&
      css`
        cursor: default !important;
      `) ||
    css`
      @media (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover {
          ${(active &&
            `background-color: ${theme.colors.keyColor};
            color:${theme.colors.keyFontColor}`) ||
          `opacity: 0.7;`}
        }
      }
    `}
`;

export const ButtonStyle = styled.button`
  transition: all 100ms;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  ${({ buttonType }) => buttonSize[buttonType]};
  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}
  ${textElement}

  ${({ $loading }) =>
    ($loading &&
      css`
        cursor: default !important;
      `) ||
    css`
      @media (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover {
          opacity: 0.7;
        }
      }

      &:not(:disabled):active {
        opacity: 1;
        &:after {
          position: absolute;
          content: ' ';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 6px;
          background-color: ${({ theme }) => hexToRGB(theme.colors.g200, 0.4)};
        }
      }
    `}
`;

export const FloatingButtonStyle = styled(Div)`
  @media (hover: hover) and (pointer: fine) {
    &:not(:disabled):hover {
      ${({ editing }) =>
        !editing &&
        css`
          animation: ${jelloAnim} 0.9s both;
        `}
    }
  }
`;
