import React from 'react';
import {
  Checkbox,
  Div,
  Textarea,
  InputFile,
  Radio,
  WYSIWYGTitle,
  Row,
  Text,
  Birthday,
  Email,
  Address,
  Col,
  Phone,
  CountryCode,
  Verified,
  Dropdown,
  CalendarInput,
  Name,
  Invalid,
  VerifiedEmailSelection,
  SimpleInput,
} from 'components';
import { genders, gendersWithETC } from 'data/registration';
import { useTranslation } from 'react-i18next';

export function BorderBox({ border, active, invalid, ...props }) {
  return (
    <Div
      borderRadius="12px"
      border={
        (border && {
          color:
            (active && 'keyColor') ||
            (invalid?.type === 'error' && 'red') ||
            'g200',
          width: (active && '3px') || '1px',
        }) || { color: 'transparent' }
      }
      transition="all 150ms ease"
      width="100%"
      {...props}
    ></Div>
  );
}

export function InputCard({
  designType,
  item,
  name,
  invalid,
  value,
  etcValues,
  setEtcValues,
  onEtcChange,
  onCheckChange,
  onMutipleChange,
  onChange,
  initValue,
  currentLanguage,
  disabled,
  isTemporary,
  isRegistration,
  children,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <Div {...props}>
      {!item.itemType.includes('name_detail') && (
        <WYSIWYGTitle title={item.title} item={item} />
      )}
      {item.itemType.includes('name_detail') && (
        <Name
          item={item}
          onChange={onChange}
          name={name}
          initValue={initValue}
          disabled={disabled}
          invalid={invalid}
        />
      )}
      {item.itemType === 'upload' && (
        <InputFile
          name={name}
          value={value?.fileName}
          onChange={onChange}
          moduleCode={item.moduleCode}
          placeholder={t('input_placeholder')}
          disabled={disabled}
          invalid={invalid}
        />
      )}
      {item.itemType === 'multiple_upload' && (
        <Col gap={10}>
          {value.length > 0 &&
            value.map((val, index) => (
              <InputFile
                key={`${item.itemCode}-file-${index}`}
                name={`${item.itemCode}-file-${index}`}
                value={val.fileName}
                onChange={(value) => onMutipleChange(value, name, index)}
                moduleCode={item.moduleCode}
                placeholder={t('input_placeholder')}
                disabled={disabled}
                invalid={invalid}
              />
            ))}
          <InputFile
            name={name}
            value=""
            onChange={(value) => onMutipleChange(value, name, value.length + 1)}
            moduleCode={item.moduleCode}
            placeholder={t('input_placeholder')}
            disabled={disabled}
            invalid={invalid}
          />
        </Col>
      )}
      {(item.itemType === 'name' ||
        item.itemType === 'subject' ||
        item.itemType === 'age' ||
        item.itemType === 'employee_number' ||
        item.itemType === 'number') && (
        <SimpleInput
          name={name}
          type={
            ((item.itemType === 'age' || item.itemType === 'number') &&
              'tel') ||
            ''
          }
          value={value}
          onChange={onChange}
          disabled={disabled}
          invalid={invalid}
        />
      )}
      {item.itemType === 'singleDropDown' && (
        <Dropdown
          list={
            item.selectionEtcTitle
              ? [...(item.selections || []), item.selectionEtcTitle]
              : [...(item.selections || [])]
          }
          customItem={(el) => el.title}
          customKey="title"
          direct={item.selectionEtcTitle}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          invalid={invalid}
        />
      )}
      {item.itemType === 'multipleDropDown' && (
        <Dropdown
          list={
            item.selectionEtcTitle
              ? [...(item.selections || []), item.selectionEtcTitle]
              : [...(item.selections || [])]
          }
          customItem={(el) => el.title}
          customKey="title"
          direct={item.selectionEtcTitle}
          name={name}
          value={value}
          onChange={onMutipleChange}
          disabled={disabled}
          invalid={invalid}
          multiple
        />
      )}
      {item.itemType === 'subject_long' && (
        <Textarea
          height={180}
          minHeight={180}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          invalid={invalid}
        />
      )}
      {item.itemType === 'single' && (
        <Row
          gap={20}
          flexDirection={
            (item.orientation === 'horizontal' && 'row') || 'column'
          }
        >
          {item.selections?.map((selection) => (
            <BorderBox
              key={`${item.itemCode}-${selection.selectionCode}`}
              active={value.selectionCode === selection.selectionCode}
              invalid={invalid}
              border={designType === 'design2'}
              width={(designType === 'design1' && 'auto') || '100%'}
            >
              <Radio
                value={selection.selectionCode}
                name={name}
                onChange={(value, name, checked) => {
                  if (item.selectionEtcTitle) {
                    delete etcValues[item.itemCode];
                    setEtcValues(etcValues);
                  }
                  onChange(selection, name, checked);
                }}
                checked={value?.selectionCode === selection.selectionCode}
                disabled={disabled}
                padding={
                  (designType === 'design1' && '10px 10px 10px 0') || '30px'
                }
                width={(designType === 'design1' && 'fit-content') || '100%'}
              >
                <Text>{selection.title}</Text>
              </Radio>
            </BorderBox>
          )) || ''}
        </Row>
      )}
      {item.itemType === 'single' && item.selectionEtcTitle?.selectionCode && (
        <BorderBox
          mt={20}
          border={designType === 'design2'}
          active={
            value.selectionCode === item.selectionEtcTitle.selectionCode ||
            Object.keys(etcValues).includes(item.itemCode)
          }
          invalid={invalid}
          borderRadius="12px"
        >
          <Radio
            value={item.selectionEtcTitle.selectionCode}
            name={name}
            onChange={() => onChange(item.selectionEtcTitle, name)}
            checked={
              value.selectionCode === item.selectionEtcTitle.selectionCode ||
              Object.keys(etcValues).includes(item.itemCode)
            }
            disabled={disabled}
            padding={(designType === 'design1' && '10px 10px 10px 0') || '30px'}
            width={(designType === 'design1' && 'fit-content') || '100%'}
          >
            <Text>{item.selectionEtcTitle.title}</Text>
          </Radio>
          <Textarea
            minHeight={180}
            onMouseDown={() => {
              onChange(item.selectionEtcTitle, name, true);
              setEtcValues({
                ...etcValues,
                [item.itemCode]: {
                  selectionCode: item.selectionEtcTitle.selectionCode,
                  title: etcValues[name]?.title || '',
                },
              });
            }}
            name={name}
            value={etcValues[name]?.title || ''}
            onChange={onEtcChange}
            disabled={disabled}
            wrapStyles={{
              width: 'calc(100% - 60px)',
              margin: '0 auto',
            }}
          />
        </BorderBox>
      )}
      {item.itemType === 'multiple' && (
        <Row
          gap={20}
          flexDirection={
            (item.orientation === 'horizontal' && 'row') || 'column'
          }
        >
          {(item.selections &&
            item.selections?.map((selection) => (
              <BorderBox
                key={`${item.itemCode}-${selection.selectionCode}`}
                border={designType === 'design2'}
                active={
                  value &&
                  !!value?.find(
                    (el) => el.selectionCode === selection.selectionCode,
                  )
                }
                invalid={invalid}
                borderRadius="12px"
                width={(designType === 'design1' && 'auto') || '100%'}
              >
                <Checkbox
                  value={selection.selectionCode}
                  name={name}
                  onChange={(val, name, checked) =>
                    onCheckChange(selection, name, checked)
                  }
                  checked={
                    value &&
                    !!value?.find(
                      (el) => el.selectionCode === selection.selectionCode,
                    )
                  }
                  disabled={disabled}
                  padding={
                    (designType === 'design1' && '10px 10px 10px 0') || '30px'
                  }
                  width={(designType === 'design1' && 'fit-content') || '100%'}
                >
                  <Text>{selection.title}</Text>
                </Checkbox>
              </BorderBox>
            ))) ||
            ''}
        </Row>
      )}
      {item.itemType === 'multiple' &&
        item.selectionEtcTitle?.selectionCode && (
          <BorderBox
            mt={20}
            active={Object.keys(etcValues).includes(item.itemCode)}
            invalid={invalid}
            border={designType === 'design2'}
          >
            <Checkbox
              value={item.selectionEtcTitle}
              name={name}
              onChange={(value, name, checked) => {
                onCheckChange(item.selectionEtcTitle, name, checked);
                if (checked) {
                  setEtcValues({
                    ...etcValues,
                    [item.itemCode]: {
                      selectionCode: item.selectionEtcTitle.selectionCode,
                      title: etcValues[name]?.title || '',
                    },
                  });
                } else {
                  delete etcValues[item.itemCode];
                  setEtcValues(etcValues);
                }
              }}
              checked={Object.keys(etcValues).includes(item.itemCode)}
              width={(designType === 'design1' && 'fit-content') || '100%'}
              padding={
                (designType === 'design1' && '10px 10px 10px 0') || '30px'
              }
              disabled={disabled}
            >
              <Text>{item.selectionEtcTitle.title}</Text>
            </Checkbox>
            <Textarea
              minHeight={180}
              onMouseDown={() => {
                if (
                  !value?.find(
                    (el) =>
                      el.selectionCode === item.selectionEtcTitle.selectionCode,
                  )
                ) {
                  onCheckChange(
                    item.selectionEtcTitle,
                    name,
                    !value?.includes(item.selectionEtcTitle),
                  );
                  setEtcValues({
                    ...etcValues,
                    [item.itemCode]: {
                      selectionCode: item.selectionEtcTitle.selectionCode,
                      title: etcValues[name]?.title || '',
                    },
                  });
                }
              }}
              name={name}
              value={etcValues[item.itemCode]?.title || ''}
              onChange={onEtcChange}
              wrapStyles={{
                width: 'calc(100% - 60px)',
                margin: '0 auto',
              }}
              disabled={disabled}
            />
          </BorderBox>
        )}

      {isRegistration && item.itemType === 'gender' && (
        <Row gap={10}>
          {item.selections?.map((selection) => (
            <BorderBox
              key={`${item.itemCode}-${selection.selectionCode}`}
              flex={1}
              active={value.selectionCode === selection.selectionCode}
              border
              invalid={invalid}
            >
              <Radio
                width="100%"
                value={selection.selectionCode}
                name={name}
                onChange={(value, name, checked) => {
                  if (item.selectionEtcTitle) {
                    delete etcValues[item.itemCode];
                    setEtcValues(etcValues);
                  }
                  onChange(selection, name, checked);
                }}
                checked={value?.selectionCode === selection.selectionCode}
                disabled={disabled}
                padding="20px"
              >
                <Text>{selection.title}</Text>
              </Radio>
            </BorderBox>
          )) || ''}
        </Row>
      )}
      {!isRegistration && item.itemType === 'gender' && (
        <Row gap={10}>
          {genders.map((gender) => (
            <BorderBox
              key={`${item.itemCode}-${gender}`}
              flex={1}
              active={value === gender}
              border
              invalid={invalid}
            >
              <Radio
                width="100%"
                value={gender}
                name={name}
                onChange={onChange}
                checked={value === gender}
                padding="20px"
                disabled={disabled}
              >
                <Text>{t(gender)}</Text>
              </Radio>
            </BorderBox>
          ))}
        </Row>
      )}
      {item.itemType === 'genderEtc' && (
        <Row gap={10}>
          {gendersWithETC.map((gender) => (
            <BorderBox
              key={`${item.itemCode}-${gender}`}
              flex={1}
              active={value === gender}
              border
              invalid={invalid}
            >
              <Radio
                width="100%"
                value={gender}
                name={name}
                onChange={onChange}
                checked={value === gender}
                padding="20px"
                disabled={disabled}
              >
                <Text>{t(gender)}</Text>
              </Radio>
            </BorderBox>
          ))}
        </Row>
      )}
      {item.itemType === 'email' && (
        <Email
          onChange={onChange}
          name={name}
          value={value}
          selectionEtcTitle={item.selectionEtcTitle}
          selections={item.selections}
          initValue={initValue}
          disabled={disabled}
          invalid={invalid}
        />
      )}
      {(item.itemType === 'birthdate' ||
        item.itemType === 'birthdateAdult') && (
        <Birthday
          onChange={onChange}
          name={name}
          initValue={initValue}
          disabled={disabled}
          invalid={invalid}
          isOnlyAdult={item.itemType === 'birthdateAdult'}
        />
      )}
      {(item.itemType === 'calendar' ||
        item.itemType === 'calendarTime' ||
        item.itemType === 'time') && (
        <CalendarInput
          onChange={onChange}
          name={name}
          initValue={initValue}
          disabled={disabled}
          invalid={invalid}
          hasTime={item.itemType === 'calendarTime'}
          onlyTime={item.itemType === 'time'}
          is24Hour={item.is24Hour}
          minuteInterval={item.minuteInterval}
        />
      )}
      {!item.itemType.includes('verified') &&
        item.itemType.includes('phone') && (
          <Phone
            useCountryCode={item.itemType.includes('global')}
            onChange={onChange}
            name={name}
            initValue={initValue}
            disabled={disabled}
          />
        )}
      {item.itemType === 'country' && (
        <CountryCode
          type="country"
          currentLanguage={currentLanguage}
          name={name}
          onChange={(val, phoneType, countryName) => {
            if (val) {
              onChange({ countryCode: val, countryName }, name);
            } else {
              onChange('', name);
            }
          }}
          initValue={initValue}
          disabled={disabled}
        />
      )}
      {item.itemType === 'verified_email' && item.selections?.length > 0 && (
        <VerifiedEmailSelection
          type="email"
          name={name}
          onChange={onChange}
          initValue={initValue}
          disabled={disabled}
          selectionEtcTitle={item.selectionEtcTitle}
          selections={item.selections}
          isTemporary={isTemporary}
        />
      )}
      {((item.itemType === 'verified_email' && item.selections?.length === 0) ||
        item.itemType === 'verified_phone' ||
        item.itemType === 'verified_global_phone') && (
        <Verified
          useCountryCode={item.itemType.includes('global')}
          type={(item.itemType.includes('phone') && 'phone') || 'email'}
          name={name}
          onChange={onChange}
          initValue={initValue}
          disabled={disabled}
        />
      )}
      {(item.itemType.includes('address') ||
        item.itemType === 'post_number') && (
        <Address
          type={item.itemType}
          onChange={onChange}
          name={name}
          initValue={initValue}
          disabled={disabled}
        />
      )}
      <Invalid invalid={{ ...invalid, text: t('invalid_item') }} />
      {children}
    </Div>
  );
}
