import React from 'react';
import { LoadingStyles } from 'styles/LoadingStyles';

export function Loading({
  type,
  size,
  mSize,
  position,
  backgroundColor,
  delay,
  ...props
}) {
  const onClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <LoadingStyles
      width="100%"
      height="100%"
      position={position || 'fixed'}
      type={type}
      size={size}
      mSize={mSize}
      alignItems="center"
      justifyContent="center"
      onClick={onClickHandler}
      backgroundColor={backgroundColor || 'rgba(255, 255, 255, 0)'}
      delay={delay}
      zIndex={9999}
      {...props}
    >
      <div className="loader" />
    </LoadingStyles>
  );
}
