import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'styles/css/calendar.css';
import styled, { useTheme } from 'styled-components';
import { Row, Icon } from 'components';

const DateSelect = styled.select`
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
  height: auto; /* 높이 초기화 */
  line-height: normal; /* line-height 초기화 */
  font-family: inherit; /* 폰트 상속 */
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
`;

export function CalendarInput({
  onChange,
  name,
  invalid,
  initValue,
  disabled,
  hasTime,
  onlyTime,
  is24Hour,
  minuteInterval,
  ...props
}) {
  const theme = useTheme();
  const [date, setDate] = useState();

  function range(start, end) {
    const array = [];
    for (let i = start; i < end; i += 1) {
      array.push(i);
    }
    return array;
  }

  const getYear = (year) => year.getFullYear();
  const years = range(getYear(new Date()), getYear(new Date()) + 10, 1);

  const getMonth = (month) => month.getMonth();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const onDateChangeHandler = (date) => {
    if (!disabled) {
      setDate(date);

      if (onlyTime && date) {
        onChange(
          {
            hour: moment(date).format('HH'),
            minute: moment(date).format('mm'),
          },
          name,
        );
      } else if (hasTime && date) {
        onChange(
          {
            year: moment(date).format('YYYY'),
            month: moment(date).format('MM'),
            date: moment(date).format('DD'),
            hour: moment(date).format('HH'),
            minute: moment(date).format('mm'),
          },
          name,
        );
      } else if (date) {
        onChange(
          {
            year: moment(date).format('YYYY'),
            month: moment(date).format('MM'),
            date: moment(date).format('DD'),
          },
          name,
        );
      } else {
        onChange('', name);
      }
    }
  };

  useEffect(() => {
    if (initValue) {
      let tempMoment = null;

      if (hasTime) {
        tempMoment = moment(
          `${initValue.year}-${initValue.month}-${initValue.date} ${initValue.hour}:${initValue.minute}`,
        );
      } else if (onlyTime) {
        tempMoment = moment(`${initValue.hour}:${initValue.minute}`, 'HH:mm');
      } else {
        tempMoment = moment(
          `${initValue.year}-${initValue.month}-${initValue.date}`,
          'YYYY-MM-DD',
        );
      }

      if (tempMoment.isValid()) {
        setDate(tempMoment.toDate());
      }
    }
  }, [initValue]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-calendar__tile');
    [...elements].forEach((element) => {
      element.style.setProperty('--key-color', theme.colors.keyColor);
      element.style.setProperty('--key500-color', theme.colors.key500);
    });
  }, []);

  return (
    <DatePicker
      selected={date}
      onChange={onDateChangeHandler}
      dateFormat={
        (hasTime &&
          ((is24Hour && 'yyyy-MM-dd HH:mm') || 'yyyy-MM-dd HH:mm aa')) ||
        (onlyTime && (is24Hour ? 'HH:mm' : 'HH:mm aa')) ||
        'yyyy-MM-dd'
      }
      showTimeSelect={hasTime || onlyTime}
      showTimeSelectOnly={onlyTime}
      timeFormat={(is24Hour && 'HH:mm') || 'HH:mm aa'}
      timeIntervals={minuteInterval}
      className={(invalid?.type === 'error' && 'invalid') || ''}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Row
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          padding="0 8px"
        >
          <Icon
            name="arrow"
            transform="rotate(90deg)"
            fill="g700"
            cursor={(prevMonthButtonDisabled && 'not-allowed') || 'pointer'}
            hover
            onClick={() => {
              if (!prevMonthButtonDisabled) {
                decreaseMonth();
              }
            }}
          />
          <Row justifyContent="center" alignItems="center" flex={1}>
            <DateSelect
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </DateSelect>
            <DateSelect
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </DateSelect>
          </Row>
          <Icon
            name="arrow"
            transform="rotate(-90deg)"
            fill="g700"
            cursor={(nextMonthButtonDisabled && 'not-allowed') || 'pointer'}
            hover
            onClick={() => {
              if (!nextMonthButtonDisabled) {
                increaseMonth();
              }
            }}
          />
        </Row>
      )}
    />
  );
}
