import { Col } from 'components';
import { useWindowSize } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { animations } from 'styles/css';

export function Dim({ onClick, overflowHidden, children, ...props }) {
  const windowSize = useWindowSize();
  const dimRef = useRef();
  const [parentWidth, setParentWidth] = useState();
  const [parentHeight, setParentHeight] = useState();
  useEffect(() => {
    if (overflowHidden) {
      document.body.style.setProperty('overflow-y', 'hidden');
    }

    const wrapElement = dimRef.current.closest('.dim-disabled-overlay');
    if (wrapElement) {
      setParentWidth(wrapElement.getBoundingClientRect().width);
      setParentHeight(wrapElement.getBoundingClientRect().height);
    }

    return () => {
      if (overflowHidden) {
        document.body.style.setProperty('overflow-y', 'overlay');
      }
    };
  }, [overflowHidden]);

  const onClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Col
      setRef={dimRef}
      position="fixed"
      top="0"
      left="0"
      width={parentWidth || '100vw'}
      height={parentHeight || windowSize.height}
      zIndex={9999}
      backgroundColor="dim"
      alignItems="center"
      justifyContent="center"
      animation={animations.fadeIn}
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </Col>
  );
}
