import React from 'react';
import { LabelStyle } from 'styles/LabelStyle';

export function Label({ setRef, children, ...props }) {
  return (
    <LabelStyle ref={setRef} {...props}>
      {children}
    </LabelStyle>
  );
}
