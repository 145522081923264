import React from 'react';
import { Dim, Div, Icon } from 'components';
import { animations } from 'styles/css';
import { useWindowSize } from 'hooks';

export function Modal({ close, children, childrenProps, ...props }) {
  const windowSize = useWindowSize();

  return (
    <>
      <Dim onClick={close} overflowHidden />
      <Div
        maxWidth={1280}
        width="calc(100vw - 40px)"
        position="fixed"
        zIndex={9999}
        top="calc(50% + 6px)"
        left="50%"
        animation={animations.slideUp()}
        {...props}
      >
        <Icon
          name="close"
          position="absolute"
          size={36}
          top="-56px"
          right="0"
          cursor="pointer"
          onClick={close}
          stroke="white"
        />
        <Div
          width="100%"
          padding="68px min(7% , 100px) min(7% , 50px) min(7% , 100px)"
          overflowY="overlay"
          height="100%"
          maxHeight={Math.round(windowSize.height * 0.85 - 56 - 70)}
          borderRadius="1.2rem"
          backgroundColor="backgroundColor"
          {...childrenProps}
        >
          {children}
        </Div>
      </Div>
    </>
  );
}
