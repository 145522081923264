import styled, { css } from 'styled-components';
import {
  common,
  space,
  borderElement,
  elementSize,
  flexContainer,
  flexElement,
} from './css';

export const InputImageStyles = styled.label`
  transition: all 100ms;
  display: inline-flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.g200};
  width: 250px;
  height: 250px;
  border-radius: 0.3rem;
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}

  @media ${({ theme }) => theme.device.tablet} {
    width: 100px;
    height: 100px;
  }
`;

export const InputFileStyles = styled.label`
  transition: all 100ms;
  display: inline-flex;
  width: 100%;
  border: 1px solid
    ${({ invalid, theme }) =>
      (invalid && theme.colors[(invalid === 'error' && 'red') || 'blue']) ||
      theme.colors.g200};
  border-radius: 0.3rem;
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: inherit;
  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}

  & ~ .input-button > .input-delete {
    display: none;
  }

  &:focus {
    border: 1px solid
      ${({ keyColor, theme }) => keyColor || theme.colors.keyColor};

    & ~ .input-button > .input-delete {
      display: inline-block;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    & ~ .input-button > *:not(.input-disabled-click) {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const InputStyles = styled.input`
  transition: all 100ms;
  width: 100%;
  border: 1px solid
    ${({ invalid, theme }) =>
      (invalid && theme.colors[(invalid === 'error' && 'red') || 'blue']) ||
      theme.colors.g200};
  border-radius: 10px;
  padding: 15px 12px;
  font-size: 16px;
  line-height: 1.5;
  font-family: inherit;
  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}

  & ~ .input-button > .input-delete {
    display: none;
  }

  &:focus {
    border: 1px solid
      ${({ keyColor, theme }) => keyColor || theme.colors.keyColor};

    & ~ .input-button > .input-delete {
      display: inline-block;
    }
  }

  &:disabled {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.g200};
  }

  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.g300};
  }

  @media (hover: hover) and (pointer: fine) {
    & ~ .input-button > *:not(.input-disabled-click) {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const ChatInputStyles = styled.textarea`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 1.5;
  resize: none;
  background-color: transparent;
  padding: 0;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.g300};
    border-radius: 20px;
  }

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}


  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.g300};
  }
`;

export const CommentInputStyles = styled.textarea`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 1.5;
  resize: none;
  background-color: transparent;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.g300};
    border-radius: 20px;
  }

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}

  & ~ .input-button > .input-delete {
    display: none;
  }

  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.g300};
  }

  @media (hover: hover) and (pointer: fine) {
    & ~ .input-button > *:not(.input-disabled-click) {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const TagInputStyles = styled.input`
  transition: all 100ms;
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  background-color: transparent;

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}
 
  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.g500};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const TitleInputStyles = styled.input`
  transition: all 100ms;
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.5;
  background-color: transparent;

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}
 
  &::placeholder {
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.g300};
  }
`;

export const SearchInputStyles = styled.input`
  transition: all 100ms;
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 1.4rem;
  background-color: transparent;

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}
 
  &::placeholder {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.g300};
  }
`;

export const TextareaStyles = styled.textarea`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 100ms;
  border-radius: 0.3rem;
  border: 1px solid
    ${({ invalid, theme }) =>
      (invalid && theme.colors[(invalid === 'error' && 'red') || 'blue']) ||
      theme.colors.g200};
  width: 100%;
  font-family: inherit;
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  resize: none;
  overflow: hidden;

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}

  & ~ .input-button > .input-delete {
    display: none;
  }

  &:focus {
    border: 1px solid
      ${({ keyColor, theme }) => keyColor || theme.colors.keyColor};

    & ~ .input-button > .input-delete {
      display: inline-block;
    }
  }

  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.g300};
  }

  @media (hover: hover) and (pointer: fine) {
    & ~ .input-button > *:not(.input-disabled-click) {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const GalleryTextareaStyles = styled.textarea`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 100ms;
  border: none;
  border-bottom: 1px solid
    ${({ invalid, theme }) =>
      (invalid && theme.colors[(invalid === 'error' && 'red') || 'blue']) ||
      theme.colors.g200};
  width: 100%;
  font-family: inherit;
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  resize: none;
  overflow: hidden;

  ${common}
  ${space}
  ${borderElement}
  ${elementSize}
  ${flexContainer}
  ${flexElement}

  & ~ .input-button > .input-delete {
    display: none;
  }

  &:focus {
    border-bottom: 1px solid
      ${({ keyColor, theme }) => keyColor || theme.colors.keyColor};

    & ~ .input-button > .input-delete {
      display: inline-block;
    }
  }

  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.g300};
  }

  @media (hover: hover) and (pointer: fine) {
    & ~ .input-button > *:not(.input-disabled-click) {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
