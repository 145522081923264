import React, { Suspense, lazy } from 'react';
import { Skeleton } from 'components';

const GalleryCard1 = lazy(() => import('./GalleryCard1'));

const renderLoader = (height) => <Skeleton width="100%" height={height} />;

export function BoardGalleryCard1({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(250)}>
      <GalleryCard1
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}

const DefaultCard = lazy(() => import('./DefaultCard'));

export function BoardDefaultCard({
  module,
  item,
  itemClick,
  logging,
  ...props
}) {
  return (
    <Suspense fallback={renderLoader(250)}>
      <DefaultCard
        module={module}
        item={item}
        onClick={itemClick}
        logging={logging}
        {...props}
      />
    </Suspense>
  );
}
