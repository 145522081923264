import { HeaderLogo, HeaderLanguage } from 'components';
import { useLanguageNavigate } from 'hooks';
import React from 'react';
import { HeaderStyle } from 'styles/HeaderStyle';

export function HeaderLogin({ bucket, ...props }) {
  const { header, headerOption, logoImg, languages } = bucket;

  return (
    header && (
      <>
        <HeaderStyle
          display="flex"
          position={(header !== 'scroll' && 'fixed') || ''}
          top="0"
          left="0"
          height={60}
          width="100vw"
          borderBottom={{ color: 'g200' }}
          backgroundColor={headerOption.headerBgColor}
          transition="all 150ms"
          alignItems="center"
          justifyContent="space-between"
          padding="0 20px"
          zIndex={9000}
          {...props}
        >
          <HeaderLogo bucket={bucket} src={logoImg} zIndex={9999} />
          {languages?.length > 1 && (
            <HeaderLanguage
              type={parseInt(
                headerOption.headerType.replace(/[^0-9]/g, ''),
                10,
              )}
              bucket={bucket}
              fontColor={headerOption.headerFontColor}
              backgroundColor={headerOption.headerBgColor}
            />
          )}
        </HeaderStyle>
      </>
    )
  );
}
