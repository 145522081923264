import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UserChat from 'modules/UserListChat';
import { useCookieUtil } from 'hooks';
import UserlistPresenter from './UserlistPresenter';

function UserlistContainer({
  bucket,
  module,
  filters,
  setFilters,
  loadingRef,
  socket,
}) {
  const { getCookie } = useCookieUtil();
  const { user } = useSelector((state) => ({
    user: state.user.info,
  }));
  const [onMyInfo, setOnMyInfo] = useState(true);

  const [targetCard, setTargetCard] = useState();

  const onClickCard = (item) => {
    setTargetCard(item);
  };

  return (
    <>
      {!targetCard && (
        <UserlistPresenter
          module={module}
          user={user}
          loadingRef={loadingRef}
          filters={filters}
          onMyInfo={onMyInfo}
          setOnMyInfo={setOnMyInfo}
          onClickCard={onClickCard}
          targetCard={targetCard}
          setTargetCard={setTargetCard}
          getCookie={getCookie}
        />
      )}
      {targetCard && module?.designType === 'design2' && (
        <UserChat
          bucket={bucket}
          module={module}
          item={targetCard}
          back={() => {
            setTargetCard();
            setFilters({
              ...filters,
              page: 1,
              lastRowNum: 0,
              perPage: filters.perPage,
            });
          }}
          socket={socket}
        />
      )}
    </>
  );
}

export default UserlistContainer;
