import { Div, Col, Text, Button, Row } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { animations } from 'styles/css';
import { Dim } from './Dim';

export function Alert({
  title,
  content,
  confirm,
  close,
  cancel,
  confirmLabel,
  cancelLabel,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Dim onClick={close} overflowHidden>
        <Div
          position="fixed"
          zIndex={9999}
          backgroundColor="white"
          top="50%"
          left="50%"
          borderRadius="1.2rem"
          padding="30px 30px 20px 30px"
          animation={animations.slideUp()}
          width={320}
        >
          <Col gap={10}>
            {title && (
              <Col
                minHeight={(content && 'unset') || 43}
                alignItems="center"
                justifyContent="center"
              >
                <Text type="h6" textAlign="center">
                  {title}
                </Text>
              </Col>
            )}
            {content && (
              <Col minHeight={43} alignItems="center" justifyContent="center">
                <Text color="g500" textAlign="center">
                  {content}
                </Text>
              </Col>
            )}
          </Col>
          <Row mt={20} gap={10}>
            {cancel && (
              <Button ghost borderRadius="4px" onClick={cancel}>
                {cancelLabel || t('cancel')}
              </Button>
            )}
            {confirm && (
              <Button
                active
                width={(!cancel && '100%') || ''}
                keyColor="keyColor"
                keyFontColor="keyFontColor"
                onClick={confirm}
                loading={loading}
                borderRadius="4px"
              >
                {confirmLabel || t('confirm')}
              </Button>
            )}
          </Row>
        </Div>
      </Dim>
    </>
  );
}
