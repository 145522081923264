import { Row } from 'components';
import styled from 'styled-components';

export const PaginationStyles = styled.button`
  text-align: center;
  box-sizing: border-box;
  padding: 4px 4px 3px 4px;
  border-radius: 9px;
  font-size: 14px;
  line-height: 1;
  color: ${({ active, theme }) =>
    (active && theme.colors.keyColor) || theme.colors.g500};
  background-color: ${({ active, theme }) =>
    (active && theme.colors.key100) || 'transparent'};
  min-width: 28px;
  height: 28px;

  @media (hover: hover) and (pointer: fine) {
    &:not(:disabled):hover {
      opacity: 0.7;
    }
  }
`;
