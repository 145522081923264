import { Div, StreamingSideCard } from 'components';
import React from 'react';

export function SidePlayList({ list, user, changeStreaming }) {
  return (
    <Div
      width="100%"
      height="100%"
      overflowY="overlay"
      // padding="30px 20px 18px 20px"
    >
      {list?.map((item, index) => (
        <StreamingSideCard
          key={item.itemCode}
          user={user}
          item={item}
          mb={(list?.length - 1 !== index && 20) || 0}
          onClick={() => changeStreaming(item)}
        />
      ))}
    </Div>
  );
}
