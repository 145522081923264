import { useCountDown } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { LANGUAGE } from 'data/keyFile';
import PaymentResultPresenter from './PaymentResultPresenter';

function PaymentResultContainer({ bucket }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentLanguage = searchParams.get(LANGUAGE);
  const [isSuccess, setIsSuccess] = useState();

  const { count } = useCountDown();

  useEffect(() => {
    setIsSuccess(searchParams.get('isSuccess') === 'true');
  }, []);

  const goHome = () => {
    navigate(
      {
        pathname: '/',
        search: createSearchParams({
          [LANGUAGE]: currentLanguage,
        }).toString(),
      },
      { replace: true },
    );
  };

  return (
    <PaymentResultPresenter
      count={count}
      isSuccess={isSuccess}
      goHome={goHome}
    />
  );
}

export default PaymentResultContainer;
