import React, { useState } from 'react';
import { ReactComponent as ProfileIcon } from 'assets/icon/profile-edit.svg';
import { useTheme } from 'styled-components';
import { Image, Label } from 'components';
import { BUCKET_CODE, authApi } from 'api';
import { useCookieUtil } from 'hooks';
import { API_KEY, BUCKET_VERSION, UUID } from 'data/keyFile';

export function ProfileImageEdit({
  name,
  value,
  fileUpload,
  onChange,
  ...props
}) {
  const { getCookie } = useCookieUtil();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const onChangeHandler = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      reader.onloadend = async () => {
        if (!loading) {
          setLoading(true);
          const fd = new FormData();
          fd.append('file', file);
          fd.append('apiKey', getCookie(API_KEY));
          fd.append('uuid', getCookie(UUID));
          fd.append('bucketVersion', getCookie(BUCKET_VERSION));
          if (process.env.NODE_ENV !== 'production') {
            fd.append('bucketCode', BUCKET_CODE);
          }

          const [info] = await authApi.profileThumbnail(fd);
          if (info) {
            onChange(info.result.fileUrl, name);
            setLoading(false);
            event.target.value = '';
          } else {
            setLoading(false);
            event.target.value = '';
          }
        }
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  return (
    <Label htmlFor={name} {...props}>
      <input
        id={name}
        name={name}
        type="file"
        accept="image/*"
        readOnly
        value=""
        onChange={onChangeHandler}
        style={{ display: 'none' }}
      />
      {value && value.length !== 1 && (
        <Image
          src={value}
          width={80}
          height={80}
          borderRadius="999px"
          objectFit="cover"
        />
      )}
      {(!value || value.length === 1) && (
        <ProfileIcon fill={theme.colors.keyColor} />
      )}
    </Label>
  );
}
