import { moduleApi, detailApi, menuApi, reservationApi } from 'api';
import { Alert, Loading, ReservationCheck } from 'components';
import {
  useApiError,
  useApiParams,
  useBucketReset,
  useLanguageNavigate,
} from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setPage } from 'store/page';
import { ThemeProvider, useTheme } from 'styled-components';
import { makeTheme } from 'utils/css/makeTheme';
import { isEmptyObject } from 'utils/isEmptyObject';
import { isMobile } from 'utils/isMobile';
import DetailPresenter from './DetailPresenter';

function DetailContainer({
  close,
  type,
  bucket,
  headerCallback,
  modalDetailCode,
  setDetailModal,
  setFilters,
  ...props
}) {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguageNavigate();
  const { languageNavigate } = useLanguageNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [innerTheme, setInnerTheme] = useState({});
  const [loading, setLoading] = useState(true);
  const { logout, reload, needLogin, needProfile } = useBucketReset();
  const { menuCode, detailCode } = useParams();
  const itemCode = modalDetailCode || detailCode;
  const { DEFAULT_PARAMS } = useApiParams();

  const { user, page, module } = useSelector((state) => ({
    user: state.user.info,
    page: state.page.info,
    module: state.module.info,
  }));

  const getMenuInfo = async () => {
    setLoading(true);
    const [info, error] = await menuApi.menuInfo(menuCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      const { result } = info;
      if (result.isNecessaryInit === 1) {
        logout();
      } else if (result.isNecessaryReload === 1) {
        reload();
      } else if (result.isNecessaryLogin === 1) {
        needLogin();
      } else if (result.isNecessaryProfile === 1) {
        needProfile();
      } else {
        dispatch(setPage({ ...result, menuCode }));
      }
    } else if (error.resCode === 454) {
      // error page undefind
    } else {
      //
    }
  };

  const [item, setItem] = useState();
  const getDetail = async () => {
    const [info, error] = await detailApi.detailInfo(itemCode, {
      ...DEFAULT_PARAMS,
    });
    if (info?.resCode === 200) {
      const { result } = info;
      if (result.isNecessaryInit === 1) {
        logout();
      } else if (result.isNecessaryReload === 1) {
        reload();
      } else if (result.isNecessaryLogin === 1) {
        needLogin();
      } else if (result.isNecessaryProfile === 1) {
        needProfile();
      } else {
        setItem({ ...info.result });
        if (result.isUsedReservation === 1 && user.name) {
          getReservation(info.result);
        } else {
          setLoading(false);
        }
      }
    } else if (error) {
      if (error.resCode === 4542) {
        setAlert({
          title: t('alert_title'),
          content: t('api_error_4542'),
          confirm: () => {
            if (type !== 'modal') {
              languageNavigate(`/${menuCode}`);
            } else {
              setDetailModal();
            }
            setAlert();
          },
        });
      } else {
        setError(error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bucket && itemCode && page.menuCode === menuCode) {
      const { styles, headerOption } = page;
      const userTheme = { ...makeTheme(styles, theme) };
      setInnerTheme({
        ...theme,
        colors: {
          ...theme.colors,
          ...userTheme,
        },
      });
      getDetail();
      if (headerCallback) {
        headerCallback(headerOption);
      }
    } else if (bucket && (!page || page.menuCode !== menuCode)) {
      getMenuInfo();
    }
  }, [bucket, itemCode, page]);

  const { alert, setAlert, setError } = useApiError();
  const [actionbarLoading, setActionbarLoading] = useState(false);
  const logging = async (type, item) => {
    if (!actionbarLoading && user.name) {
      setActionbarLoading(true);
      const [info, error] = await moduleApi.moduleLogging(
        type,
        item.moduleCode,
        item.itemCode,
        DEFAULT_PARAMS,
      );
      if (info?.resCode === 200) {
        const { result } = info;
        setItem({
          ...item,
          ...result,
          likes:
            type === 'like'
              ? (result.isLike === 1 && item.likes + 1) || item.likes - 1
              : item.likes,
        });
        setActionbarLoading(false);
      } else if (error) {
        setActionbarLoading(false);
        setError(error);
      }
    } else if (!user.name) {
      setError({ resCode: 457 });
    }
  };

  const goList = () => {
    languageNavigate(`/${menuCode}`, currentLanguage);
  };

  const [commentLength, setCommentLength] = useState();
  const commentLengthCallback = useCallback((target) => {
    setCommentLength((commentLength && commentLength + 1) || target + 1);
  }, []);

  const reservation = () => {
    setAlert({
      title: t('alert_title'),
      content: t('item_completed_reservation'),
      confirm: () => setAlert(),
    });
  };

  const [reservationItem, setReservationItem] = useState();
  const getReservation = async (rItem) => {
    const [info, error] = await reservationApi.reservationInfo(
      rItem.moduleCode,
      rItem.itemCode,
      {
        ...DEFAULT_PARAMS,
      },
    );
    if (info?.resCode === 200) {
      const { result } = info;
      if (result.isNecessaryInit === 1) {
        logout();
      } else if (result.isNecessaryReload === 1) {
        reload();
      } else if (result.isNecessaryLogin === 1) {
        needLogin();
      } else if (result.isNecessaryProfile === 1) {
        needProfile();
      } else {
        setReservationItem({ ...result });
        if (
          !isMobile() &&
          result?.isAlreadyReservation === 0 &&
          result?.isConfirmedReservation === 0
        ) {
          setOpenReservation(true);
        }

        setLoading(false);
      }
    } else if (error) {
      setError(error);
      setLoading(false);
    }
  };

  const [openReservation, setOpenReservation] = useState(false);
  const [reservationValues, setReservationValues] = useState();

  const filterRequiredArray = () => {
    const requiredArray = reservationItem.list;

    const a = !requiredArray.find(
      (item) => !reservationValues || !reservationValues[item.itemCode],
    );

    const b = !isEmptyObject(reservationValues);

    return a && b;
  };

  const [reservationCheck, setReservationCheck] = useState(false);
  const reservationClick = async () => {
    if (!openReservation) {
      setOpenReservation(true);
    } else if (reservationValues && filterRequiredArray()) {
      setOpenReservation(false);
      setReservationCheck(true);
    }
  };

  const [done, setDone] = useState(false);
  const reservationDone = async () => {
    const [info] = await reservationApi.reservation(
      item.moduleCode,
      item.itemCode,
      { ...DEFAULT_PARAMS, selectionCodes: Object.values(reservationValues) },
    );
    if (info) {
      setOpenReservation(false);
      setDone(true);
    }
  };

  const reservationCancelWarning = () => {
    setAlert({
      title: t('alert_title'),
      content: t('reservation_cancel_warning'),
      confirm: () => reservationCancel(),
      cancel: () => setAlert(),
    });
  };

  const reservationCancel = async () => {
    const [info] = await reservationApi.cancel(item.moduleCode, item.itemCode, {
      ...DEFAULT_PARAMS,
    });
    if (info) {
      setAlert();
      getDetail();
      setDone(false);
      setOpenReservation(true);
      setReservationCheck(false);
      setReservationValues();
    }
  };

  const [searchParams] = useSearchParams();
  const deleteItem = async (itemCode) => {
    if (!loading) {
      setLoading(true);
      const [info] = await moduleApi.deleteModuleItem(
        item.moduleCode,
        itemCode,
        { ...DEFAULT_PARAMS },
      );
      if (info?.resCode === 200) {
        setLoading(false);
        setAlert();
        if (type !== 'modal') {
          languageNavigate(`/${menuCode}`);
        } else {
          setDetailModal();
          setFilters({
            page: searchParams.get('page') || 1,
            lastRowNum: searchParams.get('lastRowNum') || 0,
            perPage: 30,
          });
        }
      } else {
        setLoading(false);
        setAlert({
          title: t('alert_title'),
          content: t('delete_comment_error'),
          confirm: () => setAlert(),
          close: () => setAlert(),
        });
      }
    }
  };

  const deleting = (item) => {
    setAlert({
      title: t('alert_title'),
      content: t('delete_warning'),
      confirm: () => deleteItem(item.itemCode),
      cancel: () => setAlert(),
    });
  };

  const [targetEditItem, setTargetEditItem] = useState();

  return (
    <ThemeProvider theme={innerTheme}>
      <DetailPresenter
        type={type}
        t={t}
        bucket={bucket}
        isUsedTabMenu={bucket?.tabMenuInfo?.[currentLanguage]?.length > 0}
        page={page}
        module={module.moduleCode === item?.moduleCode && module}
        item={item}
        logging={logging}
        commentLength={commentLength}
        commentLengthCallback={commentLengthCallback}
        goList={goList}
        openReservation={openReservation}
        setOpenReservation={setOpenReservation}
        reservationItem={reservationItem}
        reservationCheck={reservationCheck}
        reservation={reservation}
        reservationCancel={reservationCancelWarning}
        reservationValues={reservationValues}
        setReservationValues={setReservationValues}
        reservationClick={reservationClick}
        setReservationCheck={setReservationCheck}
        reservationDone={reservationDone}
        done={done}
        getDetail={getDetail}
        deleting={deleting}
        targetEditItem={targetEditItem}
        setTargetEditItem={setTargetEditItem}
        setFilters={setFilters}
        setError={setError}
        {...props}
      />
      {reservationCheck && (
        <ReservationCheck
          type={type}
          reservationItem={reservationItem}
          reservationValues={reservationValues}
          close={() => {
            setReservationCheck(false);
            setReservationValues();
          }}
          reservation={reservationDone}
          done={done}
          getDetail={getDetail}
          display="none"
          mDisplay="flex"
        />
      )}
      {alert && <Alert {...alert} />}
      {loading && <Loading />}
    </ThemeProvider>
  );
}

export default DetailContainer;
