import { authApi } from 'api';
import { Row, Div, Input, CountryCode, Button } from 'components';
import { useApiParams, useLanguageNavigate } from 'hooks';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { encrypt } from 'utils/encrypt';

export function LoginCustomPassword({ loginToMain, info, setAlert, ...props }) {
  const { t } = useTranslation();
  const { languageNavigate } = useLanguageNavigate();
  const { TOKEN_PARAMS } = useApiParams();
  const [values, setValues] = useState({
    customKey: '',
    password: '',
  });

  const onChange = (value, name) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const active = useMemo(() => {
    if (!values?.customKey) return false;
    if (!values.password) return false;

    return true;
  }, [values]);

  const [loading, setLoading] = useState(false);
  const login = async () => {
    // 코드 인증
    if (active && !loading) {
      setLoading(true);
      const loginValue = values.customKey;
      const [info, error] = await authApi.login({
        ...TOKEN_PARAMS,
        password: encrypt(values.password),
        customKey: loginValue,
      });
      if (info) {
        const { result } = info;
        loginToMain(result.token, result.refreshCode);
      } else if (error?.resCode === 451) {
        setAlert({
          title: t('alert_title'),
          content: t('account_alert'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      } else if (error?.resCode === 4511) {
        setAlert({
          title: t('alert_title'),
          content: t('custom_account_alert'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      } else if (error?.resCode === 4513) {
        setAlert({
          title: t('alert_title'),
          content: t('fail_pre_registration_account'),
          confirm: () => setAlert(),
        });
        setLoading(false);
      } else if (error?.resCode === 461) {
        // setInvalids({
        //   ...invalids,
        //   password: { type: 'error', text: t('invalid_password') },
        // });
        setLoading(false);
      } else if (error) {
        // 451 : 인증코드 틀림
        // setInvalids({ type: 'error', text: t('invalid_code') });
        setLoading(false);
      }
    }
  };

  return (
    <Div>
      <Input
        label={info.firstInputTitle}
        onChange={onChange}
        name="customKey"
        placeholder={info.firstInputPlaceholder}
        value={(values && values.customKey) || ''}
        disabled={loading}
        onKeyDown={login}
        onKeyDownActive={active}
      />
      <Input
        label={info.secondInputTitle}
        placeholder={info.secondInputPlaceholder}
        wrapStyles={{ mt: 10 }}
        name="password"
        onChange={onChange}
        value={values.password}
        disabled={loading}
        onKeyDown={login}
        onKeyDownActive={active}
      />
      <Button
        buttonType="large"
        active={active}
        onClick={login}
        mt={20}
        display="block"
        margin="0 auto"
      >
        {t('login')}
      </Button>
    </Div>
  );
}
