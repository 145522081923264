import React from 'react';
import { Div } from 'components';

export function Toggle({ checked, onClick, ...props }) {
  return (
    <Div
      width={30}
      height={16}
      padding="1px"
      borderRadius="999px"
      backgroundColor={(checked && 'keyColor') || 'g500'}
      position="relative"
      onClick={onClick}
      hover
      cursor="pointer"
      {...props}
    >
      <Div
        position="absolute"
        borderRadius="999px"
        width={14}
        height={14}
        backgroundColor="keyFontColor"
        left={(checked && '50%') || '1px'}
        transition="all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550)"
        boxShadow="toggle"
      />
    </Div>
  );
}
