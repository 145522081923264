import React, { useEffect, useState } from 'react';

function useViewedItems(props) {
  const [item, setItem] = useState();
  const [isViewedThumbnail, setIsViewedThumbnail] = useState(false);
  const [isViewedLabel, setIsViewedLabel] = useState(false);
  const [isViewedName, setIsViewedName] = useState(false);
  const [isViewedDescription, setIsViewedDescription] = useState(false);
  const [isViewedTags, setIsViewedTags] = useState(false);
  const [isViewedCreatedDt, setIsViewedCreatedDt] = useState(false);
  const [isUsedContent, setIsUsedContent] = useState(false);

  useEffect(() => {
    setIsUsedContent(
      isViewedLabel ||
        isViewedName ||
        isViewedDescription ||
        isViewedTags ||
        isViewedCreatedDt,
    );
  }, [
    isViewedLabel,
    isViewedName,
    isViewedDescription,
    isViewedTags,
    isViewedCreatedDt,
  ]);

  // action bar items
  const [isViewedAttachmentFileBtn, setIsViewedAttachmentFileBtn] =
    useState(false);
  const [isViewedBookmarkBtn, setisViewedBookmarkBtn] = useState(false);
  const [isViewedLikeBtn, setIsViewedLikeBtn] = useState(false);
  const [isViewedCommentBtn, setisViewedCommentBtn] = useState(false);
  const [isUsedActionBar, setIsUsedActionBar] = useState(false);

  useEffect(() => {
    setIsUsedActionBar(
      isViewedAttachmentFileBtn ||
        isViewedBookmarkBtn ||
        isViewedLikeBtn ||
        isViewedCommentBtn,
    );
  }, [
    isViewedAttachmentFileBtn,
    isViewedBookmarkBtn,
    isViewedLikeBtn,
    isViewedCommentBtn,
  ]);

  const [isViewedNew, setIsViewedNew] = useState(false);
  const [isViewedStatus, setIsViewedStatus] = useState(false);

  const [isViewedCommentCount, setIsViewedCommentCount] = useState(false);
  const [isViewedCommentUserName, setIsViewedCommentUserName] = useState(false);
  const [isViewedCommentUserThumbnail, setIsViewedCommentUserThumbnail] =
    useState(false);

  const [isViewedUserLabel, setIsViewedUserLabel] = useState(false);
  const [isViewedUserName, setIsViewedUserName] = useState(false);
  const [isViewedUserThumbnail, setIsViewedUserThumbnail] = useState(false);
  const [isViewedClickCount, setisViewedClickCount] = useState(false);
  const [isMine, setIsMine] = useState(false);

  useEffect(() => {
    if (item) {
      setIsViewedThumbnail(
        !!(item?.thumbnail && item?.isViewedThumbnail === 1),
      );
      setIsViewedLabel(!!(item?.isViewedLabel !== 0 && item?.label));
      setIsViewedName(!!(item?.isViewedName !== 0 && item?.name));
      setIsViewedDescription(
        !!(item?.isViewedDescription !== 0 && item?.description),
      );

      setIsViewedTags(
        !!(
          item?.isViewedTags !== 0 &&
          (item?.tags?.length > 0 || item?.playItemTags?.length > 0)
        ),
      );
      setIsViewedCreatedDt(!!(item?.isViewedCreatedDt !== 0));
      setIsViewedAttachmentFileBtn(item?.isViewedAttachmentFileBtn !== 0);
      setisViewedBookmarkBtn(item?.isViewedBookmarkBtn !== 0);
      setIsViewedLikeBtn(item?.isViewedLikeBtn === 1);
      setisViewedCommentBtn(item?.isViewedCommentBtn === 1);
      setIsViewedNew(item?.isViewedNewLabel === 1 && item?.isNew === 1);
      setIsViewedStatus(item?.isViewedStatusLabel === 1);

      setIsViewedCommentCount(item?.isViewedCommentCount === 1);
      setIsViewedCommentUserName(item?.isViewedCommentUserName === 1);
      setIsViewedCommentUserThumbnail(item?.isViewedCommentUserThumbnail === 1);

      setIsViewedUserLabel(item?.isViewedUserLabel === 1);
      setIsViewedUserName(item?.isViewedUserName === 1);
      setIsViewedUserThumbnail(item?.isViewedUserThumbnail === 1);
      setisViewedClickCount(item?.isViewedClickCount === 1);
      setIsMine(item?.isMine === 1);
    }
  }, [item]);

  return {
    setItem,
    isViewedThumbnail,
    isViewedLabel,
    isViewedName,
    isViewedDescription,
    isViewedTags,
    isViewedCreatedDt,
    isUsedContent,
    isViewedAttachmentFileBtn,
    isViewedBookmarkBtn,
    isViewedLikeBtn,
    isViewedCommentBtn,
    isUsedActionBar,
    isViewedNew,
    isViewedStatus,
    isViewedCommentCount,
    isViewedCommentUserName,
    isViewedCommentUserThumbnail,
    isViewedUserLabel,
    isViewedUserName,
    isViewedUserThumbnail,
    isViewedClickCount,
    isMine,
  };
}

export default useViewedItems;
